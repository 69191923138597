import "./NewIdentification.css";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./RegistrationIdentification.css";
import { getDropdownValues } from "../../apis/dropdown";
import { UMU_URL } from "../../constants/config";
import { setResponse } from "../../store/slices/responseSlice";
import ReactCrop from "react-image-crop";
import { modifyWallet } from "../../apis/wallet";
import { decrypytdata } from "../../apis/walletKey";
import { setLoader } from "../../store/slices/loaderSlice";

import { hideLoaderFromElement, showLoaderOnElement } from "../../utils/loader";
import { useContext } from "react";
import { ModalOverlayContext } from "../../context/ModalOverlayContext";

export default function Identification() {
  const imageInput = useRef();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userData } = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.loader);
  const { toggleModal } = useContext(ModalOverlayContext);

  const {
    PublicAddress: APPID,
    PrivateKey: APIKEY,
    UserID,
    Firstname,
    Lastname,
    ImageFN,
    IdentificationType
  } = userData;

  const [identificationData, setIdentificationData] = useState({
    userDocumentTypecode: "",
    documentNumber: "",
    dateOfBirth: null,
    expirationDate: null,
    documentFN: ""
  });

  //   const [documentFN, setDocumentFN] = useState(null);

  const [userDocumentTypecodes, setUserDocumentTypecodes] = useState([]);
  const [validationError, setValidationError] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 80, y: 50, width: 100, height: 100 });
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);
  //   const [outputType, setOutputType] = useState(null);
  const [isImageUploadingOpen, setIsImageUploadingOpen] = useState(false);
  const [startDate, setStartDate] = useState("");

  const selectImage = (file) => {
    setSrc(URL.createObjectURL(file));
  };

  const cropImageNow = () => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64
    const base64Image = canvas.toDataURL("image/jpeg");
    setOutput(base64Image);
  };

  useEffect(() => {
    getDropdownValues("LKUserDocumentType").then((response) => {
      setUserDocumentTypecodes(() => response.data.commandDataset);

      setIdentificationData((prev) => ({
        ...prev,
        userDocumentTypecode: response.data.commandDataset[0].Keycode
      }));
    });
  }, []);

  function validate(data) {
    if (!data.documentNumber) {
      return "Document number is required";
    }

    if (!data.dateOfBirth) {
      return "Birthdate is required";
    }

    const currentDate = new Date();
    const selectedBirthDate = new Date(data.dateOfBirth);
    const selectedExpirationDate = new Date(data.expirationDate);

    if (selectedBirthDate > currentDate)
      return "Selecting future date for birthdate is not allowed";

    if (!data.expirationDate) return "Expiration date is required";

    if (selectedExpirationDate < currentDate)
      return "Selecting past date for expiration is not allowed";
  }

  async function uploadImageFNFile() {
    try {
      const blob = await fetch(output).then((res) => res.blob());
      const file = new File([blob], selectedFile.name, {
        type: selectedFile.type
      });

      const formData = new FormData();
      formData.append("File", file);
      formData.append("PublicAddress", APPID);
      formData.append("IsPublic", false);

      const response = await axios({
        baseURL: UMU_URL,
        url: "/general/UploadFile",
        method: "POST",
        data: formData,
        headers: {
          Authorization:
            "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
        }
      });

      // let filePath = null;
      const path = response?.data?.commandResponse;

      return path;
      /* const downloadFileResponse = await axios({
        baseURL: UMU_URL,
        url: "/general/DownloadFile",
        method: "POST",
        data: { Filepath: path },
        headers: {
          Authorization:
            "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
        }
      });

      filePath = downloadFileResponse.data?.commandResponse[0]?.presignedUrl;

      return filePath; */
    } catch (error) {
      throw error;
    }
  }

  const handleIdentificationData = async (e) => {
    try {
      setValidationError("");

      const data = {
        sourcePublicAddress: APPID,
        userDocumentTypecode: identificationData.userDocumentTypecode,
        documentNumber: identificationData.documentNumber || null,
        dateOfBirth: identificationData.dateOfBirth || null,
        expirationDate: identificationData.expirationDate || null
      };

      const error = validate(data);

      if (error) {
        setValidationError(error);

        return;
      }

      let path = null;

      // dispatch(setLoader(true));
      showLoaderOnElement(e.target);
      toggleModal(true);

      if (selectedFile) {
        path = await uploadImageFNFile();

        if (!path) {
          setValidationError("Failed to upload the file");
          return;
        }
      }

      // console.log(uploadResponse);

      // return;

      data.documentFN = path;

      // const data3 = {
      //   mobilePhone: "1234567890",
      //   userProfileTypecode: "Individual",
      //   mobileDialingcode: "+91",
      //   currencycode: "INR",
      //   mobileCountrycode: "IN",
      //   paymentCurrencycode: "UMU",
      //   currencycodeList: null,
      //   firstname: "ABC",
      //   secondFirstname: null,
      //   lastname: "PQR",
      //   secondLastname: null,
      //   fullname: "ABC PQR",
      //   emailAddress: "abcpqr@gmail.com",
      //   cashID: "abc1234",
      //   shortDescription: "abc1234",
      //   profileDescription: "abc1234",
      //   password: "12345678",
      //   userDocumentTypecode: "DL",
      //   documentNumber: "1234567890",
      //   dateOfBirth: "2000-01-01",
      //   expirationDate: "2023-02-28",
      //   documentFN: null
      // };
      // data3.documentFN = path;
      // console.log(data3);
      // return;

      const modifyResponse = await modifyWallet(APPID, APIKEY, {
        sourcePublicAddress: identificationData.sourcePublicAddress,
        ...data
        // ...data3
      });

      const decryptedModifyResponse = await decrypytdata(
        modifyResponse?.data,
        APIKEY
      );

      const responseObj = JSON.parse(decryptedModifyResponse);

      const responseAPI = {
        ...modifyResponse,
        headers: undefined,
        request: undefined,
        config: {
          baseURL: modifyResponse.config.baseURL,
          url: modifyResponse.config.url
        }
      };

      if (
        responseObj.ReturnKeycode === "Success" ||
        responseObj.returnKeycode === "Success"
      ) {
        /* if (data.firstname) {
          const newWalletData = {
            ...userData,
            Firstname: identificationData.firstname
          };

          localStorage.setItem("user", JSON.stringify(newWalletData));

          dispatch(setUser(newWalletData));
        } */

        dispatch(
          setResponse({
            apiResponse: responseAPI,
            apiData: responseObj
          })
        );
      } else {
        dispatch(setResponse({ apiResponse: responseAPI, apiData: null }));
      }

      navigate("/result");

      /* const response = await axios({
        method: "POST",
        baseURL: UMU_URL,
        url: `/general/RequestWallet`,
        data: data,

        headers: {
          Authorization:
            "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
        }
      });

      const responseAPI = {
        ...response,
        headers: undefined,
        request: undefined,
        config: { baseURL: response.config.baseURL, url: response.config.url }
      };

      const responseAPIData = JSON.parse(response.data.CommandResponse);

      dispatch(
        setResponse({
          apiResponse: responseAPI,
          apiData: responseAPIData
        })
      ); */
    } catch (error) {
      /* if (error.response) {
        const response = error.response;

        const responseAPI = {
          ...response,
          headers: undefined,
          request: undefined,
          config: { baseURL: response.config.baseURL, url: response.config.url }
        };

        dispatch(
          setResponse({ apiResponse: responseAPI, apiData: response.data })
        );

        return;
      } */

      console.error(error);
    } finally {
      // dispatch(setLoader(false));
      toggleModal(false);
      hideLoaderFromElement(e.target);
    }
  };

  const handleIdentificationDataChange = (e) => {
    const { name, value } = e.target;

    setIdentificationData((prev) => ({ ...prev, [name]: value }));
    setValidationError(() => "");
  };

  // console.log(identificationData);

  return (
    <div className="email-address p-0">
      {isImageUploadingOpen ? (
        <div className="modal show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-sound btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    imageInput.current.value = null;
                    setSelectedFile(null);
                    setIsImageUploadingOpen(false);
                    setSrc(null);
                    setImage(null);
                    setOutput(null);
                    setCrop();
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <div>
                  {src && (
                    <div className="d-flex justify-content-center align-items-center">
                      <ReactCrop
                        src={src}
                        onImageLoaded={setImage}
                        crop={crop}
                        onChange={setCrop}
                        style={{ height: "225px" }}
                      />
                    </div>
                  )}
                </div>

                <div className="my-3 d-flex justify-content-center align-items-center">
                  {output && <img src={output} />}
                </div>

                <div
                  className="d-flex justify-content-around align-items-center"
                  style={{ borderTop: "1px solid #000", padding: "5px" }}
                >
                  <button
                    className="btn-sound btn"
                    style={{
                      backgroundColor: "#3361a3",
                      color: "#fff"
                    }}
                    onClick={cropImageNow}
                  >
                    CROP
                  </button>
                  <button
                    className="btn-sound btn"
                    style={{
                      backgroundColor: "#3361a3",
                      color: "#fff"
                    }}
                    onClick={() => {
                      setIsImageUploadingOpen(false);
                      setSrc(null);
                      setImage(null);
                      // setOutput(null);
                      setCrop();
                    }}
                  >
                    UPLOAD
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div
              style={{
                width: "100%",
                position: "absolute",
                top: "50%",
                // left: "50%",
                // transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                marginTop: "30px",
                padding: "12px"
              }}
            >
              <center>
                
                <br />
                <br />
            
              </center>
            </div> */}
        </div>
      ) : null}

      <div className="identification">
        <div className="container">
          <div className="header">
            <h3>Identification</h3>
            <Link
              to="/term_purchase_add"
              title="Term Purchase"
              className="arrow-bg no_arrow_bg"
            >
              <img src="icons\term-light.png" style={{ width: "25px" }} />
            </Link>
          </div>

          {!isLoading && (
            <>
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li
                  onClick={() => {
                    setIdentificationData((prev) => ({
                      ...prev,
                      userDocumentTypecode: userDocumentTypecodes[0]?.Keycode
                    }));
                  }}
                  className="nav-item"
                  role="presentation"
                >
                  <button
                    className="btn-sound nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    <img
                      src="/icons/passport-light.png"
                      className="light"
                      alt="light-icons"
                    />
                    <img
                      src="/icons/passport-dark.png"
                      className="dark"
                      alt="dark-icons"
                    />
                    Passport
                  </button>
                </li>

                <li
                  onClick={() => {
                    setIdentificationData((prev) => ({
                      ...prev,
                      userDocumentTypecode: userDocumentTypecodes[1]?.Keycode
                    }));
                  }}
                  className="nav-item"
                  role="presentation"
                >
                  <button
                    className="btn-sound nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="false"
                  >
                    <img
                      src="/icons/driver-license-light.png"
                      className="light mb-2"
                      alt="light-icons"
                    />
                    <img
                      src="/icons/driver-license-dark.png"
                      className="dark mb-2"
                      alt="dark-icons"
                    />
                    Driver's License
                  </button>
                </li>

                <li
                  onClick={() => {
                    setIdentificationData((prev) => ({
                      ...prev,
                      userDocumentTypecode: userDocumentTypecodes[2]?.Keycode
                    }));
                  }}
                  className="nav-item"
                  role="presentation"
                >
                  <button
                    className="btn-sound nav-link"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="false"
                  >
                    <img
                      src="/icons/government-light.png"
                      className="light"
                      alt="light-icons"
                    />
                    <img
                      src="/icons/government-dark.png"
                      className="dark"
                      alt="dark-icons"
                    />
                    Government ID
                  </button>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                {validationError ? (
                  <p className="text-danger">{validationError}</p>
                ) : null}

                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div>
                    <div className="row">
                      <div className="col-12 mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="documentNumber"
                          placeholder="Document Number"
                          value={identificationData.documentNumber}
                          onChange={handleIdentificationDataChange}
                        />
                      </div>
                      {/* <div className="col-6 mb-4">
                      <input
                        type="text"
                        className="form-control"
                        name="firstname"
                        placeholder="First Name"
                        value={identificationData.firstname}
                        onChange={handleIdentificationDataChange}
                      />
                    </div>
                    <div className="col-6 mb-4">
                      <input
                        type="text"
                        className="form-control"
                        name="secondname"
                        placeholder="Second Name"
                        value={identificationData.secondname}
                        onChange={handleIdentificationDataChange}
                      />
                    </div>
                    <div className="col-6 mb-4">
                      <input
                        type="text"
                        className="form-control"
                        name="lastname"
                        placeholder="Last Name"
                        value={identificationData.lastname}
                        onChange={handleIdentificationDataChange}
                      />
                    </div>
                    <div className="col-6 mb-4">
                      <input
                        type="text"
                        className="form-control"
                        name="secondLastname"
                        placeholder="Second Last Name"
                        value={identificationData.secondLastname}
                        onChange={handleIdentificationDataChange}
                      />
                    </div> */}
                      {/* <div className="col-12 mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="dateOfBirth"
                          placeholder="Date of Birth"
                          onFocus={(e) => {
                            e.target.type = "date";
                          }}
                          onBlur={(e) => {
                            console.log(e.target.value);
                            if (!e.target.value) {
                              e.target.type = "text";
                            }
                          }}
                          value={identificationData.dateOfBirth}
                          onChange={handleIdentificationDataChange}
                        />
                      </div> */}

                      <div className="col-12 mb-4">
                        <DatePicker
                          showIcon
                          // isClearable
                          className="form-control"
                          selected={identificationData.dateOfBirth}
                          onChange={(date, e) => {
                            setIdentificationData((prev) => {
                              return { ...prev, dateOfBirth: date };
                            });
                          }}
                          placeholderText="Date of Birth"
                        />
                      </div>

                      {/* <div className="col-12 mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="expirationDate"
                          placeholder="Expiration Date"
                          onFocus={(e) => {
                            e.target.type = "date";
                          }}
                          onBlur={(e) => {
                            if (!e.target.value) {
                              e.target.type = "text";
                            }
                          }}
                          value={identificationData.expirationDate}
                          onChange={handleIdentificationDataChange}
                        />
                      </div> */}

                      <div className="col-12 mb-4">
                        <DatePicker
                          showIcon
                          // isClearable
                          className="form-control"
                          selected={identificationData.expirationDate}
                          onChange={(date, e) => {
                            setIdentificationData((prev) => {
                              return { ...prev, expirationDate: date };
                            });
                          }}
                          placeholderText="Expiration Date"
                        />
                      </div>

                      <div className="col-12">
                        <div className="upload-box">
                          <span>
                            {selectedFile
                              ? selectedFile.name
                              : "Upload your ID photo"}
                          </span>
                          {!selectedFile && (
                            <i className="bi bi-cloud-upload ms-2"></i>
                          )}
                          <input
                            ref={imageInput}
                            className="form-control-md"
                            id="formFileLg"
                            placeholder="Upload your ID photo"
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              if (e.target.files[0]) {
                                // setDocumentFN(e.target.files[0]);
                                setOutput(null);
                                setSelectedFile(e.target.files[0]);
                                // setOutputType(e.target.files[0].type);
                                selectImage(e.target.files[0]);
                                setIsImageUploadingOpen(true);
                              }
                            }}
                          />
                          {selectedFile && (
                            <i
                              class="bi bi-x-lg close-icon"
                              onClick={() => {
                                imageInput.current.value = null;
                                setSelectedFile(null);
                                setSrc(null);
                                setImage(null);
                                setOutput(null);
                                setCrop();
                              }}
                            ></i>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="create-payee-page text-center mt-4 d-flex align-items-center">
                      <button
                        type="button"
                        className="btn-sound btn next-btn next-btn-border me-3 mt-0"
                        onClick={() => navigate(-1)}
                      >
                        CANCEL
                      </button>

                      <button
                        type="button"
                        className="btn-sound next-btn mt-0"
                        data-color="#3361a3"
                        data-text="SUBMIT"
                        onClick={(e) => {
                          handleIdentificationData(e);
                        }}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
