import { createContext, useState } from "react";

export const ModalOverlayContext = createContext(null);

export default function ModalOverlayContextProvider({ children }) {
  const [isModalOverlayOpen, setIsModalOverlayOpen] = useState(false);

  function toggleModal(val) {
    setIsModalOverlayOpen(val);
  }

  return (
    <ModalOverlayContext.Provider value={{ isModalOverlayOpen, toggleModal }}>
      {children}
    </ModalOverlayContext.Provider>
  );
}
