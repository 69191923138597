import "./Error.css";
import { Link, useNavigate } from "react-router-dom";

export default function Error() {
  const navigate = useNavigate();

  return (
    <div className="email-address p-0 identification">
      <div className="container">
        <div className="header">
          <Link
            /* to="/splash" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>Error</h3>
        </div>
        <div className="error-conten">
          <img src="/icons/alert-triangle.png" alt="" />
          <p>
            Ops, the Create Payee information you have entered is not found.
          </p>
        </div>
      </div>
    </div>
  );
}
