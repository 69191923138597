import "./EscrowConfirm.css";
import { Link, useNavigate } from "react-router-dom";

export default function EscrowConfirm() {
  const navigate = useNavigate();

  return (
    <div className="email-address p-0 identification">
      <div className="container">
        <div className="header">
          <Link
            /* to="/splash" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>Confirm Escrow</h3>
        </div>
        <div className="summary-box escrow-information">
          <div className="summary-box-logo">
            <img src="/images/summary-logo.png" alt="logo" />
          </div>
          <h1 className="text-center">Escrow Information</h1>
          <div className="summary-list mb-3">
            <p>Escrow Tyoe</p>
            <h5>Product</h5>
          </div>
          <div className="summary-list mb-3">
            <p>Escrow Amount</p>
            <h5>
              <span>$</span> 2,333.00 USD
            </h5>
          </div>
          <div className="summary-list mb-3">
            <p>Escrow Short Description</p>
            <h5>
              <span>$</span> 2,333.00 USD
            </h5>
          </div>
          <div className="summary-list mb-3">
            <p>Escrow Long Description</p>
            <h5> Coffee purchased to be delivered by Freight on 11.22.33.</h5>
          </div>
          <div className="summary-list mb-3">
            <p>Target Completion Date</p>
            <h5>12/22/33</h5>
          </div>
          <div className="summary-list">
            <p>Purchase Agreement</p>
            <img src="/images/default.png" alt="default-img" />
          </div>
        </div>
        <div className="text-center">
          <Link className="next-btn w-100 mt-4 next-btn-last" to="/">
            <span>SUBMIT</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
