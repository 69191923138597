import "./RegistrationCashId.css";
import { useDebugValue, useEffect, useState } from "react";
import axios from "axios";
import { UMU_URL, UMU_SITE } from "../../constants/config";
import { decrypytdata } from "../../apis/walletKey";
import { useDispatch, useSelector } from "react-redux";
import { modifyWallet } from "../../apis/wallet";
import { setResponse } from "../../store/slices/responseSlice";
import { Link, useNavigate } from "react-router-dom";
import { setLoader } from "../../store/slices/loaderSlice";

export default function WalletCashIdLink() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: userData } = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.loader);

  const {
    PublicAddress: APPID,
    PrivateKey: APIKEY,
    CashID: userCashId
  } = userData;

  // const [isLoading, setIsLoading] = useState(false);
  const [isCashIdSubmiting, setIsCashIdSubmiting] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [popup, setPopup] = useState({
    success: false,
    show: false
  });

  const [cashData, setCashData] = useState({
    cashID: "",
    shortDescription: "",
    profileDescription: ""
  });

  const { cashID, profileDescription, shortDescription } = cashData;
  useEffect(() => {
    setValidationError("");
  }, [cashID, profileDescription, shortDescription]);

  function cashidValidation() {
    if (!cashData.cashID) return "CashID is required";
    if (cashData.cashID.length < 2 || cashData.cashID.length > 16)
      return "CashID should be Minimum 2 Maximum 16 characters long.";
  }

  async function validateCashId() {
    const error = cashidValidation();
    if (error) {
      setValidationError(error);
      return;
    }

    try {
      dispatch(setLoader(true));
      setValidationError("");

      const response = await axios.post(
        `${UMU_URL}/general/ValidateCashID`,
        {
          cashID: cashData.cashID
        },
        {
          headers: {
            Authorization:
              "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
          }
        }
      );

      if (
        response.data.ReturnKeycode === "Success" ||
        response.data.returnKeycode === "Success"
      ) {
        setPopup({ success: true, show: true });
        setIsVerified(true);
      }
    } catch (error) {
      setPopup({ success: false, show: true });
    } finally {
      dispatch(setLoader(false));
    }
  }

  async function handleCashIdSubmit() {
    try {
      setValidationError("");

      if (!isVerified) {
        setValidationError("Please verify cash id");
        return;
      }

      dispatch(setLoader(true));

      const addCashIdResponse = await modifyWallet(APPID, APIKEY, {
        ...cashData,
        shortDescription: cashData.shortDescription || null,
        profileDescription: cashData.profileDescription || null
      });

      const decryptedAddCashIdResponse = await decrypytdata(
        addCashIdResponse.data,
        APIKEY
      );

      const responseObj = JSON.parse(decryptedAddCashIdResponse);

      console.log(responseObj);

      const responseAPI = {
        ...addCashIdResponse,
        headers: undefined,
        request: undefined,
        config: {
          baseURL: addCashIdResponse.config.baseURL,
          url: addCashIdResponse.config.url
        }
      };

      if (
        responseObj.ReturnKeycode === "Success" ||
        responseObj.returnKeycode === "Success"
      ) {
        // const responseAPIData = JSON.parse(responseObj.CommandResponse);

        dispatch(
          setResponse({
            apiResponse: responseAPI,
            apiData: responseObj
          })
        );
      } else {
        dispatch(setResponse({ apiResponse: responseAPI, apiData: null }));
      }

      navigate("/result");
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoader(false));
    }
  }

  /* console.log(userCashId); */

  return (
    <>
      <div className="email-address py-0">
        <div className="container">
          <div className="email-content identification">
            <div className="header mb-4">
              <h3 className="ms-4">Your Cash ID Page</h3>
              <div title="View ID">
                <Link className="arrow-bg" to={`/cashID/${userCashId}`}>
                  <i className="bi bi-eye-fill"></i>
                </Link>
              </div>
            </div>
            <p>
              Enter an easy to remember public cash identifier to receive money.
            </p>

            {isLoading ? (
              <></>
            ) : (
              <>
                <div className="identification mt-5">
                  {validationError && (
                    <p className="text-danger">{validationError}</p>
                  )}
                  <div className=" create-payee-page">
                    <div className="bank">
                      <div className="add">
                        <input
                          type="text"
                          className="form-control mt-4"
                          placeholder="Cash ID"
                          id="CashIDinput"
                          value={cashData.cashID}
                          onChange={(e) => {
                            setCashData((prev) => ({
                              ...prev,
                              cashID: e.target.value
                            }));
                          }}
                          disabled={isVerified}
                        />
                        <button
                          className="btn-sound add-btn border-0"
                          onClick={() => validateCashId()}
                        >
                          VERIFY
                        </button>
                      </div>
                      {/* <select
                    className="mt-4 form-select"
                    aria-label="Default select example"
                  >
                    <option>Industry</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select> */}

                      <input
                        type="text"
                        className="form-control mt-4"
                        placeholder="Industry or Profession (Optional)"
                        value={cashData.shortDescription}
                        onChange={(e) => {
                          setCashData((prev) => ({
                            ...prev,
                            shortDescription: e.target.value
                          }));
                        }}
                      />

                      <div>
                        <textarea
                          className="form-control mt-4"
                          placeholder="Profile Description (Optional)"
                          rows="3"
                          value={cashData.profileDescription}
                          onChange={(e) => {
                            setCashData((prev) => ({
                              ...prev,
                              profileDescription: e.target.value
                            }));
                          }}
                        ></textarea>
                      </div>

                      <div className="text-center mt-5 d-flex align-items-center">
                        {/* <button
                      type="button"
                      className="btn next-btn next-btn-border me-3 mt-0"
                      onClick={() => {
                        navigate("/password", {
                          state: { ...location.state, ...cashData },
                          replace: true
                        });
                      }}
                    >
                      SKIP
                    </button> */}

                        <button
                          type="button"
                          className="btn-sound next-btn mt-0"
                          onClick={handleCashIdSubmit}
                        >
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {popup.show ? (
                  <div className="popup-model">
                    {popup.show && popup.success ? (
                      <div className="modal-box">
                        <div className="icons-fixed">
                          <img src="/icons/success-icon.png" alt="icon" />
                        </div>
                        <h2>Your Cash ID</h2>
                        <div className="modal-list">
                          <h5>
                            {UMU_SITE}/cashID/
                            <br />
                            {cashData.cashID}
                          </h5>
                        </div>
                        <div className="d-flex align-items-center w-100 mt-3">
                          <button
                            className="btn-sound modal-btn me-3 w-50 justify-content-center"
                            onClick={() => {
                              setPopup({ show: false, success: false });
                            }}
                          >
                            CLOSE
                          </button>
                          <button
                            className="btn-sound modal-btn fill w-50 justify-content-center"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${UMU_SITE}/cashID/${cashData.cashID}`
                              );

                              setIsVerified(true);
                              setPopup({ show: false, success: false });
                            }}
                          >
                            COPY
                          </button>
                        </div>
                      </div>
                    ) : null}

                    {popup.show && !popup.success ? (
                      <div className="modal-box">
                        <div className="icons-fixed">
                          <img src="/icons/fail.png" alt="icon" />
                        </div>
                        <h2>Cash ID is taken</h2>
                        <div className="text-center mt-3 mb-5">
                          <h6>Try another cash ID</h6>
                        </div>
                        <div className="d-flex align-items-center w-100 mt-3">
                          <button
                            className="btn-sound modal-btn fill w-100 justify-content-center"
                            onClick={() =>
                              setPopup({ show: false, success: false })
                            }
                          >
                            CLOSE
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
