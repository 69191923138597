import "./CreateMilestone";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { setLoader } from "../../store/slices/loaderSlice";
import { UMU_URL } from "../../constants/config";
import DatePicker from "react-datepicker";
import { getDropdownValues } from "../../apis/dropdown";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import { hideLoaderFromElement, showLoaderOnElement } from "../../utils/loader";
import { useContext } from "react";
import { ModalOverlayContext } from "../../context/ModalOverlayContext";

export default function CreateMilestone() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { toggleModal } = useContext(ModalOverlayContext);
  const { data: userData } = useSelector((state) => state.user);

  const [escrowType, setEscrowType] = useState("PRODUCT");
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [milestoneTypes, setMilestoneTypes] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(null);
  const [validationError, setValidationError] = useState("");

  const { PublicAddress: APPID, PrivateKey: APIKEY } = userData;

  const { SourcePublicAddress, item } = location.state;
  console.log(location.state);

  async function getMilestoneTypes() {
    try {
      const response = await getDropdownValues("LKMilestoneType");
      setMilestoneTypes(response.data.commandDataset || []);
      setEscrowType(response.data.commandDataset[0]);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    if (milestoneTypes.length > 0) {
      setEscrowType(milestoneTypes[0].Keycode);
    }
    if (item.isModify) {
      setEscrowType(item.TransactionTypecode);
      setShortDescription(item.Displayname);
      setLongDescription(item.Milestone);
      setExpectedDeliveryDate(Date.parse(item.ExpectedDeliveryDate));
    }
  }, [milestoneTypes]);
  useEffect(() => {
    const main = async () => {
      await getMilestoneTypes();
    };

    main();
  }, []);

  function validate() {
    if (!shortDescription) return "Short Description is required";
    if (!longDescription) return "Long Description is required";
    if (!expectedDeliveryDate) {
      return "Expected Delivery Date is required";
    }
    return null;
  }

  async function handleUploadFile() {
    if (selectedFile) {
      /* const file = await convertToBase64(selectedFile);
    
          console.log(selectedFile); */

      const formData = new FormData();
      formData.append("File", selectedFile);
      formData.append("PublicAddress", APPID);
      formData.append("IsPublic", false);

      const response = await axios({
        baseURL: UMU_URL,
        url: "/general/UploadFile",
        method: "POST",
        data: formData,
        headers: {
          Authorization:
            "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
        }
      });

      // console.log(response.data);

      return response.data.commandResponse;
    }
  }

  async function handleCreateMilestone(e) {
    const error = validate();
    if (error) {
      setValidationError(error);
      return;
    }
    try {
      // dispatch(setLoader(true));
      showLoaderOnElement(e.target);
      toggleModal(true);

      let uploadedFilePath = null;
      let downloadedFile = null;
      if (selectedFile) {
        const uploadResponse = await handleUploadFile();
        uploadedFilePath = uploadResponse;
      }
      console.log(location.state);
      let data = {
        sourcePublicAddress: APPID,
        milestoneTypecode: escrowType,
        settlementCurrencycode: item.SettlementCurrencycode,
        settlementUnits: 0,
        shortDescription: shortDescription,
        longDescription: longDescription,

        // "referenceNumber": ,
        // "documentFN": ,
        // "comment": ,

        expectedDeliveryDate: new Date(expectedDeliveryDate)
      };
      if (uploadedFilePath) {
        // data.documentPdf = "";
        data = { ...data, documentFN: uploadedFilePath };
      }

      navigate("/create_milestone_summary", {
        state: {
          Escrow_Summary: location.state.Escrow_Summary,
          item: location.state.item,
          milestone_summarypage: data
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      toggleModal(false);
      hideLoaderFromElement(e.target);
      // dispatch(setLoader(false));
    }
  }
  //   console.log(location.state)
  return (
    <div className="identification">
      <div className="container">
        <div className="header">
          <Link
            /* to="/activity" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>{item.isModify ? "Modify" : "Create"} Milestone</h3>
        </div>
        <div className="create-payee-page">
          <p>Provide the following details for this Milestone.</p>
          {location.state.Escrow_Summary.Escrow_transtionTypeCode ==
            ("PRODUCT" || "Product") && (
            <div className="row">
              <div className="col-4 mb-4" id="Agreement">
                <button
                  className={`btn-sound payee-box ${
                    escrowType === "Agreement" ? "active" : ""
                  }`}
                  onClick={() => !item.isModify && setEscrowType("Agreement")}
                >
                  <img
                    src="/icons/agreement-dark.png"
                    className="dark"
                    alt="dark-icon"
                  />
                  <img
                    src="/icons/agreement-light.png"
                    className="light"
                    alt="light-icon"
                  />
                  <h5>Agreement</h5>
                </button>
              </div>
              <div className="col-4 mb-4" id="Product">
                <button
                  className={`btn-sound payee-box ${
                    escrowType === "Product" ? "active" : ""
                  }`}
                  onClick={() => !item.isModify && setEscrowType("Product")}
                >
                  <img
                    src="/icons/product-dark.png"
                    className="dark"
                    alt="dark-icon"
                  />
                  <img
                    src="/icons/product-light.png"
                    className="light"
                    alt="light-icon"
                  />
                  <h5>Product</h5>
                </button>
              </div>
              <div className="col-4 mb-4" id="Shipping">
                <button
                  className={`btn-sound payee-box ${
                    escrowType === "Shipment" ? "active" : ""
                  }`}
                  onClick={() => !item.isModify && setEscrowType("Shipment")}
                >
                  <img
                    src="/icons/shipping-dark.png"
                    className="dark"
                    alt="dark-icon"
                  />
                  <img
                    src="/icons/shipping-light.png"
                    className="light"
                    alt="light-icon"
                  />
                  <h5>Shipping</h5>
                </button>
              </div>
              <div className="col-4 mb-4" id="Delivery">
                <button
                  className={`btn-sound payee-box ${
                    escrowType === "Delivery" ? "active" : ""
                  }`}
                  onClick={() => !item.isModify && setEscrowType("Delivery")}
                >
                  <img
                    src="/icons/delivery-dark.png"
                    className="dark"
                    alt="dark-icon"
                  />
                  <img
                    src="/icons/delivery-light.png"
                    className="light"
                    alt="light-icon"
                  />
                  <h5>Delivery</h5>
                </button>
              </div>
              <div className="col-4 mb-4" id="Inspection">
                <button
                  className={`btn-sound payee-box ${
                    escrowType === "Inspection" ? "active" : ""
                  }`}
                  onClick={() => !item.isModify && setEscrowType("Inspection")}
                >
                  <img
                    src="/icons/inspection-dark.png"
                    className="dark"
                    alt="dark-icon"
                  />
                  <img
                    src="/icons/inspection-light.png"
                    className="light"
                    alt="light-icon"
                  />
                  <h5>Inspection</h5>
                </button>
              </div>
              <div className="col-4 mb-4" id="BOL">
                <button
                  className={`btn-sound payee-box ${
                    escrowType === "BOL" ? "active" : ""
                  }`}
                  onClick={() => !item.isModify && setEscrowType("BOL")}
                >
                  <img
                    src="/icons/other-dark.png"
                    className="dark"
                    alt="dark-icon"
                  />
                  <img
                    src="/icons/other-light.png"
                    className="light"
                    alt="light-icon"
                  />
                  <h5>Other</h5>
                </button>
              </div>
            </div>
          )}
          {location.state.Escrow_Summary.Escrow_transtionTypeCode ==
            "SERVICE" && (
            <div className="row">
              <div className="col-4 mb-4">
                <button
                  className={`btn-sound payee-box ${
                    escrowType === "Task1" ? "active" : ""
                  }`}
                  onClick={() => !item.isModify && setEscrowType("Task1")}
                >
                  <img
                    src="/icons/task-1-dark.png"
                    className="dark"
                    alt="dark-icon"
                    style={{ width: "25px" }}
                  />
                  <img
                    src="/icons/task-1-light.png"
                    className="light"
                    style={{ width: "25px" }}
                    alt="light-icon"
                  />
                  <h5>Task 1</h5>
                </button>
              </div>
              <div className="col-4 mb-4">
                <button
                  className={`btn-sound payee-box ${
                    escrowType === "Task2" ? "active" : ""
                  }`}
                  onClick={() => !item.isModify && setEscrowType("Task2")}
                >
                  <img
                    src="/icons/task-2-dark.png"
                    className="dark"
                    style={{ width: "25px" }}
                    alt="dark-icon"
                  />
                  <img
                    src="/icons/task-2-light.png"
                    className="light"
                    style={{ width: "25px" }}
                    alt="light-icon"
                  />
                  <h5>Task 2</h5>
                </button>
              </div>
              <div className="col-4 mb-4">
                <button
                  className={`btn-sound payee-box ${
                    escrowType === "Task3" ? "active" : ""
                  }`}
                  onClick={() => !item.isModify && setEscrowType("Task3")}
                >
                  <img
                    src="/icons/task-3-dark.png"
                    className="dark"
                    style={{ width: "25px" }}
                    alt="dark-icon"
                  />
                  <img
                    src="/icons/task-3-light.png"
                    className="light"
                    style={{ width: "25px" }}
                    alt="light-icon"
                  />
                  <h5>Task 3</h5>
                </button>
              </div>
              <div className="col-4 mb-4">
                <button
                  className={`btn-sound payee-box ${
                    escrowType === "Task4" ? "active" : ""
                  }`}
                  onClick={() => !item.isModify && setEscrowType("Task4")}
                >
                  <img
                    src="/icons/task-4-dark.png"
                    className="dark"
                    style={{ width: "25px" }}
                    alt="dark-icon"
                  />
                  <img
                    src="/icons/task-4-light.png"
                    className="light"
                    style={{ width: "25px" }}
                    alt="light-icon"
                  />
                  <h5>Task 4</h5>
                </button>
              </div>
              <div className="col-4 mb-4">
                <button
                  className={`btn-sound payee-box ${
                    escrowType === "Task5" ? "active" : ""
                  }`}
                  onClick={() => !item.isModify && setEscrowType("Task5")}
                >
                  <img
                    src="/icons/task-5-dark.png"
                    className="dark"
                    style={{ width: "25px" }}
                    alt="dark-icon"
                  />
                  <img
                    src="/icons/task-5-light.png"
                    className="light"
                    style={{ width: "25px" }}
                    alt="light-icon"
                  />
                  <h5>Task 5</h5>
                </button>
              </div>
              <div className="col-4 mb-4">
                <button
                  className={`btn-sound payee-box ${
                    escrowType === "Task6" ? "active" : ""
                  }`}
                  onClick={() => !item.isModify && setEscrowType("Task6")}
                >
                  <img
                    src="/icons/task-6-dark.png"
                    className="dark"
                    style={{ width: "25px" }}
                    alt="dark-icon"
                  />
                  <img
                    src="/icons/task-6-light.png"
                    className="light"
                    style={{ width: "25px" }}
                    alt="light-icon"
                  />
                  <h5>Task 6</h5>
                </button>
              </div>
            </div>
          )}
          <p className="text-danger">{validationError}</p>
          <div className="row">
            <div className="col-12 mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Milestone Short Description"
                value={shortDescription}
                onChange={(e) => setShortDescription(e.target.value)}
              />
            </div>
            <div className="col-12 mb-4">
              <textarea
                className="form-control"
                placeholder="Milestone Long Description"
                rows="3"
                value={longDescription}
                onChange={(e) => setLongDescription(e.target.value)}
              ></textarea>
            </div>

            <div className="col-12 mb-4">
              <DatePicker
                showIcon
                className="form-control"
                selected={expectedDeliveryDate}
                onChange={(date, e) => {
                  console.log(date);
                  setExpectedDeliveryDate(date);
                }}
                placeholderText="Target Completion Date"
              />
            </div>
            <div className="col-12">
              <div className="upload-box product-upload">
                <span>
                  {selectedFile
                    ? selectedFile.name
                    : "Upload Milestone Agreement (optional)"}
                </span>
                <i className="bi bi-cloud-upload ms-2"></i>
                <input
                  className="form-control-md"
                  id="formFileLg"
                  placeholder="Upload your ID photo"
                  type="file"
                  accept="application/pdf, image/*, application/msword"
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      setSelectedFile(e.target.files[0]);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <button
            className="btn-sound btn next-btn mt-5"
            data-color="#3361a3"
            data-text="CONTINUE"
            onClick={(e) => handleCreateMilestone(e)}
          >
            CONTINUE
          </button>
        </div>
      </div>
      <ReactTooltip
        anchorId="Agreement"
        place="bottom"
        content="Purchase Agreement Signing"
      />
      <ReactTooltip
        anchorId="Product"
        place="bottom"
        content="Proof of Product Inspection"
      />
      <ReactTooltip
        anchorId="Shipping"
        place="bottom"
        content="Proof of Shipment"
      />
      <ReactTooltip
        anchorId="Delivery"
        place="bottom"
        content="Proof of Delivery"
      />
      <ReactTooltip
        anchorId="Inspection"
        place="bottom"
        content="Post-Delivery Product Inspection"
      />
      <ReactTooltip anchorId="BOL" place="bottom" content="Bill of Laden" />
    </div>
  );
}
