import "./Security.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { setLoader } from "../../store/slices/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { modifyWallet } from "../../apis/wallet";
import { decrypytdata } from "../../apis/walletKey";
import { setResponse } from "../../store/slices/responseSlice";
import { setUser } from "../../store/slices/userSlice";
import axios from "axios";
import { UMU_URL } from "../../constants/config";
import { resetEmailAPI } from "../../apis/general";

export default function Security() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userData } = useSelector((state) => state.user);

  const {
    pincodeEnabled,
    PublicAddress: APPID,
    PrivateKey: APIKEY,
    Email
  } = userData;

  const [pinActive, setPinActive] = useState(pincodeEnabled);
  const [pincode, setPincode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState("");
  // console.log("PinEnabled", pinActive);
  const [updatingPincode, setUpdatingPincode] = useState(false);
  const [updatingPassword, setUpdatingPassword] = useState(false);

  useEffect(() => {
    setErrorMessage("");
    setPasswordError("");
  }, [pincode]);

  function validatePincode() {
    if (pincode.length < 4 || pincode.length > 6)
      return "Pincode should be 4 to 6 characters long";
  }

  async function handlePinUpdate() {
    try {
      /* const error = validatePincode();

      if (error) {
        setErrorMessage(error);
        return;
      } */

      dispatch(setLoader(true));
      const modifyWalletResponse = await modifyWallet(APPID, APIKEY, {
        pincode: pincode
      });
      const decryptedResponse = await decrypytdata(
        modifyWalletResponse.data,
        APIKEY
      );
      const response = JSON.parse(decryptedResponse);
      const responseAPI = {
        ...modifyWalletResponse,
        headers: undefined,
        request: undefined,
        config: {
          baseURL: modifyWalletResponse.config.baseURL,
          url: modifyWalletResponse.config.url
        }
      };
      if (
        response.ReturnKeycode === "Success" ||
        response.returnKeycode === "Success"
      ) {
        const updatedUserData = { ...userData, pincode };
        dispatch(setUser(updatedUserData));
        localStorage.setItem("user", JSON.stringify(updatedUserData));
        dispatch(
          setResponse({
            apiResponse: responseAPI,
            apiData: response
          })
        );
      } else {
        dispatch(setResponse({ apiResponse: responseAPI, apiData: null }));
      }

      navigate("/result");
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoader(false));
    }
  }

  async function handlePinChange(e) {
    try {
      const enabled = !pinActive;
      dispatch(setLoader(true));
      const modifyWalletResponse = await modifyWallet(APPID, APIKEY, {
        pincodeEnabled: enabled
      });
      const decryptedResponse = await decrypytdata(
        modifyWalletResponse.data,
        APIKEY
      );
      const response = JSON.parse(decryptedResponse);
      // console.log(response);
      if (
        response.ReturnKeycode === "Success" ||
        response.returnKeycode === "Success"
      ) {
        setPinActive(enabled);
        const updatedUserData = { ...userData, pincodeEnabled: enabled };
        dispatch(setUser(updatedUserData));
        localStorage.setItem("user", JSON.stringify(updatedUserData));
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoader(false));
    }
  }

  async function handleVerifyEmail() {
    try {
      dispatch(setLoader(true));
      const response = await resetEmailAPI(Email);

      if (response.data) {
        navigate(`/change_password?email=${Email}`);
      } else {
        setPasswordError("Failed to generate otp for your email");
      }
    } catch (error) {
      console.error(error);
      setPasswordError("Failed to generate otp for your email");
    } finally {
      dispatch(setLoader(false));
    }
  }

  return (
    <>
      <div className="email-address p-0 identification milestones">
        <div className="container">
          <div className="header">
            <Link
              role="button"
              className="arrow-bg btn-sound"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <i className="bi bi-arrow-left-short"></i>
            </Link>
            <h3>Privacy and Security</h3>
          </div>

          <div className="confirm-payee-information mt-4">
            {errorMessage && <p className="text-danger">{errorMessage}</p>}

            <div className="payee-content security-content">
              <h6>
                Security Lock
                <p className="mb-0">
                  Require secret identifications for transfer funds
                </p>
              </h6>

              <div className="form-check form-switch d-flex pb-4">
                <label className="form-check-label" htmlFor="PIN">
                  PIN
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="PIN"
                  checked={pinActive}
                  onChange={handlePinChange}
                />
              </div>

              <h6>
                PIN Code
                <p className="mb-0">
                  Secure your transaction process with PIN Code
                </p>
              </h6>
              <div className="d-flex align-items-center justify-content-between">
                <div className="py-2 ps-1">
                  {updatingPincode ? (
                    <>
                      <input
                        type="text"
                        placeholder="Enter Pin"
                        className="form-control w-100"
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                      />
                    </>
                  ) : (
                    <>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </>
                  )}
                </div>
                {updatingPincode ? (
                  <button
                    className="btn-sound btn next-btn px-2 py-1 m-0"
                    onClick={() => {
                      // console.log("Clicked");
                      handlePinUpdate();
                    }}
                  >
                    Add
                  </button>
                ) : (
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => setUpdatingPincode(true)}
                  >
                    <img
                      src="/icons/edit-icon.png"
                      className="edit-icon px-3"
                      alt="icon"
                    />
                  </p>
                )}
              </div>
              <h6>
                Password
                <p className="mb-0">Secure your account with password.</p>
              </h6>
              <div className="d-flex align-items-center justify-content-between">
                <div className="py-2 ps-1">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <p
                  // to=""
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleVerifyEmail();
                  }}
                >
                  <img
                    src="/icons/edit-icon.png"
                    className="edit-icon px-3"
                    alt="icon"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

{
  /* <div className="mt-3 d-flex align-items-center justify-content-between">
  <button
    // disabled={password ? false : true}
    className="ms-2 btn next-btn w-100 mt-0 py-1"
    // onClick={handlePasswordUpdate}
    data-bs-toggle="modal"
    data-bs-target="#passwordChangeModal"
    onClick={() => {
      setPassword("");
      setPasswordError("");
      setForgotPasswordEmail("");
      setForgotPasswordOtp("");
      setIsOtpSended(false);
    }}
  > */
}
{
  /* <img
                  src="/icons/edit-icon.png"
                  className="edit-icon px-3"
                  alt="icon"
                /> */
}
{
  /*     Change Password
  </button>
</div>; */
}

{
  /* <div className="mt-3 d-flex align-items-center justify-content-between">
  <div className="py-2 ps-1" style={{ width: "100%" }}> */
}
{
  /* {!updatingPassword ? (
                  <>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </>
                ) : (
                  <input
                    className="privacy-input"
                    type="text"
                    placeholder="****"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    // disabled={!updatingPassword}
                  />
                )} */
}
{
  /* <input
                  className="privacy-input"
                  type="password"
                  placeholder="****"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  // disabled={!updatingPassword}
                /> */
}
{
  /*     <input
      className="privacy-input form-control"
      type="text"
      placeholder="Enter Password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      // disabled={!updatingPassword}
    />
  </div>
  <button
    disabled={password ? false : true}
    className="ms-2 btn next-btn mt-0 py-1"
    // onClick={handlePasswordUpdate}
    data-bs-toggle="modal"
    data-bs-target="#passwordChangeModal"
  > */
}
{
  /* <img
                  src="/icons/edit-icon.png"
                  className="edit-icon px-3"
                  alt="icon"
                /> */
}
{
  /*     Add
  </button>
</div>; */
}
