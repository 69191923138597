import axios from "axios";
import { UMU_URL } from "../constants/config";
import { hmacencrypt } from "./walletKey";

export async function getBalancesApi(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/GetBalances",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/money/GetBalances`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;
    throw error;
  }
}

export async function SellBalance(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/Sell",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/money/Sell`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;
    throw error;
  }
}
export async function BuyUMU(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/Buy",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/money/Buy`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;
    throw error;
  }
}

export async function CashoutUMU(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/Cashout",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/money/Cashout`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;
    throw error;
  }
}