import { useSelector } from "react-redux";

import CommandResultSuccess from "./CommandResultSuccess";
import CommandResultFailure from "./CommandResultFailure";

function CommandResult() {
  const state = useSelector((state) => state);

  return (
    <div className="text-white">
      {state.response.apiResponse?.status ? (
        <div>
          {state.response.apiResponse.status >= 200 &&
          state.response.apiResponse.status <= 299 ? (
            <CommandResultSuccess state={state} />
          ) : null}

          {state.response.apiResponse.status >= 400 &&
          state.response.apiResponse.status <= 500 ? (
            <CommandResultFailure state={state} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default CommandResult;
