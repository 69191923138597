import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import HomeFooter from "../components/HomeFooter";
import HomeHeader from "../components/HomeHeader";

export default function HomeLayout() {
  const { pathname } = useLocation();

  const paths = ["/", "/whitepaper"];

  useEffect(() => {
    const parentDiv = document.querySelector(".mobile-screen");

    if (paths.includes(pathname)) {
      parentDiv.style.paddingBottom = 0;
    }

    return () => {
      parentDiv.style.paddingBottom = "110px";
    };
  }, [pathname]);

  return (
    <div className="home">
      <HomeHeader />
      <Outlet />
      <HomeFooter />
    </div>
  );
}
