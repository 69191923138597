import { Routes, Route, BrowserRouter, Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import "bootstrap-icons/font/bootstrap-icons.css";

// Components
import Navbar from "./components/Navbar";
import CheckAuth from "./components/CheckAuth";
import RequireAuth from "./components/RequireAuth";
import AppLogout from "./components/AppLogout";
import ModalOverlay from "./components/ModalOverlay";

// Pages
import TestCommand from "./pages/TestCommand";

import Home from "./pages/Home";
import Splash from "./pages/wallet/Splash";
// import Splash3 from "./pages/wallet/Splash-1-Page";

import Login from "./pages/wallet/Login";
import RegistrationPhone from "./pages/wallet/RegistrationPhone";
import RegistrationPhoneVerify from "./pages/wallet/RegistrationPhoneVerify";
import RegistrationEmail from "./pages/wallet/RegistrationEmail";
import RegistrationEmailVerify from "./pages/wallet/RegistrationEmailVerify";
import RegistrationPassword from "./pages/wallet/RegistrationPassword";
import RegistrationIdentification from "./pages/wallet/RegistrationIdentification";

import CreatePayee from "./pages/wallet/payees/CreatePayee";
import PayeeUsername from "./pages/wallet/payees/Username";
import PayeeBank from "./pages/wallet/payees/Bank";
import PayeeEmail from "./pages/wallet/payees/Email";
import PayeeMobile from "./pages/wallet/payees/Mobile";

import PayeeConfirm from "./pages/wallet/payees/PayeeConfirm";

import TransferAmount from "./pages/payments/TransferAmount";
import Pincode from "./pages/payments/Pincode";
import Payees from "./pages/wallet/Payees";
import PayeesPOF from "./pages/pof/payees";
import Typepof from "./pages/pof/typespof";
import TransferReviewPOF from "./pages/pof//TransferReview2";
import TransferAmountPOF from "./pages/pof/TransferAmountPOF";
import Getpof from "./pages/pof/getpof";
import LiquidateReview from "./pages/services/LiquidateReview";
import TransferReview2 from "./pages/payments/TransferReview2";
import ReceiverSummary from "./pages/payments/ReceiverSummary";

import PaymentReceipt from "./pages/payments/PaymentReceipt";

import Error from "./pages/wallet/Error";

import CreateEscrow from "./pages/escrows/CreateEscrow";

import EscrowInformation from "./pages/escrows/EscrowInformation";

import EscrowConfirm from "./pages/escrows/EscrowConfirm";

import CreateMilestone from "./pages/milestones/CreateMilestone";
import Milestone from "./pages/milestones/Milestone";
import MilestoneConfirm from "./pages/milestones/MilestoneConfirm";

import Balance from "./pages/services/Balance";
import Withdraw from "./pages/services/Withdraw";
import Deposit from "./pages/services/Deposit";

import LinkMobile from "./pages/wallet/LinkMobile";

import Payments from "./pages/services/Activity/Payments";
import Activity from "./pages/services/Activity/Activity";
import Escrows from "./pages/services/Activity/Escrows";
import Milestones from "./pages/services/Activity/Milestones";

import Wallet from "./pages/wallet/Wallet";
import PersonalInformation from "./pages/wallet/PersonalInformation";
import Identification from "./pages/wallet/Identification";
import NewIdentification from "./pages/wallet/NewIdentification";
import Security from "./pages/wallet/Security";
import Notification from "./pages/wallet/Notification";
import Logout from "./pages/wallet/Logout";

import AttachBankAccount from "./pages/wallet/Attachment/AttachBankAccount";
import AttachAll from "./pages/wallet/Attachment/AttachAll";
import AttachCryptocurrency from "./pages/wallet/Attachment/AttachCryptocurrency";
import AttachMobile from "./pages/wallet/Attachment/AttachMobile";
import AttachEmail from "./pages/wallet/Attachment/AttachEmail";
import AttachUsername from "./pages/wallet/Attachment/AttachUsername";

import ExchangeRates from "./pages/services/ExchangeRates";
import AddExchangeRates from "./pages/services/AddExchangeRates";
import RequestEscrowRelease from "./pages/escrows/RequestEscrowRelease";
import RequestMilestoneRelease from "./pages/milestones/RequestMilestoneRelease";

import CommandResult from "./pages/response/CommandResult";
import RegistrationCashId from "./pages/wallet/RegistrationCashId";

import WalletListing from "./pages/wallet/WalletListing";
import AddUMUCrypto from "./pages/services/AddUMUCrypto";
import Liquidate from "./pages/services/Liquidate";

import WalletCashId from "./pages/wallet/WalletCashId";

import TermPurchaseListing from "./pages/TermPurchase/TermPurchaseListing";
import TermPurchaseAdd from "./pages/TermPurchase/TermPurchaseAdd";
import TermPurchaseAddReview from "./pages/TermPurchase/TermPurchaseAddReview";

import WalletCashIdLink from "./pages/wallet/WalletCashIdLink";

import { useSelector } from "react-redux";
import PasswordChange from "./pages/wallet/PasswordChange";

import CreateEscrowSummary from "./pages/escrows/CreateEscrowSummary";

import CreateMilestoneSummary from "./pages/milestones/CreateMilestoneSummary";
import AmountCreateMilestone from "./pages/milestones/amountCreateMilestone";

import AttachApps from "./pages/wallet/Attachment/AttachApps";

import AddFunds from "./pages/services/AddFunds";
// import PDFPreview from "./pages/PDFPreview";
import NotFound from "./pages/NotFound";

// import { useEffect } from "react";
// import axios from "axios";
// import { UMU_URL } from "./constants/config";
import loaderGif from "./assets/gifs/unicoin_loader.gif";
import AddUMUBank from "./pages/services/AddUMUBank";
import AddUMUApps from "./pages/services/AddUMUApps";

import Crypto_Amount from "./pages/services/CryptoAmount";
import AddFundsCryptoReview from "./pages/services/AddFundsCryptoReview";

import Balances from "./pages/Balances/Balances";
import TransferAmountBalance from "./pages/Balances/TransferAmount";
import UMUBalance from "./pages/Balances/UMUBalance";
import TermBalance from "./pages/Balances/TermBalance";
import MarginBalance from "./pages/Balances/MarginBalance";
import EscrowBalance from "./pages/Balances/EscrowBalance";
import CashBalance from "./pages/Balances/CashBalance";
import TransferReviewBalance from "./pages/Balances/TransferReview2";
import PayeesCashBalance from "./pages/Balances/payees";
import TransferCashBalance from "./pages/Balances/TransferCashBalance";
import TransferReviewCash from "./pages/Balances/TransferReviewCash";
import CashoutAmount from "./pages/Balances/CashoutAmount";
import CashoutReview from "./pages/Balances/CashoutReview";

import GetTermPurchases from "./pages/GetTerms/GetTermPurchases";
import WhitePaperPDF from "./pages/WhitePaperPDF";
import HomeLayout from "./layouts/HomeLayout";
import ScrollToTop from "./components/ScrollToTop";
import UMUPrivacyPolicy from "./pages/UMUPrivacyPolicy";

import ReferralCodeComponent from './components/ReferralCodeComponent'

export default function App() {
  /* useEffect(() => {
    async function main() {
      const response = await axios({
        method: "POST",
        baseURL: UMU_URL,
        url: "/general/DeleteUser",
        // data: { email: "shaikhzaidszs995@gmail.com" },
        data: { email: "darrellhubbard2000@gmail.com" },
        headers: {
          Authorization:
            "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
        }
      });

      console.log(response);
    }

    // main();
  }, []); */

  const loader = useSelector((state) => state.loader);

  return (
    <div className="scroll-top">
      <BrowserRouter>
        <ScrollToTop />
        <ModalOverlay />
        <div className="mobile-screen">
          {loader ? (
            <div className="d-flex justify-content-center logo_loader">
              <img className="logo_loader_img" src={loaderGif} />
            </div>
          ) : null}

          <div>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar
              theme="light"
            />
          </div>

          <Routes>
            <Route element={<ReferralCodeComponent />}>
              {/* <Route path="/Splash3" element={<Splash3 />} /> */}
              <Route path="/" element={<HomeLayout />}>
                <Route index element={<Home />} />
                <Route path="whitepaper" element={<WhitePaperPDF />} />
              </Route>

              {/* <Route path="/whitepaper" element={<WhitePaperPDF />} /> */}
              <Route path="/result" element={<CommandResult />} />
              <Route path="/change_password" element={<PasswordChange />} />

              <Route path="/cashID/:walletCashId" element={<WalletCashId />} />

              <Route element={<CheckAuth />}>
                <Route path="/splash" element={<Splash />} />
                <Route path="/login" element={<Login />} />

                <Route path="/mobilephone" element={<RegistrationPhone />} />
                <Route
                  path="/mobilecode"
                  element={<RegistrationPhoneVerify />}
                />

                <Route path="/email" element={<RegistrationEmail />} />
                <Route
                  path="/emailcode"
                  element={<RegistrationEmailVerify />}
                />

                <Route
                  path="/registration_cash_id"
                  element={<RegistrationCashId />}
                />

                <Route path="/privacy_policy" element={<UMUPrivacyPolicy />} />

                {/* <Route path="/password" element={<RegistrationPassword />} /> */}

                {/* <Route
              path="/identification"
              element={<RegistrationIdentification />}
            /> */}
              </Route>

              <Route
                element={
                  <>
                    <RequireAuth>
                      <AppLogout>
                        <Outlet />
                        <Navbar />
                      </AppLogout>
                    </RequireAuth>
                  </>
                }
              >
                <Route path="test_command" element={<TestCommand />} />

                {/* <Route path="pdfPreview" element={<PDFPreview />} /> */}

                <Route
                  path="term_purchase_list"
                  element={<TermPurchaseListing />}
                />
                <Route path="term_purchase_add" element={<TermPurchaseAdd />} />
                <Route
                  path="/term_purchase_add_review"
                  element={<TermPurchaseAddReview />}
                />

                <Route path="/cashid" element={<WalletCashIdLink />} />

                <Route path="/create_payee" element={<CreatePayee />} />
                <Route path="/payee_username" element={<PayeeUsername />} />
                <Route path="/payee_bank" element={<PayeeBank />} />
                <Route path="/payee_email" element={<PayeeEmail />} />
                <Route path="/payee_mobile" element={<PayeeMobile />} />

                <Route path="/payee_confirm" element={<PayeeConfirm />} />

                <Route path="/transfer_amount" element={<TransferAmount />} />
                <Route
                  path="/transfer_amount_pof"
                  element={<TransferAmountPOF />}
                />
                <Route path="/pincode" element={<Pincode />} />

                <Route path="/payees" element={<Payees />} />
                <Route path="/payees_pof" element={<PayeesPOF />} />
                <Route
                  path="/transfer_review"
                  element={
                    <TransferReview2
                    /* refersTo={"recipient"}
                  name={"Vavel Mfg"}
                  settlementCurrency="EUR"
                  settlementAmount="€ 1,800,000.00"
                  umuRate="2.4590"
                  umuAmount="Ü 732,004.880000"
                  walletCurrency="GHS"
                  walletAmount="882,000,000.00" */
                    />
                  }
                />
                <Route
                  path="/transfer_review_pof"
                  element={
                    <TransferReviewPOF
                    /* refersTo={"recipient"}
                  name={"Vavel Mfg"}
                  settlementCurrency="EUR"
                  settlementAmount="€ 1,800,000.00"
                  umuRate="2.4590"
                  umuAmount="Ü 732,004.880000"
                  walletCurrency="GHS"
                  walletAmount="882,000,000.00" */
                    />
                  }
                />

                <Route path="/liquidate_review" element={<LiquidateReview />} />

                <Route path="/receiver_summary" element={<ReceiverSummary />} />

                <Route path="/payments/receipt" element={<PaymentReceipt />} />

                <Route path="/message" element={<Error />} />

                {/* <Route path="/balance" element={<Balance />} /> */}
                <Route path="/add_umu_crypto" element={<AddUMUCrypto />} />
                <Route path="/liquidate" element={<Liquidate />} />
                <Route path="/withdraw" element={<Withdraw />} />
                <Route path="/deposit" element={<Deposit />} />

                <Route path="/escrow_create" element={<CreateEscrow />} />
                <Route path="/escrow" element={<EscrowInformation />} />
                <Route path="/escrow_confirm" element={<EscrowConfirm />} />

                <Route path="/milestone_create" element={<CreateMilestone />} />
                <Route path="/getmilestone" element={<Milestone />} />
                <Route
                  path="/milestone_confirm"
                  element={<MilestoneConfirm />}
                />

                <Route path="/activity" element={<Activity />} />
                <Route path="/payments" element={<Payments />} />
                <Route path="/escrows" element={<Escrows />} />
                <Route path="/milestones" element={<Milestones />} />

                <Route
                  path="/request_release_escrow"
                  element={<RequestEscrowRelease />}
                />
                <Route
                  path="/request_release_milestone"
                  element={<RequestMilestoneRelease />}
                />

                <Route path="/wallet" element={<Wallet />} />
                <Route path="/getpof" element={<Getpof />} />
                <Route path="/wallet_attachments" element={<WalletListing />} />

                <Route path="/link_mobile" element={<LinkMobile />} />
                <Route path="/types_pof" element={<Typepof />} />
                <Route
                  path="/personal_review"
                  element={<PersonalInformation />}
                />
                <Route
                  path="/identification_review"
                  element={<NewIdentification />}
                />
                <Route path="/security_review" element={<Security />} />
                <Route path="/notify" element={<Notification />} />
                <Route path="/logout" element={<Logout />} />

                {/* <Route path="/attach" element={<AttachBankAccount />} /> */}
                <Route path="/attach" element={<AttachAll />} />

                <Route path="/attach_all" element={<AttachAll />} />
                <Route path="/attach_bank" element={<AttachBankAccount />} />
                <Route
                  path="/attach_cryptocurrency"
                  element={<AttachCryptocurrency />}
                />
                <Route path="/attach_mobile" element={<AttachMobile />} />
                <Route path="/attach_email" element={<AttachEmail />} />
                <Route path="/attach_username" element={<AttachUsername />} />

                <Route path="/transfer" element={<TransferAmount />} />

                <Route path="/fx_rates" element={<ExchangeRates />} />
                <Route path="/add_fx_rates" element={<AddExchangeRates />} />

                {/* <Route path="/cashID/:walletCashId" element={<WalletCashId />} /> */}

                <Route
                  path="/create_escrow_summary"
                  element={<CreateEscrowSummary />}
                />
                <Route
                  path="/amount_milestone_create"
                  element={<AmountCreateMilestone />}
                />
                <Route
                  path="/create_milestone_summary"
                  element={<CreateMilestoneSummary />}
                />

                <Route path="/attachapps" element={<AttachApps />} />

                <Route path="/addfunds" element={<AddFunds />} />
                <Route path="/add_umu_bank" element={<AddUMUBank />} />
                <Route path="/add_umu_apps" element={<AddUMUApps />} />

                <Route path="/crypto_amount" element={<Crypto_Amount />} />
                <Route
                  path="/add_funds_crypto_review"
                  element={<AddFundsCryptoReview />}
                />

                <Route path="/get_balances" element={<Balances />} />
                <Route path="/balance" element={<UMUBalance />} />
                <Route path="/term_balance" element={<TermBalance />} />
                <Route path="/margin_balance" element={<MarginBalance />} />
                <Route path="/escrow_balance" element={<EscrowBalance />} />
                <Route path="/cash_balance" element={<CashBalance />} />
                <Route
                  path="/transferAmountBalance"
                  element={<TransferAmountBalance />}
                />
                <Route
                  path="/transferReviewBalance"
                  element={<TransferReviewBalance />}
                />
                <Route
                  path="/payeesCashBalance"
                  element={<PayeesCashBalance />}
                />
                <Route
                  path="/TransferCashBalance"
                  element={<TransferCashBalance />}
                />
                <Route
                  path="/TransferReviewCash"
                  element={<TransferReviewCash />}
                />
                <Route path="/CashoutAmount" element={<CashoutAmount />} />
                <Route path="/CashoutReview" element={<CashoutReview />} />

                <Route
                  path="/TransferCashBalance"
                  element={<TransferCashBalance />}
                />
                <Route
                  path="/TransferReviewCash"
                  element={<TransferReviewCash />}
                />

                <Route
                  path="/get_term_purchases"
                  element={<GetTermPurchases />}
                />

                <Route
                  path="/documents/umu_whitepaper"
                  element={<WhitePaperPDF />}
                />
              </Route>

              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}
