import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AttacheWallet } from "../../../apis/wallet";
import { decrypytdata } from "../../../apis/walletKey";
import { setLoader } from "../../../store/slices/loaderSlice";
import { setResponse } from "../../../store/slices/responseSlice";

import {
  hideLoaderFromElement,
  showLoaderOnElement
} from "../../../utils/loader";
import { useContext } from "react";
import { ModalOverlayContext } from "../../../context/ModalOverlayContext";

export default function AttachApps() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userData } = useSelector((state) => state.user);
  const { toggleModal } = useContext(ModalOverlayContext);

  const { PublicAddress: APPID, PrivateKey: APIKEY } = userData;

  const [walletAttachmentCode, setWalletAttachmentCode] = useState("PayPal");
  const [email, setEmail] = useState("");
  const [validationError, setValidationError] = useState("");

  function validateEmail() {
    if (!email) return "Email is required";
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
      return "Email is not valid";
  }

  async function attachApp(e) {
    setValidationError("");
    const error = validateEmail();
    if (error) {
      setValidationError(error);
      return;
    }

    let data = {
      walletLinkTypecode: walletAttachmentCode,
      cashID: email
    };

    try {
      // dispatch(setLoader(true));
      showLoaderOnElement(e.target);
      toggleModal(true);

      const response = await AttacheWallet(APPID, APIKEY, data);

      const responseAPI = {
        ...response,
        headers: undefined,
        request: undefined,
        config: { baseURL: response.config.baseURL, url: response.config.url }
      };

      const decryptedString = await decrypytdata(response.data || "", APIKEY);
      const decryptedData = decryptedString && JSON.parse(decryptedString);

      console.log(decryptedData);

      dispatch(
        setResponse({ apiResponse: responseAPI, apiData: decryptedData })
      );

      navigate("/result");
    } catch (error) {
      console.error(error);
    } finally {
      // dispatch(setLoader(false));
      toggleModal(false);
      hideLoaderFromElement(e.target);
    }
  }

  return (
    <div className="identification">
      <div className="container">
        <div className="header">
          <h3 className="my-3" style={{ marginLeft: "0" }}>
            Apps Attachment
          </h3>
        </div>

        <div className="create-payee-page">
          <div className="row">
            <div className="col-4 mb-4" id="Product">
              <button
                className={`btn-sound payee-box${
                  walletAttachmentCode === "PayPal" ? " active" : ""
                }`}
                onClick={() => setWalletAttachmentCode("PayPal")}
              >
                <img src="/icons/paypal-icon.png" alt="dark-icon" />
                <h5>PayPal</h5>
              </button>
            </div>
            <div className="col-4 mb-4" id="Shipping">
              <button
                className={`btn-sound payee-box${
                  walletAttachmentCode === "Cashapp" ? " active" : ""
                }`}
                onClick={() => setWalletAttachmentCode("Cashapp")}
              >
                <img src="/icons/cashapp-icon.png" alt="dark-icon" />
                <h5>Cashapp</h5>
              </button>
            </div>
            <div className="col-4 mb-4" id="Delivery">
              <button
                className={`btn-sound payee-box${
                  walletAttachmentCode === "Zelle" ? " active" : ""
                }`}
                onClick={() => setWalletAttachmentCode("Zelle")}
              >
                <img src="/icons/zelle-icon.png" alt="dark-icon" />
                <h5>Zelle</h5>
              </button>
            </div>
          </div>

          {validationError && (
            <div>
              <p className="my-0 text-danger">{validationError}</p>
            </div>
          )}

          <div className="add">
            <input
              type="email"
              className="form-control mt-4"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={
                walletAttachmentCode === "PayPal"
                  ? "Enter PayPal Email"
                  : walletAttachmentCode === "Cashapp"
                  ? "Enter CashApp Username"
                  : walletAttachmentCode === "Zelle"
                  ? "Enter Zelle Email"
                  : "Enter email"
              }
            />
            <button
              className="btn-sound add-btn"
              data-color="#3361a3"
              data-text="ADD"
              onClick={(e) => attachApp(e)}
            >
              ADD
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
