import { createSlice } from "@reduxjs/toolkit";

const userDataFromStorage = localStorage.getItem("user");

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: userDataFromStorage ? JSON.parse(userDataFromStorage) : null
  },
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload;
    }
  }
});

export default userSlice.reducer;

export const { setUser } = userSlice.actions;
