import "./PersonalInformation.css";
import { Link, useNavigate } from "react-router-dom";

export default function PersonInformation() {
  const navigate = useNavigate();

  return (
    <div className="email-address p-0 identification milestones ">
      <div className="container">
        <div className="header">
          <Link
            /* to="/splash" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>Personal Information</h3>
        </div>
        <div className="confirm-payee-information mt-4">
          <div className="payee-content">
            <h6>Full Name</h6>
            <div className="d-flex align-items-center justify-content-between">
              <h4>Anyel Torres</h4>
              <Link to="/">
                <img
                  src="/icons/edit-icon.png"
                  className="edit-icon px-3"
                  alt="icon"
                />
              </Link>
            </div>
            <h6>Email Address</h6>
            <h4>anela234@gmail.com</h4>
            <h6>Mobile Number</h6>
            <div className="d-flex align-items-center justify-content-between last">
              <h4>
                <img
                  src="/icons/flags/US-flag.png"
                  className="id-photo me-2"
                  alt="id"
                />
                01938735833
              </h4>
              <Link to="/">
                <img
                  src="/icons/edit-icon.png"
                  className="edit-icon px-3"
                  alt="icon"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
