import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./RegistrationEmail.css";
import { UMU_URL } from "../../constants/config";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../store/slices/loaderSlice";

export default function RegistrationEmail() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader);

  const [email, setEmail] = useState("");
  const [validationError, setValidationError] = useState("");
  // const [isLoading, setIsLoading] = useState(false);

  function handleEmailValueChange(e) {
    setEmail(e.target.value);
    setValidationError(() => "");
  }

  async function handleEmailSubmit() {
    // console.log(location.state);

    // Validate
    let errorMessage = "";

    if (
      !email ||
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
    ) {
      errorMessage = "Please provide valid email address";
    }

    if (errorMessage) {
      setValidationError(errorMessage);

      return;
    }

    let data = { email: email.toLowerCase() };

    try {
      dispatch(setLoader(true));

      const response = await axios.post(
        UMU_URL + "/general/GetEmailVerificationCode",
        data,
        {
          headers: {
            Authorization:
              "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
          }
        }
      );

      if (
        response.data?.returnKeycode === "Success" ||
        response.data?.ReturnKeycode === "Success"
      ) {
        navigate("/emailcode", {
          replace: true,
          state: { ...location.state, emailAddress: email }
        });
      } else {
        setValidationError(
          response.data?.returnMessage ||
            response.data?.ReturnMessage ||
            "OTP is not valid"
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setLoader(false));
    }
  }

  return (
    <div className="email-address">
      <div className="container">
        <div className="email-content">
          <h3>Enter your email address</h3>
          <p>We must verify your email address.</p>

          {!isLoading && (
            <>
              <div>
                {validationError ? (
                  <p className="text-danger">{validationError}</p>
                ) : null}

                <input
                  type="email"
                  className="form-control"
                  placeholder="Email address"
                  value={email}
                  onChange={handleEmailValueChange}
                />
                <button
                  to="/emailcode"
                  className="btn-sound btn next-btn mt-3"
                  onClick={handleEmailSubmit}
                >
                  NEXT
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
