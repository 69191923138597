import axios from "axios";

import { UMU_URL } from "../constants/config";
import { hmacencrypt } from "./walletKey";

export async function getEscrows(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/GetEscrows",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/money/GetEscrows`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}

export async function getTransfers(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/GetTransfers",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/money/GetTransfers`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}

export async function createEscrow(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/CreateEscrow",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/money/CreateEscrow`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}

export async function releaseEscrow(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/ReleaseEscrow",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/money/ReleaseEscrow`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}

export async function cancelEscrow(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/CancelEscrow",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/money/CancelEscrow`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}

export async function acceptEscrow(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/AcceptEscrow",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/money/AcceptEscrow`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}

export async function declineEscrow(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/DeclineEscrow",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/money/DeclineEscrow`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}
