import "./Milestones.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMilestones } from "../../../apis/milestone";
import { decrypytdata } from "../../../apis/walletKey";
import { setLoader } from "../../../store/slices/loaderSlice";

export default function Milestones() {
  const { data: userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoading = useSelector((state) => state.loader);
  const { TxHash } = location.state;
  const { PublicAddress: APPID, PrivateKey: APIKEY } = userData;

  // const [isLoading, setIsLoading] = useState(false);
  const [milestoneList, setMilestoneList] = useState([]);

  useEffect(() => {
    async function main() {
      try {
        dispatch(setLoader(true));
        //   await getEscrowsList();
        await getMilestonesList();
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setLoader(false));
      }
    }

    main();
  }, []);

  async function getMilestonesList() {
    const response = await getMilestones(APPID, APIKEY, { txHash: TxHash });
    const decryptedResponse = await decrypytdata(response.data, APIKEY);
    const responseObj = JSON.parse(decryptedResponse);
    // console.log(responseObj);
    if (
      responseObj.ReturnKeycode === "Success" ||
      responseObj.ReturnMessage === "Success"
    ) {
      setMilestoneList(JSON.parse(responseObj.CommandResponse) || []);
    }
  }

  return (
    <div className="email-address p-0 identification milestones ">
      <div className="container">
        <div className="header">
          <Link
            /* to="/splash" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3 className="ms-0">Milestones</h3>
          <Link
            /* to="/splash" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-plus"></i>
          </Link>
        </div>

        {isLoading ? (
          <></>
        ) : (
          <div className="milestones-list mt-4">
            <div>
              {milestoneList.length > 0 ? (
                <>
                  <p>Milestones description here</p>
                  {milestoneList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="milestones-box mb-3"
                        onClick={() => {
                          navigate("/getmilestone", {
                            state: {
                              EscrowInfo: location.state,
                              milestoneInfo: item
                            }
                          });
                        }}
                      >
                        <div>
                          <h6>{item.Milestone}</h6>
                          <p>{item.TransactionType}</p>
                        </div>
                        <div className="text-end">
                          <h5>
                            {Number(item.SettlementUnits)?.toFixed(3, "0")}{" "}
                            {item.SettlementCurrencycode}
                          </h5>
                          <h4
                            className={
                              item.Status == "Active" ? "green" : "red"
                            }
                          >
                            {item.Status}
                          </h4>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="email-address p-0 identification">
                  <div className="container">
                    <div className="error-conten">
                      <img src="/icons/alert-triangle.png" alt="" />
                      <p>No Milestones Found</p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* <div className="milestones-box mb-3">
              <div>
                <h6>Hasan</h6>
                <p>Product</p>
              </div>
              <div className="text-end">
                <h5>20.00</h5>
                <h4 className="red">Inactive</h4>
              </div>
            </div>
            <div className="milestones-box mb-3">
              <div>
                <h6>Giana</h6>
                <p>Shipping</p>
              </div>
              <div className="text-end">
                <h5>6.00</h5>
                <h4 className="red">Inactive</h4>
              </div>
            </div>
            <div className="milestones-box mb-3">
              <div>
                <h6>Novel 99</h6>
                <p>Other</p>
              </div>
              <div className="text-end">
                <h5>17.00</h5>
                <h4 className="green">Active</h4>
              </div>
            </div>
            <div className="milestones-box mb-3">
              <div>
                <h6>Anyel's A22</h6>
                <p>Agreement</p>
              </div>
              <div className="text-end">
                <h5>20.00</h5>
                <h4 className="green">Active</h4>
              </div>
            </div>
            <div className="milestones-box">
              <div>
                <h6>Hasan</h6>
                <p>Product</p>
              </div>
              <div className="text-end">
                <h5>20.00</h5>
                <h4 className="red">Inactive</h4>
              </div>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
}
