import "./LinkMobile.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { GetWalletQRCode } from "../../apis/wallet";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setLoader } from "../../store/slices/loaderSlice";
import QRCode from "qrcode.react";

export default function LinkMobile() {
  const navigate = useNavigate();
  const { data: userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader);

  // const [isLoading, setIsLoading] = useState(false);
  /* const [qrCodeUrl, setQrCodeUrl] = useState(null); */

  const { PublicAddress: APPID, PrivateKey: APIKEY } = userData;

  /* async function generateQrCode() {
    try {
      dispatch(setLoader(true));

      const response = await GetWalletQRCode(APPID, APIKEY);

      setQrCodeUrl(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoader(false));
    }
  }

  useEffect(() => {
    generateQrCode();
  }, []); */

  return (
    <div className="email-address p-0 identification">
      <div className="container">
        <div className="header">
          <Link
            /* to="/wallet" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>Link Mobile Wallet</h3>
        </div>
        <div className="link-mobile mt-4">
          <h3>Scan QR Code</h3>
          <p>
            Click <strong>Link Bank Wallet</strong> from your Mobile Wallet App
            to scan the below QR CODE.
          </p>
          {/* <p>
            Click Link Bank Wallet from your Mobile Wallet App.
          </p> */}
        </div>

        {/* <div className="qr-code-box">
          {qrCodeUrl ? <img src={qrCodeUrl} alt="QR-CODE" /> : null}
        </div> */}

        {!isLoading ? (
          <div className="mt-5 pt-5">
            {/* {qrCodeUrl && <img src={qrCodeUrl} alt="QR-CODE" />} */}
            {/* {qrCodeUrl && ( */}
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
                // backgroundColor: "#fff",
                // padding: "1rem",
                // borderRadius: "1rem 0.5rem"
              }}
            >
              <QRCode
                value={APPID}
                size={256}
                imageSettings={{
                  src: "images/splash-logo.png",
                  height: 80,
                  width: 80,
                  excavate: false
                }}
                style={{
                  padding: "1rem",
                  borderRadius: "1rem",
                  backgroundColor: "#fff"
                }}
              />

              {/* <img src="http://api.testnet.unicoinnetwork.com/img/IN/1177995/QRcodeimg.svg" /> */}
            </div>
            {/* )} */}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
