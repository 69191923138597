import { Link } from "react-router-dom";

export default function HomeFooter() {
  return (
    <footer className="mt-5">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-4 col-xl-3">
            <div className="footer-logo">
              <Link to="/">
                <img src="/images/dcma-logo.png" alt="logo" />
              </Link>
              <p className="mb-0">
                The DCMA is a world leader in the advocacy of digital currency
                innovations for monetary authorities.
              </p>
            </div>
          </div>
          <div className="col-md-auto">
            <div className="legal-information">
              <h3>Navigation</h3>
              <ul className="mb-0">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <a href="https://dcma.io" target="_blank">
                    DCMA
                  </a>
                </li>
                <li>
                  <a href="https://unicoinnetwork.com" target="_blank">
                    Unicoin Network
                  </a>
                </li>
                <li>
                  <Link to="/splash">Bank Wallet</Link>
                </li>
                <li>
                  <Link to="/whitepaper" className="mb-0">
                    Whitepaper
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-auto">
            <div className="social-media-links">
              <h3>Social Media Links</h3>
              <ul className="mb-0">
                <li className="me-2">
                  <a
                    href="https://twitter.com/DcmaPress"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi bi-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/76535284/admin/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="bi bi-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
