import { useState, useRef, useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";
import { useDispatch, useSelector } from "react-redux";

import "../payments/TransferAmount.css";
import { GetFXRatesApi, getFxRatesApi2 } from "../../apis/FxRatesApi";
import { decrypytdata } from "../../apis/walletKey";
import { setLoader } from "../../store/slices/loaderSlice";

export default function amountCreateMilestone() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader);
  const { data: userData } = useSelector((state) => state.user);

  const { PublicAddress: APPID, PrivateKey: APIKEY, Balance } = userData;

  const amountInputElement = useRef(null);

  const [validationError, setValidationError] = useState("");
  const [amount, setAmount] = useState("");
  const [fxRates, setFxRates] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [payeeData, setPayeeData] = useState(
    location?.state?.payeeData || null
  );
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [walletRates, setWalletRates] = useState(null);
  const [settlementRates, setSettlementRates] = useState(null);

  // if (!payeeData) {
  //   return <Navigate to="/payees" replace={true} />;
  // }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // setIsLoading(() => true);
      dispatch(setLoader(true));
      const response = await getFxRatesApi2(APPID, APIKEY, {
        currencycodeList: userData.CurrencycodeList
      });

      let data = response.data || [];

      setFxRates(() => data);

      if (!location?.state?.rate) {
        const myCurrencyRate = data.find(
          (item) =>
            item.QuoteCurrencycode === location.state.SettlementCurrencycode
        );

        const umuFound = data.find((item) => item.QuoteCurrencycode === "UMU");

        if (myCurrencyRate) {
          // setSelectedCurrency(myCurrencyRate);
          setWalletRates(myCurrencyRate);
          setSettlementRates(myCurrencyRate);
        } else if (umuFound) {
          // setSelectedCurrency(umuFound);
          setSettlementRates(umuFound);
          setWalletRates(umuFound);
        } /*  else { */
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoader(false));
    }
  };

  const handleChange = (value) => {
    let maxAmount = 10000000000.123456;

    if (Number(value) > maxAmount) return;

    if (value) {
      const amountValue = Number(value).toLocaleString("us-en");

      if (amountValue.length <= 16) {
        amountInputElement.current.style.fontSize = "32px";
      }

      if (amountValue.length > 16) {
        amountInputElement.current.style.fontSize = "26px";
      }
    } else {
      amountInputElement.current.style.fontSize = "32px";
    }

    setAmount(() => value);
  };

  const toggleOptions = () => {
    setIsOptionsOpen(() => !isOptionsOpen);
  };

  function FROM_UMU(UMU_FXRate, Amount) {
    const WALLET_UNITS = UMU_FXRate * Amount;
    return WALLET_UNITS;
  }

  function validate(amount) {
    // Remove the belove line
    // return null;

    if (!amount) return "Amount is required";
    else if (isNaN(Number(amount))) return "Amount should be a valid number";
    /* else if (
      Number(amount) > Number(FROM_UMU(settlementRates?.CoinPrice, Balance))
    )
      return "Insufficient Balance"; */
    else if (Number(amount) > location.state.SettlementCurrencyBalance)
      return "Invalid Amount. Balance Error.";
    return null;
  }

  function handlePayClick() {
    const error = validate(amount);

    if (error) {
      setValidationError(error);
      return;
    }

    const SETTLEMENT_UMU_UNITS = (1.0 / settlementRates?.QuotePrice) * amount;

    const WALLET_UMU_UNITS = SETTLEMENT_UMU_UNITS * walletRates.QuotePrice;

    const transfer_summary = {
      refersTo: "originator",
      // ...payeeData,
      SourcePublicAddress: userData.PublicAddress,
      SourceDisplayname: userData.Displayname,
      // TargetPublicAddress: payeeData.PublicAddress,
      // TargetDisplayname: payeeData.Displayname,

      wallet_currency_code: walletRates.QuoteCurrencycode,
      wallet_quote_price: Number(walletRates.QuotePrice)?.toFixed?.(6, "0"),
      wallet_umu_units: Number(WALLET_UMU_UNITS)?.toFixed?.(6, "0"),

      settlement_currency_code: settlementRates?.QuoteCurrencycode,
      settlement_quote_price: Number(settlementRates?.QuotePrice)?.toFixed?.(
        6,
        "0"
      ),
      settlement_umu_units: Number(SETTLEMENT_UMU_UNITS)?.toFixed?.(6, "0"),

      umu_transaction_fee: 0.0,
      amount: Number(amount)?.toFixed?.(6, "0")
    };

    // navigate("/transfer_review", { state: transfer_summary ,item:location.state});
  }

  function handleEscrowClick() {
    const error = validate(amount);

    if (error) {
      setValidationError(error);
      return;
    }

    const SETTLEMENT_UMU_UNITS = (1.0 / settlementRates?.QuotePrice) * amount;
    const WALLET_UMU_UNITS = SETTLEMENT_UMU_UNITS * walletRates.QuotePrice;

    const transfer_summary = {
      refersTo: "originator",
      SourcePublicAddress: userData.PublicAddress,
      SourceDisplayname: userData.Displayname,
      // TargetPublicAddress: payeeData.PublicAddress,
      // TargetDisplayname: payeeData.Displayname,

      wallet_currency_code: walletRates.QuoteCurrencycode,
      wallet_quote_price: Number(walletRates.QuotePrice)?.toFixed?.(6, "0"),
      wallet_umu_units: Number(WALLET_UMU_UNITS)?.toFixed?.(6, "0"),

      settlement_currency_code: settlementRates?.QuoteCurrencycode,
      settlement_quote_price: Number(settlementRates?.QuotePrice)?.toFixed?.(
        6,
        "0"
      ),
      settlement_umu_units: Number(SETTLEMENT_UMU_UNITS)?.toFixed?.(6, "0"),
      Escrow_transtionTypeCode: location.state?.EscrowInfo?.TransactionTypecode
        ? location.state.EscrowInfo.TransactionTypecode
        : location.state.TransactionTypecode,
      umu_transaction_fee: 0.0,
      amount: Number(amount)?.toFixed?.(6, "0")
    };
    navigate("/milestone_create", {
      state: { Escrow_Summary: transfer_summary, item: location.state }
    });
  }

  return (
    <div className="identification">
      <div className="container">
        <div className="header">
          <Link
            /* to="/activity" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3 className="ms-1">Settlement Currency Amount</h3>
        </div>

        {!isLoading && (
          <div className="enter-amount">
            <div className="d-flex align-items-center">
              <h1 className="mb-0 me-2">
                {settlementRates?.QuoteCurrencySymbol}
              </h1>
              <CurrencyInput
                ref={amountInputElement}
                placeholder="0"
                className="form-control"
                defaultValue={"0"}
                value={amount}
                onValueChange={handleChange}
                decimalsLimit={6}
                allowNegativeValue={false}
                style={{ textAlign: "right", height: "55px" }}
              />
            </div>
            {validationError ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {validationError}
              </p>
            ) : null}
            <div
              className="enterp-amount-content"
              onClick={toggleOptions}
              style={{ cursor: "pointer" }}
            >
              <div className="box-logo me-3">
                <img src={settlementRates?.QuoteImageFN} alt="icons" />
              </div>
              <div className="form-floating">
                <div className="">
                  <div className="text-light">
                    <div>
                      <div className="font-size d-flex justify-content-between">
                        <div>
                          <span>1 Ü = </span>
                          <span>
                            {settlementRates?.QuoteCurrencySymbol}{" "}
                            {Number(settlementRates?.CoinPrice).toFixed(6, "0")}
                          </span>
                          <span className="ps-2">
                            {settlementRates?.Currencycode}
                          </span>
                        </div>
                        <span className="text-success me-2">
                          {settlementRates?.QuoteCurrencySymbol}{" "}
                          {Number(
                            FROM_UMU(settlementRates?.CoinPrice, Balance)
                          ).toFixed(6, "0")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center d-flex align-items-center">
              <button
                className="btn-sound next-btn me-2 w-100"
                to="/"
                onClick={() => {
                  // SenderReceipt();
                  handlePayClick();
                }}
              >
                PAY
              </button>
              <button
                className="btn-sound next-btn w-100"
                /* to="/escrow_create" */ onClick={handleEscrowClick}
              >
                Milestone
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
