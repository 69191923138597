import "./PaymentReceipt.css";
import { Link, useNavigate } from "react-router-dom";

export default function PaymentReceipt() {
  const navigate = useNavigate();

  return (
    <div className="email-address p-0 identification">
      <div className="container">
        <div className="header">
          <Link
            /* to="/splash" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>Receipt</h3>
        </div>
        <div className="summary-box success-box">
          <div className="summary-box-logo">
            <img src="/icons/success-icon.png" alt="logo" />
          </div>
          <h1 className="text-center">Success</h1>
          <p className="text-center">Send money sucessful!!</p>

          <div className="round-line d-flex align-items-center">
            <div>
              <div className="round"></div>
            </div>
            <div className="line mx-2"></div>
            <div>
              <div className="round"></div>
            </div>
          </div>
          <div className="d-flex justify-content-between mb-3">
            <div className="summary-list">
              <p>Date</p>
              <h5>20 June, 2021</h5>
            </div>
            <div className="summary-list text-end">
              <p>Time</p>
              <h5>11:28 AM</h5>
            </div>
          </div>
          <div className="d-flex justify-content-between mb-3">
            <div className="summary-list">
              <p>To</p>
              <h5>Giana </h5>
            </div>
            <div className="summary-list text-end">
              <img
                src="/images/default.png"
                className="profile-img"
                alt="profile"
              />
            </div>
          </div>
          <div className="d-flex justify-content-between mb-3">
            <div className="summary-list">
              <p>Amount</p>
              <h5>
                <span>&Uuml;</span> 5,432
              </h5>
            </div>
            <div className="summary-list text-end">
              <p>Wallet</p>
              <h5>UMU</h5>
            </div>
          </div>
          <div className="summary-list">
            <p>Transaction No</p>
            <h5>#AKFJEIWDAF78QERUIN</h5>
          </div>
        </div>
        <div className="text-center">
          <Link className="next-btn w-100 mt-4" to="/">
            GO BACK
          </Link>
        </div>
      </div>
    </div>
  );
}
