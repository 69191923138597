import axios from "axios";

import { UMU_URL } from "../constants/config";
import { hmacencrypt } from "./walletKey";

export async function getMilestones(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/GetMilestones",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/money/GetMilestones`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}

export async function createMilestone(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/CreateMilestone",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/money/CreateMilestone`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}

export async function getMilestone(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/CreateMilestone",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/money/CreateMilestone`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}

export async function releaseMilestone(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "money/ReleaseMilestone",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `money/ReleaseMilestone`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}

export async function cancelMilestone(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/CancelMilestone",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/money/CancelMilestone`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}

export async function modifyMilestone(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "money/ModifyMilestone",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `money/ModifyMilestone`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}
