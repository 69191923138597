import "../payments/TransferReview.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { transfer } from "../../apis/payees";
import { useSelector } from "react-redux";
import { decrypytdata } from "../../apis/walletKey";
import { useState } from "react";
import axios from "axios";
import { UMU_URL } from "../../constants/config";

import { hideLoaderFromElement, showLoaderOnElement } from "../../utils/loader";
import { useContext } from "react";
import { ModalOverlayContext } from "../../context/ModalOverlayContext";

export default function TransferReview(/* {
  refersTo,
  name,
  settlementCurrency,
  settlementAmount,
  umuRate,
  umuAmount,
  walletCurrency,
  walletAmount
} */) {
  const navigate = useNavigate();
  const location = useLocation();
  const { toggleModal } = useContext(ModalOverlayContext);

  // console.log(location.state);

  const [isTransactionSubmited, setIsTransactionSubmited] = useState(false);

  const { data: userData } = useSelector((state) => state.user);
  const { PublicAddress: APPID, PrivateKey: APIKEY } = userData;

  const {
    refersTo = "originator",
    settlement_currency_code: settlementCurrency,
    amount: settlementAmount,
    settlement_quote_price: umuRate,
    settlement_umu_units: umuAmount,
    wallet_currency_code: walletCurrency,
    wallet_umu_units: walletAmount
  } = location.state;

  async function handleMakeTransaction(e) {
    try {
      showLoaderOnElement(e.target);
      toggleModal(true);
      setIsTransactionSubmited(true);

      const data = {
        settlementCurrencycode: location.state.settlement_currency_code,
        settlementUnits: location.state.amount,
        sourcePublicAddress: APPID
        // targetPublicAddress: location.state.TargetPublicAddress
      };

      let filePath = null;
      if (output) {
        const blob = await fetch(output).then((res) => res.blob());

        const file = new File([blob], selectedFile.name, {
          type: selectedFile.type
        });

        const formData = new FormData();

        formData.append("File", file);
        formData.append("PublicAddress", APPID);

        const fileUploadResponse = await axios({
          baseURL: UMU_URL,
          url: "/common/UploadFile",
          method: "POST",
          data: formData
        });

        if (fileUploadResponse?.data?.returnMessage === "Success") {
          filePath = fileUploadResponse?.data?.filePath;
        }
      }

      data.documentFN = filePath;

      // console.log(data);

      const response = await transfer(APPID, APIKEY, data);
      const decryptedResponse = await decrypytdata(response.data, APIKEY);

      console.log(decryptedResponse);

      const ResponseDataObj = JSON.parse(decryptedResponse);

      console.log(ResponseDataObj);

      if (
        ResponseDataObj &&
        (ResponseDataObj.ReturnKeycode === "Success" ||
          ResponseDataObj.ReturnMessage === "Success")
      ) {
        const payeeListData = JSON.parse(ResponseDataObj.CommandResponse);

        console.log(payeeListData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      toggleModal(false);
      hideLoaderFromElement(e.target);
      setIsTransactionSubmited(false);
    }
  }

  return (
    <div className="email-address p-0">
      <div className="identification">
        <div className="container">
          <div className="header">
            <Link
              /* to="/liquidate" */ role="button"
              onClick={() => navigate(-1, { replace: true })}
              className="arrow-bg"
            >
              <i className="bi bi-arrow-left-short"></i>
            </Link>
            <h3>Summary</h3>
          </div>

          <div className="summary-box">
            <div className="summary-box-logo">
              <img src="/images/summary-logo.png" alt="logo" />
            </div>

            <h1 className="text-center">Summary</h1>

            <div className="summary-list mb-3">
              {refersTo === "originator" && <p>To</p>}
              {refersTo === "recipient" && <p>From</p>}
              <h5 className=" mb-3">Bank Account</h5>
            </div>

            <div className="d-flex justify-content-between mb-3">
              <div className="summary-list mb-3">
                <p>Bank Currency</p>
                <h5>{settlementCurrency}</h5>
              </div>
              <div className="summary-list text-end mb-3">
                <p>Liquidate Amount</p>
                <h5>{Number(settlementAmount)?.toFixed(6, "0")}</h5>
              </div>
            </div>

            <div className="d-flex justify-content-between mb-3">
              <div className="summary-list mb-3">
                <p>UMU Rate</p>
                <h5>{Number(umuRate)?.toFixed(6, "0")}</h5>
              </div>

              <div className="summary-list mb-3 text-end">
                <p>UMU Amount</p>
                <h5>{Number(umuAmount)?.toFixed(6, "0")}</h5>
              </div>
            </div>

            <div className="d-flex justify-content-between mb-3">
              <div className="summary-list mb-3">
                <p>Wallet</p>
                <h5>{walletCurrency}</h5>
              </div>

              <div className="summary-list mb-3 text-end">
                <p>Wallet Amount</p>
                <h5>{Number(walletAmount)?.toFixed(6, "0")}</h5>
              </div>
            </div>

            <div className="d-flex justify-content-end mb-3"></div>
          </div>

          <div className="text-center">
            <button
              className="btn-sound next-btn w-100 mt-3"
              role="button"
              data-color="#3361a3"
              data-text="CONTINUE"
              // disabled={isTransactionSubmited ? true : false}
              onClick={(e) => handleMakeTransaction(e)}
            >
              CONTINUE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
