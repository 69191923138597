import { Navigate } from "react-router-dom";

function RequireAuth({ children }) {
  const userDataString = localStorage.getItem("user");

  return (
    <>{!userDataString ? <Navigate to="/login" replace={true} /> : children}</>
  );
}

export default RequireAuth;
