import "./TransferReview.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { transfer } from "../../apis/payees";
import { useDispatch, useSelector } from "react-redux";
import { decrypytdata } from "../../apis/walletKey";
import { useRef, useState } from "react";
import axios from "axios";
import { ADMIN_URL, UMU_URL } from "../../constants/config";

import ReactCrop from "react-image-crop";
import { setResponse } from "../../store/slices/responseSlice";
import { setLoader } from "../../store/slices/loaderSlice";

import { useContext } from "react";
import { hideLoaderFromElement, showLoaderOnElement } from "../../utils/loader";
import { ModalOverlayContext } from "../../context/ModalOverlayContext";

export default function TransferReview(/* {
  refersTo,
  name,
  settlementCurrency,
  settlementAmount,
  umuRate,
  umuAmount,
  walletCurrency,
  walletAmount
} */) {
  const imageInput = useRef(null);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { toggleModal } = useContext(ModalOverlayContext);

  const [isTransactionSubmited, setIsTransactionSubmited] = useState(false);
  const [transferData, setTransferData] = useState({
    referencecode: "",
    documentFN: null,
    comments: ""
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 80, y: 50, width: 100, height: 100 });
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);
  const [outputType, setOutputType] = useState(null);
  const [isImageUploadingOpen, setIsImageUploadingOpen] = useState(false);

  const { data: userData } = useSelector((state) => state.user);
  const { PublicAddress: APPID, PrivateKey: APIKEY } = userData;

  const {
    refersTo = "originator",
    TargetDisplayname: name,
    settlement_currency_code: settlementCurrency,
    amount: settlementAmount,
    settlement_quote_price: umuRate,
    settlement_umu_units: umuAmount,
    wallet_currency_code: walletCurrency,
    wallet_umu_units: walletAmount
  } = location.state;

  // console.log(location.state);

  async function handleMakeTransaction(e) {
    try {
      // dispatch(setLoader(true));
      showLoaderOnElement(e.target);
      toggleModal(true);
      setIsTransactionSubmited(true);

      const data = {
        referencecode: transferData.referencecode || null,
        comments: transferData.comments || null,
        settlementCurrencycode: location.state.settlement_currency_code,
        settlementUnits: Number(location.state?.amount)?.toFixed?.(6, "0"),
        sourcePublicAddress: APPID,
        targetPublicAddress: location.state.TargetPublicAddress
      };

      let filePath = null;
      if (output) {
        const blob = await fetch(output).then((res) => res.blob());

        const file = new File([blob], selectedFile.name, {
          type: selectedFile.type
        });

        const formData = new FormData();

        formData.append("File", file);
        formData.append("PublicAddress", APPID);
        formData.append("IsPublic", false);

        const fileUploadResponse = await axios({
          baseURL: UMU_URL,
          url: "/general/UploadFile",
          method: "POST",
          data: formData,
          headers: {
            Authorization:
              "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
          }
        });

        if (fileUploadResponse?.data?.returnMessage === "Success") {
          filePath = fileUploadResponse?.data?.commandResponse;
        }
      }

      data.documentFN = filePath || "";

      // console.log(data);

      const response = await transfer(APPID, APIKEY, data);
      const decryptedResponse = await decrypytdata(response.data, APIKEY);

      console.log(decryptedResponse);

      const ResponseDataObj = JSON.parse(decryptedResponse);

      // console.log(ResponseDataObj);
      const responseAPI = {
        ...response,
        headers: undefined,
        request: undefined,
        config: {
          baseURL: response.config.baseURL,
          url: response.config.url
        }
      };

      if (
        ResponseDataObj &&
        (ResponseDataObj.ReturnKeycode === "Success" ||
          ResponseDataObj.ReturnMessage === "Success")
      ) {
        dispatch(
          setResponse({
            apiResponse: responseAPI,
            apiData: ResponseDataObj
          })
        );
      } else {
        dispatch(
          setResponse({ apiResponse: responseAPI, apiData: ResponseDataObj })
        );
      }

      navigate("/result");
    } catch (error) {
      console.error(error);
    } finally {
      // dispatch(setLoader(false));
      toggleModal(false);
      hideLoaderFromElement(e.target);
      setIsTransactionSubmited(false);
    }
  }

  const selectImage = (file) => {
    setSrc(URL.createObjectURL(file));
  };

  const cropImageNow = () => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64
    const base64Image = canvas.toDataURL("image/jpeg");
    setOutput(base64Image);
  };

  return (
    <div className="email-address p-0">
      {isImageUploadingOpen ? (
        <div className="modal show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-sound btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    imageInput.current.value = null;
                    setSelectedFile(null);
                    setIsImageUploadingOpen(false);
                    setSrc(null);
                    setImage(null);
                    setOutput(null);
                    setCrop();
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <div>
                  {src && (
                    <div className="d-flex justify-content-center align-items-center">
                      <ReactCrop
                        src={src}
                        onImageLoaded={setImage}
                        crop={crop}
                        onChange={setCrop}
                        style={{ height: "225px" }}
                      />
                    </div>
                  )}
                </div>

                <div className="my-3 d-flex justify-content-center align-items-center">
                  {output && <img src={output} />}
                </div>

                <div
                  className="d-flex justify-content-around align-items-center"
                  style={{ borderTop: "1px solid #000", padding: "5px" }}
                >
                  <button
                    className="btn-sound btn"
                    style={{
                      backgroundColor: "#3361a3",
                      color: "#fff"
                    }}
                    onClick={cropImageNow}
                  >
                    CROP
                  </button>
                  <button
                    className="btn-sound btn"
                    style={{
                      backgroundColor: "#3361a3",
                      color: "#fff"
                    }}
                    onClick={() => {
                      setIsImageUploadingOpen(false);
                      setSrc(null);
                      setImage(null);
                      // setOutput(null);
                      setCrop();
                    }}
                  >
                    DONE
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div
              style={{
                width: "100%",
                position: "absolute",
                top: "50%",
                // left: "50%",
                // transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                marginTop: "30px",
                padding: "12px"
              }}
            >
              <center>
                
                <br />
                <br />
            
              </center>
            </div> */}
        </div>
      ) : null}

      <div className="identification">
        <div className="container">
          <div className="header">
            <p
              style={{ cursor: "pointer" }}
              /* to="/transfer_amount" replace={true} */ role="button"
              onClick={() => navigate(-1, { replace: true })}
              className="arrow-bg"
            >
              <i className="bi bi-arrow-left-short"></i>
            </p>
            <h3>Summary</h3>
          </div>

          <div className="summary-box">
            <div className="summary-box-logo">
              <img src="/images/summary-logo.png" alt="logo" />
            </div>

            <h1 className="text-center">Summary</h1>

            <div className="summary-list mb-3">
              {refersTo === "originator" && <p>To</p>}
              {refersTo === "recipient" && <p>From</p>}
              {/* <h5 className=" mb-3">Vavel Mfg</h5> */}
              <h5 className=" mb-3">{name}</h5>
            </div>

            <div className="d-flex justify-content-between mb-3">
              <div className="summary-list mb-3">
                {/* <p>Payment Amount</p> */}
                <p>Settlement</p>
                <h5>
                  {/* <span>$</span> 5,432 */}
                  {/* <span>$</span> */} {settlementCurrency}
                </h5>
              </div>
              <div className="summary-list text-end mb-3">
                {/* <p>Payment Currency</p> */}
                <p>Settlement Amount</p>
                {/* <h5>EUR</h5> */}
                <h5>{Number(settlementAmount)?.toFixed(6, "0")}</h5>
              </div>
            </div>

            <div className="d-flex justify-content-between mb-3">
              <div className="summary-list mb-3">
                {/* <p>Bank Rate</p> */}
                <p>UMU Rate</p>
                <h5>
                  {/* <span>$</span> 123.987655 */}
                  {/* <span>$</span>  */} {Number(umuRate)?.toFixed(6, "0")}
                </h5>
              </div>

              <div className="summary-list mb-3 text-end">
                {/* <p>Bank Fee</p> */}
                <p>UMU Amount</p>
                <h5>
                  {/* <span>&Uuml;</span> 0.00001 */}
                  {/* <span>&Uuml;</span> */} {/* Ü 732,004.880000 */}
                  {Number(umuAmount)?.toFixed(6, "0")}
                </h5>
              </div>
            </div>

            <div className="d-flex justify-content-between mb-3">
              <div className="summary-list mb-3">
                {/* <p>Recipient Amount</p> */}
                {/* <p>Originating</p> */}
                {/* <p>Local Currency</p> */}
                <p>Wallet</p>
                <h5>
                  {/* <span>$</span> 123.987655 */}
                  {/* <span>$</span> */} {/* GHS */}
                  {walletCurrency}
                </h5>
              </div>

              <div className="summary-list mb-3 text-end">
                {/* <p>Recipient Currency</p> */}
                {/* <p>Originating Amount</p> */}
                {/* <p>Local Amount</p> */}
                <p>Wallet Amount</p>
                {/* <h5>NGN</h5> */}
                {/* <h5>882,000,000.00</h5> */}
                <h5>{Number(walletAmount)?.toFixed(6, "0")}</h5>
              </div>
            </div>

            <div className="d-flex justify-content-end mb-3">
              {/* <div className="summary-list mb-3">
              <p>UMU Rate</p>
              <h5>
                <span>$</span> 123.987655
              </h5>
            </div> */}

              {/* <div className="summary-list text-end">
              <p>UMU Total Amount</p>
              <h5>
                <span>&Uuml;</span> 5,432.987655
              </h5>
            </div> */}
            </div>
          </div>

          <div>
            <h6 className="mt-3 text-white">Payment Information (Optional)</h6>

            <input
              type="text"
              className="form-control mt-3"
              placeholder="Reference Number"
              value={transferData.referencecode}
              onChange={(e) => {
                setTransferData((prev) => ({
                  ...prev,
                  referencecode: e.target.value
                }));
              }}
            />

            <div>
              <textarea
                className="form-control mt-3"
                placeholder="Comments"
                rows="3"
                value={transferData.comments}
                onChange={(e) => {
                  setTransferData((prev) => ({
                    ...prev,
                    comments: e.target.value
                  }));
                }}
              ></textarea>
            </div>

            <div className="mt-3">
              <div className="upload-box">
                <span>
                  {selectedFile
                    ? selectedFile.name
                    : "Upload Supporting Document"}
                </span>
                {/* <i className="bi bi-cloud-upload ms-2"></i> */}
                {!selectedFile && <i className="bi bi-cloud-upload ms-2"></i>}
                <input
                  ref={imageInput}
                  className="form-control-md"
                  id="formFileLg"
                  placeholder="Upload Supporting Document"
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.target.files?.[0]) {
                      // console.log(e.target.files?.[0]);
                      setOutput(null);
                      setSelectedFile(e.target.files[0]);
                      setOutputType(e.target.files[0].type);
                      selectImage(e.target.files[0]);
                      setIsImageUploadingOpen(true);
                    }
                  }}
                />
                {selectedFile && (
                  <i
                    class="bi bi-x-lg close-icon"
                    onClick={() => {
                      imageInput.current.value = null;
                      setSelectedFile(null);
                      setSrc(null);
                      setImage(null);
                      setOutput(null);
                      setCrop();
                    }}
                  ></i>
                )}
              </div>
            </div>
          </div>

          <div className="text-center">
            <button
              className="btn-sound next-btn w-100 mt-3"
              role="button"
              data-color="#3361a3"
              data-text="CONTINUE"
              // disabled={isTransactionSubmited ? true : false}
              onClick={(e) => handleMakeTransaction(e)}
            >
              CONTINUE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
