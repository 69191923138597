import "../wallet/Payees.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// style="background-color: rgb(255 199 109);"
// style="background-color: rgb(255 203 86);"

import { GetPayees } from "../../apis/payees";
import { decrypytdata } from "../../apis/walletKey";
import { setLoader } from "../../store/slices/loaderSlice";

export default function PayeesPOF() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { data: userData } = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.loader);

  const [search, setSearch] = useState("");
  const [payeeList, setPayeeList] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [filteredList, setFilteredList] = new useState([]);

  useEffect(() => {
    getPayeeList();
  }, []);

  const APPID = userData?.PublicAddress;
  const APIKEY = userData?.PrivateKey;

  const filterBySearch = (event) => {
    // Access input value
    const query = event.target.value;

    setSearch(query);
    // Create copy of item list
    var updatedList = [...payeeList];
    // Include all elements which includes the search query
    updatedList = updatedList.filter((item) => {
      return (
        item?.Displayname?.toLowerCase?.()?.indexOf(query?.toLowerCase()) !== -1
      );
    });
    // Trigger render with updated values
    setFilteredList(updatedList);
  };

  async function getPayeeList() {
    try {
      dispatch(setLoader(true));

      const encryptedResponse = await GetPayees(APPID, APIKEY);

      const decryptedDataString = await decrypytdata(
        encryptedResponse.data,
        APIKEY
      );

      // console.log(decryptedDataString);

      const ResponseDataObj = JSON.parse(decryptedDataString);

      if (
        ResponseDataObj &&
        (ResponseDataObj.ReturnKeycode === "Success" ||
          ResponseDataObj.ReturnMessage === "Success")
      ) {
        const payeeListData = JSON.parse(ResponseDataObj.CommandResponse);

        // console.log(payeeListData);

        setPayeeList(payeeListData);
        setFilteredList(payeeListData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoader(false));
    }
  }

  return (
    <div className="email-address p-0 identification">
      <div className="container">
        <div className="header">
          <Link to="/wallet" className="btn-sound arrow-bg">
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>Payees</h3>
        </div>

        {!isLoading ? (
          <div className="payees-content">
            <h6>Search Payee</h6>

            <div className="d-flex mb-4">
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <i className="bi bi-search"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={search}
                  onChange={filterBySearch}
                />
              </div>
              <div className="plus-icon ms-3">
                <Link to="/create_payee" className="btn-sound text-white">
                  <i className="bi bi-plus-lg"></i>
                </Link>
              </div>
            </div>

            {filteredList && filteredList.length > 0 ? (
              <>
                <div
                  className="table-bg table-content mt-5"
                  style={{ height: "320px", overflowY: "auto" }}
                >
                  <table className="table mb-0">
                    <thead>
                      <tr /* style={{ backgroundColor: "rgb(6, 0, 32)" }} */>
                        <th></th>
                        <th>Payee</th>
                        <th></th>
                        <th>Country</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredList.map((li, idx) => {
                        return (
                          <Fragment key={idx}>
                            <tr
                              id="row_container"
                              onClick={(e) => {
                                let data = {
                                  payeeData: {
                                    PublicAddress: li.PublicAddress,
                                    Displayname: li.Displayname
                                  }
                                };

                                if (location.state?.rate) {
                                  data = {
                                    ...data,
                                    rate: location.state?.rate
                                  };
                                }
                                data={...data,type:location.state.type}
                                navigate("/transfer_amount_pof", { state: data });
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <td className="border-0">
                                <img
                                  className="payee_image"
                                  src="/images/default.png"
                                  alt="default-img"
                                />
                              </td>
                              <td className="border-0">
                                <p className="payee_name mb-0">
                                  {li.Displayname}
                                </p>
                              </td>
                              <td
                                className="border-0"
                                style={{ textAlign: "right" }}
                              >
                                <img
                                  src={
                                    li?.Countrycode
                                      ? `/icons/flags/${li?.Countrycode?.toLowerCase()}.png`
                                      : "/icons/flags/US-flag.png"
                                  }
                                  className="me-0"
                                  alt="flag"
                                  style={{ width: "32px" }}
                                />
                              </td>
                              <td className="border-0">
                                <span className="mb-0">{li.Countrycode}</span>
                              </td>
                              <td className="border-0">
                                <div
                                  className="table-icon"
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src="/icons/table-icon.png" alt="icon" />
                                </div>
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}
                      {/* <tr>
                        <td className="border-0"></td>
                        <td className="border-0"></td>
                        <td className="border-0"></td>
                        <td className="text-end border-0">
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
                {/* <div className="payees_table_container">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Country</th>
                      <th>Payee</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredList.map((li, idx) => {
                      return (
                        <>
                          <td>
                            <img
                              className="payee_image"
                              src="/images/default.png"
                              alt="default-img"
                              style={{ width: "32px" }}
                            />
                          </td>
                          <td>
                            <img
                              src={
                                li?.Countrycode
                                  ? `/icons/flags/${li?.Countrycode?.toLowerCase()}.png`
                                  : "/icons/flags/US-flag.png"
                              }
                              className="ms-2 me-2"
                              alt="flag"
                              style={{ width: "32px" }}
                            />
                            <span className="mb-0 ms-2">{li.Countrycode}</span>
                          </td>
                          <td>
                            <span className="payee_name mb-0 ms-2">
                              {li.Displayname}
                            </span>
                          </td>
                          <td></td>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div> */}
              </>
            ) : (
              <>
                <div className="email-address p-0 identification">
                  <div className="container">
                    {/* <div className="header">
                      <Link to="/splash" className="arrow-bg">
                        <i className="bi bi-arrow-left-short"></i>
                      </Link>
                      <h3>Error</h3>
                    </div> */}
                    <div className="error-conten">
                      <img src="/icons/alert-triangle.png" alt="" />
                      <p>No Payees Found</p>
                    </div>
                  </div>
                </div>
                {/* <div className="mt-5 text-white text-center">
                <h5>No Payees Found</h5>
              </div> */}
              </>
            )}

            {/* <div className="payees-list mb-2">
              <div>
                <img src="/images/default.png" alt="default-img" />
              </div>
              <p className="mb-0 ms-4">Hasan 22</p>
            </div>

            <div className="payees-list mb-2">
              <div>
                <img src="/images/default.png" alt="default-img" />
              </div>
              <p className="mb-0 ms-4">Giana</p>
            </div>

            <div className="payees-list mb-2">
              <div>
                <img src="/images/default.png" alt="default-img" />
              </div>
              <p className="mb-0 ms-4">Noval 99</p>
            </div>

            <div className="payees-list mb-2">
              <div>
                <img src="/images/default.png" alt="default-img" />
              </div>
              <p className="mb-0 ms-4">Adam Husein</p>
            </div>

            <div className="payees-list">
              <div>
                <img src="/images/default.png" alt="default-img" />
              </div>
              <p className="mb-0 ms-4">Adriana</p>
            </div> */}
          </div>
        ) : null}
      </div>
    </div>
  );
}

{
  /* <div key={idx} className="payees-list mb-2">
                          <div>
                            <img
                              className="payee_image"
                              src="/images/default.png"
                              alt="default-img"
                            />
                          </div>

                          <img
                            src={
                              li?.Countrycode
                                ? `/icons/flags/${li?.Countrycode?.toLowerCase()}.png`
                                : "/icons/flags/US-flag.png"
                            }
                            className="ms-2 me-2"
                            alt="flag"
                            style={{ width: "32px" }}
                          />
                          <p className="mb-0 ms-2">{li.Countrycode}</p>

                          <p className="payee_name mb-0 ms-2">
                            {li.Displayname}
                          </p>
                        </div> */
}
