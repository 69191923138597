import "../services/Activity/Payments.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams
} from "react-router-dom";
import { getEscrows, getTransfers } from "../../apis/escrow";
// import { Link, useNavigate } from "react-router-dom";
import {
  acceptEscrow,
  cancelEscrow,
  declineEscrow,
  releaseEscrow
} from "../../apis/escrow";
import { decrypytdata } from "../../apis/walletKey";
import { setLoader } from "../../store/slices/loaderSlice";
import { setResponse } from "../../store/slices/responseSlice";
import { getTermPurchasesAPI } from "../../apis/termPurchase";

export default function GetTermPurchases() {
  const { data: userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.loader);
  const [query] = useSearchParams();

  const tabValue = query.get("Tab");
  const { PublicAddress: APPID, PrivateKey: APIKEY } = userData;

  const [termPurchaseList, setTermPurchaseList] = useState([]);

  const [selectedTab, setSelectedTab] = useState(
    tabValue ? tabValue : "TERM_BUYS"
  );

  useEffect(() => {
    async function main() {
      try {
        dispatch(setLoader(true));
        await getTermPurchases();
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setLoader(false));
      }
    }

    main();
  }, []);

  async function getTermPurchases() {
    const response = await getTermPurchasesAPI(APPID, APIKEY);
    const decryptedResponse = await decrypytdata(response.data, APIKEY);
    const responseObj = JSON.parse(decryptedResponse);
    if (
      responseObj.ReturnKeycode === "Success" ||
      responseObj.ReturnMessage === "Success"
    ) {
      const data =
        responseObj.CommandResponse && JSON.parse(responseObj.CommandResponse);

      console.log(data);

      setTermPurchaseList(data || []);

      /* const termPurchases =
        (data &&
          data.map((row) => {
            const settlementCurrencyBalance = new Intl.NumberFormat(undefined, {
              currency: row.SettlementCurrencycode,
              style: "currency",
              minimumFractionDigits: 3,
              maximumFractionDigits: 3
            }).format(+Number(row.SettlementCurrencyBalance).toFixed(6, "0"));

            const balance = new Intl.NumberFormat(undefined, {
              currency: "USD",
              minimumFractionDigits: 6,
              maximumFractionDigits: 6
            }).format(row.Balance);

            return {
              ...row,
              settlementCurrencyBalance,
              balance
            };
          })) ||
        []; */
    }
  }

  return (
    <div>
      <div className="email-address p-0">
        <div className="container">
          <div className="phone-content email-content activity-content">
            <div className="header text-center">
              <h3 className="ms-0 mt-3 mb-5">Term Purchases</h3>
            </div>

            {isLoading ? (
              <></>
            ) : (
              <>
                <div className="tabs my-4 d-flex justify-content-between">
                  <Link
                    className="btn-sound"
                    role="button"
                    onClick={() => setSelectedTab("TERM_BUYS")}
                  >
                    <p
                      className={`mx-1 ${
                        selectedTab === "TERM_BUYS" ? "active" : ""
                      }`}
                    >
                      Term Buys
                    </p>
                  </Link>
                  <Link role="button" className="btn-sound" onClick={() => setSelectedTab("MARGIN")}>
                    <p
                      className={`mx-1 ${
                        selectedTab === "MARGIN" ? "active" : ""
                      }`}
                    >
                      Margin
                    </p>
                  </Link>
                </div>

                {selectedTab === "TERM_BUYS" ? (
                  <div>
                    {termPurchaseList.length > 0 ? (
                      <>
                        {termPurchaseList.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div className="payments-list mb-3">
                                <div>
                                  <h3>
                                    <span className="text-success">
                                      {item?.LocalCurrencycode}
                                    </span>{" "}
                                    <span>
                                      {new Intl.NumberFormat(undefined, {
                                        currency:
                                          item?.LocalCurrencycode || "USD",
                                        style: "currency",
                                        maximumFractionDigits: 3
                                      }).format(item?.AltBalance || 0)}
                                    </span>
                                  </h3>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      color: "#c5c5c5"
                                    }}
                                  >
                                    {item.MaturityDate}
                                  </p>
                                </div>
                                <div className="">
                                  <div>
                                    <h6 className="m-0 text-white">
                                      Ü
                                      {new Intl.NumberFormat(undefined, {
                                        currency: "USD",
                                        maximumFractionDigits: 6
                                      }).format(item?.Units || 0)}
                                    </h6>
                                  </div>
                                  <div>
                                    <h3 style={{ color: "#3361a3" }}>
                                      {item.Displayname}
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </>
                    ) : (
                      <div className="email-address p-0 identification">
                        <div className="container">
                          <div className="error-conten">
                            <img src="/icons/alert-triangle.png" alt="" />
                            <p>No Payments Found</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}

                {selectedTab === "MARGIN" ? (
                  <div>
                    {termPurchaseList.length > 0 ? (
                      <>
                        {termPurchaseList
                          .filter((item) => item.MarginEnabled)
                          .map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div className="payments-list mb-3">
                                  <div>
                                    <h3>
                                      <span className="text-success">
                                        {item?.LocalCurrencycode}
                                      </span>{" "}
                                      <span>
                                        {new Intl.NumberFormat(undefined, {
                                          currency:
                                            item?.LocalCurrencycode || "USD",
                                          style: "currency",
                                          maximumFractionDigits: 3
                                        }).format(item?.AltBalance || 0)}
                                      </span>
                                    </h3>
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        color: "#c5c5c5"
                                      }}
                                    >
                                      {item.MaturityDate}
                                    </p>
                                  </div>
                                  <div className="">
                                    <div>
                                      <h6 className="m-0 text-white">
                                        Ü
                                        {new Intl.NumberFormat(undefined, {
                                          currency: "USD",
                                          maximumFractionDigits: 6
                                        }).format(item?.Units || 0)}
                                      </h6>
                                    </div>

                                    <div>
                                      <h3 style={{ color: "#3361a3" }}>
                                        {item.Displayname}
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                      </>
                    ) : (
                      <div className="email-address p-0 identification">
                        <div className="container">
                          <div className="error-conten">
                            <img src="/icons/alert-triangle.png" alt="" />
                            <p>No Escrows Found</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
