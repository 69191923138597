import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { setLoader } from "../../store/slices/loaderSlice";
import { UMU_URL } from "../../constants/config";
import { toast } from "react-toastify";
import { setUser } from "../../store/slices/userSlice";

import { hideLoaderFromElement, showLoaderOnElement } from "../../utils/loader";
import { useContext } from "react";
import { ModalOverlayContext } from "../../context/ModalOverlayContext";

export default function PasswordChange() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { toggleModal } = useContext(ModalOverlayContext);

  const Email = searchParams.get("email");

  const [successMessage, setSuccessMessage] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPasswordOtp, setForgotPasswordOtp] = useState("");
  const [passwordError, setPasswordError] = useState("");

  if (!Email) {
    return <Navigate to="/login" replace={true} />;
  }

  useEffect(() => {
    setPasswordError("");
  }, [password, forgotPasswordOtp]);

  async function handlePasswordChange(e) {
    setPasswordError("");
    setSuccessMessage("");

    console.log(
      "Password",
      !password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/
      )
    );

    if (!forgotPasswordOtp) {
      setPasswordError("Otp is required");
      return;
    } else if (forgotPasswordOtp.length < 6 || forgotPasswordOtp.length > 6) {
      setPasswordError("Otp is not valid");
      return;
    } else if (!password) {
      setPasswordError("Password is required");
      return;
    } else if (
      !password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/
      )
    ) {
      setPasswordError(
        "Password should be between 8 to 16 charactes. It should contain uppercase, lowercase, special charater and number."
      );
      return "Email is not valid";
    }

    try {
      // dispatch(setLoader(true));
      showLoaderOnElement(e.target);
      toggleModal(true);

      /* setIsPasswordChanged(true);
      return; */

      const response = await axios({
        method: "POST",
        baseURL: UMU_URL,
        url: "/general/ResetPassword",
        data: {
          email: Email,
          password: password,
          otp: forgotPasswordOtp
        },
        headers: {
          Authorization:
            "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
        }
      });

      // console.log(response);

      if (response?.data === true) {
        localStorage.clear();
        dispatch(setUser({ data: null }));
        toast("Password successfuly changed");
        navigate("/login", { replace: true });
      } else {
        setPasswordError(
          response?.data?.ReturnMessage ||
            response?.data?.returnMessage ||
            "Something went wrong"
        );
      }
    } catch (error) {
      if (error.response) {
        setPasswordError(
          error.response?.data?.ReturnMessage || "Something went wrong"
        );
        return;
      }
      console.error(error);
      setPasswordError("Something went wrong");
    } finally {
      // dispatch(setLoader(false));
      toggleModal(false);
      hideLoaderFromElement(e.target);
    }
  }

  return (
    <div className="email-address">
      <div className="container">
        <div className="email-content">
          <h3>Change Password</h3>
          <p>Please enter 6 digit code we have sent to {Email}</p>
          <div>
            {successMessage && <p className="text-success">{successMessage}</p>}
            {passwordError && <p className="text-danger">{passwordError}</p>}

            <input
              type="text"
              className="form-control"
              placeholder="Enter OTP"
              value={forgotPasswordOtp}
              onChange={(e) => setForgotPasswordOtp(e.target.value)}
            />

            <input
              type="password"
              className="mt-3 form-control"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <button
              to="/emailcode"
              className="btn-sound mt-3 btn next-btn"
              onClick={(e) => handlePasswordChange(e)}
              data-color="#3361a3"
              data-text="Change Password"
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
