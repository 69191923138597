import "./Payments.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getEscrows, getTransfers } from "../../../apis/escrow";
// import { Link, useNavigate } from "react-router-dom";
import {
  acceptEscrow,
  cancelEscrow,
  declineEscrow,
  releaseEscrow
} from "../../../apis/escrow";
import { decrypytdata } from "../../../apis/walletKey";
import { setLoader } from "../../../store/slices/loaderSlice";
import { setResponse } from "../../../store/slices/responseSlice";

import {
  hideLoaderFromElement,
  showLoaderOnElement
} from "../../../utils/loader";
import { useContext } from "react";
import { ModalOverlayContext } from "../../../context/ModalOverlayContext";

export default function Activity() {
  const { data: userData } = useSelector((state) => state.user);
  const { toggleModal } = useContext(ModalOverlayContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state) => state.loader);
  const navigate = useNavigate();

  const { PublicAddress: APPID, PrivateKey: APIKEY } = userData;
  const constants = {
    RELEASE_ESCROW: "RELEASE ESCROW",
    CANCEL_ESCROW: "CANCEL ESCROW",
    CREATE_MILESTONE: "CREATE MILESTONE",
    ACCEPT_ESCROW: "ACCEPT ESCROW",
    DECLINE_ESCROW: "DECLINE ESCROW"
  };

  const [selectedTab, setSelectedTab] = useState("PAYMENTS");
  const [escrowList, setEscrowList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [paymentSearch, setPaymentSearch] = useState("");
  const [filteredPaymentList, setFilteredPaymentList] = useState([]);
  const [command, setCommand] = useState("");
  const [clickedEscrow, setClickedEscrow] = useState(null);

  useEffect(() => {
    async function main() {
      try {
        dispatch(setLoader(true));
        if (location?.state?.type) {
          setSelectedTab("ESCROWS");
        }
        await getEscrowsList();
        await getPaymentList();
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setLoader(false));
      }
    }

    main();
  }, []);

  function filterBySearch(event) {
    const query = event.target.value;
    setPaymentSearch(query);
    var updatedList = [...paymentList];
    updatedList = updatedList.filter((item) => {
      return (
        item?.Displayname?.toLowerCase?.()?.indexOf(query?.toLowerCase()) !== -1
      );
    });
    setFilteredPaymentList(updatedList);
  }

  async function getEscrowsList() {
    const response = await getEscrows(APPID, APIKEY);
    const decryptedResponse = await decrypytdata(response.data, APIKEY);
    const responseObj = JSON.parse(decryptedResponse);
    if (
      responseObj.ReturnKeycode === "Success" ||
      responseObj.ReturnMessage === "Success"
    ) {
      const data =
        responseObj.CommandResponse && JSON.parse(responseObj.CommandResponse);

      const escrows =
        (data &&
          data.map((row) => {
            const settlementCurrencyBalance = new Intl.NumberFormat(undefined, {
              currency: row.SettlementCurrencycode,
              style: "currency",
              minimumFractionDigits: 3,
              maximumFractionDigits: 3
            }).format(+Number(row.SettlementCurrencyBalance).toFixed(6, "0"));

            const balance = new Intl.NumberFormat(undefined, {
              currency: "USD",
              minimumFractionDigits: 6,
              maximumFractionDigits: 6
            }).format(row.Balance);

            return {
              ...row,
              settlementCurrencyBalance,
              balance
            };
          })) ||
        [];
      setEscrowList(escrows || []);
    }
  }

  async function getPaymentList() {
    const response = await getTransfers(APPID, APIKEY);
    const decryptedResponse = await decrypytdata(response.data, APIKEY);
    const responseObj = JSON.parse(decryptedResponse);
    if (
      responseObj.ReturnKeycode === "Success" ||
      responseObj.ReturnMessage === "Success"
    ) {
      const data = JSON.parse(responseObj.CommandResponse || "");

      // console.log(data);

      const transfers =
        (data &&
          data.map((row) => {
            const formatter = new Intl.DateTimeFormat(undefined, {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true
            });

            const formetedDate = formatter.format(
              new Date(row.TransactionDate)
            );

            const localCurrencyAmount = new Intl.NumberFormat(undefined, {
              currency: row.LocalCurrencycode,
              style: "currency",
              minimumFractionDigits: 3,
              maximumFractionDigits: 3
            }).format(+Number(row.LocalCurrencyAmount).toFixed(6, "0"));

            const umuAmount = new Intl.NumberFormat(undefined, {
              currency: "USD",
              minimumFractionDigits: 6,
              maximumFractionDigits: 6
            }).format(row.UMUBalance);

            return {
              ...row,
              LocalCurrencyAmount: localCurrencyAmount,
              UMUBalance: umuAmount,
              UMUPreBalance: Number(row.UMUPreBalance).toFixed(6, "0"),
              TransactionDate: formetedDate
            };
          })) ||
        [];

      setPaymentList(transfers);
      setFilteredPaymentList(transfers);
    }
  }

  async function handleCommandClick(e) {
    try {
      // dispatch(setLoader(true));
      showLoaderOnElement(e.target);
      toggleModal(true);

      let response = null;

      if (command === constants.RELEASE_ESCROW) {
        response = await releaseEscrow(APPID, APIKEY, {
          txHASH: clickedEscrow.TxHash
        });
      }

      if (command === constants.CANCEL_ESCROW) {
        response = await cancelEscrow(APPID, APIKEY, {
          txHASH: clickedEscrow.TxHash
        });
      }

      if (command === constants.ACCEPT_ESCROW) {
        response = await acceptEscrow(APPID, APIKEY, {
          txHASH: clickedEscrow.TxHash
        });
      }

      if (command === constants.DECLINE_ESCROW) {
        response = await declineEscrow(APPID, APIKEY, {
          txHASH: clickedEscrow.TxHash
        });
      }

      if (command === constants.CREATE_MILESTONE) {
        return;
      }

      const responseAPI = {
        ...response,
        headers: undefined,
        request: undefined,
        config: { baseURL: response.config.baseURL, url: response.config.url }
      };

      const decryptedResponse = await decrypytdata(response.data, APIKEY);
      const data = JSON.parse(decryptedResponse);

      dispatch(setResponse({ apiResponse: responseAPI, apiData: data }));
      navigate("/result");
    } catch (error) {
      console.error(error);
    } finally {
      // dispatch(setLoader(false));
      toggleModal(false);
      hideLoaderFromElement(e.target);
    }
  }

  return (
    <div>
      <div
        className="modal fade"
        id="RELEASE"
        tabIndex="-1"
        aria-labelledby="RELEASE"
        aria-hidden="true"
      >
        <div className="modal-dialog" style={{ position: "unset" }}>
          <div className="modal-box">
            <div className="icons-fixed">
              <img src="/icons/success-icon-modal.png" alt="icon" />
            </div>
            {/* <h2>Release Escrow</h2> */}
            <h2>{command}</h2>
            <div className="modal-list">
              <div className="d-flex align-items-center justify-content-between mb-2">
                {/* <h6>Milestone Type</h6> */}
                <h6>Escrow Type</h6>
                {/* <h5>Product</h5> */}
                <h5>{clickedEscrow?.TransactionTypecode || ""}</h5>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <h6>Description</h6>
                {/* <h5>$ 2,333.00 USD</h5> */}
                <h5>{clickedEscrow?.Displayname || ""}</h5>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <h6>Amount</h6>
                {/* <h5>$ 2,333.00 USD</h5> */}
                <h5>
                  {clickedEscrow?.settlementCurrencyBalance || 0}{" "}
                  {clickedEscrow?.SettlementCurrencycode}
                </h5>
              </div>
            </div>
            <div className="d-flex align-items-center w-100 mt-3">
              <button
                className="btn-sound modal-btn me-3"
                data-bs-dismiss="modal"
              >
                CANCEL
              </button>
              <button
                data-color="#3361a3"
                data-text={command?.split?.(" ")?.[0] || "DONE"}
                className="btn-sound modal-btn fill"
                onClick={(e) => handleCommandClick(e)}
              >
                {/* RELEASE */}
                {command?.split?.(" ")?.[0] || "DONE"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="email-address p-0">
        <div className="container">
          <div className="phone-content email-content activity-content">
            <div className="header text-center">
              <h3 className="ms-0 mt-3 mb-5">Activity</h3>
            </div>

            {isLoading ? (
              <></>
            ) : (
              <>
                {selectedTab === "PAYMENTS" ? (
                  <div>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        <i className="bi bi-search"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control border-left-0"
                        placeholder="Search..."
                        value={paymentSearch}
                        onChange={filterBySearch}
                      />
                    </div>
                  </div>
                ) : null}

                <div className="tabs my-4">
                  <Link
                    className="btn-sound"
                    role="button"
                    onClick={() => setSelectedTab("PAYMENTS")}
                  >
                    <p
                      className={`me-3 ${
                        selectedTab === "PAYMENTS" ? "active" : ""
                      }`}
                    >
                      PAYMENTS
                    </p>
                  </Link>
                  <Link
                    className="btn-sound"
                    role="button"
                    onClick={() => setSelectedTab("ESCROWS")}
                  >
                    <p
                      className={`me-3 ${
                        selectedTab === "ESCROWS" ? "active" : ""
                      }`}
                    >
                      ESCROWS
                    </p>
                  </Link>
                </div>

                {selectedTab === "PAYMENTS" ? (
                  <div>
                    {filteredPaymentList.length > 0 ? (
                      filteredPaymentList.map((item, index) => {
                        return (
                          <div key={index} className="payments-list mb-3">
                            <div>
                              {/* <h3>Anyel's A22</h3> */}
                              <h3>
                                <span>{item.LocalCurrencycode} </span>
                                {item.SentReceived === "Sent" && (
                                  <span className="text-danger">
                                    - {item.LocalCurrencyAmount}
                                  </span>
                                )}
                                {item.SentReceived === "Received" && (
                                  <span className="text-success">
                                    + {item.LocalCurrencyAmount}
                                  </span>
                                )}
                              </h3>
                              {/* <p>22 June, 2022 10:24 AM</p> */}
                              <p style={{ fontSize: "12px" }}>
                                {item.TransactionDate}
                              </p>
                            </div>
                            <h6 className="mb-0 text-white">
                              Ü {item.UMUBalance}
                            </h6>

                            {/* <h6 className="mb-0 green">+ 20.00</h6> */}
                            {/* {item.SentReceived === "Sent" && (
                              <h6 className="mb-0 text-danger">
                                {item.UMUBalance}
                              </h6>
                            )} */}
                            {/* {item.SentReceived === "Received" && (
                              <h6 className="mb-0 green">
                                Ü {item.UMUBalance}
                              </h6>
                            )} */}
                          </div>
                        );
                      })
                    ) : (
                      <div className="email-address p-0 identification">
                        <div className="container">
                          <div className="error-conten">
                            <img src="/icons/alert-triangle.png" alt="" />
                            <p>No Payments Found</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}

                {selectedTab === "ESCROWS" ? (
                  <div>
                    {escrowList.length > 0 ? (
                      <>
                        {escrowList.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div
                                className="payments-list mb-3"
                                onClick={(e) => {
                                  if (
                                    e.target.closest(".escrow_menu_button") ||
                                    e.target.closest(".escrow_dropdown")
                                  ) {
                                    return;
                                  }

                                  navigate("/escrow", {
                                    state: { ...item, command }
                                  });
                                }}
                              >
                                <div>
                                  <h3>
                                    <span>{item.SettlementCurrencycode} </span>
                                    {item.SentReceived === "Sent" && (
                                      <span className="text-danger">
                                        - {item.settlementCurrencyBalance}
                                      </span>
                                    )}
                                    {item.SentReceived === "Received" && (
                                      <span className="text-success">
                                        + {item.settlementCurrencyBalance}
                                      </span>
                                    )}
                                  </h3>
                                  <p style={{ fontSize: "12px" }}>
                                    {item.CalendarDate}
                                  </p>
                                </div>
                                <div style={{ width: "40%" }}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="m-0 me-2 text-white">
                                      Ü {item.balance}
                                    </h6>
                                    <div
                                      className="btn-group dropstart bg-icons"
                                      role="group"
                                    >
                                      <button
                                        type="button"
                                        className="btn-sound dropdown-toggle escrow_menu_button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={() => {
                                          setClickedEscrow(item);
                                        }}
                                      >
                                        <i
                                          className="text-white fa fa-bars"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                      <ul className="escrow_dropdown dropdown-menu dropdown-menu-dark">
                                        {item.SentReceived === "Sent" && (
                                          <>
                                            <li>
                                              <button
                                                className="btn-sound dropdown-item"
                                                onClick={() => {
                                                  // console.log("RELEASE_ESCROW");
                                                  setCommand(
                                                    constants.RELEASE_ESCROW
                                                  );
                                                }}
                                                data-bs-toggle="modal"
                                                data-bs-target="#RELEASE"
                                              >
                                                RELEASE ESCROW
                                              </button>
                                              {/* <a className="dropdown-item" href="#">
                                            Dropdown link
                                          </a> */}
                                            </li>
                                            <li>
                                              <button
                                                className="btn-sound dropdown-item"
                                                onClick={() => {
                                                  // console.log("CANCEL_ESCROW");
                                                  setCommand(
                                                    constants.CANCEL_ESCROW
                                                  );
                                                }}
                                                data-bs-toggle="modal"
                                                data-bs-target="#RELEASE"
                                              >
                                                CANCEL ESCROW
                                              </button>
                                              {/* <a className="dropdown-item" href="#">
                                            Dropdown link
                                          </a> */}
                                            </li>
                                            <li>
                                              <button
                                                className="btn-sound dropdown-item"
                                                onClick={() => {
                                                  // console.log(
                                                  //   "CREATE_MILESTONE"
                                                  // );
                                                  setCommand(
                                                    constants.CREATE_MILESTONE
                                                  );
                                                  navigate(
                                                    "/amount_milestone_create",
                                                    {
                                                      state: {
                                                        SourcePublicAddress:
                                                          APPID,
                                                        ...item
                                                      }
                                                    }
                                                  );
                                                }}
                                              >
                                                CREATE MILESTONE
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                className="btn-sound dropdown-item"
                                                onClick={() => {
                                                  navigate("/milestones", {
                                                    state: {
                                                      SourcePublicAddress:
                                                        APPID,
                                                      ...item
                                                    }
                                                  });
                                                }}
                                              >
                                                GET MILESTONE
                                              </button>
                                            </li>
                                          </>
                                        )}
                                        {item.SentReceived === "Received" && (
                                          <>
                                            <li>
                                              <button
                                                className="btn-sound dropdown-item"
                                                onClick={() => {
                                                  // console.log("ACCEPT_ESCROW");
                                                  setCommand(
                                                    constants.ACCEPT_ESCROW
                                                  );
                                                }}
                                                data-bs-toggle="modal"
                                                data-bs-target="#RELEASE"
                                              >
                                                ACCEPT ESCROW
                                              </button>
                                              {/* <a className="dropdown-item" href="#">
                                          Dropdown link
                                        </a> */}
                                            </li>
                                            <li>
                                              <button
                                                className="btn-sound dropdown-item"
                                                onClick={() => {
                                                  // console.log("DECLINE_ESCROW");
                                                  setCommand(
                                                    constants.DECLINE_ESCROW
                                                  );
                                                }}
                                                data-bs-toggle="modal"
                                                data-bs-target="#RELEASE"
                                              >
                                                DECLINE ESCROW
                                              </button>
                                              {/* <a className="dropdown-item" href="#">
                                          Dropdown link
                                        </a> */}
                                            </li>
                                          </>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                  <span className="escrow_display_name">
                                    {item.Displayname}
                                  </span>
                                </div>
                              </div>
                              {/* <div className="escrows-list mb-3">
                              <div>
                                <h3>Anyel's A22</h3>
                                <p>22 June, 2022 10:24 AM</p>
                              </div>
                              <div className="d-flex align-items-center">
                                <h4 className="me-2">+ 20.00</h4>
                                <div className="bg-icons">
                                  <img
                                    src="/icons/escrows-icon.png"
                                    alt="icon"
                                  />
                                </div>
                              </div>
                            </div> */}
                            </React.Fragment>
                          );
                        })}
                      </>
                    ) : (
                      <div className="email-address p-0 identification">
                        <div className="container">
                          <div className="error-conten">
                            <img src="/icons/alert-triangle.png" alt="" />
                            <p>No Escrows Found</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
