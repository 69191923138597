import "./Splash.css";
import { Link } from "react-router-dom";

export default function Splash() {
  return (
    <div className="splash">
      <div className="container">
        <div className="splash-content">
          <div className="text-center">
            <img
              src="/images/splash-logo.png"
              className="my-5 img-fluid w-75"
              alt="logo"
            />
          </div>
          <h3>Mobile to Mobile Digital Cash</h3>
          <p>A Store of Value Innovation</p>
          <div className="text-center d-flex align-items-center justify-content-center">
            <Link className="next-btn login-btn me-3" to="/login">
              <div>
                <img
                  src="/icons/login-icon.png"
                  className="icon-login"
                  alt="icon"
                />
              </div>
              {/* <i class="bi bi-key"></i>*/}
              <span className="ms-1">SIGN-IN</span>
            </Link>
            {/* <Link className="next-btn login-btn" to="/mobilephone">
              <div>
                <img
                  src="/icons/login-icon-1.png"
                  className="icon-login"
                  alt="icon"
                />
              </div>
              // <i class="bi bi-file-lock"></i>
              <span className="ms-4">REGISTER</span>
            </Link> */}
          </div>
          <p className="mt-3">
            <Link to="/privacy_policy" className="me-2">
              Privacy Policy
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
