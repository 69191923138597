import "./Withdraw.css";
import { Link, useNavigate } from "react-router-dom";

export default function Withdraw() {
  const navigate = useNavigate();

  return (
    <div className="email-address p-0 identification">
      <div className="container">
        <div className="header">
          <Link
            /* to="/splash" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>Liquidate</h3>
        </div>
        <div className="deposit mt-4">
          <div className="row justify-content-center">
            <div className="col-8">
              <img
                src="/images/splash-logo.png"
                className="img=fluid w-100"
                alt="logo"
              />
            </div>
            <div className="col-12 text-center mt-4">
              <input
                type="text"
                className="form-control text-center"
                placeholder="Enter Liquidate"
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <Link className="next-btn w-100 mt-4" to="/">
            SUBMIT
          </Link>
        </div>
      </div>
    </div>
  );
}
