import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./getpof.css";
import "../milestones/MilestoneConfirm.css";
import { GetPOFSApi } from "../../apis/pof";
import { decrypytdata } from "../../apis/walletKey";
import { setLoader } from "../../store/slices/loaderSlice";

export default function Typepof() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userData } = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.loader);
  const [fxRates, setFxRates] = useState([]);
  const [showRatesOf, setShowRatesOf] = useState("MY_RATES");
  const [recivedData, setRecivedData] = useState([]);
  const [sentData, setSentData] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);

  const APPID = userData?.PublicAddress;
  const APIKEY = userData?.PrivateKey;
  const userRates = userData?.CurrencycodeList;

  const userRatesList = userRates.split(",");
  const [myRates, setMyRates] = useState([]);

  

  const fetchData = async () => {
    try {
      dispatch(setLoader(true));
      const response = await GetPOFSApi(APPID, APIKEY, {
        currencyPairTypeID: 1
      });

      const fxRatesJSON = await decrypytdata(response?.data, APIKEY);
      const fxRatesObj = JSON.parse(fxRatesJSON);
      let data = [];

      if (fxRatesObj.CommandResponse) {
        data = JSON.parse(fxRatesObj.CommandResponse);
      }

      setFxRates(() => data);

      const recivedData = data.filter((row) => row.SentReceived === "Received");
      setRecivedData(recivedData);
      const sentData = data.filter((row) => row.SentReceived === "Sent");
      setSentData(sentData);
    } catch (error) {
      if (error.response) {
        // const fxRatesJSON = await decrypytdata(error.response?.data, APIKEY);
        // if (!fxRatesJSON) return;
        // const dataJSON = JSON.parse(fxRatesJSON);
        // let data = [];
        // if (dataJSON?.CommandResponse) {
        //   data = JSON.parse(dataJSON?.CommandResponse);
        // }
        // // console.log(data);
      } else {
        console.log(error);
      }
    } finally {
      dispatch(setLoader(false));
    }
  };

  // console.log(myRates);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="email-address p-0 identification milestones">
      <div className="container">

        <div className="header">
          <h3 className="ms-0 mt-3">PROOF OF FUNDS</h3>
        </div>

        <div className="phone-content email-content activity-content">
          
          <div className="tabs my-4">
                  <div
                    role="button"
                    onClick={() => setShowRatesOf("MY_RATES")}
           
                  >
                      <p
                className={showRatesOf === "MY_RATES" ? "me-5 active" : "me-5"}
              >
                      Sent
                    </p>
                  </div>
                  <div role="button"   onClick={() => setShowRatesOf("ALL_RATES")}
             >
                   <p className={showRatesOf === "ALL_RATES" ? "me-5 active" : "me-5"}>
                    Recived
                    </p>
                  </div>
                </div>
        </div>

        <div className="milestones-list mt-4" style={{ color: "white" }}>
          {!isLoading && (
            <>
              {showRatesOf === "MY_RATES" && (
                <>Request Proof of Funds from a buyer.</>
              )}

              {showRatesOf === "ALL_RATES" && (
                <>Send Proof of Funds to a supplier.</>
              )}
            </>
          )}

          <button className="btn-sound next-btn w-100 mt-3" role="button" onClick={()=>{
            if(showRatesOf === "MY_RATES"){
            navigate("/payees_pof",{state:{type:"SENT"}})
            }else{
            navigate("/payees_pof",{state:{type:"RECIVED"}})
            }
          }}>
            {"Continue"}
          </button>
        </div>
        </div>
 
    </div>
  );
}
