import "./CreateEscrow.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { getDropdownValues } from "../../apis/dropdown";
import { useState } from "react";
import { createEscrow } from "../../apis/escrow";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import axios from "axios";
import { UMU_URL } from "../../constants/config";
import { setLoader } from "../../store/slices/loaderSlice";
import { useDispatch } from "react-redux";

import { hideLoaderFromElement, showLoaderOnElement } from "../../utils/loader";
import { useContext } from "react";
import { ModalOverlayContext } from "../../context/ModalOverlayContext";

export default function CreateEscrow() {
  const imageInput = useRef();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { toggleModal } = useContext(ModalOverlayContext);
  const { data: userData } = useSelector((state) => state.user);

  const { PublicAddress: APPID, PrivateKey: APIKEY } = userData;

  const {
    SourcePublicAddress,
    TargetPublicAddress,
    SourceDisplayname,
    settlement_currency_code,
    settlement_umu_units,
    amount
  } = location.state;

  const [selectedFile, setSelectedFile] = useState(null);
  const [validationError, setValidationError] = useState("");
  const [escrowType, setEscrowType] = useState("PRODUCT");
  const [escrowTypes, setEscrowTypes] = useState([]);
  const [escrowData, setEscrowData] = useState({
    sourcePublicAddress: SourcePublicAddress,
    targetPublicAddress: TargetPublicAddress,
    settlementUnits: +Number(amount).toFixed(6, "0"),
    settlementCurrencycode: settlement_currency_code,
    escrowTypecode: escrowType,
    comments: null,
    referenceNumber: null,
    documentFN: null,
    shortDescription: "",
    longDescription: "",
    expectedDeliveryDate: null
    // sourceTokenSymbol: "string",
    // sourceLedgercode: "string",
    // targetTokenSymbol: "string",
    // targetLedgercode: "string",
    // sourceUnits: 0,
    // referencecode: null,
    // escrow: null,
    // displayname: SourceDisplayname,
    // completionDate: null
  });

  // console.log(escrowTypes);

  useEffect(() => {
    if (escrowTypes.length > 0) {
      setEscrowType(escrowTypes[0].Keycode);
    }
  }, [escrowTypes]);

  useEffect(() => {
    const main = async () => {
      await getEscrowTypes();
    };

    main();
  }, []);

  async function handleUploadFile() {
    if (selectedFile) {
      /* const file = await convertToBase64(selectedFile);

      console.log(selectedFile); */

      const formData = new FormData();
      formData.append("File", selectedFile);
      formData.append("PublicAddress", APPID);
      formData.append("IsPublic", false);

      const response = await axios({
        baseURL: UMU_URL,
        url: "/general/UploadFile",
        method: "POST",
        data: formData,
        headers: {
          Authorization:
            "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
        }
      });

      // console.log(response.data);

      return response.data.commandResponse;
    }
  }

  async function getEscrowTypes() {
    try {
      const response = await getDropdownValues("LKESCROWTYPE");
      setEscrowTypes(response.data.commandDataset || []);
      setEscrowType(response.data.commandDataset[0]);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleCreateEscrow(e) {
    const error = validate();
    if (error) {
      setValidationError(error);
      return;
    }

    try {
      // dispatch(setLoader(true));
      showLoaderOnElement(e.target);
      toggleModal(true);

      let uploadedFilePath = null;
      let downloadedFile = null;
      if (selectedFile) {
        const uploadResponse = await handleUploadFile();
        uploadedFilePath = uploadResponse;
      }

      const transferData = {
        refersTo: location.state.refersTo,
        name: location.state.TargetDisplayname,
        settlementCurrency: location.state.settlement_currency_code,
        settlementAmount: location.state.amount,
        umuRate: location.state.settlement_quote_price,
        umuAmount: location.state.settlement_umu_units,
        walletCurrency: location.state.wallet_currency_code,
        walletAmount: location.state.wallet_umu_units
      };

      let data = {
        escrowData,
        transferData
      };

      if (uploadedFilePath) {
        // data.documentPdf = "";
        data.escrowData = { ...data.escrowData, documentFN: uploadedFilePath };
      }

      /* const response = await createEscrow(APPID, APIKEY, data);
      console.log(response.data); */

      navigate("/create_escrow_summary", { state: data });
    } catch (error) {
      console.error(error);
    } finally {
      // dispatch(setLoader(false));
      toggleModal(false);
      hideLoaderFromElement(e.target);
    }
  }

  function validate() {
    const { shortDescription, longDescription, expectedDeliveryDate } =
      escrowData;
    if (!shortDescription) return "Short Description is required";
    if (!longDescription) return "Long Description is required";
    if (!expectedDeliveryDate) {
      return "Expected Delivery Date is required";
    }

    const selectedDate = new Date(expectedDeliveryDate);
    const currentDate = new Date(new Date().setHours(0, 0, 0, 0));

    if (selectedDate < currentDate)
      return "Expected delivery date should be greater then or equal to current date";
    return null;
  }

  return (
    <div className="identification">
      <div className="container">
        <div className="header">
          <Link
            /* to="/splash" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>Create Escrow</h3>
        </div>
        <div className="create-payee-page">
          <p>Provide the following details for this escrow.</p>
          <div className="row">
            <div className="col-4 mb-4">
              <button
                to="/escrow_create"
                className={`btn-sound payee-box ${
                  escrowType === escrowTypes?.[0]?.Keycode ? "active" : ""
                }`}
                onClick={() => {
                  setEscrowData((prev) => ({
                    ...prev,
                    escrowTypecode: escrowTypes[0].Keycode
                  }));
                  setEscrowType(escrowTypes[0].Keycode);
                }}
              >
                <img
                  src="/icons/product-dark.png"
                  className="dark"
                  alt="dark-icon"
                />
                <img
                  src="/icons/product-light.png"
                  className="light"
                  alt="light-icon"
                />
                <h5>Product</h5>
              </button>
            </div>
            <div className="col-4 mb-4">
              <button
                to="/"
                className={`btn-sound payee-box ${
                  escrowType === escrowTypes?.[1]?.Keycode ? "active" : ""
                }`}
                onClick={() => {
                  setEscrowData((prev) => ({
                    ...prev,
                    escrowTypecode: escrowTypes[1].Keycode
                  }));
                  setEscrowType(escrowTypes[1].Keycode);
                }}
              >
                <img
                  src="/icons/service-dark.png"
                  className="dark"
                  alt="dark-icon"
                />
                <img
                  src="/icons/service-light.png"
                  className="light"
                  alt="light-icon"
                />
                <h5>Service</h5>
              </button>
            </div>
          </div>
          <p className="text-danger">{validationError}</p>
          <div className="row">
            <div className="col-12 mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Escrow Short Description (25+ letters)"
                value={escrowData.shortDescription}
                maxLength="25"
                onChange={(e) =>
                  setEscrowData((prev) => ({
                    ...prev,
                    shortDescription: e.target.value
                  }))
                }
              />
            </div>
            <div className="col-12 mb-4">
              <textarea
                className="form-control"
                placeholder="Escrow Long Description (100+ letters)"
                rows="3"
                value={escrowData.longDescription}
                maxLength="100"
                onChange={(e) =>
                  setEscrowData((prev) => ({
                    ...prev,
                    longDescription: e.target.value
                  }))
                }
              ></textarea>
            </div>

            {/* <div className="col-12 mb-4">
              <input
                type="date"
                className="form-control"
                placeholder="Target Completion Date"
                value={escrowData.completionDate}
                onChange={(e) =>
                  setEscrowData((prev) => ({
                    ...prev,
                    completionDate: e.target.value
                  }))
                }
              />
            </div> */}

            <div className="col-12 mb-4">
              {/* <input
                type="text"
                className="form-control"
                name="expirationDate"
                placeholder="Expected Delivery Date"
                onFocus={(e) => {
                  e.target.type = "date";
                }}
                onBlur={(e) => {
                  if (!e.target.value) {
                    e.target.type = "text";
                  }
                }}
              /> */}
              <DatePicker
                showIcon
                // isClearable
                className="form-control"
                selected={escrowData.expectedDeliveryDate}
                onChange={(date, e) => {
                  setEscrowData((prev) => {
                    return { ...prev, expectedDeliveryDate: date };
                  });
                }}
                placeholderText="Expected Delivery Date"
              />
            </div>

            {/* <div className="col-12">
              <div className="upload-box product-upload">
                <span>Upload Purchase Agreement (optional)</span>
                <i className="bi bi-cloud-upload ms-2"></i>
                <input
                  className="form-control-md"
                  id="formFileLg"
                  placeholder="Upload your ID photo"
                  type="file"
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                  }}
                />
              </div>
            </div> */}

            <div className="col-12">
              <div className="upload-box">
                <span>
                  {selectedFile ? selectedFile.name : "Upload your ID photo"}
                </span>
                {!selectedFile && <i className="bi bi-cloud-upload ms-2"></i>}
                <input
                  ref={imageInput}
                  className="form-control-md"
                  id="formFileLg"
                  placeholder="Upload your ID photo"
                  type="file"
                  accept="image/*, application/pdf"
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      setSelectedFile(e.target.files[0]);
                    }
                  }}
                />
                {selectedFile && (
                  <i
                    className="bi bi-x-lg close-icon"
                    onClick={() => {
                      imageInput.current.value = null;
                      setSelectedFile(null);
                    }}
                  ></i>
                )}
              </div>
            </div>
          </div>
          <button
            className="btn-sound btn next-btn mt-5"
            data-color="#3361a3"
            data-text="CONTINUE"
            onClick={(e) => handleCreateEscrow(e)}
          >
            CONTINUE
          </button>
        </div>
      </div>
    </div>
  );
}
