import axios from "axios";

import { UMU_URL } from "../constants/config";
import { hmacencrypt } from "./walletKey";

export async function GetPayees(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/GetPayees",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/money/GetPayees`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}

export async function CreatePayee(publickey, privateKey, data = {}) {
  try {
    const value = {
      // sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/CreatePayee",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/money/CreatePayee`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}

export async function transfer(publickey, privateKey, data) {
  try {
    let values = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      `${UMU_URL}/money/Transfer`,
      values,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      url: "/money/Transfer",
      data: values,
      headers: {
        Authorization: token
      }
    });

    /* const response = await axios.post(`${UMU_URL}`, values, {
      headers: {
        Authorization: token
      }
    }); */

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}
