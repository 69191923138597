import { useEffect /* useRef */ } from "react";
import docs from "../assets/docs/umu_whitepaper.pdf";

import "./WhitePaper.css";
import { showLoaderOnElement } from "../utils/loader";

export default function WhitePaperPDF() {
  /* const divRef = useRef(); */

  useEffect(() => {
    document.title = "UMU Whitepaper";
  }, []);

  /* useEffect(() => {
    if (divRef.current) {
      const parentDiv = divRef.current?.closest?.(".mobile-screen");
      if (parentDiv) {
        parentDiv.style.padding = 0;
      }
    }
  }, []); */

  return (
    <div /* style={{ width: "100vw", height: "100vh" }} */
    /* ref={divRef} */
    /* className="home" */
    >
      <img
        src="/images/whitepaper.png"
        style={{ width: "100%", height: "970px" }}
      />

      <div className="container">
        <div className="text-white">
          <div className="row" /* style={{ margin: "0 200px" }} */>
            <div className="mt-3 col-md-4 d-flex justify-content-center flex-column">
              <div>
                <p
                  style={{
                    color: "#fff",
                    fontFamily: "IBM Plex Sans",
                    fontSize: "20px",
                    fontWeight: 400
                    // marginBottom: "10px"
                  }}
                >
                  Universal Monetary Unit
                </p>

                <h1
                  style={{
                    color: "#fff",
                    fontFamily: "Libre Franklin",
                    fontSize: "32px",
                    fontWeight: "500",
                    // lineHeight: "63px",
                    marginBottom: "0"
                  }}
                >
                  An International
                  <br />
                  <span>CBDC</span>
                </h1>
              </div>
            </div>

            <div
              className="col-md-8 d-flex" /* style={{ marginTop: "8rem" }} */
            >
              <div className="my-5">
                <h2 className="top-heading" /* style={{ fontSize: '30px' }} */>
                  A Best-in-Class Money Commodity for Strengthening
                </h2>
                <h2 className="top-heading" /* style={{ fontSize: '30px' }} */>
                  Monetary Sovereignty with a Digital Economic Union
                </h2>

                <h5 className="my-3" style={{ color: "#6eaaff" }}>
                  Abstract
                </h5>

                <p style={{ fontSize: "14px" }}>
                  Bitcoin inspired a generation of cryptographic store of value
                  and medium of exchange innovations intended to disrupt and to
                  circumvent regulated banking and financial institutions. The
                  International Monetary Fund (IMF) recently published a report
                  on the potential risks of crypto assets to the international
                  monetary system and recommended not to provide legal tender
                  status to crypto assets.
                </p>

                <p style={{ fontSize: "14px" }}>
                  The IMF did not consider the possibility of a new class of
                  cryptographic innovations conceived to not only support but to
                  strengthen the monetary sovereignty of the international
                  banking system (i.e., Crypto 2.0).
                </p>

                <p style={{ fontSize: "14px" }}>
                  The Digital Currency Monetary Authority (DCMA) is a world
                  leader in the advocacy of digital currency innovations for
                  governments and monetary authorities and has innovated a
                  best-in-class design for international Central Bank Digital
                  Currency (CBDC) leveraging a digital economic union.
                </p>

                <p style={{ fontSize: "14px" }}>
                  The IMF states it has a mandate to provide economic and
                  financial stability to its member states. There are some noted
                  encumbrances in the current international monetary system that
                  continually challenge emerging markets, and even some advanced
                  economies, in sustaining their monetary sovereignty.
                </p>

                <p style={{ fontSize: "14px" }}>
                  This paper outlines the blueprint for a best-in-class CBDC
                  design and discusses how a digital economic union and an
                  international CBDC could complement and strengthen the
                  international monetary system.
                </p>

                <p style={{ fontSize: "14px" }}>
                  Several sovereign states have collaborated with the DCMA on a
                  Model Law for an international CBDC, or money commodity, and
                  have provided draft legislation for such outlined in Appendix
                  1.
                </p>

                <div>
                  <p style={{ fontSize: "11px" }}>
                    by Darrell Hubbard
                    <br />
                    Harvard MBA, M.S. Computer Science
                  </p>
                </div>

                <div>
                  <a
                    href={"/docs/umu_whitepaper.pdf"}
                    download={"umu_whitepaper"}
                  >
                    <button
                      style={{
                        border: "none",
                        // backgroundColor: "#3361a3",
                        // backgroundColor: "#1f1e32",
                        textTransform: "uppercase",
                        fontWeight: "bold"
                      }}
                      className="btn-sound dw-btn mt-2 px-3 py-2 rounded text-white"
                      role="button"
                      data-color="#3361a3"
                      data-text="DOWNLOAD"
                      /* onClick={(e) => {
                        e.target.closest(".dw-btn").style.backgroundColor =
                          "#3c5881";
                        setTimeout(() => {
                          e.target.closest(".dw-btn").style.backgroundColor =
                            "#3361a3";
                        }, 100);
                      }} */
                    >
                      DOWNLOAD
                      <i className="ms-2 bi bi-download"></i>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
