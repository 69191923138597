import axios from "axios";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "./RegistrationPhoneVerify.css";
import { UMU_URL } from "../../constants/config";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../store/slices/loaderSlice";

export default function RegistrationPhoneVerify() {
  const TIMER = 120;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader);

  const [otp, setOtp] = useState("");
  const [validationError, setValidationError] = useState("");
  const [showResetLink, setShowResetLink] = useState(false);
  const [remainingTime, setRemainingTime] = useState(TIMER);

  useEffect(() => {
    let interval;
    if (!showResetLink) {
      interval = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [showResetLink]);

  useEffect(() => {
    if (remainingTime === 0) {
      setShowResetLink(true);
    }
  }, [remainingTime]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  // console.log(location.state);
  function handleChange(otp) {
    setValidationError(() => "");
    setOtp(otp);
  }

  async function handleSubmit() {
    try {
      // Validation
      let errorMessage = "";

      if (!otp || otp.length < 6) {
        errorMessage = "Please provide a valid 6 digit otp code";
      }

      if (errorMessage) {
        setValidationError(errorMessage);

        return;
      }

      const data = {
        mobilePhoneEmail: `${location.state?.mobileDialingcode}${location.state?.mobilePhone}`,
        otp
      };

      dispatch(setLoader(true));

      const response = await axios.post(
        UMU_URL + "/general/ConfirmVerification",
        data,
        {
          headers: {
            Authorization:
              "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
          }
        }
      );

      if (
        response.data?.returnKeycode === "Success" ||
        response.data?.ReturnKeycode === "Success"
      )
        navigate("/email", {
          state: { ...location.state },
          replace: true
        });
      else
        setValidationError(
          response.data?.returnMessage ||
            response.data?.ReturnMessage ||
            "OTP is not valid"
        );
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoader(false));
    }
  }

  async function getOtp() {
    dispatch(setLoader(true));
    try {
      const response = await axios.post(
        `${UMU_URL}/general/GetMobileVerificationCode`,
        {
          mobilePhone: `${location.state?.mobileDialingcode}${location.state?.mobilePhone}`
        },
        {
          headers: {
            Authorization:
              "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
          }
        }
      );

      if (response.data?.returnKeycode === "Success") {
        setOtp("");
        setShowResetLink(false);
        setRemainingTime(TIMER);
      } else {
        setValidationError(response.data?.returnMessage);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoader(false));
    }
  }

  return (
    <>
      <div className="email-address">
        <div className="container">
          <div className="email-content phone-verify-content">
            <h3>Enter your code</h3>
            <p>
              Please enter 6 digit code we have sent to{" "}
              {location.state?.mobileDialingcode}
              {location.state?.mobilePhone}
            </p>

            {!isLoading && (
              <>
                <div>
                  {validationError ? (
                    <p className="text-danger">{validationError}</p>
                  ) : null}

                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    separator={<span>-</span>}
                    // isInputSecure={true}
                    containerStyle={{
                      padding: "10px",
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                    inputStyle={{ fontSize: "1.5rem", width: "40px" }}
                  />

                  {/* <p
                    className="p-2 btn text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => getOtp()}
                  >
                    RESEND CODE
                  </p> */}

                  {!showResetLink && (
                    <p className="my-0 p-2">
                      Resend otp in: {formatTime(remainingTime)}
                    </p>
                  )}

                  {showResetLink && (
                    <p className="my-0 p-2 btn text-primary" onClick={getOtp}>
                      Reset OTP
                    </p>
                  )}
                </div>

                <button
                  // style={{ marginTop: "0" }}
                  to="/email"
                  className="btn-sound btn next-btn mt-0"
                  onClick={handleSubmit}
                >
                  CONTINUE
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
