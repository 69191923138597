import "./TermPurchaseListing.css";
import { Link } from "react-router-dom";

export default function TermPurchaseListing() {
    return (
        <div class="email-address p-0 identification">
            <div class="container">
                <div class="header">
                    <a class="arrow-bg" href="/splash"><i class="bi bi-arrow-left-short"></i></a>
                    <h3>UMU Term Purchases</h3>
                </div>
                <div class="payees-content">
                    <h6>Search Term Purchases</h6>
                    <div class="d-flex mb-4">
                        <div class="input-group">
                            <span class="input-group-text" id="basic-addon1">
                                <i class="bi bi-search"></i>
                            </span>
                            <input type="text" class="form-control" placeholder="Search..." value="" />
                        </div>
                        <div class="plus-icon ms-3">
                            <Link class="text-white" to="/">
                                <i class="bi bi-plus-lg"></i>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="table-bg table-content mt-5">
                <div className="payees_table_container purchases-list">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="">Term</th>
                                <th className="text-center">Maturity Date</th>
                                <th className=" text-end">Balance</th>
                                <th className=""></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="">
                                    <span>1 Month</span>
                                </td>
                                <td className=" text-center">
                                    <span>Jan 23, 2024</span>
                                </td>
                                <td className=" text-end">
                                    &Uuml;  123,000,000.090999
                                </td>
                                    <td className="">
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="table-icon"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <img src="/icons/table-icon.png" alt="icon" />
                                            </div>
                                            {/*<span className="ms-2"><i class="fa fa-basket-shopping"></i></span>*/}
                                        </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="">
                                    <span>1 Month</span>
                                </td>
                                <td className="text-center">
                                    <span>Jan 23, 2024</span>
                                </td>
                                <td className="text-end">
                                    &Uuml;  123,000,000.090999
                                </td>
                                <td className="">
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="table-icon"
                                                style={{ cursor: "pointer" }}
                                            >
                                                <img src="/icons/table-icon.png" alt="icon" />
                                            </div>
                                            {/*<span className="ms-2"><i class="fa fa-basket-shopping"></i></span>*/}
                                        </div>  
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
        </div>
    )
}