import { Link } from 'react-router-dom';
import './Escrows.css';

export default function Escrows() {
    return (
        <div>
            <div className="email-address p-0">
                <div className="container">
                    <div className="phone-content email-content activity-content">
                        <div className="header text-center">
                            <h3 className="ms-0 mt-3 mb-5">Activity</h3>
                        </div>
                        <div className="tabs my-4">
                            <Link to="/payments">
                                <p className="me-3 ">PAYMENTS</p>
                            </Link>
                            <Link to="/escrows">
                                <p className="active">ESCROWS</p>
                            </Link>
                        </div>
                        <div className="escrows-list mb-3">
                            <div>
                                <h3>Anyel's A22</h3>
                                <p>22 June, 2022 10:24 AM</p>
                            </div>
                            <div className="d-flex align-items-center">
                                <h4 className="me-2">+ 20.00</h4>
                                <div className="bg-icons">
                                    <img src="/icons/escrows-icon.png" alt="icon"/>
                                </div>
                            </div>
                        </div>
                        <div className="escrows-list mb-3">
                            <div>
                                <h3>Anyel's A22</h3>
                                <p>22 June, 2022 10:24 AM</p>
                            </div>
                            <div className="d-flex align-items-center">
                                <h4 className="me-2">+ 20.00</h4>
                                <div className="bg-icons">
                                    <img src="/icons/escrows-icon.png" alt="icon"/>
                                </div>
                            </div>
                        </div>
                        <div className="escrows-list mb-3">
                            <div>
                                <h3>Anyel's A22</h3>
                                <p>22 June, 2022 10:24 AM</p>
                            </div>
                            <div className="d-flex align-items-center">
                                <h4 className="me-2">+ 20.00</h4>
                                <div className="bg-icons">
                                    <img src="/icons/escrows-icon.png" alt="icon"/>
                                </div>
                            </div>
                        </div>
                        <div className="escrows-list mb-3">
                            <div>
                                <h3>Anyel's A22</h3>
                                <p>22 June, 2022 10:24 AM</p>
                            </div>
                            <div className="d-flex align-items-center">
                                <h4 className="me-2">+ 20.00</h4>
                                <div className="bg-icons">
                                    <img src="/icons/escrows-icon.png" alt="icon"/>
                                </div>
                            </div>
                        </div>
                        <div className="escrows-list mb-3">
                            <div>
                                <h3>Anyel's A22</h3>
                                <p>22 June, 2022 10:24 AM</p>
                            </div>
                            <div className="d-flex align-items-center">
                                <h4 className="me-2">+ 20.00</h4>
                                <div className="bg-icons">
                                    <img src="/icons/escrows-icon.png" alt="icon"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
