import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./getpof.css";
import "../milestones/MilestoneConfirm.css"
import { GetPOFSApi } from "../../apis/pof";
import { decrypytdata } from "../../apis/walletKey";
import { setLoader } from "../../store/slices/loaderSlice";

export default function Getpof() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userData } = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.loader);
  const [fxRates, setFxRates] = useState([]);
  const [showRatesOf, setShowRatesOf] = useState("MY_RATES");
  const [recivedData,setRecivedData] = useState([])
  const [sentData,setSentData] = useState([])
  // const [isLoading, setIsLoading] = useState(false);

  const APPID = userData?.PublicAddress;
  const APIKEY = userData?.PrivateKey;
  const userRates = userData?.CurrencycodeList;

  const userRatesList = userRates.split(",");
  const [myRates, setMyRates] = useState([]);

  // const userDataString = localStorage.getItem("user");

  // let userData = null;

  // if (userDataString) {
  //   userData = JSON.parse(userDataString);
  // }

  // const APPID = userData.commandResponse[0].PublicAddress;
  // const APIKEY = userData.commandResponse[0].PrivateKey;

  // console.log(APPID, APIKEY);

  const fetchData = async () => {
    try {
      dispatch(setLoader(true));
      const response = await GetPOFSApi(APPID, APIKEY, {
        currencyPairTypeID: 1
      });

      const fxRatesJSON = await decrypytdata(response?.data, APIKEY);
      const fxRatesObj = JSON.parse(fxRatesJSON);
      let data = [];

      if (fxRatesObj.CommandResponse) {
        data = JSON.parse(fxRatesObj.CommandResponse);
      }

      setFxRates(() => data);

      const recivedData = data.filter((row) => row.SentReceived==="Received")
      setRecivedData(recivedData)
      const sentData = data.filter((row) => row.SentReceived==="Sent")
      setSentData(sentData)
    } catch (error) {
      if (error.response) {
        // const fxRatesJSON = await decrypytdata(error.response?.data, APIKEY);
        // if (!fxRatesJSON) return;
        // const dataJSON = JSON.parse(fxRatesJSON);
        // let data = [];
        // if (dataJSON?.CommandResponse) {
        //   data = JSON.parse(dataJSON?.CommandResponse);
        // }
        // // console.log(data);
      } else {
        console.log(error);
      }
    } finally {
      dispatch(setLoader(false));
    }
  };

  // console.log(myRates);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="email-address p-0 identification milestones">
      <div className="container">
        <div className="header">
          <h3 className="ms-0 mt-3">PROOF OF FUNDS</h3>
        </div>

        <div className="phone-content exchange-rates  email-content d-flex align-items-center mt-4">
          <div className="tabs mt-0">
            <div
              onClick={() => setShowRatesOf("MY_RATES")}
              style={{ cursor: "pointer" }}
            >
              <p
                className={showRatesOf === "MY_RATES" ? "me-3 active" : "me-3"}
              >
                Sent
              </p>
            </div>
            <div
              onClick={() => setShowRatesOf("ALL_RATES")}
              style={{ cursor: "pointer" }}
            >
              <p className={showRatesOf === "ALL_RATES" ? "active" : ""}>
                Recived
              </p>
            </div>
          </div>
       
          <div>
            <Link to="/types_pof" className="plus-icon ms-2">
              <i className="bi bi-plus"></i>
            </Link>
          </div>
        </div>

        <div className="milestones-list mt-4">
          {!isLoading && (
            <>
              {showRatesOf === "MY_RATES" && (
                <>
                {sentData && sentData.map((item,index)=>{
              return (
                <div key={index} className="milestones-box mb-3" >
                  <div>
                    <h6>{item.SentReceived}</h6>
                    <p>{item.TransactionType}</p>
                  </div>
                  <div className="text-end">
                    <h5>{Number(item.SettlementUnits)?.toFixed(3, "0")} {item.SettlementCurrencycode}</h5>
                    <h4 className={item.Status=="Active"?"green":"red"}>{item.Status}</h4>
                  </div>
                </div>
              );
            })}
                </>
              )}

{showRatesOf === "ALL_RATES" && (
                <>
                {recivedData && recivedData.map((item,index)=>{
              return (
                <div key={index} className="milestones-box mb-3" >
                  <div>
                    <h6>{item.SentReceived}</h6>
                    <p>{item.TransactionType}</p>
                  </div>
                  <div className="text-end">
                    <h5>{Number(item.SettlementUnits)?.toFixed(3, "0")} {item.SettlementCurrencycode}</h5>
                    <h4 className={item.Status=="Active"?"green":"red"}>{item.Status}</h4>
                  </div>
                </div>
              );
            })}
                </>
              )}
            </>
          )}

          {/* {!isLoading && (
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th className="text-center">Country</th>
                  <th className="text-center">Currency</th>
                  <th className="text-end">
                    USD
                    <br />
                    Rate
                  </th>
                  <th className="text-end">
                    UMU
                    <br />
                    Rate
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody> */}
          {/* {showRatesOf === "ALL_RATES" &&
                  fxRates &&
                  fxRates.length > 0 &&
                  fxRates.map((rate) => (
                    <tr key={rate.RowID}>
                      <td className="text-center">
                        <img src={rate.QuoteImageFN} alt="flag" />
                      </td>
                      <td className="text-center">{rate.Currencycode}</td>
                      <td className="text-end">
                        <div className="d-flex justify-content-between">
                          <span>{rate.QuoteCurrencySymbol}</span>
                          <span>{Number(rate.BasePrice).toFixed(2)}</span>
                        </div>
                      </td>
                      <td className="text-end">
                        <div className="d-flex justify-content-between">
                          <span>{rate.QuoteCurrencySymbol}</span>
                          <span>{Number(rate.CoinPrice).toFixed(6)}</span>
                        </div>
                      </td>
                      <td>
                        <div
                          className="table-icon"
                          onClick={() =>
                            navigate("/transfer_amount", { state: { rate } })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <img src="/icons/table-icon.png" alt="icon" />
                        </div>
                      </td>
                    </tr>
                  ))} */}
          {/* </tbody>
            </table>
          )} */}
        </div>
      </div>
    </div>
  );
}

/*
<tr key={rate.RowID}>
  <td>
    <div>
      <input type="checkbox" />
    </div>
  </td>
  <td className="text-center">
    <img src={rate.QuoteImageFN} alt="flag" />
  </td>
  <td className="text-center">{rate.Currencycode}</td>
  <td className="text-end">
    <div className="d-flex justify-content-between">
      <span>{rate.QuoteCurrencySymbol}</span>
      <span>{Number(rate.BasePrice).toFixed(2)}</span>
    </div>
  </td>
  <td className="text-end">
    <div className="d-flex justify-content-between">
      <span>{rate.QuoteCurrencySymbol}</span>
      <span>{Number(rate.CoinPrice).toFixed(6)}</span>
    </div>
  </td>
</tr>;
*/

{
  /*
  showRatesOf === "MY_RATES" && (
    <>
      <div className="table-height">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-center">Country</th>
              <th className="text-center">Currency</th>
              <th className="text-end">
                USD
                <br />
                Rate
              </th>
              <th className="text-end">
                UMU
                <br />
                Rate
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {fxRates &&
              fxRates.length > 0 &&
              fxRates.map((rate) => (
                <tr key={rate.RowID}>
                  <td>
                    <div>
                      <input type="checkbox" />
                    </div>
                  </td>
                  <td className="text-center">
                    <img src={rate.QuoteImageFN} alt="flag" />
                  </td>
                  <td className="text-center">{rate.Currencycode}</td>
                  <td className="text-end">
                    <div className="d-flex justify-content-between">
                      <span>{rate.QuoteCurrencySymbol}</span>
                      <span>{Number(rate.BasePrice).toFixed(2)}</span>
                    </div>
                  </td>
                  <td className="text-end">
                    <div className="d-flex justify-content-between">
                      <span>{rate.QuoteCurrencySymbol}</span>
                      <span>{Number(rate.CoinPrice).toFixed(6)}</span>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="text-center mt-4">
        <button className="next-btn mt-4 me-2">CANCEL</button>
        <button className="next-btn mt-4">SUBMIT</button>
      </div>
    </>
  );
              */
}
