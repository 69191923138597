import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { modifyWalletApi } from "../../apis/wallet";
import { decrypytdata } from "../../apis/walletKey";
import { ADMIN_URL, UMU_URL } from "../../constants/config";
import { setUser } from "../../store/slices/userSlice";

import ReactCrop from "react-image-crop";

import "./Wallet.css";
import "react-image-crop/dist/ReactCrop.css";
import { setResponse } from "../../store/slices/responseSlice";

export default function Wallet() {
  const imageInput = useRef();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userData } = useSelector((state) => state.user);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [isImageUploadingOpen, setIsImageUploadingOpen] = useState(false);

  const {
    PublicAddress: APPID,
    PrivateKey: APIKEY,
    Countrycode,
    Currency,
    CurrencyImageFN,
    Currencycode
  } = userData;

  async function handleUpload() {
    try {
      if (!output && !selectedFile) return;

      setIsImageUploading(true);
      setIsImageUploadingOpen(false);
      setSrc(null);
      setImage(null);
      setOutput(null);
      setCrop();

      let filePath = null;

      let imageData = null;

      if (output) {
        const blob = await fetch(output).then((res) => res.blob());

        const file = new File([blob], selectedFile.name, {
          type: selectedFile.type
        });

        imageData = file;
      } else if (selectedFile) {
        imageData = selectedFile;
      }

      const formData = new FormData();

      formData.append("File", imageData);
      formData.append("PublicAddress", APPID);
      formData.append("IsPublic", true);

      const fileUploadResponse = await axios({
        baseURL: UMU_URL,
        url: "/general/UploadFile",
        method: "POST",
        data: formData,
        headers: {
          Authorization:
            "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
        }
      });

      // console.log(fileUploadResponse);

      // return;

      if (fileUploadResponse?.data?.returnMessage === "Success") {
        // const path = fileUploadResponse?.data?.commandResponse;
        filePath = fileUploadResponse?.data?.commandResponse;

        /* const downloadFileResponse = await axios({
          baseURL: UMU_URL,
          url: "/general/DownloadFile",
          method: "POST",
          data: { Filepath: path },
          headers: {
            Authorization:
              "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
          }
        });

        filePath = downloadFileResponse.data?.commandResponse[0]?.presignedUrl; */
      }
      // const imagePath = await uploadImageFile(imageFile);

      if (!filePath) return;

      const modifyWalletResponse = await modifyWalletApi(APPID, APIKEY, {
        imageFN: filePath
      });

      const responseAPI = {
        ...modifyWalletResponse,
        headers: undefined,
        request: undefined,
        config: {
          baseURL: modifyWalletResponse.config.baseURL,
          url: modifyWalletResponse.config.url
        }
      };

      const decryptedModifyWalletResponse = await decrypytdata(
        modifyWalletResponse.data,
        APIKEY
      );

      if (decryptedModifyWalletResponse) {
        const responseObj = JSON.parse(decryptedModifyWalletResponse);

        const commandResponseObj = JSON.parse(responseObj.CommandResponse);

        const newWalletData = { ...userData, ImageFN: filePath };

        localStorage.setItem("user", JSON.stringify(newWalletData));

        dispatch(setUser(newWalletData));
        dispatch(
          setResponse({ apiResponse: responseAPI, apiData: newWalletData })
        );
      } else {
        dispatch(setResponse({ apiResponse: responseAPI, apiData: null }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      // navigate("/result");
      setIsImageUploading(false);
    }
  }

  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 80, y: 50, width: 100, height: 100 });
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);
  const [cropError, setCropError] = useState("");

  useEffect(() => {
    setCropError("");
  }, [crop]);

  const selectImage = (file) => {
    setSrc(URL.createObjectURL(file));
  };

  const cropImageNow = () => {
    if (!crop.width) {
      setOutput(null);
      setCropError("No crop area selected");
      return;
    }

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64
    const base64Image = canvas.toDataURL("image/jpeg");
    setOutput(base64Image);
  };

  return (
    <>
      {/* <div className="email-address p-0 identification milestones "> */}
      <div className="email-address p-0 milestones">
        {isImageUploadingOpen ? (
          // <div style={{ position: "relative" }}>
          <div className="modal show" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  {/* <h5 className="modal-title">Modal title</h5> */}
                  <button
                    type="button"
                    className="btn-sound btn-close"
                    // data-bs-dismiss="modal"
                    // aria-label="Close"
                    onClick={() => {
                      imageInput.current.value = null;
                      setIsImageUploadingOpen(false);
                      setSrc(null);
                      setImage(null);
                      setOutput(null);
                      setCrop(null);
                      setSelectedFile(null);
                    }}
                  ></button>
                </div>
                <div className="modal-body">
                  {/* <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      selectImage(e.target.files[0]);
                    }}
                  /> */}

                  <div>
                    {cropError && <p className="text-danger">{cropError}</p>}

                    {src && (
                      <div className="d-flex justify-content-center align-items-center">
                        <ReactCrop
                          src={src}
                          onImageLoaded={setImage}
                          crop={crop}
                          onChange={setCrop}
                          style={{ height: "225px" }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="my-3 d-flex justify-content-center align-items-center">
                    {output && <img src={output} />}
                    {!output && <img src={image} alt="" />}
                  </div>

                  <div
                    className="d-flex justify-content-around align-items-center"
                    style={{ borderTop: "1px solid #000", padding: "5px" }}
                  >
                    <button
                      className="btn-sound btn"
                      style={{
                        backgroundColor: "#3361a3",
                        color: "#fff"
                      }}
                      onClick={cropImageNow}
                    >
                      CROP
                    </button>
                    <button
                      className="btn-sound btn"
                      style={{
                        backgroundColor: "#3361a3",
                        color: "#fff"
                      }}
                      onClick={handleUpload}
                    >
                      UPLOAD
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div
              style={{
                width: "100%",
                position: "absolute",
                top: "50%",
                // left: "50%",
                // transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                marginTop: "30px",
                padding: "12px"
              }}
            >
              <center>
                
                <br />
                <br />
            
              </center>
            </div> */}
          </div>
        ) : null}

        <div className="container identification">
          {/* <div className="header mt-3">
            <h3 className="ms-0">Wallet</h3>
          </div> */}

          <div className="header">
            <h3 className="ms-0 wallet-head">Wallet</h3>

            <Link
              to="/term_purchase_add"
              title="Term Purchase"
              className="arrow-bg no_arrow_bg"
            >
              <img src="icons\term-light.png" style={{ width: "25px" }} />
            </Link>
          </div>

          <div className="wallet-page mt-5">
            <div className="profile-name text-center">
              {!isImageUploading ? (
                <>
                  <img
                    src={
                      userData?.ImageFN
                        ? userData?.ImageFN
                        : "/images/default.png"
                    }
                    alt="default"
                    style={{ cursor: "pointer", width: "64px", height: "64px" }}
                    onClick={(e) => {
                      const fileInput = e.target.nextSibling;
                      fileInput.value = null;
                      fileInput.click();
                    }}
                  />
                  {/* <img
                    className="user-profile"
                    src="/images/default.png"
                    alt="default"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      const fileInput = e.target.nextSibling;

                      fileInput.click();
                    }}
                  /> */}
                </>
              ) : null}

              {isImageUploading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : null}

              <input
                type="file"
                accept="image/*"
                ref={imageInput}
                style={{ display: "none" }}
                onChange={(e) => {
                  setSrc(null);
                  setImage(null);
                  setOutput(null);
                  setCrop(null);
                  setSelectedFile(e.target.files[0]);
                  selectImage(e.target.files[0]);
                  setIsImageUploadingOpen(true);
                }}
              />
              <h3 className="mb-4 currency">{userData.Displayname}</h3>

              <div className="profile-list-currency mb-3">
                <div>
                  {/* <img
                    src="/icons/personal-icon.png"
                    
                    alt="icon"
                  /> */}
                  <img
                    src={CurrencyImageFN}
                    alt="flag"
                    style={{ width: "25px", height: "18px" }}
                    className="me-3"
                  />
                </div>
                <p>
                  <span className="me-1">
                    {Currencycode} {Currency}
                  </span>
                </p>
              </div>
            </div>

            {/* <img
            src="https://dcma-dev.s3.ap-southeast-1.amazonaws.com/IN/1177995/33245823-f5c0-479e-8fe0-96b515377cb4_luke-tanis-yqarsL2B5dU-unsplash.jpg"
            alt="someimage"
          /> */}

            <Link to="/cashid" className="profile-list mb-3">
              <div>
                <img
                  src="/icons/personal-icon.png"
                  className="me-3"
                  alt="icon"
                />
              </div>
              {/* <h6>Personal Information</h6> */}
              <h6>Cash ID</h6>
            </Link>
            <Link to="/identification_review" className="profile-list mb-3">
              <div>
                <img
                  src="/icons/identification-icon.png"
                  className="me-3"
                  alt="icon"
                />
              </div>
              <h6>Identification</h6>
            </Link>
            <Link to="/link_mobile" className="profile-list mb-3">
              <div>
                <img
                  src="/icons/link-mobile-icon.png"
                  className="me-3"
                  alt="icon"
                />
              </div>
              <h6>Link Mobile Wallet</h6>
            </Link>
            <Link to="/wallet_attachments" className="profile-list mb-3">
              <div>
                <img
                  src="/icons/wallet-attachments-icon.png"
                  className="me-3"
                  alt="icon"
                />
              </div>
              <h6>Wallet Attachments</h6>
            </Link>
            <Link to="/payees" className="profile-list mb-3">
              <div>
                <img src="/icons/payees-icon.png" className="me-3" alt="icon" />
              </div>
              <h6>Payees</h6>
            </Link>
            {/* <Link to="/notify" className="profile-list mb-3">
              <div>
                <img
                  src="/icons/notifications-icon.png"
                  className="me-3"
                  alt="icon"
                />
              </div>
              <h6>Notifications</h6>
            </Link> */}
            <Link to="/security_review" className="profile-list mb-3">
              <div>
                <img
                  src="/icons/privacy-icon.png"
                  className="me-3"
                  alt="icon"
                />
              </div>
              <h6>Privacy and Security</h6>
            </Link>
            <button
              role="button"
              className="btn-sound profile-list w-100"
              data-bs-toggle="modal"
              data-bs-target="#RELEASE"
            >
              <div>
                <img src="/icons/logout-icon.png" className="me-3" alt="icon" />
              </div>
              <h6>Logout</h6>
            </button>
            <div
              className="modal fade"
              id="RELEASE"
              tabIndex="-1"
              aria-labelledby="RELEASE"
              aria-hidden="true"
            >
              <div className="modal-box h-auto">
                <div className="modal-dialog">
                  <div className="icons-fixed">
                    <img src="/icons/success-icon-modal.png" alt="icon" />
                  </div>
                  <h2>Are you sure?</h2>
                </div>

                <div className="d-flex align-items-center mt-3">
                  <div>
                    <button
                      // to="/"
                      className="btn-sound modal-btn me-3"
                      data-bs-dismiss="modal"
                      type="button"
                    >
                      CANCEL
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      // to="/"
                      className="btn-sound modal-btn fill"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        // console.log("Logout");
                        localStorage.clear();
                        dispatch(setUser({ data: null }));
                        navigate("/login");
                      }}
                    >
                      LOG OUT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    //<Link to='/personal_review'>Personal Information</Link>
    //<Link to='/identification_review'>Identification</Link>
    //<Link to='/security_review'>Privacy and Security</Link>
    //<Link to='/link_mobile'>Link Mobile Wallet</Link>
    //<Link to='/attach'>Wallet Attachments</Link>
    //<Link to='/payees'>Payees</Link>
    //<Link to='/notify'>Notifications</Link>
    //<Link to='/logout'>Logout</Link>
  );
}

{
  /* <div className="transaction-page">
  <div className="money-transaction-icon">
    <h3 className="local-currency">Local Currency</h3>
  </div>
  <div className="money-transaction-text">
    <p> */
}
{
  /* {resultData.length > 0 &&
                                       resultData[0].lk1[0].o[0].lk2[0].Status} */
}
{
  /* <img
        src={CurrencyImageFN}
        alt="flag"
        style={{ width: "32px" }}
        className="me-1"
      />
      <span className="me-2">{Currencycode}</span>
      <span className="">{Currency}</span>
    </p>
  </div> */
}
{
  /* <span className="text-white">Local Currency</span>
                <p className="text-light">
                  <img
                    src={CurrencyImageFN}
                    alt="flag"
                    style={{ width: "32px" }}
                    className="me-1"
                  />
                  <span className="me-1">{Currencycode}</span>
                  <span className="me-1">{Currency}</span>
                </p> */
}
{
  /* <span>Country Flag, Currencycode, and Currency Name </span> */
}
/* </div>; */
