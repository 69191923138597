import "./Notification.css";
import { Link, useNavigate } from "react-router-dom";

export default function Notification() {
  const navigate = useNavigate();

  return (
    <div className="email-address p-0 identification milestones ">
      <div className="container">
        <div className="header">
          <Link
            /* to="/splash" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>Notifications</h3>
        </div>
        <div className="confirm-payee-information mt-4">
          <div className="payee-content security-content">
            <h6>
              Push notification to
              <p className="mb-0">
                We will push notification to personal addresses
              </p>
            </h6>
            <div className="form-check form-switch d-flex py-3">
              <label className="form-check-label" for="MobilePhone">
                Mobile Phone
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="MobilePhone"
              />
            </div>
            <h6>
              Notifications when
              <p className="mb-0">We will notify you when</p>
            </h6>
            <div className="form-check form-switch d-flex py-3">
              <label className="form-check-label" for="ReceiveMoney">
                Receive Money
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="ReceiveMoney"
              />
            </div>
            <div className="form-check form-switch d-flex">
              <label className="form-check-label" for="SendMoney">
                Send Money
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="SendMoney"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
