import { Link, useNavigate } from "react-router-dom";
export default function RequestEscrowRelease() {
  const navigate = useNavigate();

  return (
    <div className="identification">
      <div className="container">
        <div className="header">
          <Link
            /* to="/splash" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>Request Escrow Release</h3>
        </div>
        <div className="create-payee-page">
          <p>Escrow Type</p>
          <div className="row">
            <div className="col-4 mb-4">
              <Link to="/request_release_escrow" className="payee-box active">
                <img
                  src="/icons/product-dark.png"
                  className="dark"
                  alt="dark-icon"
                />
                <img
                  src="/icons/product-light.png"
                  className="light"
                  alt="light-icon"
                />
                <h5>Product</h5>
              </Link>
            </div>
            <div className="col-4 mb-4">
              <Link to="/" className="payee-box">
                <img
                  src="/icons/service-dark.png"
                  className="dark"
                  alt="dark-icon"
                />
                <img
                  src="/icons/service-light.png"
                  className="light"
                  alt="light-icon"
                />
                <h5>Service</h5>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Lorem Ipsum"
              />
            </div>

            <div className="col-12 mb-4">
              <input
                type="date"
                className="form-control"
                placeholder="Target Completion Date"
              />
            </div>
            <div className="col-12">
              <div className="upload-box">
                <div className="product-upload">
                  <span>Upload Proof of Release</span>
                  <i className="bi bi-cloud-upload ms-2"></i>
                  <input
                    className="form-control-md"
                    id="formFileLg"
                    placeholder="Upload your ID photo"
                    type="file"
                  />
                </div>
                {/*<div className="row justify-content-center mt-2">*/}
                {/*    <div className="col-3 mb-2">*/}
                {/*        <img src="/images/default.png" className="img-fluid rounded" alt="img" />*/}
                {/*    </div>*/}
                {/*</div>*/}
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="total-list">
                <hr className="mb-3" />
                <div className="d-flex align-items-center justify-content-between">
                  <h6>Escrow Amount</h6>
                  <h3>$ 2,333.00 USD</h3>
                </div>
              </div>
            </div>
          </div>
          <Link to="/" className="btn next-btn mt-5">
            SUBMIT
          </Link>
        </div>
      </div>
    </div>
  );
}
