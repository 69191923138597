import { Link } from "react-router-dom";

import "./UMUPrivacyPolicy.css";

export default function UMUPrivacyPolicy() {
  return (
    <div className="mb-0 email-address pt-0 pb-0 text-white">
      <div className="container identification">
        <div className="header">
          <div>
            <Link
              to="/splash"
              className="arrow-bg p-2"
              style={{ width: "35px", height: "35px" }}
            >
              <i className="bi bi-arrow-left-short"></i>
            </Link>
          </div>
        </div>

        <div>
          <h3 className="mt-3 mb-4 title-heading text-center">
            Universal Monetary Unit (UMU) Privacy Policy
          </h3>
        </div>

        <div className="mt-3 texts">
          <p>
            Where we use “we”, “our” “us” in this Privacy Policy, we mean
            Universal Monetary Unit Corporation unless we say otherwise.
          </p>
          {/* ============ */}
          <h5>Introduction</h5>
          <p>
            Universal Monetary Unit (UMU), Unicoin, is a digital currency public
            monetary system enabling the settlement and payment of domestic and
            international money transfers. To achieve this purpose in compliance
            with banking regulations, we must collect personal information from
            our users.
          </p>
          {/* ============ */}
          <h5>What type of Personal Data do we collect?</h5>
          <p>
            Personal data is any information that relates to an identifiable
            individual, and we may collect this information when you interact
            with our products or services.
          </p>
          <p>
            Among the types of personal data that this application collects, by
            itself or through third parties, there are: Camera permission;
            Telephone permission; Files/Media/Storage permission; Other
            Permissions such as view Network/Wi-Fi connections; view Google
            service configuration; use fingerprint/biometric hardware; upload of
            user’s installed application information.
          </p>
          <p>
            Complete details on each type of personal data collected are
            provided in the dedicated sections of this privacy policy. Personal
            data may be freely provided by the user. Unless specified otherwise,
            all data requested by this application is mandatory and failure to
            provide this data may make it impossible for this application to
            provide its services.
          </p>
          {/* =============== */}
          <h5>Types of Personal Data</h5>
          <p>
            Personal Identifiable Information (PII) (e.g. Name, Address, Date of
            Birth) Address and employment details Your banking information and
            financial details Details of interactions with us (e.g. Call Center,
            WhatsApp Chat, Message Center)
          </p>
          {/* ================== */}
          <h5>Registration and authentication</h5>
          <p>
            By registering or authenticating, users allow this application to
            identify them and give them access to dedicated services. The
            personal data that is stored in the user’s device or on our servers
            are used for registration or identification purposes only.
          </p>
          <p>
            The user registers by filling out the registration form and
            providing the personal data directly to this application. Personal
            data that are processed includes: Full name, mobile phone, email
            address, physical address, date of birth, government identification,
            username, and profile photo.
          </p>
          {/* =============== */}
          <h5>How do we collect your Personal Data?</h5>
          <p>
            Any personal information held by us will be committed to a high
            degree of protection. All PII is encrypted and not stored within our
            transaction systems.
          </p>
          <p>
            We leverage Amazon Web Services (AWS) encryption and security
            features throughout the application. We outsource the encryption and
            handling of personal data and sensitive wallet details with AWS.
          </p>
          <p>
            Here are the ways that we collect your data – through these
            touchpoints which are relevant to how you may interact with our
            products/services in your daily lives:
          </p>
          {/* =============== */}
          <h5>Online and Digital</h5>
          <p>
            Product sign-ups and promotions, contests and giveaways, app
            downloads and registrations, general digital banking services.
          </p>
          {/* =============== */}
          <h5>Cashless Transactions</h5>
          <p>
            Digital payments through website and apps, contactless payments.
          </p>
          {/* =============== */}
          <h5>Mobile Device Personal Data</h5>
          <p>
            DFor our mobile device users, we may further obtain information
            obtained from mobile device with your consent such as device
            permissions for personal data access.
          </p>
          <p>
            Depending on the user’s specific device, this application may
            request certain permissions that allow it to access the user’s
            device data as described below.{" "}
          </p>
          <p>
            By default, these permissions must be granted by the user before the
            respective information can be accessed. Once the permission has been
            given, it can be revoked by the user at any time.
          </p>
          <p>
            The exact procedure for controlling app permissions may be dependent
            on the user’s device and software. Please note that the revoking of
            such permissions might impact the proper functioning of this
            application.
          </p>
          {/* =============== */}
          <h5>Camera permission</h5>
          <p>
            Used for accessing the camera or capturing pictures for profile
            photo, capturing face for face registration and authentication from
            the device, allow to capture photos of personal information like ID,
            passport, employment letter etc., while applying for personal
            information update feature in the application.
          </p>
          {/* =============== */}
          <h5>Telephone permission</h5>
          <p>
            Used for capturing phones status and identity such as Device ID
            (unique Device ID provided by the platform), Device OS type, Device
            Name and Device Model.
          </p>
          {/* =============== */}
          <h5>Files/Media/Storage permission</h5>
          <p>
            Allows upload of photos or documents when you wish to update your
            personal information or profile picture through the application by
            selecting from your phone’s Gallery. You are allowed to select only
            PDF, JPG, JPEG, PNG documents while you wish to apply for updating
            profile information.
          </p>
          <p>
            Your personal information allows us to create more personalized and
            unique user experiences. We analyze personal information to evaluate
            and improve products and services, develop new products or features,
            and conduct audits and troubleshooting activities.
          </p>
          <p>
            When you trust us with your data, it’s only right that you
            understand how we may use it:
          </p>
          {/* =============== */}
          <h5>For General Support</h5>
          <p>
            Verify your identity before providing our services, or responding to
            any of your queries, applications, feed-back and complaints.
          </p>
          {/* =============== */}
          <h5>For our Internal Operations</h5>
          <ol type="a">
            <li>
              Aid our research and analysis, so that we keep improving our
              services, products and advertising strategies.
            </li>
            <li>Manage our day-to-day business operations.</li>
            <li>
              Ensure that the information we have about you is up to date.
            </li>
            <li>
              Conduct market research, project planning, troubleshooting
              problems, detecting, and protecting against error, fraud or other
              criminal activity.
            </li>
            <li>
              Comply with all laws and obligations of any legal authorities.
            </li>
            <li>Seek professional advice, including legal.</li>
            <li>Update you on changes to our products or services. </li>
          </ol>
          {/* =============== */}
          <h5>For Marketing Purposes</h5>
          <p>
            We may contact you to suggest products and services of potential
            interest to you.
          </p>
          {/* =============== */}
          <h5>Who do we share your Personal Data with?</h5>
          <p>
            We reserve the right to share or disclose your PII in its sole
            discretion with regulatory authorities, that the disclosure of such
            PII is necessary or appropriate.
          </p>
          <p>
            For example, we may share PII with regulatory authorities (i) to
            enforce our rights against you or in connection with a breach by you
            of this privacy policy or the terms and conditions of use of our
            digital channels and services, (ii) to prevent prohibited or illegal
            activities, or (iii) when required by law, legal process,
            litigation, or requests from credit agencies, respective central
            banks or any other governmental authorities within the respective
            countries, disclosure is necessary or appropriate.
          </p>
          {/* =============== */}
          <h5>What rights you have regarding your Personal Data?</h5>
          <p>
            Users may exercise certain rights regarding their data processed by
            the owner.
          </p>
          <p>Users have the right to do the following:</p>
          <p>
            <strong>Withdraw their consent at any time.</strong> If you do take
            away your consent, we’ll stop using your personal data straight
            away, except where the law requires us to store it. It’s worth
            knowing that if we can’t use your personal data, it will affect how
            we can provide you with services. It may also mean that our
            relationship with you must end, either partially or completely.
          </p>
          <p>
            <strong>Object to processing of their Data.</strong> Users have the
            right to object to the processing of their data if the processing is
            carried out on a legal basis other than consent.
          </p>
          <p>
            <strong>
              Verify and seek rectification to gain full access to our services.
            </strong>{" "}
            Users have the right to verify the accuracy of their data and ask
            for it to be updated or corrected. You can access the personal data
            that we hold about you at any time.
          </p>

          <p>
            It is your responsibility to keep your personal information up to
            date by updating your profile through our digital channels. We are
            not responsible for any costs, losses, damages or expenses or other
            issues arising from you by not keeping your personal information up
            to date.
          </p>

          <p>Additional information about Data collection and processing –</p>

          <h5>Legal action</h5>
          <p>
            The user’s personal data may be used for legal purposes by the owner
            in Court or in the stages leading to possible legal action arising
            from improper use of this application or the related Services. The
            user declares to be aware that the owner may be required to reveal
            personal data upon request of public authorities.
          </p>

          <h5>System logs and maintenance</h5>
          <p>
            For operation and maintenance purposes, this application may collect
            files that record interaction with this application.
          </p>

          <h5>Changes to this privacy policy</h5>
          <p>
            UMU reserves the right to make changes to this privacy policy from
            time to time by notifying its users on this page and possibly within
            this application to ensure consistency with any developments to the
            way UMU uses your data. It is strongly recommended to check this
            page often, referring to the date of the last modification listed at
            the bottom.
          </p>

          <h5>Contact Us</h5>
          <p>
            Should you have any inquiries with regards to the accuracy of your
            personal information or of any transaction occurring through our
            digital touchpoints, please contact us{" "}
            <span className="text-primary">privacy@unicoinnetwork.com</span>.
          </p>

          <p>
            This Privacy Policy was last reviewed and updated in February, 2023.
          </p>
        </div>
      </div>
    </div>
  );
}
