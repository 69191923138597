import "./Crypto.css";
import { useState } from "react";
import { Link } from "react-router-dom";

// import { getDropdownValues } from "../../../apis/dropdown";

export default function Crypto({
  cryptoData,
  setCryptoData,
  handleSubmit,
  cryptoList,
  selectedCrypto,
  setSelectedCrypto
}) {
  //   const [cryptoList, setCryptoList] = useState([]);
  //   const [selectedCrypto, setSelectedCrypto] = useState(null);

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  //   useEffect(() => {
  //     getDropdownValues("LKCrypto")
  //       .then((response) => {
  //         console.log(response);

  //         setCryptoList(response.data.commandDataset);
  //         setSelectedCrypto(response.data.commandDataset[0]);
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   }, []);

  return (
    <>
      {/* <p>
        Attach your crypto, then transfer your coins to the wallet address
        provided to convert and purchase UMU.
      </p> */}

      <p>
        Attach your crypto, then{" "}
        <Link className="add_umu_link" to="/add_umu">
          ADD FUNDS
        </Link>{" "}
        to fund your wallet.
      </p>

      <div className="d-flex mobile mt-4">
        <div className="crypto-select">
          <div
            className="select"
            onClick={() => setIsDropDownOpen((prev) => !prev)}
            style={{ cursor: "pointer" }}
          >
            <img
              src={`/images/crypto/${selectedCrypto?.ImageFN}`}
              style={{ width: "16px" }}
            />
            <h6>{selectedCrypto?.Keycode}</h6>
            <span>
              {isDropDownOpen ? (
                <i
                  style={{ width: "10px", height: "16px" }}
                  className="fa fa-angle-down text-white"
                ></i>
              ) : (
                <i
                  style={{ width: "10px", height: "16px" }}
                  className="fa fa-angle-right text-white"
                ></i>
              )}
            </span>
          </div>
          {isDropDownOpen ? (
            <div className="crypto-dropdown">
              {cryptoList.map((val, idx) => {
                return (
                  <div
                    key={idx}
                    onClick={() => {
                      setIsDropDownOpen(false);
                      setSelectedCrypto(val);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={`/images/crypto/${val.ImageFN}`}
                      style={{ width: "16px" }}
                    />{" "}
                    <span>{val.Keycode}</span>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
        {/* <select
        className="form-select select rounded-start"
        aria-label="Default select example"
      >
        <option>Open this select menu</option>
        <option value="1">One</option>
        <option value="2">Two</option>
        <option value="3">Three</option>
      </select> */}
        <div className="add">
          <input
            disabled
            type="text"
            className="form-control rounded-end"
            placeholder="Wallet Address"
            defaultValue={selectedCrypto?.DataValue2}
            // onChange={(e) => {
            //   setCryptoData((prev) => {
            //     return { ...prev, cryptoPublicAddress: e.target.value };
            //   });
            // }}
          />
          {/* <button
            className="add-btn border-0"
            onClick={() => {
              handleSubmit();
            }}
          >
            ADD
          </button> */}
        </div>
      </div>

      <div className="add mt-3">
        <input
          type="text"
          className="form-control rounded-end"
          placeholder="Paste Your Wallet Address"
          id="walletInput"
          value={cryptoData.cryptoPublicAddress}
          onChange={(e) => {
            setCryptoData((prev) => {
              return { ...prev, cryptoPublicAddress: e.target.value };
            });
          }}
        />
        <button
          className="btn-sound add-btn border-0"
          onClick={() => {
            handleSubmit();
          }}
        >
          ADD
        </button>
      </div>

      {/* <button
        // className="add-btn border-0"
        className="mt-3 py-1 rounded"
        style={{ width: "100%" }}
        // onClick={() => {
        //   handleSubmit();
        // }}
      >
        ADD
      </button> */}
    </>
  );
}

// {cryptoList.map((val, idx) => {
//     return (
//       <div key={idx}>
//         <img
//           src={`/images/crypto/${val.ImageFN}`}
//           style={{ width: "16px" }}
//         />{" "}
//         <span>{val.Keycode}</span>
//       </div>
//     );
//   })}
