import { createSlice } from "@reduxjs/toolkit";

const responseSlice = createSlice({
  name: "response",
  initialState: {
    apiData: null,
    apiResponse: null
  },
  reducers: {
    setResponse: (state, action) => {
      state.apiData = action.payload.apiData || null;
      state.apiResponse = action.payload.apiResponse || null;
    }
  }
});

export default responseSlice.reducer;

export const { setResponse } = responseSlice.actions;
