import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import "./Balances.css";
import "../services/Balance.css";
import { getBalancesApi } from "../../apis/balances";
import { decrypytdata } from "../../apis/walletKey";
import { setLoader } from "../../store/slices/loaderSlice";
import { hideLoaderFromElement, showLoaderOnElement } from "../../utils/loader";

export default function Balances() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: userData } = useSelector((state) => state.user);

  const [balanceList, setBalanceList] = useState([]);

  let umuBalance = balanceList.find((item) => item.Keycode === "UMU");
  let termBalance = balanceList.find((item) => item.Keycode === "TERM");
  let marginBalance = balanceList.find((item) => item.Keycode === "MARGIN");
  let cashBalance = balanceList.find((item) => item.Keycode === "CASH");
  let cashBalances = balanceList.filter((item) => item.Keycode === "CASH");
  let escrowBalances = balanceList.filter((item) => item.Keycode === "ESCROW");

  //   console.log("umuBalance", umuBalance);
  //   console.log("termBalance", termBalance);
  //   console.log("marginBalance", marginBalance);
  //   console.log("cashBalance", cashBalance);
  //   console.log("escrowBalances", escrowBalances);

  const {
    PublicAddress: APPID,
    PrivateKey: APIKEY,
    Balance,
    Displayname
  } = userData;

  useEffect(() => {
    async function main() {
      try {
        dispatch(setLoader(true));
        await getBalances();
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setLoader(false));
      }
    }
    main();
  }, []);

  async function getBalances() {
    const getBalancesResponse = await getBalancesApi(APPID, APIKEY);

    const decryptedResponse = await decrypytdata(
      getBalancesResponse?.data,
      APIKEY
    );

    const jsonResponse = decryptedResponse && JSON.parse(decryptedResponse);

    if (
      jsonResponse &&
      jsonResponse.ReturnKeycode === "Success" &&
      jsonResponse?.CommandResponse
    ) {
      const data = JSON.parse(jsonResponse?.CommandResponse);
      //   console.log(data);
      setBalanceList(data);
    }
  }

  return (
    <div>
      <div className="email-address p-0 identification">
        <div className="container">
          <div className="header">
            <h3 className="wallet-head text-center ms-0">Balances</h3>
          </div>

          <div className="balance">
            <p>Hello!</p>
            <h6>{Displayname}</h6>

            <div className="mt-5">
              {umuBalance && (
                <div
                  className="btn-sound balance-box mx-2 px-1 py-2 text-white row align-items-center balances_box"
                  onClick={() => {
                    navigate("/balance");
                  }}
                  style={{ backgroundColor: "#3c3939" }}
                >
                  <div className="col-1 text-start">
                    <img
                      src={`/icons/${umuBalance.ImageFN}`}
                      className="balances_box_image"
                      // style={{ width: "32px" }}
                    />
                  </div>
                  <div className="col text-end">
                    <h5
                      className="balances_box_heading"
                      // style={{ color: "#4ba9ed", fontSize: "18px" }}
                    >
                      {umuBalance.Displayname}
                    </h5>
                    <span>
                      Ü
                      {new Intl.NumberFormat(undefined, {
                        currency: "USD",
                        minimumFractionDigits: 6
                      }).format(umuBalance.Balance)}
                    </span>
                  </div>
                  <div className="col-1 text-end">
                    <i className="fa-sharp fa-solid fa-angle-right"></i>
                  </div>
                </div>
              )}

              {termBalance && (
                <div
                  className="btn-sound balance-box mt-3 mx-2 px-1 py-2 text-white row align-items-center balances_box"
                  onClick={() => {
                    navigate("/term_balance");
                  }}
                  style={{ backgroundColor: "#3c3939" }}
                >
                  <div className="col-1 text-start">
                    <img
                      src={`/icons/${termBalance.ImageFN}`}
                      className="balances_box_image"
                      // style={{ width: "32px" }}
                    />
                  </div>
                  <div className="col text-end">
                    <h5
                      className="balances_box_heading"
                      // style={{ color: "#4ba9ed", fontSize: "18px" }}
                    >
                      {termBalance.Displayname}
                    </h5>
                    <span>
                      Ü
                      {new Intl.NumberFormat(undefined, {
                        currency: "USD",
                        minimumFractionDigits: 6
                      }).format(termBalance.Balance)}
                    </span>
                  </div>
                  <div className="col-1 text-end">
                    <i className="fa-sharp fa-solid fa-angle-right"></i>
                  </div>
                </div>
              )}

              {marginBalance && (
                <div
                  className="btn-sound balance-box mt-3 mx-2 px-1 py-2 text-white row align-items-center balances_box"
                  onClick={() => {
                    navigate("/margin_balance");
                  }}
                  style={{ backgroundColor: "#3c3939" }}
                >
                  <div className="col-1 text-start">
                    <img
                      src={`/icons/${marginBalance.ImageFN}`}
                      className="balances_box_image"
                      // style={{ width: "32px" }}
                    />
                  </div>
                  <div className="col text-end">
                    <h5
                      className="balances_box_heading"
                      // style={{ color: "#4ba9ed", fontSize: "18px" }}
                    >
                      {marginBalance.Displayname}
                    </h5>
                    <span>
                      Ü
                      {new Intl.NumberFormat(undefined, {
                        currency: "USD",
                        minimumFractionDigits: 6
                      }).format(marginBalance.Balance)}
                    </span>
                  </div>
                  <div className="col-1 text-end">
                    <i className="fa-sharp fa-solid fa-angle-right"></i>
                  </div>
                </div>
              )}

              {/* {cashBalance && (
                <div
                  className="balance-box mt-3 mx-2 px-1 py-2 text-white row align-items-center balances_box"
                  onClick={() => {
                    navigate(
                      `/cash_balance?Currencycode=${cashBalance?.Currencycode}`
                    );
                  }}
                  style={{ backgroundColor: "#3c3939" }}
                >
                  <div className="col-1 text-start">
                    <img
                      src={`/images/${umuBalance.ImageFN}`}
                      className="balances_box_image"
                    />
                  </div>
                  <div className="col text-end">
                    <h5 className="balances_box_heading">
                      {cashBalance.Currencycode} {cashBalance.Displayname}
                    </h5>
                    <span>
                      {new Intl.NumberFormat(undefined, {
                        currency: cashBalance.Currencycode,
                        style: "currency",
                        minimumFractionDigits: 3
                      }).format(cashBalance.Balance)}
                    </span>
                  </div>
                  <div className="col-1 text-end">
                    <i className="fa-sharp fa-solid fa-angle-right"></i>
                  </div>
                </div>
              )} */}

              {cashBalances.map((item, index) => {
                return (
                  <div
                    key={`${item.Displayname}-${index}`}
                    className="btn-sound balance-box mt-3 mx-2 px-1 py-2 text-white row align-items-center balances_box"
                    onClick={() => {
                      navigate(
                        `/cash_balance?Currencycode=${item?.Currencycode}`
                      );
                    }}
                    style={{ backgroundColor: "#3c3939" }}
                  >
                    <div className="col-1 text-start">
                      <img
                        src={`/images/${umuBalance.ImageFN}`}
                        className="balances_box_image"
                      />
                    </div>
                    <div className="col text-end">
                      <h5 className="balances_box_heading">
                        {item.Currencycode} {item.Displayname}
                      </h5>
                      <span>
                        {new Intl.NumberFormat(undefined, {
                          currency: item.Currencycode,
                          style: "currency",
                          minimumFractionDigits: 3
                        }).format(item.Balance)}
                      </span>
                    </div>
                    <div className="col-1 text-end">
                      <i className="fa-sharp fa-solid fa-angle-right"></i>
                    </div>
                  </div>
                );
              })}

              {escrowBalances.map((item, index) => {
                return (
                  <div
                    key={`${item.Displayname}-${index}`}
                    className="btn-sound balance-box mt-3 mx-2 px-1 py-2 text-white row align-items-center balances_box"
                    onClick={() => {
                      navigate(
                        `/escrow_balance?Currencycode=${item.Currencycode}`
                      );
                    }}
                    style={{ backgroundColor: "#3c3939" }}
                  >
                    <div className="col-1 text-start">
                      <img
                        src={`/images/${umuBalance.ImageFN}`}
                        className="balances_box_image"
                      />
                    </div>
                    <div className="col text-end">
                      <h5
                        className="balances_box_heading"
                        // style={{ color: "#4ba9ed", fontSize: "18px" }}
                      >
                        {item.Currencycode} {item.Displayname}
                      </h5>
                      <span>
                        {item.Currencycode === "UMU"
                          ? `Ü${new Intl.NumberFormat(undefined, {
                              currency: item.Currencycode,
                              minimumFractionDigits: 6
                            }).format(item.Balance)}`
                          : new Intl.NumberFormat(undefined, {
                              currency: item.Currencycode,
                              style: "currency",
                              minimumFractionDigits: 3
                            }).format(item.Balance)}
                      </span>
                    </div>
                    <div className="col-1 text-end">
                      <i className="fa-sharp fa-solid fa-angle-right"></i>
                    </div>
                  </div>
                );
              })}

              {/* <div className="mt-3 identification">
                <button
                  className="btn-sound m-0 btn next-btn"
                  data-color="#3361a3"
                  data-text="Submit"
                  style={{ width: "100%" }}
                  onClick={(e) => {
                    showLoaderOnElement(e.target);
                    setTimeout(() => {
                      hideLoaderFromElement(e.target);
                    }, 5000);
                  }}
                >
                  Submit
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
