import { createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
  name: "error",
  initialState: false,
  reducers: {
    setLoader: (state, action) => action.payload
  }
});

export default loaderSlice.reducer;

export const { setLoader } = loaderSlice.actions;
