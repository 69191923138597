import {Link} from 'react-router-dom';

import './Payments.css';

export default function Payments() {
    return (
        <div>
            <div className="email-address p-0">
                <div className="container">
                    <div className="phone-content email-content activity-content">
                        <div className="header text-center">
                            <h3 className="ms-0 mt-3 mb-5">Activity</h3>
                        </div>
                        <div>
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                                <input type="text" className="form-control border-left-0" placeholder="Search..." />
                            </div>
                        </div>
                        <div className="tabs my-4">
                            <Link to="/payments">
                                <p className="me-3 active">PAYMENTS</p>
                            </Link>
                            <Link to="/escrows">
                                <p className="">ESCROWS</p>
                            </Link>
                        </div>
                        <div className="payments-list mb-3">
                            <div>
                                <h3>Anyel's A22</h3>
                                <p>22 June, 2022 10:24 AM</p>
                            </div>
                            <h6 className="mb-0 green">+ 20.00</h6>
                        </div>
                        <div className="payments-list mb-3">
                            <div>
                                <h3>Hasan</h3>
                                <p>22 June, 2022 10:24 AM</p>
                            </div>
                            <h6 className="mb-0 red">- 120.00</h6>
                        </div>
                        <div className="payments-list mb-3">
                            <div>
                                <h3>Giana</h3>
                                <p>22 June, 2022 10:24 AM</p>
                            </div>
                            <h6 className="mb-0 red">- 6.00</h6>
                        </div>
                        <div className="payments-list mb-3">
                            <div>
                                <h3>Novel 99</h3>
                                <p>22 June, 2022 10:24 AM</p>
                            </div>
                            <h6 className="mb-0 green">+ 90.00</h6>
                        </div>
                        <div className="payments-list mb-3">
                            <div>
                                <h3>Adam Husein</h3>
                                <p>22 June, 2022 10:24 AM</p>
                            </div>
                            <h6 className="mb-0 green">+ 33.00</h6>
                        </div>
                        <div className="payments-list mb-3">
                            <div>
                                <h3>Anyel's A22</h3>
                                <p>22 June, 2022 10:24 AM</p>
                            </div>
                            <h6 className="mb-0 green">+ 20.00</h6>
                        </div>
                        <div className="payments-list mb-3">
                            <div>
                                <h3>Hasan</h3>
                                <p>22 June, 2022 10:24 AM</p>
                            </div>
                            <h6 className="mb-0 red">- 120.00</h6>
                        </div>
                        <div className="payments-list mb-3">
                            <div>
                                <h3>Giana</h3>
                                <p>22 June, 2022 10:24 AM</p>
                            </div>
                            <h6 className="mb-0 red">- 6.00</h6>
                        </div>
                        <div className="payments-list mb-3">
                            <div>
                                <h3>Novel 99</h3>
                                <p>22 June, 2022 10:24 AM</p>
                            </div>
                            <h6 className="mb-0 green">+ 90.00</h6>
                        </div>
                        <div className="payments-list last">
                            <div>
                                <h3>Adam Husein</h3>
                                <p>22 June, 2022 10:24 AM</p>
                            </div>
                            <h6 className="mb-0 green">+ 33.00</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
