import React from "react";
import { Link, useSearchParams } from "react-router-dom";

const CommandResultSuccess = ({ state }) => {
  const [params] = useSearchParams();

  console.log(params.get("command"));

  const commandResponseData =
    state.response.apiData?.CommandResponse ||
    state.response.apiData?.commandResponse
      ? JSON.parse(
          state.response.apiData?.CommandResponse ||
            state.response.apiData?.commandResponse
        )
      : "";

  const removeArr = [
    "PublicAddress",
    "BlockID",
    "Datetimestamp",
    "Command",
    "CommandType",
    "Status",
    "TxHash",
    "NetworkFee",
    "Objectcode",
    "Objectname"
  ];

  const initialValues = {
    Transaction:
      state.response.apiData?.TransactionHash ||
      state.response.apiData?.transactionHash ||
      "NULL",
    NetworkResponseMessage:
      state.response.apiData?.ReturnMessage ||
      state.response.apiData?.returnMessage ||
      "NULL",
    CreationDate:
      state.response.apiData?.CreationDate ||
      state.response.apiData?.creationDate ||
      "NULL",
    ElapsedTime:
      state.response.apiData?.ElapsedTime ||
      state.response.apiData?.elapsedTime ||
      "NULL",
    ReturnMessage:
      state.response.apiData?.ReturnMessage ||
      state.response.apiData?.returnMessage ||
      "NULL",
    ReturnKeycode:
      state.response.apiData?.ReturnKeycode ||
      state.response.apiData?.returnKeycode ||
      "NULL",
    ReturnCode:
      state.response.apiData?.Returncode ||
      state.response.apiData?.returncode ||
      "NULL",

    NodeNetwork: state.response.apiResponse?.config?.baseURL || "NULL",
    EndPoint: state.response.apiResponse?.config?.url || "NULL",
    NetworkResponseCode: state.response.apiResponse?.status || "NULL",

    PublicAddress: state.user.data?.PublicAddress
      ? state.user.data?.PublicAddress
      : "NULL",
    PrivateKey: state.user.data?.PrivateKey
      ? state.user.data?.PrivateKey
      : "NULL"
  };

  const regex =
    /(?:[A-Z](?=[A-Z][a-z])|[^A-Z](?=[A-Z])|[A-Za-z](?=[^A-Za-z]))/g;

  return (
    <div className="transaction-page">
      <div className="row g-0">
        <div className="col-12 identification mb-4">
          <div className="header">
            <h3 className="ms-0 mt-3">
              {commandResponseData?.Command?.replace?.(regex, " ")} Result
            </h3>
          </div>
        </div>
        <div className="col-12">
          <div className="heading">
            <div className="money-heading">
              <h3>Transaction</h3>
              {initialValues?.Transaction && (
                <p>
                  {/* {resultData.length > 0 &&
                                       resultData[0].TxHash} */}
                  {initialValues?.Transaction}
                </p>
              )}
            </div>
            {/* <i className="bi bi-pencil-square"></i> */}
          </div>
          <hr className="mb-4" />
        </div>

        <div className="col-12">
          {initialValues?.NodeNetwork && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Node Network <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultData.length > 0 &&
                                       encryptData.data.config.baseURL} */}
                  {initialValues?.NodeNetwork}
                </p>
              </div>
            </>
          )}

          {initialValues?.EndPoint && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  End Point <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultData.length > 0 &&
                                       encryptData.data.config.url} */}
                  {initialValues?.EndPoint}
                </p>
              </div>
            </>
          )}

          {initialValues?.NetworkResponseCode && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Network Response Code{" "}
                  <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultData.length > 0 &&
                                       encryptData.data.status} */}
                  {initialValues?.NetworkResponseCode}
                </p>
              </div>
            </>
          )}

          {initialValues?.NetworkResponseMessage && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Network Response Message{" "}
                  <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultData.length > 0 &&
                                       selectData.data.returnMessage} */}
                  {initialValues?.NetworkResponseMessage}
                </p>
              </div>
            </>
          )}
          {initialValues?.CreationDate && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Creation Date <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {state.EncryptDataReducer.data?.data.Returncode} */}
                  {initialValues?.CreationDate}
                </p>
              </div>
            </>
          )}
          {initialValues?.ElapsedTime && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Elapsed Time <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {state.EncryptDataReducer.data?.data.Returncode} */}
                  {initialValues?.ElapsedTime}
                </p>
              </div>
              <hr className="mt-4" />
            </>
          )}

          {commandResponseData && commandResponseData?.Command && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Command <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultData.length > 0 &&
                                       resultData[0].Command} */}
                  {commandResponseData?.Command}
                </p>
              </div>
            </>
          )}

          {commandResponseData && commandResponseData?.CommandType && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Command Type <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultData.length > 0 &&
                                       resultData[0].lk1[0].CommandType} */}
                  {/* {state.resultReducer.data.transactionHash} */}
                  {commandResponseData.CommandType}
                </p>
              </div>
            </>
          )}

          {commandResponseData && commandResponseData?.PublicAddress && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Public Address <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultData.length > 0 &&
                                       resultData[0].Datetimestamp} */}
                  {commandResponseData?.PublicAddress}
                </p>
              </div>
            </>
          )}

          {commandResponseData && commandResponseData?.Objectcode && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Object Code <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultData.length > 0 &&
                                       resultData[0].lk1[0].o[0].Objectcode} */}
                  {/* {state.resultReducer.data.transactionHash} */}
                  {commandResponseData?.Objectcode}
                </p>
              </div>
            </>
          )}

          {commandResponseData && commandResponseData?.Objectname && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Object Name <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultData.length > 0 &&
                                       resultData[0].lk1[0].o[0].Objectname} */}
                  {/* {state.resultReducer.data.transactionHash} */}
                  {commandResponseData?.Objectname}
                </p>
              </div>
            </>
          )}
        </div>

        <div className="col-12">
          {/* {initialValues?.PublicAddress && (
                    <>
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="fa fa-circle-question me-2"></i>Public Address
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                         
                          </p>
                        </div>
                      </div>
                    </div>
                  )} */}
          {/* {initialValues?.PublicAddress
                              ? initialValues?.PublicAddress
                              : "NULL"} */}
          {/* {initialValues?.PrivateKey && (
                    <>
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="fa fa-circle-question me-2"></i>Private Key
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                           
                          </p>
                        </div>
                      </div>
                    </div>
                  )} */}
          {/* {initialValues?.PrivateKey
                    ? initialValues?.PrivateKey
                    : "NULL"} */}
          {initialValues?.ReturnMessage && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Return Message <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultData.length > 0 &&
                                       selectData.data.returnMessage} */}
                  {/* {state.resultReducer.data.transactionHash} */}
                  {initialValues?.ReturnMessage}
                </p>
              </div>
            </>
          )}

          {initialValues?.ReturnKeycode && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Return Keycode <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultData.length > 0 &&
                                       selectData.data.returnKeycode} */}
                  {/* {state.resultReducer.data.transactionHash} */}
                  {initialValues?.ReturnKeycode}
                </p>
              </div>
            </>
          )}

          {initialValues?.ReturnCode >= 0 && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Return Code <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultData.length > 0 &&
                                       selectData.data.returnKeycode} */}
                  {/* {state.resultReducer.data.transactionHash} */}
                  {initialValues?.ReturnCode}
                </p>
              </div>
              <hr className="mt-4" />
            </>
          )}
        </div>

        <div className="col-12">
          {commandResponseData && commandResponseData?.TxHash && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  TxHash <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p style={{ fontSize: "12px" }}>
                  {commandResponseData?.TxHash}
                </p>
              </div>
            </>
          )}

          {commandResponseData && commandResponseData?.Status && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  TxReceipt Status{" "}
                  <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultData.length > 0 &&
                                       resultData[0].lk1[0].o[0].lk2[0].Status} */}
                  {commandResponseData?.Status}
                </p>
              </div>
              {/* <hr className="mt-4" /> */}
            </>
          )}

          {commandResponseData && commandResponseData?.BlockID && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Block <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultData.length > 0 &&
                                       resultData[0].BlockID} */}
                  {commandResponseData?.BlockID}
                </p>
              </div>
            </>
          )}

          {commandResponseData && commandResponseData?.Datetimestamp && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Time Stamp <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultData.length > 0 &&
                                       resultData[0].Datetimestamp} */}
                  {commandResponseData?.Datetimestamp}
                </p>
              </div>
            </>
          )}

          {commandResponseData && commandResponseData?.NetworkFee >= 0 && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Network Fee <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <div className="d-flex">
                  <p className="brown-p pe-2">
                    Ü {commandResponseData.NetworkFee.toFixed(6)}
                  </p>
                  {/* {resultData.length > 0 &&
                                       resultData[0].NetworkFee} */}
                  {/* {commandResponseData[0].NetworkFee} */}

                  {/* {commandResponseData.NetworkFee} */}
                </div>
              </div>
              <hr className="mt-4" />
            </>
          )}
        </div>

        {/* {console.log(
                  Object.keys(commandResponseData),
                  "===-=-==-=-=-=-=-=-"
                )}
                {console.log(
                  Object.keys(commandResponseData).forEach((key) =>
                    console.log(
                      key,
                      commandResponseData[key],
                      "------------------------------------"
                    )
                  ),
                  "===-=-==-=-=-=-=-=-=---------------[["
                )} */}
        {/* {console.log(
                  Object.keys(commandResponseData),
                  "===-=-==-=-=-=-=-=-"
                )} */}
        {/* console.log(key , "this is key") */}

        {/* {Object.keys(commandResponseData)
                  .filter((data) => !removeArr.includes(data))
                  .forEach((key) => {
                    console.log(
                      key,
                      "=",
                      commandResponseData[key],
                      "---------------------------------"
                    );
                  })} */}

        {Object.entries(commandResponseData).map((row) => {
          let value;
          let key = row[0];

          if (typeof row[1] === "number") {
            const str = key.toLowerCase();
            const substringsToCheck = ["price", "amount", "balance", "fee"];

            const containsSubstring = substringsToCheck.some((substring) =>
              str.includes(substring)
            );

            if (containsSubstring) {
              value = new Intl.NumberFormat("en-us", {
                currency: "USD",
                style: "currency"
              }).format(row[1]);
            }
          } else {
            value = row[1];
          }

          // console.log(key, value);
          const str = key;
          key = str.replace(regex, " ");

          // console.log(key); // "USD Token I Am Token"

          return (
            <div className="col-12" key={key}>
              <>
                <div className="money-transaction-icon">
                  <h3>
                    {/* {key.split(/(?=[A-Z])/).join(" ")
                      ? key.split(/(?=[A-Z])/).join(" ")
                      : key}{" "} */}
                    {key} <i className="fa fa-circle-question me-2"></i>
                  </h3>
                </div>
                <div className="money-transaction-text">
                  <p>
                    {/* {resultData.length > 0 &&
                                       resultData[0].lk1[0].o[0].lk2[0].Status} */}
                    {value}
                  </p>
                </div>
              </>
              {/* <hr className="mb-4" /> */}
            </div>
          );
        })}

        {/* {Object.keys(commandResponseData)
          .filter((data) => !removeArr.includes(data))
          .map((key, index) => {
            return (
              <div className="col-12" key={index}>
                <>
                  <div className="money-transaction-icon">
                    <h3>
                      {key.split(/(?=[A-Z])/).join(" ")
                        ? key.split(/(?=[A-Z])/).join(" ")
                        : key}{" "}
                      <i className="fa fa-circle-question me-2"></i>
                    </h3>
                  </div>
                  <div className="money-transaction-text">
                    <p> */}
        {/* {resultData.length > 0 &&
                                       resultData[0].lk1[0].o[0].lk2[0].Status} */}
        {/* {commandResponseData[key]}
                    </p>
                  </div>
                </> */}
        {/* <hr className="mb-4" /> */}
        {/* </div>
            );
          })} */}
      </div>

      {params.get("command") && params.get("command") === "REGISTER" ? (
        <div className="d-grid">
          <Link
            to="/login"
            className="btn-sound btn btn-primary"
            style={{ background: "#3361A3" }}
            replace={true}
          >
            Login
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default CommandResultSuccess;
