import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import "./Login.css";
import { UMU_URL } from "../../constants/config";
import { setUser } from "../../store/slices/userSlice";
import { setLoader } from "../../store/slices/loaderSlice";
import { resetEmailAPI } from "../../apis/general";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.loader);

  // const [isLoading, setIsLoading] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: ""
  });
  const [validationError, setValidationError] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [resetEmailError, setResetEmailError] = useState("");

  function validateResetEmail() {
    if (!resetEmail) return "email is required";
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(resetEmail))
      return "Email is not valid";
  }

  async function handleResetEmail() {
    let error = validateResetEmail();
    if (error) {
      setResetEmailError(error);
      return;
    }

    try {
      dispatch(setLoader(true));
      const response = await resetEmailAPI(resetEmail);

      if (response.data) {
        navigate(`/change_password?email=${resetEmail}`);
      } else {
        setResetEmailError("Failed to generate otp for your email");
      }
    } catch (error) {
      console.error(error);
      setResetEmailError("Failed to generate otp for your email");
    } finally {
      dispatch(setLoader(false));
    }
  }

  function handleLoginDataChange(e) {
    const { name, value } = e.target;

    setLoginData((prev) => ({ ...prev, [name]: value?.trim?.() }));
  }

  function validateLoginData(data) {
    const { email, password } = data;

    if (!email) return "Email is required";
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
      // return "Email is not valid";
      return "Invalid credentials";
    if (!password) return "Password is required";
    if (password.length < 8)
      // return "Password must be atleast 8 characters long";
      return "Invalid credentials";
    if (
      !password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/
      )
    )
      // return "Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character";
      return "Invalid credentials";

    return null;
  }

  async function handleLogin() {
    const data = loginData;

    setValidationError(() => "");
    const error = validateLoginData(data);
    if (error) {
      setValidationError(() => error);
      return;
    }

    try {
      dispatch(setLoader(true));

      const response = await axios.post(`${UMU_URL}/general/Login`, data, {
        headers: {
          Authorization:
            "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
        }
      });

      if (response.data.returnKeycode === "Success") {
        const userData = JSON.parse(
          response.data?.commandResponse || response.data?.CommandResponse || ""
        );

        if (!userData) {
          setValidationError(
            response.data?.ReturnMessage || response.data?.returnMessage
          );
          return;
        }

        dispatch(setUser(userData));

        localStorage.setItem(
          "user",
          response.data?.CommandResponse || response.data?.commandResponse
        );
        localStorage.setItem("login", JSON.stringify(response.data));
      } else {
        setValidationError(
          response?.data?.ReturnMessage || response?.data?.returnMessage
        );

        return;
      }

      navigate("/get_balances", { replace: true });
    } catch (err) {
      if (err.response) {
        setValidationError(
          err.response?.data?.ReturnMessage || err.response?.data?.returnMessage
        );
        return;
      }

      setValidationError(err.message);
    } finally {
      dispatch(setLoader(false));
    }

    /* try {
      setLoading(() => true);

      // Make API call
      const response = await axios.post(`${UMU_URL}/general/Login`, data, {
        headers: {
          Authorization:
            "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
        }
      });

      // console.log(response);

      // return;

      // dispatch(setUser(response.data.commandResponse[0]));
      localStorage.setItem("user", JSON.stringify(response.data));

      navigate("/balance");
    } catch (error) {
      console.log(error);

      // Handle error
      if (error.response) {
        console.log(error.response.data);
      }
    } finally {
      setLoading(() => false);
    } */
  }

  return (
    <div className="splash email-address">
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Reset your password
              </h1>
              <button
                type="button"
                className="btn-sound btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {resetEmailError && (
                <p className="text-danger">{resetEmailError}</p>
              )}
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-sound btn btn-primary btn-blue"
                onClick={handleResetEmail}
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="splash-content">
          <div className="row justify-content-center">
            <div className="col-8">
              <img
                src="/images/splash-logo.png"
                className="my-5 img-fluid w-100"
                alt="logo"
              />
            </div>
          </div>

          {isLoading ? (
            <></>
          ) : (
            <>
              <h3>Login</h3>
              <div className="email-content">
                {validationError && (
                  <p className="text-danger">{validationError}</p>
                )}

                <input
                  type="text"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  value={loginData.email}
                  onChange={handleLoginDataChange}
                />
                <input
                  type="password"
                  name="password"
                  className="form-control mt-4"
                  placeholder="Password"
                  value={loginData.password}
                  onChange={handleLoginDataChange}
                />
              </div>

              <p className="mt-3 text-end">
                <button
                  className="btn-sound forgot-password"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  Forgot your password?
                </button>
              </p>

              <div className="text-center">
                <button
                  className="btn-sound next-btn mt-2"
                  onClick={handleLogin}
                >
                  <i className="bi bi-arrow-right-circle-fill"></i>SUBMIT
                </button>
              </div>

              <p className="mt-3 text-center">
                <Link
                  to="/mobilephone"
                  className="create_account"
                  type="button"
                >
                  Create a new account
                </Link>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
