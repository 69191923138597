import "./TermPurchaseListing.css";
import "./TermPurchaseAdd.css";
import CurrencyInput from "react-currency-input-field";
import { Fragment, useRef, useState, useEffect } from "react";
import axios from "axios";
import { UMU_URL } from "../../constants/config";
import { Link, useNavigate } from "react-router-dom";
import { getFxRatesApi2 } from "../../apis/FxRatesApi";
import { useDispatch, useSelector } from "react-redux";
import { FROM_UMU } from "../../utils/conversion";
import { setLoader } from "../../store/slices/loaderSlice";

import { hideLoaderFromElement, showLoaderOnElement } from "../../utils/loader";
import { useContext } from "react";
import { ModalOverlayContext } from "../../context/ModalOverlayContext";

export default function TermPurchaseAdd() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toggleModal } = useContext(ModalOverlayContext);
  const { data: userData } = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.loader);

  const { PublicAddress: APPID, PrivateKey: APIKEY, Currencycode } = userData;

  const amountInputElement = useRef(null);

  const [termPurchaseMarginBox, setTermPurchaseMarginBox] = useState(true);
  const [termPurchaseMaturityBox, setTermPurchaseMaturityBox] = useState(true);
  const [termRates, setTermRates] = useState([]);
  const [selectedTermRate, setSelectedTermRate] = useState(null);
  const [validationError, setValidationError] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState("");

  const handleChange = (value) => {
    let maxAmount = 10000000000.123456;

    if (Number(value) > maxAmount) return;

    if (value) {
      const amountValue = Number(value).toLocaleString("us-en");

      if (amountValue.length <= 16) {
        amountInputElement.current.style.fontSize = "32px";
      }

      if (amountValue.length > 16) {
        amountInputElement.current.style.fontSize = "26px";
      }
    } else {
      amountInputElement.current.style.fontSize = "32px";
    }

    setAmount(() => value);
  };

  useEffect(() => {
    async function main() {
      try {
        dispatch(setLoader(true));
        await getTermRates();
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setLoader(false));
      }
    }

    main();
  }, []);

  async function getTermRates() {
    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      url: "/general/GetTermRates",
      headers: {
        Authorization:
          "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
      }
    });

    // console.log(response);

    setTermRates(response.data || []);
  }

  async function getFxRates() {
    try {
      // dispatch(setLoader(true));

      const response = await getFxRatesApi2(APPID, APIKEY, {
        currencycodeList: Currencycode
      });

      return response.data;
    } catch (error) {
      console.error(error);
    } finally {
      // dispatch(setLoader(false));
    }
  }

  function validate() {
    if (!selectedTermRate) return "Please select one term rate";
    if (!amount) return "Please enter the amount";
    if (isNaN(+amount)) return "Amount should be a valid number";
  }

  async function handleContinue(e) {
    setValidationError("");

    const error = validate();
    if (error) {
      setValidationError(error);
      return;
    }

    try {
      // dispatch(setLoader(true));
      showLoaderOnElement(e.target);
      toggleModal(true);

      const selectedTerm = termRates.find((_, idx) => idx === selectedTermRate);

      const enteredAmount = +Number(amount)?.toFixed?.(6, "0");
      let marginFee = 0.0;

      if (termPurchaseMarginBox) {
        marginFee = 0.2 * enteredAmount;
      }

      const totalAmount = Number(enteredAmount + marginFee)?.toFixed?.(6, "0");

      const fxRateData = await getFxRates();
      const walletAmount = Number(
        FROM_UMU(Number(fxRateData[0].CoinPrice), Number(totalAmount))
      ).toFixed(6, "0");

      navigate("/term_purchase_add_review", {
        state: {
          selectedTerm,
          walletFxRate: fxRateData[0],
          walletAmount,
          amount: enteredAmount,
          totalAmount,
          marginFee: +Number(marginFee).toFixed(6, "0"),
          enableMargin: termPurchaseMarginBox,
          autoRenew: termPurchaseMaturityBox
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      // dispatch(setLoader(false));
      toggleModal(false);
      hideLoaderFromElement(e.target);
    }
  }

  // console.log(isCommectsCheck);

  return (
    <div className="email-address p-0 identification">
      <div className="container">
        <div className="header">
          <Link
            className="arrow-bg btn-sound"
            role="button"
            onClick={() => navigate(-1)}
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>UMU Term Purchases</h3>
        </div>

        {isLoading ? (
          <></>
        ) : (
          <div>
            <div className="payees-content">
              <p
                style={{ fontSize: "18px" }}
                className="my-0 mt-4 py-0 text-success text-center"
              >
                <strong>Select Term Purchase Period</strong>
              </p>
              <p>
                Term purchases are paid from your UMU Balance and can be
                liquidated early by selling to another party.
              </p>
            </div>

            <div
              className="table-bg table-content mt-3"
              style={{ height: "320px", overflowY: "auto" }}
            >
              <table id="maintable" className="table mb-0">
                <thead>
                  <tr>
                    <th className=""></th>
                    <th className="px-0 text-end text-success">
                      <strong>Term Purchase</strong>
                    </th>
                    <th className="px-0 text-end text-success">
                      <strong>Discount</strong>
                    </th>
                    <th className="px-0 text-end text-success">
                      <strong>UMU Price</strong>
                    </th>
                    <th className=""></th>
                  </tr>
                </thead>
                <tbody>
                  {termRates.map((li, idx) => {
                    return (
                      <Fragment key={idx}>
                        <tr
                          className={`term_rates`}
                          id="row_container"
                          style={{
                            cursor: "pointer",
                            backgroundColor: `${
                              selectedTermRate == idx
                                ? "rgb(239 239 239 / 50%)"
                                : "#1f1e32"
                            }`
                          }}
                          onClick={(e) => {
                            setSelectedTermRate(idx);
                          }}
                        >
                          <td className="border-0"></td>
                          <td className="border-0 text-end">
                            <span className="payee_name mb-0">
                              {li.Displayname}
                            </span>
                          </td>
                          <td className="border-0 text-end  ">
                            <span className="mb-0">
                              {Number(li.DiscountPercent).toFixed(2, "0")}%
                            </span>
                          </td>
                          <td
                            className="border-0"
                            style={{ textAlign: "right" }}
                          >
                            <div className="d-flex justify-content-between">
                              <span className="ps-3">$</span>
                              <span style={{ marginLeft: "auto" }}>
                                {Number(li.TermPrice).toFixed(2, "0")}
                              </span>
                            </div>
                          </td>
                          <td className="border-0"></td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="term_purchase_comments_box mt-4 text-white">
              <p
                style={{ fontSize: "18px" }}
                className="my-0 py-0 text-success text-center"
              >
                <strong>LIMITED TIME BONUS</strong>
              </p>
              <p className="mb-0 text-center">
                Double your Term Purchase for only 20% more.
              </p>
              <p className="my-2 text-center">
                The bonus coins will show in your Margin Account. Margin sales
                are subject to a 50/50 revenue share.
              </p>
              <p className="text-center">
                Applicable to term purchases of 1 year or longer.
              </p>

              <p>
                <input
                  className="me-1"
                  type="checkbox"
                  checked={termPurchaseMarginBox}
                  onChange={(e) => setTermPurchaseMarginBox(e.target.checked)}
                />{" "}
                Yes, double my term purchase using margin.
              </p>

              <p>
                <input
                  className="me-1"
                  type="checkbox"
                  checked={termPurchaseMaturityBox}
                  onChange={(e) => setTermPurchaseMaturityBox(e.target.checked)}
                />{" "}
                Yes, renew my term purchase upon maturity.
              </p>
            </div>

            {/* <div className="enter-amount mt-0">
              <div
                className="enterp-amount-content"
                style={{ cursor: "pointer", marginTop: "10px" }}
              >
                <div className="box-logo me-3">
                  <img src="/icons/flags/umu.png" alt="icons" />
                </div>
                <div className="form-floating">
                  <div className="">
                    <div className="text-light">
                      <div>
                        <div className="font-size d-flex justify-content-between">
                          <div>Balance</div>
                          <span className="text-success me-2">0.000000</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div>
              <h3
                className="text-white text-center mt-5"
                style={{ fontWeight: "bold", fontSize: "18px" }}
              >
                Enter Purchase Amount
              </h3>
            </div>

            <div className="enter-amount mt-1">
              <div className="d-flex align-items-center">
                <h1 className="mb-0 me-2" style={{ fontSize: "32px" }}>
                  Ü
                </h1>
                <CurrencyInput
                  ref={amountInputElement}
                  placeholder="0"
                  className="form-control"
                  defaultValue={"0"}
                  value={amount}
                  onValueChange={handleChange}
                  decimalsLimit={6}
                  allowNegativeValue={false}
                  style={{ textAlign: "right", height: "55px" }}
                />
              </div>
            </div>

            <div className="payees-content">
              {validationError && (
                <p className="mt-1 mb-0 text-danger">{validationError}</p>
              )}
            </div>

            <div>
              <button
                style={{ width: "100%", margin: "16px 0" }}
                to="/payee_username"
                className="btn-sound btn next-btn"
                data-color="#3361a3"
                data-text="CONTINUE"
                onClick={(e) => handleContinue(e)}
              >
                CONTINUE
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
