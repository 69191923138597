import "../payments/TransferReview.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { decrypytdata } from "../../apis/walletKey";
import { buy } from "../../apis/ledger";
import { useDispatch, useSelector } from "react-redux";
import { setResponse } from "../../store/slices/responseSlice";
import { setLoader } from "../../store/slices/loaderSlice";

import { hideLoaderFromElement, showLoaderOnElement } from "../../utils/loader";
import { useContext } from "react";
import { ModalOverlayContext } from "../../context/ModalOverlayContext";

export default function TermPurchaseAddReview() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userData } = useSelector((state) => state.user);
  const { toggleModal } = useContext(ModalOverlayContext);

  const { PublicAddress: APPID, PrivateKey: APIKEY } = userData;

  console.log(location.state);

  const {
    refersTo = "originator",
    amount,
    transactionId,
    item
  } = location.state;

  async function submit(e) {
    try {
      // dispatch(setLoader(true));
      showLoaderOnElement(e.target);
      toggleModal(true);

      const termPurchaseResponse = await buy(APPID, APIKEY, {
        sourcePublicAddress: APPID,
        sourceCurrencycode: item?.WalletLink,
        sourceUnits: amount,
        purchaseTypecode: "SPOT",
        productcode: "UMU",
        paymentMethodcode: "Crypto",
        referenceNumber: transactionId
      });

      const decryptedResponse = await decrypytdata(
        termPurchaseResponse.data,
        APIKEY
      );

      const responseAPI = {
        ...termPurchaseResponse,
        headers: undefined,
        request: undefined,
        config: {
          baseURL: termPurchaseResponse.config.baseURL,
          url: termPurchaseResponse.config.url
        }
      };
      const response = JSON.parse(decryptedResponse);

      console.log(response);

      dispatch(setResponse({ apiResponse: responseAPI, apiData: response }));

      navigate("/result", { replace: true });
    } catch (error) {
      console.error(error);
    } finally {
      // dispatch(setLoader(false));
      toggleModal(false);
      hideLoaderFromElement(e.target);
    }
  }

  return (
    <>
      <div className="email-address p-0">
        <div className="identification">
          <div className="container">
            <div className="header">
              <Link
                /* to="/splash" */ role="button"
                onClick={() => navigate(-1, { replace: true })}
                className="arrow-bg"
              >
                <i className="bi bi-arrow-left-short"></i>
              </Link>
              <h3>Summary</h3>
            </div>

            <div className="summary-box">
              <div className="summary-box-logo">
                <img src="/images/summary-logo.png" alt="logo" />
              </div>

              <h1 className="text-center">Summary</h1>

              {/* <div className="summary-list mb-3"> */}
              {/* {refersTo === "originator" && <p>To</p>}
                {refersTo === "recipient" && <p>From</p>} */}
              {/* <h5 className=" mb-3">{selectedTerm.Displayname}XYZ</h5> */}
              {/* </div> */}

              {/* <div className="d-flex justify-content-between mb-3">
                <div className="summary-list mb-3">
                  <p>Bank Currency</p>
                  <h5>{settlementCurrency}</h5>
                </div>
                <div className="summary-list text-end mb-3">
                  <p>Liquidate Amount</p>
                  <h5>{Number(settlementAmount)?.toFixed(6, "0")}</h5>
                </div>
              </div> */}

              <div className="d-flex justify-content-between mb-3">
                <div className="summary-list mb-3">
                  <p>Crypto</p>
                  <h5>
                    {item?.Displayname || "NULL"}
                    {/* {Number(walletFxRate.QuotePrice)?.toFixed(6, "0")} */}
                  </h5>
                </div>

                <div className="summary-list mb-3 text-end">
                  <p>{item?.Displayname}</p>
                  {/* <h5>123.123{Number(amount)?.toFixed(6, "0")}</h5> */}
                  <img
                    className="payee_image"
                    // src="/images/default.png"
                    src={`images/crypto/${item.ImageFN}`}
                    alt="default-img"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between mb-3">
                <div className="summary-list mb-3">
                  <p>Amount</p>
                  <h5>{amount}</h5>
                  {/* <h5>{walletFxRate.QuoteCurrencycode}XYZ</h5> */}
                </div>

                <div className="summary-list mb-3 text-end">
                  <p>Transaction ID</p>
                  <h5>{transactionId}</h5>
                  {/* <h5>{Number(walletAmount)?.toFixed(6, "0")}XYZ</h5> */}
                </div>
              </div>

              <div className="d-flex justify-content-end mb-3"></div>
            </div>

            <div className="text-center">
              <button
                data-color="#3361a3"
                data-text="CONTINUE"
                className="btn-sound next-btn w-100 mt-3"
                onClick={(e) => submit(e)}
              >
                CONTINUE
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
