import { NavLink } from "react-router-dom";

export default function Navbar() {
  return (
    <div className="header-bottom">
      <div className="header-bottom-bg">
        <div className="logo-bg">
          <img src="/icons/logo-bg.png" alt="bg" />
        </div>
        <div className="d-flex align-items-center">
          <NavLink
            to="/wallet"
            className="px-3 text-center"
            style={{ textDecoration: "none" }}
          >
            {({ isActive }) => (
              <>
                <img
                  src="/icons/header-user.png"
                  className="dark"
                  alt="icons"
                />
                <img
                  src="/icons/header-user-light.png"
                  className="light"
                  alt="icons"
                />
                <h6 className={isActive ? "text-white" : ""}>Wallet</h6>
              </>
            )}
          </NavLink>
          <NavLink
            to="/activity"
            className="px-3 text-center"
            style={{ textDecoration: "none" }}
          >
            {({ isActive }) => (
              <>
                <img
                  src="/icons/header-accounting.png"
                  className="dark"
                  alt="icons"
                />
                <img
                  src="/icons/header-accounting-light.png"
                  className="light"
                  alt="icons"
                />
                <h6 className={isActive ? "text-white" : ""}>Activity</h6>
              </>
            )}
          </NavLink>
        </div>
        <NavLink
          to="/get_balances"
          className="center-part"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <>
              <div className=" text-center">
                <img
                  src="/images/splash-logo.png"
                  className="main-logo"
                  alt="icons"
                />
                <h6 className={isActive ? "text-white" : ""}>Balances</h6>
              </div>
            </>
          )}
        </NavLink>
        <div className="d-flex align-items-center">
          <NavLink
            // to="/transfer_amount"
            to="/payees"
            className="px-3 text-center"
            style={{ textDecoration: "none" }}
          >
            {({ isActive }) => (
              <>
                <img
                  src="/icons/header-transfer.png"
                  className="dark"
                  alt="icons"
                />
                <img
                  src="/icons/header-transfer-light.png"
                  className="light"
                  alt="icons"
                />
                <h6 className={isActive ? "text-white" : ""}>Transfer</h6>
              </>
            )}
          </NavLink>
          <NavLink
            to="/fx_rates"
            className="px-3 text-center"
            style={{ textDecoration: "none" }}
          >
            {({ isActive }) => (
              <>
                <img
                  className={isActive ? "text-white dark" : "dark"}
                  src="/icons/header-percent.png"
                  /* className="dark" */ alt="icons"
                />
                <img
                  className={isActive ? "text-white light" : "light"}
                  src="/icons/header-percent-light.png"
                  /* className="light" */ alt="icons"
                />
                <h6 className={isActive ? "text-white" : ""}>Rates</h6>
              </>
            )}
          </NavLink>
        </div>
      </div>
    </div>
  );
}
