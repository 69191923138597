import "./RegistrationCashId.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { UMU_URL, UMU_SITE } from "../../constants/config";
import { useDispatch } from "react-redux";
import { setLoader } from "../../store/slices/loaderSlice";
import { requestWallet } from "../../apis/wallet";
import { setResponse } from "../../store/slices/responseSlice";

export default function RegistrationCashId() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isSkipped, setIsSkipped] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const [validationError, setValidationError] = useState("");

  const [popup, setPopup] = useState({
    success: false,
    show: false
  });

  const [cashData, setCashData] = useState({
    cashID: "",
    shortDescription: "",
    profileDescription: ""
  });

  // Make api call
  async function validateCashId() {
    if (!cashData.cashID) {
      setValidationError("CashID is requred");
      return;
    }

    try {
      const response = await axios.post(
        `${UMU_URL}/general/ValidateCashID`,
        {
          cashID: cashData.cashID
        },
        {
          headers: {
            Authorization:
              "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
          }
        }
      );

      if (response.data.returnKeycode === "Success") {
        setPopup({ success: true, show: true });
        setIsVerified(true);
      } else
        setValidationError(
          response.data?.returnMessage || response.data?.ReturnMessage || ""
        );
    } catch (error) {
      console.error(error);
    }
  }

  async function register(data) {
    /* const data = {
      mobilePhone: "1234567890",
      userProfileTypecode: "Individual",
      mobileDialingcode: "+91",
      currencycode: "INR",
      mobileCountrycode: "IN",
      paymentCurrencycode: "UMU",
      currencycodeList: null,
      firstname: "XYZ",
      secondFirstname: null,
      lastname: "PQR",
      secondLastname: null,
      fullname: "XYZ PQR",
      emailAddress: "pqr2@gmail.com",
      cashID: "xyzpqr1234",
      shortDescription: "xyzpqr1234",
      profileDescription: "xyzpqr1234",
      password: "12345678",
      userDocumentTypecode: "DL",
      documentNumber: "1234567890",
      dateOfBirth: "2002-01-25",
      expirationDate: "2023-02-28",
      documentFN: null
    }; */

    const referralCode = localStorage.getItem("referralCode");

    if (referralCode) {
      data = { ...data, referralcode: referralCode };
    }

    try {
      dispatch(setLoader(true));
      const requestWalletResponse = await requestWallet(data);
      // console.log(requestWalletResponse);
      const responseAPI = {
        ...requestWalletResponse,
        headers: undefined,
        request: undefined,
        config: {
          baseURL: requestWalletResponse.config.baseURL,
          url: requestWalletResponse.config.url
        }
      };

      if (
        requestWalletResponse?.data?.returnKeycode === "Success" ||
        requestWalletResponse?.data?.ReturnKeycode === "Success"
      ) {
        dispatch(
          setResponse({
            apiResponse: responseAPI,
            apiData: requestWalletResponse?.data
          })
        );

        localStorage.removeItem("referralCode");
      } else {
        dispatch(
          setResponse({
            apiResponse: responseAPI,
            apiData: requestWalletResponse?.data
          })
        );
      }

      navigate("/result?command=REGISTER", { replace: true });
    } catch (error) {
      setValidationError(error.message);
    } finally {
      dispatch(setLoader(false));
    }
  }

  async function handleVerifySubmit() {
    if (isVerified) {
      const data = {
        ...location.state,
        cashID: cashData.cashID,
        shortDescription: cashData.shortDescription || null,
        profileDescription: cashData.profileDescription || null
      };

      register(data);
    } else setValidationError("Please verify cash id");
  }

  return (
    <>
      <div className="email-address">
        <div className="container">
          <div className="email-content">
            <h3 className="text-center">Cash ID</h3>
            <p>
              Enter an easy to remember public cash identifier to receive money.
            </p>

            <div className="identification mt-5">
              <p className="text-danger">{validationError}</p>
              <div className=" create-payee-page pt-5">
                <div className="bank">
                  <div className="add">
                    <input
                      type="text"
                      className="form-control mt-4"
                      placeholder="Cash ID"
                      id="CashIDinput"
                      value={cashData.cashID}
                      onChange={(e) => {
                        setCashData((prev) => ({
                          ...prev,
                          cashID: e.target.value
                        }));
                      }}
                    />
                    <button
                      className="btn-sound add-btn border-0"
                      onClick={() => validateCashId()}
                    >
                      VERIFY
                    </button>
                  </div>
                  {/* <select
                    className="mt-4 form-select"
                    aria-label="Default select example"
                  >
                    <option>Industry</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select> */}

                  <input
                    type="text"
                    className="form-control mt-4"
                    placeholder="Industry or Profession (Optional)"
                    value={cashData.shortDescription}
                    onChange={(e) => {
                      setCashData((prev) => ({
                        ...prev,
                        shortDescription: e.target.value
                      }));
                    }}
                  />

                  <div>
                    <textarea
                      className="form-control mt-4"
                      placeholder="Profile Description (Optional)"
                      rows="3"
                      value={cashData.profileDescription}
                      onChange={(e) => {
                        setCashData((prev) => ({
                          ...prev,
                          profileDescription: e.target.value
                        }));
                      }}
                    ></textarea>
                  </div>

                  <div className="text-center mt-5 d-flex align-items-center">
                    <button
                      type="button"
                      className="btn-sound btn next-btn next-btn-border me-3 mt-0"
                      onClick={() => {
                        register({ ...location.state });
                      }}
                    >
                      SKIP
                    </button>

                    <button
                      type="button"
                      className="btn-sound next-btn mt-0"
                      onClick={handleVerifySubmit}
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {popup.show ? (
              <div className="popup-model">
                {popup.show && popup.success ? (
                  <div className="modal-box">
                    <div className="icons-fixed">
                      <img src="/icons/success-icon.png" alt="icon" />
                    </div>
                    <h2>Your Cash ID</h2>
                    <div className="modal-list">
                      <h5>
                        {UMU_SITE}/cashID/
                        <br />
                        {cashData.cashID}
                      </h5>
                    </div>
                    <div className="d-flex align-items-center w-100 mt-3">
                      <button
                        className="btn-sound modal-btn me-3 w-50 justify-content-center"
                        onClick={() => {
                          setPopup({ show: false, success: false });
                        }}
                      >
                        CLOSE
                      </button>
                      <button
                        className="btn-sound modal-btn fill w-50 justify-content-center"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${UMU_SITE}/cashID/${cashData.cashID}`
                          );

                          setIsVerified(true);
                          setPopup({ show: false, success: false });
                        }}
                      >
                        COPY
                      </button>
                    </div>
                  </div>
                ) : null}

                {popup.show && !popup.success ? (
                  <div className="modal-box">
                    <div className="icons-fixed">
                      <img src="/icons/fail.png" alt="icon" />
                    </div>
                    <h2>Cash ID is taken</h2>
                    <div className="text-center mt-3 mb-5">
                      <h6>Try another cash ID</h6>
                    </div>
                    <div className="d-flex align-items-center w-100 mt-3">
                      <button
                        className="btn-sound modal-btn fill w-100 justify-content-center"
                        onClick={() =>
                          setPopup({ show: false, success: false })
                        }
                      >
                        CLOSE
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
