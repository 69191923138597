import { useState, useRef, useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";
import { useDispatch, useSelector } from "react-redux";

import "./TransferAmount.css";
import { GetFXRatesApi, getFxRatesApi2 } from "../../apis/FxRatesApi";
import { decrypytdata } from "../../apis/walletKey";
import { setLoader } from "../../store/slices/loaderSlice";
import { TO_UMU } from "../../utils/conversion";
import { FROM_UMU } from "../../utils/conversion";

export default function TransferAmount() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader);
  const { data: userData } = useSelector((state) => state.user);

  const { PublicAddress: APPID, PrivateKey: APIKEY, Balance } = userData;

  const amountInputElement = useRef(null);

  const [validationError, setValidationError] = useState("");
  const [amount, setAmount] = useState("");
  const [fxRates, setFxRates] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [payeeData, setPayeeData] = useState(
    location?.state?.payeeData || null
  );
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [walletRates, setWalletRates] = useState(null);
  const [settlementRates, setSettlementRates] = useState(null);

  if (!payeeData) {
    return <Navigate to="/payees" replace={true} />;
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // setIsLoading(() => true);
      dispatch(setLoader(true));
      const response = await getFxRatesApi2(APPID, APIKEY, {
        currencycodeList: userData.CurrencycodeList
      });

      // const fxRatesJSON = await decrypytdata(response?.data, APIKEY);

      // const dataJSON = JSON.parse(fxRatesJSON);

      // console.log(response.data);

      let data = response.data || [];

      // if (dataJSON?.CommandResponse) {
      //   data = JSON.parse(dataJSON?.CommandResponse);
      // }

      // console.log(data);

      setFxRates(() => data);

      if (!location?.state?.rate) {
        const myCurrencyRate = data.find(
          (item) => item.QuoteCurrencycode === userData.Currencycode
        );

        const umuFound = data.find((item) => item.QuoteCurrencycode === "UMU");

        setWalletRates(myCurrencyRate);
        setSettlementRates(umuFound || data[0]);

        // if (myCurrencyRate) {
        // setSelectedCurrency(myCurrencyRate);
        // setWalletRates(myCurrencyRate);
        // setSettlementRates(myCurrencyRate);
        // } else if (umuFound) {
        // setSelectedCurrency(umuFound);
        // setSettlementRates(umuFound);
        // setWalletRates(umuFound);
        // } /*  else { */
        //   setSelectedCurrency(data[0]);
        //   setSettlementRates(data[0]);
        // }
      } /* else if (location?.state?.rate) {
        // console.log(location?.state?.rate);
        const currencyRate = location.state?.rate?.Currencycode;
        const list = userData.CurrencycodeList.split(",");

        if (list.includes(currencyRate)) {
          setSelectedCurrency(location?.state?.rate);
          setSettlementRates(location?.state?.rate);
          setWalletRates(location?.state?.rate);
        } else {
          const umuFound = data.find(
            (item) => item.QuoteCurrencycode === "UMU"
          );

          if (umuFound) {
            setSelectedCurrency(umuFound);
            setSettlementRates(umuFound);
            setWalletRates(umuFound);
          } else {
            setSelectedCurrency(data[0]);
          }
        }
      } */
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoader(false));
    }
  };

  const handleChange = (value) => {
    let maxAmount = 10000000000.123456;

    if (Number(value) > maxAmount) return;

    if (value) {
      const amountValue = Number(value).toLocaleString("us-en");

      if (amountValue.length <= 16) {
        amountInputElement.current.style.fontSize = "32px";
      }

      if (amountValue.length > 16) {
        amountInputElement.current.style.fontSize = "26px";
      }
    } else {
      amountInputElement.current.style.fontSize = "32px";
    }

    setAmount(() => value);
  };

  const toggleOptions = () => {
    setIsOptionsOpen(() => !isOptionsOpen);
  };
  /* 
  // {
  //   SettlementCurrencyCode,
  //   SettlementAmount,
  //   OriginatorCurrencyCode,
  //   BankBasisPoints,
  //   BankFee,
  //   UMUBasisPoints,
  //   PartyName
  // }
  const RecipientReceipt = async () => {
    // const response1 = await GetFXRatesApi(APPID, APIKEY, {
    //   currencyPaircode: "USD" + selectedCurrency.QuoteCurrencycode
    // });
    // const fxRatesJSON1 = await decrypytdata(response1?.data, APIKEY);
    // const fxRatesJsonResponse1 = JSON.parse(fxRatesJSON1);
    // console.log("API Call 1", fxRatesJsonResponse1);
    // let data1;
    // if (fxRatesJsonResponse1.CommandResponse) {
    //   data1 = JSON.parse(fxRatesJsonResponse1.CommandResponse);
    // }
    // console.log("API Call 1", data1);
  };

  // {
  //   SettlementCurrencyCode,
  //   SettlementAmount,
  //   OriginatorCurrencyCode,
  //   BankBasisPoints,
  //   BankFee,
  //   UMUBasisPoints,
  //   PartyName
  // }
  const SenderReceipt = async (data) => {
    // Make api call

    const response1 = await GetFXRatesApi(APPID, APIKEY, {
      currencyPaircode: "USD" + selectedCurrency.QuoteCurrencycode
    });

    const fxRatesJSON1 = await decrypytdata(response1?.data, APIKEY);

    const fxRatesJsonResponse1 = JSON.parse(fxRatesJSON1);

    let data1;

    if (fxRatesJsonResponse1.CommandResponse) {
      data1 = JSON.parse(fxRatesJsonResponse1.CommandResponse);
    }

    console.log("API Call 1", data1);
    // ==============
    const response2 = await GetFXRatesApi(APPID, APIKEY, {
      currencyPaircode: selectedCurrency.Currencycode + "UMU"
    });

    const fxRatesJSON2 = await decrypytdata(response2?.data, APIKEY);

    const fxRatesJsonResponse2 = JSON.parse(fxRatesJSON2);

    let data2;

    if (fxRatesJsonResponse2.CommandResponse) {
      data2 = JSON.parse(fxRatesJsonResponse2.CommandResponse);
    }

    console.log("API Call 2 =>", data2);
  };

  const handleTest = async () => {
    try {
      const encryptedResponse = await GetFXRatesApi(APPID, APIKEY, {
        currencyPairTypeID: null,
        currencyPairList: "EURUMU,UMUINR"
      });

      const decryptedResponse = await decrypytdata(
        encryptedResponse?.data,
        APIKEY
      );

      const fxRatesData = JSON.parse(decryptedResponse);

      console.log(fxRatesData);

      let data;

      if (fxRatesData.CommandResponse) {
        data = JSON.parse(fxRatesData.CommandResponse);
      }

      console.log("API Call 2 =>", data);
    } catch (error) {
      const decryptedResponse = await decrypytdata(
        error.response?.data,
        APIKEY
      );

      const fxRatesData = JSON.parse(decryptedResponse);

      console.log(fxRatesData);

      let data;

      if (fxRatesData.CommandResponse) {
        data = JSON.parse(fxRatesData.CommandResponse);
      }

      console.log("API Call 2 =>", data);
    }
  };

  function TO_UMU(UMU_FXRate, Amount, UMU_UNITS_OUTPUT) {
    UMU_UNITS = (1.0 / UMU_FXRate) * Amount;
  }

   */
  /* function FROM_UMU(UMU_FXRate, Amount) {
    const WALLET_UNITS = UMU_FXRate * Amount;
    return WALLET_UNITS;
  } */

  function validate(amount) {
    // Remove the belove line
    // return null;

    if (!amount) return "Amount is required";
    else if (isNaN(Number(amount))) return "Amount should be a valid number";
    else if (
      Number(amount) > Number(FROM_UMU(settlementRates?.CoinPrice, Balance))
    )
      return "Insufficient Balance";

    return null;
  }

  /* console.log("===============", walletRates);
  console.log("===============", settlementRates); */

  function handlePayClick() {
    const error = validate(amount);

    if (error) {
      setValidationError(error);
      return;
    }

    const SETTLEMENT_UMU_UNITS = TO_UMU(settlementRates?.CoinPrice, amount);
    /* console.log(
      `SETTLEMENT_UMU_UNITS = (1.0 / SelectedQuoteCurrency_QuotePrice[${selectedCurrency.QuotePrice}]) * amount[${amount}] = `,
      SETTLEMENT_UMU_UNITS
    ); */

    // const WALLET_UMU_UNITS = SETTLEMENT_UMU_UNITS * walletRates.QuotePrice;
    const WALLET_UMU_UNITS = FROM_UMU(
      walletRates.CoinPrice,
      SETTLEMENT_UMU_UNITS
    );

    /* console.log(
      `WALLET_UMU_UNITS = SETTLEMENT_UMU_UNITS[${SETTLEMENT_UMU_UNITS}] * WalletQuoteCurrecy_QuotePrice[${walletRates.QuotePrice}] = `,
      WALLET_UMU_UNITS
    ); */

    const transfer_summary = {
      refersTo: "originator",
      // ...payeeData,
      SourcePublicAddress: userData.PublicAddress,
      SourceDisplayname: userData.Displayname,
      TargetPublicAddress: payeeData.PublicAddress,
      TargetDisplayname: payeeData.Displayname,

      wallet_currency_code: walletRates.QuoteCurrencycode,
      wallet_quote_price: Number(walletRates.QuotePrice)?.toFixed?.(6, "0"),
      wallet_umu_units: Number(WALLET_UMU_UNITS)?.toFixed?.(6, "0"),

      settlement_currency_code: settlementRates?.QuoteCurrencycode,
      settlement_quote_price: Number(settlementRates?.QuotePrice)?.toFixed?.(
        6,
        "0"
      ),
      settlement_umu_units: Number(SETTLEMENT_UMU_UNITS)?.toFixed?.(6, "0"),

      umu_transaction_fee: 0.0,
      amount: Number(amount)?.toFixed?.(6, "0")
    };

    // console.log(transfer_summary);
    // return;

    console.log("Wallet", walletRates);
    console.log("Settlement", settlementRates);
    console.log("Settlement Amount", transfer_summary.settlement_umu_units);
    console.log("Wallet Amount", transfer_summary.wallet_umu_units);

    navigate("/transfer_review", { state: transfer_summary });
  }

  function handleEscrowClick() {
    const error = validate(amount);

    if (error) {
      setValidationError(error);
      return;
    }

    const SETTLEMENT_UMU_UNITS = TO_UMU(settlementRates?.CoinPrice, amount);
    const WALLET_UMU_UNITS = FROM_UMU(
      walletRates.CoinPrice,
      SETTLEMENT_UMU_UNITS
    );

    const transfer_summary = {
      // refersTo: "originator",
      // sourceTokenSymbol: "string",
      // sourceLedgercode: "string",
      // targetTokenSymbol: "string",
      // targetLedgercode: "string",
      // escrowTypecode: "string",
      // referencecode: "string",
      // displayname: "string",
      // documentFN: "string",
      // escrow: "string",
      // sourceUnits: 0,

      refersTo: "originator",
      SourcePublicAddress: userData.PublicAddress,
      SourceDisplayname: userData.Displayname,
      TargetPublicAddress: payeeData.PublicAddress,
      TargetDisplayname: payeeData.Displayname,

      wallet_currency_code: walletRates.QuoteCurrencycode,
      wallet_quote_price: Number(walletRates.QuotePrice)?.toFixed?.(6, "0"),
      wallet_umu_units: Number(WALLET_UMU_UNITS)?.toFixed?.(6, "0"),

      settlement_currency_code: settlementRates?.QuoteCurrencycode,
      settlement_quote_price: Number(settlementRates?.QuotePrice)?.toFixed?.(
        6,
        "0"
      ),
      settlement_umu_units: Number(SETTLEMENT_UMU_UNITS)?.toFixed?.(6, "0"),

      umu_transaction_fee: 0.0,
      amount: Number(amount)?.toFixed?.(6, "0")
    };

    navigate("/escrow_create", { state: transfer_summary });
  }

  return (
    <div className="identification">
      <div className="container">
        <div className="header">
          <Link onClick={() => navigate(-1)} role="button" className="arrow-bg">
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3 className="ms-1">Settlement Currency Amount</h3>
        </div>

        {!isLoading && (
          <div className="enter-amount">
            <div className="d-flex align-items-center">
              <h1 className="mb-0 me-2">
                {settlementRates?.QuoteCurrencySymbol}
              </h1>
              <CurrencyInput
                ref={amountInputElement}
                placeholder="0"
                className="form-control"
                defaultValue={"0"}
                value={amount}
                onValueChange={handleChange}
                decimalsLimit={6}
                allowNegativeValue={false}
                style={{ textAlign: "right", height: "55px" }}
              />
            </div>
            {validationError ? (
              <p className="text-danger" style={{ fontSize: "13px" }}>
                {validationError}
              </p>
            ) : null}
            <div
              className="enterp-amount-content"
              onClick={toggleOptions}
              style={{ cursor: "pointer" }}
            >
              <div className="box-logo me-3">
                <img src={settlementRates?.QuoteImageFN} alt="icons" />
              </div>
              <div className="form-floating">
                <div className="">
                  <div className="text-light">
                    <div>
                      <div className="font-size d-flex justify-content-between">
                        <div>
                          <span>1 Ü = </span>
                          <span>
                            {settlementRates?.QuoteCurrencySymbol}{" "}
                            {Number(settlementRates?.CoinPrice).toFixed(6, "0")}
                          </span>
                          <span className="ps-2">
                            {settlementRates?.Currencycode}
                          </span>
                        </div>
                        <span className="text-success me-2">
                          {settlementRates?.QuoteCurrencySymbol}{" "}
                          {Number(
                            FROM_UMU(settlementRates?.CoinPrice, Balance)
                          ).toFixed(6, "0")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {isOptionsOpen ? (
                  <i className="fa fa-angle-down text-white"></i>
                ) : (
                  <i className="fa fa-angle-right text-white"></i>
                )}
              </div>
            </div>

            {isOptionsOpen && (
              <>
                <div
                  className="dropdown-option"
                  style={{ listStyleType: "none" }}
                >
                  {/* <div className="my-2 d-flex align-items-center justify-content-between"> */}
                  <div className="row align-items-center text-end">
                    <div className="col-2"></div>
                    <div className="col-4">
                      <span
                        style={{ fontSize: "13px" /* , marginLeft: "70px" */ }}
                      >
                        UMU RATE
                      </span>
                    </div>
                    <div className="col-6">
                      <span style={{ fontSize: "13px" }}>BALANCE</span>
                    </div>
                  </div>
                  {/* <span style={{ fontSize: "13px", marginLeft: "70px" }}>
                      UMU RATE
                    </span>
                    <span style={{ fontSize: "13px" }}>BALANCE</span> */}
                  {/* </div> */}

                  {fxRates.map((option, index) => (
                    <div
                      className="my-1 font-size"
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setSettlementRates(() => option);
                        setIsOptionsOpen(() => false);
                      }}
                    >
                      {/* <div className="d-flex align-items-center justify-content-between">
                      <div> */}
                      <div className="row align-items-center mb-2">
                        <div className="col-2">
                          <img
                            className=""
                            src={option.QuoteImageFN}
                            style={{ width: "32px" }}
                          />
                        </div>

                        {/* <div className="col-auto p-0">
                        <span>1 Ü = </span>
                      </div> */}

                        {/* <div className="col-2 p-0 ps-2">
                          <span>{option.QuoteCurrencySymbol}</span>
                        </div> */}

                        <div className="col-4 text-end d-flex justify-content-between">
                          <span>{option.QuoteCurrencySymbol}</span>
                          <span /* style={{ marginLeft: "30px" }} */>
                            {Number(option.CoinPrice).toFixed(4, "0")}
                          </span>
                        </div>

                        {/* <div className="col- pe-0">
                          <span className="text-success">
                            {option.QuoteCurrencySymbol}
                          </span>
                        </div> */}

                        <div className="col-6 ps-0 text-end d-flex justify-content-between">
                          <span className="text-success">
                            {option.QuoteCurrencySymbol}
                          </span>
                          <span
                            className="text-success"
                            // style={{ marginLeft: "40px" }}
                          >
                            {/* {option.QuoteCurrencySymbol}{" "} */}
                            {Number(
                              FROM_UMU(option.CoinPrice, Balance)
                            ).toFixed(6, "0")}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* <table class="table text-white">
                    <thead>
                      <tr>
                        <th style={{ border: "none" }}></th>
                        <th style={{ border: "none" }} className="text-end">
                          UMU RATE
                        </th>
                        <th style={{ border: "none" }} className="text-end">
                          Balance
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fxRates.map((option, index) => {
                        return (
                          <tr key={index} style={{ fontSize: "13px" }}>
                            <td style={{ border: "none" }}>
                              <img
                                className=""
                                src={option.QuoteImageFN}
                                style={{ width: "32px" }}
                              />
                            </td>
                            <td style={{ border: "none" }}>
                              <div className="d-flex align-items-center justify-content-between">
                                <span>{option.QuoteCurrencySymbol}</span>
                                <span>
                                  {Number(option.CoinPrice).toFixed(4, "0")}
                                </span>
                              </div>
                            </td>
                            <td style={{ border: "none" }}>
                              <div className="d-flex align-items-center justify-content-between">
                                <span className="text-success">
                                  {option.QuoteCurrencySymbol}
                                </span>
                                <span className="text-success">
                                  {Number(
                                    FROM_UMU(option.CoinPrice, Balance)
                                  ).toFixed(6, "0")}
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table> */}

                  <div className="text-end">
                    <Link
                      to="/add_fx_rates"
                      style={{
                        textDecoration: "none",
                        color: "lightblue",
                        fontSize: "12px"
                      }}
                    >
                      Add more rates
                    </Link>
                  </div>
                </div>
              </>
            )}

            <div className="text-center d-flex align-items-center">
              <button
                className="btn-sound next-btn me-2 w-100"
                to="/"
                onClick={() => {
                  // SenderReceipt();
                  handlePayClick();
                }}
              >
                PAY
              </button>
              <button
                className="btn-sound next-btn w-100"
                /* to="/escrow_create" */ onClick={handleEscrowClick}
              >
                ESCROW
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

{
  /* <select
  className="form-select"
  id="floatingSelect"
  aria-label="Floating label select example"
  defaultValue="&Uuml; 23,294.138769"
  onChange={(e) => {
    setSelectedCurrency(() =>
      fxRates.find((rate) => rate.Currencycode === e.target.value)
    );
  }}
>
  {fxRates &&
    fxRates.length > 0 &&
    fxRates.map((rate) => {
      return (
        <option key={rate.RowID} value={rate.Currencycode}>
          {rate.CoinCurrencySymbol} {rate.CoinPrice}
        </option>
      );
    })} */
}
{
  /* <option>&Uuml; 23,294.138769</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option> */
}
// </select>;
