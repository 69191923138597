import "../payments/TransferReview.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { decrypytdata } from "../../apis/walletKey";
import { useState } from "react";
import { buy } from "../../apis/ledger";
import { useDispatch, useSelector } from "react-redux";
import { setResponse } from "../../store/slices/responseSlice";
import { setLoader } from "../../store/slices/loaderSlice";

import { hideLoaderFromElement, showLoaderOnElement } from "../../utils/loader";
import { useContext } from "react";
import { ModalOverlayContext } from "../../context/ModalOverlayContext";

export default function TermPurchaseAddReview() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userData } = useSelector((state) => state.user);
  const { toggleModal } = useContext(ModalOverlayContext);

  const { PublicAddress: APPID, PrivateKey: APIKEY } = userData;

  // console.log(location.state);

  const [isTransactionSubmited, setIsTransactionSubmited] = useState(false);

  const {
    refersTo = "originator",
    selectedTerm,
    walletFxRate,
    amount,
    marginFee,
    walletAmount,
    enableMargin,
    autoRenew,
    totalAmount
  } = location.state;

  async function termPurchaseSubmit(e) {
    try {
      showLoaderOnElement(e.target);
      toggleModal(true);
      // dispatch(setLoader(true));

      const termPurchaseResponse = await buy(APPID, APIKEY, {
        units: totalAmount,
        sourceUnits: totalAmount,
        productcode: selectedTerm?.Productcode,
        purchaseTypecode: "TERM",
        sourceCurrencycode: "UMU",
        enableMargin,
        autoRenew
      });

      const decryptedResponse = await decrypytdata(
        termPurchaseResponse.data,
        APIKEY
      );

      // console.log(decryptedResponse);
      const responseAPI = {
        ...termPurchaseResponse,
        headers: undefined,
        request: undefined,
        config: {
          baseURL: termPurchaseResponse.config.baseURL,
          url: termPurchaseResponse.config.url
        }
      };
      const response = JSON.parse(decryptedResponse);

      /* console.log({
        ...response,
        CommandResponse: JSON.parse(response.CommandResponse)
      }); */

      dispatch(setResponse({ apiResponse: responseAPI, apiData: response }));

      navigate("/result", { replace: true });
    } catch (error) {
      console.error(error);
    } finally {
      // dispatch(setLoader(false));
      toggleModal(false);
      hideLoaderFromElement(e.target);
    }
  }

  return (
    <>
      <div className="email-address p-0">
        <div className="identification">
          <div className="container">
            <div className="header">
              <p
                /* to="/splash" */
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
                className="m-0 arrow-bg"
              >
                <i className="bi bi-arrow-left-short"></i>
              </p>
              <h3>Summary</h3>
            </div>

            <div className="summary-box">
              <div className="summary-box-logo">
                <img src="/images/summary-logo.png" alt="logo" />
              </div>

              <h1 className="text-center">Summary</h1>

              <div className="summary-list mb-3">
                {refersTo === "originator" && <p>To</p>}
                {refersTo === "recipient" && <p>From</p>}
                <h5 className=" mb-3">{selectedTerm.Displayname}</h5>
              </div>

              {/* <div className="d-flex justify-content-between mb-3">
                <div className="summary-list mb-3">
                  <p>Bank Currency</p>
                  <h5>{settlementCurrency}</h5>
                </div>
                <div className="summary-list text-end mb-3">
                  <p>Liquidate Amount</p>
                  <h5>{Number(settlementAmount)?.toFixed(6, "0")}</h5>
                </div>
              </div> */}

              <div className="d-flex justify-content-between mb-3">
                <div className="summary-list mb-3">
                  <p>UMU Rate</p>
                  <h5>{Number(walletFxRate.QuotePrice)?.toFixed(6, "0")}</h5>
                </div>

                <div className="summary-list mb-3 text-end">
                  <p>UMU Amount</p>
                  <h5>{Number(amount)?.toFixed(6, "0")}</h5>
                </div>
              </div>

              <div className="d-flex justify-content-between mb-3">
                <div className="summary-list mb-3">
                  <p>Margin Fee</p>
                  <h5>{marginFee}</h5>
                </div>

                <div className="summary-list mb-3 text-end">
                  <p>UMU Total</p>
                  <h5>{totalAmount}</h5>
                </div>
              </div>

              <div className="d-flex justify-content-between mb-3">
                <div className="summary-list mb-3">
                  <p>Wallet</p>
                  <h5>{walletFxRate.QuoteCurrencycode}</h5>
                </div>

                <div className="summary-list mb-3 text-end">
                  <p>Wallet Amount</p>
                  <h5>{Number(walletAmount)?.toFixed(6, "0")}</h5>
                </div>
              </div>

              <div className="d-flex justify-content-end mb-3"></div>
            </div>

            <div className="text-center">
              <button
                className="btn-sound next-btn w-100 mt-3"
                style={{ width: "100%" }}
                data-color="#3361a3"
                data-text="CONTINUE"
                role="button"
                // disabled={isTransactionSubmited ? true : false}
                onClick={(e) => termPurchaseSubmit(e)}
              >
                CONTINUE
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
