import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import "../services/Balance.css";
import { GetFXRatesApi } from "../../apis/FxRatesApi";
import { decrypytdata } from "../../apis/walletKey";
import { setLoader } from "../../store/slices/loaderSlice";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { getBalancesApi } from "../../apis/balances";
import { FROM_UMU, TO_UMU } from "../../utils/conversion";

export default function Balance() {
  const dispatch = useDispatch();
  const { data: userData } = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.loader);

  const {
    PublicAddress: APPID,
    PrivateKey: APIKEY,
    Balance,
    Displayname
  } = userData;

  const [balanceList, setBalanceList] = useState([]);
  const [currentCurrency, setCurrentCurrency] = useState("UMU"); // UMU | LOCAL
  const [fxRates, setFxRates] = useState([]);

  useEffect(() => {
    async function main() {
      try {
        dispatch(setLoader(true));
        await getBalances();
        await fetchFxRates();
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setLoader(false));
      }
    }
    main();
  }, []);

  async function getBalances() {
    const getBalancesResponse = await getBalancesApi(APPID, APIKEY);

    const decryptedResponse = await decrypytdata(
      getBalancesResponse?.data,
      APIKEY
    );

    const jsonResponse = decryptedResponse && JSON.parse(decryptedResponse);

    if (
      jsonResponse &&
      jsonResponse.ReturnKeycode === "Success" &&
      jsonResponse?.CommandResponse
    ) {
      const data = JSON.parse(jsonResponse?.CommandResponse);
      setBalanceList(data);
    }
  }

  const fetchFxRates = async () => {
    const response = await GetFXRatesApi(APPID, APIKEY, {
      currencyPairTypeID: 1
    });

    const decryptedResponse = await decrypytdata(response?.data, APIKEY);

    const responseData = JSON.parse(decryptedResponse);

    let data = [];

    if (
      responseData?.ReturnKeycode === "Success" ||
      responseData?.ReturnMessage === "Success"
    ) {
      data = JSON.parse(responseData?.CommandResponse);
    }

    setFxRates(data);
  };

  const umuBalance = balanceList?.find?.((item) => item.Keycode === "TERM");

  const balance = umuBalance?.Balance || 0;

  const localCurrencyRates = fxRates.find(
    (item) => item.QuoteCurrencycode === umuBalance?.LocalCurrencycode
  );

  const umuAmount = TO_UMU(
    localCurrencyRates?.QuotePrice || 0,
    umuBalance?.AltBalance || 0
  );
  const localAmount = FROM_UMU(
    localCurrencyRates?.QuotePrice || 0,
    umuBalance?.AltBalance || 0
  );

  const formatedBalance = new Intl.NumberFormat(undefined, {
    currency: "USD",
    minimumFractionDigits: 6
  }).format(balance);

  return (
    <div>
      <div className="email-address p-0 identification">
        <div className="container">
          <div className="header">
            <h3 className="wallet-head text-center ms-0">
              {umuBalance?.BalanceType}
            </h3>
          </div>

          {!isLoading && (
            <div className="balance">
              <p>Hello!</p>

              <h6>{Displayname}</h6>

              <div className="create-payee-page text-center mt-4 d-flex align-items-center">
                {currentCurrency === "UMU" ? (
                  <button
                    type="button"
                    className="btn-sound next-btn me-3 mt-0 text-white"
                    style={{ backgroundColor: "#3361a3" }}
                    onClick={() => setCurrentCurrency("UMU")}
                  >
                    Unicoin
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-sound btn next-btn me-3 mt-0 text-white"
                    style={{ border: "1px solid #3361a3" }}
                    onClick={() => setCurrentCurrency("UMU")}
                  >
                    Unicoin
                  </button>
                )}

                {currentCurrency === "LOCAL" ? (
                  <button
                    type="button"
                    className="btn-sound next-btn mt-0 text-white"
                    style={{ backgroundColor: "#3361a3" }}
                    onClick={() => setCurrentCurrency("LOCAL")}
                  >
                    Local
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-sound btn next-btn mt-0 text-white"
                    style={{ border: "1px solid #3361a3" }}
                    onClick={() => setCurrentCurrency("LOCAL")}
                  >
                    Local
                  </button>
                )}
              </div>

              <div className="balance-box mt-4">
                <p>Universal Monetary Unit (UMU)</p>

                <div className="d-flex align-items-end justify-content-between">
                  <div>
                    {/* <h4>Balance</h4> */}
                    <h4>{umuBalance?.Displayname}</h4>
                    <h2 className="mb-0">
                      {/* <span className="me-2">${formatedBalance}</span> */}

                      <span className="me-2">
                        {currentCurrency === "UMU"
                          ? `Ü${new Intl.NumberFormat(undefined, {
                              currency: "USD",
                              minimumFractionDigits: 6
                            }).format(
                              umuBalance?.Currencycode === "UMU"
                                ? umuBalance?.Balance || 0
                                : umuBalance?.AltBalance || 0
                            )}`
                          : new Intl.NumberFormat(undefined, {
                              currency: umuBalance?.LocalCurrencycode || "USD",
                              minimumFractionDigits: 3,
                              style: "currency"
                            }).format(
                              umuBalance?.Currencycode === "UMU"
                                ? umuBalance?.AltBalance || 0
                                : umuBalance?.Balance || 0
                            )}
                      </span>

                      {/* <span className="me-2">
                        {currentCurrency === "UMU"
                          ? `Ü${new Intl.NumberFormat(undefined, {
                              currency: "USD",
                              minimumFractionDigits: 6
                            }).format(umuBalance?.AltBalance || 0)}`
                          : new Intl.NumberFormat(undefined, {
                              currency: umuBalance?.LocalCurrencycode || "USD",
                              minimumFractionDigits: 3,
                              style: "currency"
                            }).format(umuBalance?.AltBalance || 0)}
                      </span> */}
                    </h2>
                  </div>

                  <div>
                    {/* <img src="/images/splash-logo.png" alt="logo" /> */}
                    {/* {selectedCurrency.Currencycode === "UMU" ? (
                      <img
                        src="/images/splash-logo.png"
                        style={{ width: "80px" }}
                      />
                    ) : (
                      <img
                        src={selectedCurrency.QuoteImageFN}
                        alt="logo"
                        style={{ width: "32px" }}
                      />
                    )} */}
                    <img
                      src={`/icons/${umuBalance?.ImageFN}`}
                      alt="logo"
                      style={{ width: "32px" }}
                    />
                  </div>
                </div>
              </div>

              <div className="text-center d-flex mt-4">
                <Link
                  className="next-btn w-100 me-2 mt-0"
                  to="/term_purchase_add"
                >
                  <img
                    src="/icons/add-umu-icon.png"
                    className="me-2"
                    alt="icon"
                  />
                  TERM BUY
                </Link>
                <Link
                  className="next-btn w-100 mt-0"
                  to="/get_term_purchases?Tab=TERM_BUYS"
                >
                  <img
                    src="/icons/liquidate-icon.png"
                    className="me-2"
                    alt="icon"
                  />
                  View Buys
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      <ReactTooltip anchorId="1" place="bottom" content="Term Purchase" />
      <ReactTooltip anchorId="2" place="bottom" content="Margin Account" />
      <ReactTooltip anchorId="3" place="bottom" content="Proof of Funds" />
    </div>
  );
}
