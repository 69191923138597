import "./Milestone.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { releaseMilestone, cancelMilestone } from "../../apis/milestone";
import { decrypytdata } from "../../apis/walletKey";
import { setLoader } from "../../store/slices/loaderSlice";
import { setResponse } from "../../store/slices/responseSlice";

import { hideLoaderFromElement, showLoaderOnElement } from "../../utils/loader";
import { useContext } from "react";
import { ModalOverlayContext } from "../../context/ModalOverlayContext";

export default function Milestone() {
  const { data: userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { toggleModal } = useContext(ModalOverlayContext);
  const isLoading = useSelector((state) => state.loader);
  const { EscrowInfo, milestoneInfo } = location.state;
  console.log(location.state);
  const { PublicAddress: APPID, PrivateKey: APIKEY } = userData;

  // const [isLoading, setIsLoading] = useState(false);
  const [milestoneList, setMilestoneList] = useState([]);
  const date = new Date(milestoneInfo.ExpectedDeliveryDate);
  const formattedDate = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`;

  async function releseMilestone(e) {
    try {
      // dispatch(setLoader(true));
      showLoaderOnElement(e.target);
      toggleModal(true);

      let data = {
        txHASH: milestoneInfo.TxHash,
        milestoneTypecode: milestoneInfo.TransactionTypecode,
        sourcePublicAddress: APPID
      };
      const response = await releaseMilestone(APPID, APIKEY, data);
      const decryptedResponse = await decrypytdata(response.data, APIKEY);
      const responseObj = JSON.parse(decryptedResponse);
      const decryptedResponseJson = JSON.parse(decryptedResponse);

      const responseAPI = {
        ...response,
        headers: undefined,
        request: undefined,
        config: { baseURL: response.config.baseURL, url: response.config.url }
      };

      dispatch(
        setResponse({
          apiResponse: responseAPI,
          apiData: decryptedResponseJson
        })
      );

      navigate("/result", { replace: true });
    } catch (error) {
      console.error(error);
    } finally {
      // dispatch(setLoader(false));
      toggleModal(false);
      hideLoaderFromElement(e.target);
    }

    // const responseObj = JSON.parse(decryptedResponse);
  }
  async function cancelMilestones(e) {
    try {
      // dispatch(setLoader(true));
      showLoaderOnElement(e.target);
      toggleModal(true);

      let data = {
        txHASH: milestoneInfo.TxHash,
        milestoneTypecode: milestoneInfo.TransactionTypecode,
        sourcePublicAddress: APPID
      };
      const response = await cancelMilestone(APPID, APIKEY, data);
      const decryptedResponse = await decrypytdata(response.data, APIKEY);
      const responseObj = JSON.parse(decryptedResponse);
      const decryptedResponseJson = JSON.parse(decryptedResponse);

      const responseAPI = {
        ...response,
        headers: undefined,
        request: undefined,
        config: { baseURL: response.config.baseURL, url: response.config.url }
      };

      dispatch(
        setResponse({
          apiResponse: responseAPI,
          apiData: decryptedResponseJson
        })
      );

      navigate("/result", { replace: true });
    } catch (error) {
      console.error(error);
    } finally {
      // dispatch(setLoader(false));
      toggleModal(false);
      hideLoaderFromElement(e.target);
    }

    // const responseObj = JSON.parse(decryptedResponse);
  }
  async function downloadFile(documentFN) {
    /* function convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          console.log(reader.result);
          const base64 = reader.result;
          resolve(base64);
        };

        reader.onerror = () => {
          reject(new Error("Failed to convert the file to base64"));
        };
      });
    } */

    var req = new XMLHttpRequest();
    var uurrll =
      "https://protocol.umu.testnet.unicoinnetwork.com" +
      "/general/DownloadFile";
    req.open("POST", uurrll, true);
    req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    req.setRequestHeader(
      "Authorization",
      "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
    );
    req.send(
      JSON.stringify({
        fileName: documentFN,
        publicAddress: APPID
      })
    );

    req.responseType = "blob";
    req.onload = function () {
      if (documentFN.lastIndexOf(".pdf") > 0) {
        var blob = new Blob([req.response], { type: "application/pdf" });
        window.open(URL.createObjectURL(blob), "_blank");
      } else {
        var blob = new Blob([req.response], { type: "image/*" });

        const myFile = new File([blob], "image.jpeg", {
          type: blob.type
        });
        var link = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.setAttribute("href", link);
        a.setAttribute("download", documentFN);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }

      // const response = await axios({
      //   baseURL: UMU_URL,
      //   url: "/general/DownloadFile",
      //   method: "POST",
      //   headers: {
      //     Authorization:
      //       "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
      //   },
      //   data: {
      //     // fileName: "ec517bc4-1547-4618-a453-f65f3224e361test pdf.pdf",
      //     fileName: documentFN,
      //     publicAddress: APPID
      //   }
      // });
      // debugger
      // var file = new Blob([response.data], { type: "application/pdf" });
      // var files = new File([response.data], documentFN, {lastModified: 1534584790000});
      // return response.data;

      /* const res = await fetch(
      "https://protocol.umu.testnet.unicoinnetwork.com/general/DownloadFile",
      {
        fileName: "ec517bc4-1547-4618-a453-f65f3224e361test pdf.pdf",
        publicAddress: APPID
      }
    ).then((res) => {
      var file = new Blob([res], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      setF(fileURL);
      console.log(fileURL);
    });

    return;

    return response.data;
    console.log(response.data); */
    };
  }

  return (
    <div className="email-address p-0 identification">
      <div className="container">
        <div className="header">
          <Link
            /* to="/splash" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>Milestone Information</h3>
        </div>

        <div className="summary-box escrow-information">
          <div className="summary-box-logo">
            <img src="/images/summary-logo.png" alt="logo" />
          </div>
          <h1 className="text-center">Milestone Information</h1>
          <div className="summary-list mb-3">
            <p>Milestone Type</p>
            <h5>{milestoneInfo.TransactionType}</h5>
          </div>
          <div className="summary-list mb-3">
            <p>Milestone Amount</p>
            <h5>
              <span></span> {milestoneInfo.SettlementUnits}{" "}
              {milestoneInfo.SettlementCurrencycode}
            </h5>
          </div>
          <div className="summary-list mb-3">
            <p>Milestone Short Description</p>
            <h5>
              <span></span> {milestoneInfo.Displayname}{" "}
            </h5>
          </div>
          <div className="summary-list mb-3">
            <p>Milestone Long Description</p>
            <h5> {milestoneInfo.Milestone}</h5>
          </div>
          <div className="summary-list mb-3">
            <p>Target Completion Date</p>
            <h5>{formattedDate}</h5>
          </div>
          <div className="summary-list">
            <p>Milestone Agreement</p>
            {milestoneInfo.DocumentFN ? (
              <img
                src="/images/default.png"
                alt="default-img"
                onClick={() => {
                  downloadFile(milestoneInfo.DocumentFN);
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="text-center milestone-btn">
          {milestoneInfo.SentReceived == "Sent" && (
            <button
              className="btn-sound next-btn w-100 mt-4 d-flex align-items-center ps-4 justify-content-between"
              data-bs-toggle="modal"
              data-bs-target="#RELEASE"
              to="/"
            >
              <div>
                <i className="bi bi-upload me-2"></i>
                <span>RELEASE</span>
              </div>
              <i className="bi bi-arrow-right"></i>
            </button>
          )}

          {milestoneInfo.SentReceived == "Sent" && (
            <button
              onClick={() => {
                navigate("/amount_milestone_create", {
                  state: {
                    ...EscrowInfo,
                    isModify: true,
                    TransactionTypecode: milestoneInfo.TransactionTypecode,
                    ...milestoneInfo,
                    EscrowInfo: EscrowInfo
                  }
                });
              }}
              className="btn-sound next-btn w-100 mt-2 d-flex align-items-center ps-4 justify-content-between"
              to="/"
            >
              <div>
                <img
                  src="/icons/modify-icon.png"
                  className="me-2"
                  alt="icons"
                />
                <span>MODIFY</span>
              </div>
              <i className="bi bi-arrow-right"></i>
            </button>
          )}

          {/* <Link
            className="next-btn w-100 mt-2 d-flex align-items-center ps-4 justify-content-between"
            to="/"
          >
            <div>
              <img src="/icons/dispute-icon.png" className="me-2" alt="icons" />
              <span>DISPUTE</span>
            </div>
            <i className="bi bi-arrow-right"></i>
          </Link> */}

          {/* <Link
            className="next-btn w-100 mt-2 d-flex align-items-center ps-4 justify-content-between"
            to="/"
          >
            <div>
              <img src="/icons/suspend-icon.png" className="me-2" alt="icons" />
              <span>SUSPEND</span>
            </div>
            <i className="bi bi-arrow-right"></i>
          </Link> */}

          <Link
            className="next-btn w-100 mt-2 d-flex align-items-center ps-4 justify-content-between"
            to="/request_release_milestone"
          >
            <div>
              <img src="/icons/request.png" className="me-2" alt="icons" />
              <span>REQUEST RELEASE</span>
            </div>
            <i className="bi bi-arrow-right"></i>
          </Link>

          {milestoneInfo.SentReceived == "Sent" && (
            <button
              className="btn-sound next-btn w-100 mt-2 next-btn-last d-flex align-items-center ps-4 justify-content-between"
              data-bs-target="#CANCEL"
              data-bs-toggle="modal"
              to="/"
            >
              <div>
                <i className="bi bi-x-lg"></i>
                <span>CANCEL</span>
              </div>
              <i className="bi bi-arrow-right"></i>
            </button>
          )}

          <div
            className="modal fade"
            id="RELEASE"
            tabindex="-1"
            aria-labelledby="RELEASE"
            aria-hidden="true"
          >
            <div className="modal-dialog" style={{ position: "unset" }}>
              <div className="modal-box">
                <div className="icons-fixed">
                  <img src="/icons/success-icon-modal.png" alt="icon" />
                </div>
                <h2>Release Milestone</h2>
                <div className="modal-list">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <h6>Milestone Type</h6>
                    <h5>{milestoneInfo.TransactionType}</h5>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <h6>Short Description</h6>
                    <h5>{milestoneInfo.Displayname}</h5>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <h6>Amount</h6>
                    <h5>
                      {milestoneInfo.SettlementUnits}{" "}
                      {milestoneInfo.SettlementCurrencycode}
                    </h5>
                  </div>
                </div>
                <div className="d-flex align-items-center w-100 mt-3">
                  <Link
                    to="/"
                    className="modal-btn me-3"
                    data-bs-dismiss="modal"
                  >
                    CANCEL
                  </Link>
                  <button
                    to="/"
                    className="btn-sound modal-btn fill"
                    data-color="#3361a3"
                    data-text="RELEASE"
                    onClick={(e) => {
                      releseMilestone(e);
                    }}
                  >
                    RELEASE
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="CANCEL"
            tabindex="-1"
            aria-labelledby="CANCEL"
            aria-hidden="true"
          >
            <div className="modal-dialog" style={{ position: "unset" }}>
              <div className="modal-box">
                <div className="icons-fixed">
                  <img src="/icons/success-icon-modal.png" alt="icon" />
                </div>
                <h2>Release Milestone</h2>
                <div className="modal-list">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <h6>Milestone Type</h6>
                    <h5>{milestoneInfo.TransactionType}</h5>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <h6>Short Description</h6>
                    <h5>{milestoneInfo.Displayname}</h5>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <h6>Amount</h6>
                    <h5>
                      {milestoneInfo.SettlementUnits}{" "}
                      {milestoneInfo.SettlementCurrencycode}
                    </h5>
                  </div>
                </div>
                <div className="d-flex align-items-center w-100 mt-3">
                  <Link
                    to="/"
                    className="modal-btn me-3"
                    data-bs-dismiss="modal"
                  >
                    CANCEL
                  </Link>
                  <button
                    to="/"
                    className="btn-sound modal-btn fill"
                    data-color="#3361a3"
                    data-text="CANCEL"
                    onClick={(e) => {
                      cancelMilestones(e);
                    }}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
