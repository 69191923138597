import axios from "axios";

import { UMU_URL } from "../constants/config";
import { hmacencrypt } from "./walletKey";


export async function pof(publickey, privateKey, data) {
  try {
    let values = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      `${UMU_URL}/money/PoF`,
      values,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      url: "/money/PoF",
      data: values,
      headers: {
        Authorization: token
      }
    });

    /* const response = await axios.post(`${UMU_URL}`, values, {
      headers: {
        Authorization: token
      }
    }); */

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}

export async function GetPOFSApi(publickey, privateKey, data = {}) {
  try {
    let values = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/GetPOFs",
      values,
      publickey,
      privateKey
    );

    const response = await axios.post(`${UMU_URL}/money/GetPOFs`, values, {
      headers: {
        Authorization: token
      }
    });



    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    }

    throw error;
  }
}

export async function RecivedPOFSApi(publickey, privateKey, data = {}) {
  try {
    let values = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/RequestPoF",
      values,
      publickey,
      privateKey
    );

    const response = await axios.post(`${UMU_URL}/money/RequestPoF`, values, {
      headers: {
        Authorization: token
      }
    });



    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    }

    throw error;
  }
}