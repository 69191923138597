import { Outlet, Navigate } from "react-router-dom";

function CheckAuth() {
  const userDataString = localStorage.getItem("user");

  return (
    <>
      {userDataString ? <Navigate to="/get_balances" replace={true} /> : <Outlet />}
    </>
  );
}

export default CheckAuth;
