import axios from "axios";
import { hmacencrypt } from "./walletKey";
import { UMU_URL } from "../constants/config";

export async function buy(publicKey, privateKey, data = {}) {
  try {
    let values = {
      sourcePublicAddress: publicKey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      `${UMU_URL}/money/Deposit`,
      values,
      publicKey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      url: "money/Deposit",
      data: values,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (err) {
    if (err.response) return err.response;

    throw err;
  }
}
