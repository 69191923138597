import React from "react";
import { useEffect } from "react";
import { Outlet, useSearchParams } from "react-router-dom";

export default function () {
  const [queryParams] = useSearchParams();

  const referralCode = queryParams.get("referral");

  if (referralCode) {
    localStorage.setItem("referralCode", referralCode);
  }

  return (
    <React.Fragment>
      <Outlet />
    </React.Fragment>
  );
}
