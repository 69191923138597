import { useState, Fragment } from "react";
import { useEffect } from "react";
import { getFxRatesApi2 } from "../apis/FxRatesApi";
import { Link } from "react-router-dom";
import "./home.css";

// Charts
import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { useDispatch } from "react-redux";

export default function Home() {
  const dispatch = useDispatch();

  const [fxRates, setFxRates] = useState([]);
  const [rateType, setRateType] = useState("OFFICIAL_RATE");

  // const [filteredFxRates, setFilteredFxRates] = useState([]);

  //   const [grid, setGrid] = useState("Box Format");
  const [isLoading, setIsLoading] = useState(false);
  const [isAllFxRatesFetched, setIsAllFxRatesFetched] = useState(false);

  // Chart states //
  const [state, setState] = useState({ selection: "Monthly" });
  const [moneySupplyName, setMoneySupplyName] = useState(
    "UMU Liquid Circulating Money Supply"
  );
  const [LKCurrency, setLKCurrency] = useState([]);
  const [LKTimePeriods, setLKTimePeriods] = useState([]);
  const [LKProducts, setLKProducts] = useState([]);
  const [LKMoneySupply, setLKMoneySupply] = useState([]);
  const [LKCurrencyData, setLKCurrencyData] = useState("USD");
  const [filterData, setFilterData] = useState("");
  const [ledgercodeData, setLedgercodeData] = useState("UMUM1");
  const [moneySupply, setMoneySupply] = useState("M1");
  const [series, setSeries] = useState([]);

  // console.log(LKProducts);
  // console.log(ledgercodeData);

  let alldata = {
    options: {
      chart: {
        id: "area-datetime",
        type: "area",
        height: 350,
        foreColor: "#b8b8b8",
        toolbar: {
          show: false
        },
        zoom: {
          autoScaleYaxis: false
        }
      },
      annotations: {
        yaxis: [
          {
            y: 30,
            borderColor: "#999",
            label: {
              show: true,
              text: "Support",
              style: {
                color: "#fff",
                background: "#00E396"
              }
            }
          }
        ],
        xaxis: [
          {
            borderColor: "#999",
            yAxisIndex: 0,
            label: {
              show: true,
              text: "Rally",
              style: {
                color: "#fff",
                background: "#775DD0"
              }
            }
          }
        ]
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0,
        style: "hollow"
      },
      xaxis: {
        type: "datetime",
        tickAmount: 6,

        title: {
          text: state.selection,
          style: {
            color: "#b8b8b8",
            fontSize: "16px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600
          }
        }
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy"
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100]
        }
      },
      yaxis: {
        show: true,
        showAlways: true,
        showForNullSeries: true,
        seriesName: undefined,
        opposite: false,
        reversed: false,
        logarithmic: false,
        labels: {
          show: true,
          formatter: (val) => parseFloat(val).toFixed(2) // just formats date to hh:mm format
        },
        forceNiceScale: false,
        floating: false,
        decimalsInFloat: undefined,
        title: {
          text:
            moneySupply == "M1"
              ? filterData === ""
                ? "USD - US Dollar"
                : filterData[0].DataValue2 +
                  " " +
                  filterData[0].Keycode +
                  " - " +
                  filterData[0].Displayname
              : "Total Units",
          style: {
            color: "#b8b8b8",
            fontSize: "16px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 700,
            cssClass: "apexcharts-xaxis-title"
          }
        }
      }
    },

    selection: "year"
  };

  const getAlldata = async () => {
    const LKCurrencyResult = await axios.post(
      "https://api.testnet.unicoinnetwork.com/common/GetLookupTable",
      {
        lookupTable: "LKCurrency"
      }
    );
    setLKCurrency(LKCurrencyResult.data.commandDataset);

    const LKTimePeriodsResult = await axios.post(
      "https://api.testnet.unicoinnetwork.com/common/GetLookupTable",
      {
        lookupTable: "LKTimePeriods"
      }
    );
    setLKTimePeriods(LKTimePeriodsResult.data.commandDataset);

    const LKProductsResult = await axios.post(
      "https://api.testnet.unicoinnetwork.com/common/GetLookupTable",
      {
        lookupTable: "LKProducts"
      }
    );
    setLKProducts(LKProductsResult.data.commandDataset);

    const LKMoneySupplyResult = await axios.post(
      "https://api.testnet.unicoinnetwork.com/common/GetLookupTable",
      {
        lookupTable: "LKMoneySupply"
      }
    );
    setLKMoneySupply(LKMoneySupplyResult.data.commandDataset);
  };

  const updateChartData = async () => {
    if (moneySupply == "M1") {
      const body = {
        moneySupplyClasscode: null,
        moneySupplyTypecode: moneySupply,
        // startDate: null,
        // endDate: null,
        maxRows: 50,
        tokenSymbol: "UMU",
        ledgercode: ledgercodeData,
        timePeriodCode: state.selection,
        currencycode: LKCurrencyData,
        currency: null,
        productname: null
      };

      const PricingHistoryResult = await axios.post(
        "https://protocol.umu.testnet.unicoinnetwork.com/general/GetPricingHistory",
        body,
        {
          headers: {
            Authorization:
              "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
          }
        }
      );
      let filterResult = [];
      PricingHistoryResult.data.map((value) => {
        filterResult.push([value.ChartDate, value.ChartPrice]);
      });
      setSeries([
        {
          name: LKCurrencyData,
          data: filterResult
        }
      ]);
    } else {
      const bodys = {
        tokenSymbol: "UMU",
        timePeriodCode: state.selection,
        moneySupplycode: moneySupply,
        maxRows: 0,
        productname: null
      };
      const moneysupplyResult = await axios.post(
        "https://protocol.umu.testnet.unicoinnetwork.com/general/GetMoneySupply",
        bodys,
        {
          headers: {
            Authorization:
              "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
          }
        }
      );
      const JSONmoneysupplyResult = JSON.parse(
        moneysupplyResult.data.commandResponse
      );
      let filterResult = [];
      JSONmoneysupplyResult.map((value) => {
        filterResult.push([value.ChartDate, value.Units]);
      });
      setSeries([
        {
          name: moneySupply,
          data: filterResult
        }
      ]);
    }
  };

  const onChange = async (event) => {
    const values = event.target.value;
    setLKCurrencyData(values);
    const filternamedata = LKCurrency.filter((x) => x.Keycode == values);
    setFilterData(filternamedata);
  };

  const onChangeLedgercode = async (event) => {
    const value = event.target.value;
    setLedgercodeData(value);
  };

  useEffect(() => {
    getAlldata();
  }, []);

  useEffect(() => {
    updateChartData();
  }, [LKCurrencyData, state, ledgercodeData, moneySupply]);
  // Chart states //

  const APPID = "91ca2e37-d738-4306-8808-c87cc3d871f9";
  const APIKEY = "359bq90kYLO8RRf4F0uoEpfiAXc/+yf1kAbvNC1Twpk=";

  useEffect(() => {
    getRates();
  }, [isAllFxRatesFetched]);

  async function getRates() {
    // const response = await GetFXRatesApi(APPID, APIKEY, {
    //   currencyPairTypeID: 1
    // });

    const response = await getFxRatesApi2(APPID, APIKEY, {
      priorityID: isAllFxRatesFetched ? 3 : 2
    });

    // console.log(response);

    // return;

    // const fxRatesJSON = await decrypytdata(response?.data, APIKEY);

    // const fxRatesObj = JSON.parse(fxRatesJSON);

    // const data = JSON.parse(fxRatesObj.CommandResponse);

    setFxRates(response?.data);
    // setFilteredFxRates(response?.data);

    // console.log(response?.data);
  }

  return (
    <div /* className="home" */>
      {/* <div
        className="container-fluid"
        style={{ backgroundColor: "rgb(217, 174, 105)" }}
      >
        <div className="">
          <div className="text-center">
            <img src="/coins/umucoin.png" className="img-fluid logo" alt="" />
          </div>
        </div>

        <div className="">
          <div className="justify-content-center d-flex pb-2 pb-md-3 pb-lg-4">
            <Link to="/" className="menu-list">
              Home
            </Link>
            <a
              href="https://dcma.io"
              className="menu-list px-4"
              target="_blank"
              rel="noreferrer"
            >
              DCMA
            </a>
            <a
              href="https://unicoinnetwork.com"
              target="_blank"
              className="menu-list"
              rel="noreferrer"
            >
              Unicoin Network
            </a>
            <Link className="menu-list ps-4" to="/splash" target="_blank">
              Bank Wallet
            </Link>

            <Link className="menu-list ps-4" to="/whitepaper" target="_blank">
              Whitepaper
            </Link>
          </div>
        </div>
      </div> */}

      <div className="container-fluid p-0">
        <div className="slider">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="slider-heading">
                  <span>A Digital Cash Store of Value Innovation</span>
                  <h3>
                    The Best Money Rates Worldwide Powered by Artificial
                    Intelligence
                  </h3>
                  <div className="launching-btn">
                    <a href="/splash">REGISTER</a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="slider-img">
                  <img src="/images/slider-img.png" alt="alt" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="chart">
        <div className="containe">
          <div className="main-d">
            <div className="main-select">
              {moneySupply == "M1" && (
                <select className="select-box" onChange={onChangeLedgercode}>
                  {LKProducts.map((data) => (
                    <option value={data.Keycode} key={data.key}>
                      &nbsp;&nbsp;&nbsp;{data.Displayname}
                    </option>
                  ))}
                  ;
                </select>
              )}

              {/* <div>
                <input className="input-box" type="text" value="1.00" />
              </div> */}
            </div>
            <div className="main-select"></div>
            <div className="main-select">
              {moneySupply == "M1" && (
                <select className="select-box" onChange={onChange}>
                  {LKCurrency.map(
                    (data) =>
                      data.Keycode != "UMU" && (
                        <option
                          value={data.Keycode}
                          key={data.key}
                          selected={data.Keycode == "USD" ? true : false}
                        >
                          &nbsp;&nbsp;&nbsp;{data.DataValue2} {data.Keycode} -{" "}
                          {data.Displayname}
                        </option>
                      )
                  )}
                  ;
                </select>
              )}
              {/* <div>
                <input className="input-box" type="text" value="22676.40" />
              </div> */}
            </div>
          </div>

          <div className="toolbar">
            <div className="currancy-main">
              {LKMoneySupply.map((y) => (
                <button
                  id={y.Keycode}
                  onClick={() => {
                    setMoneySupplyName(y.Displayname);
                    setMoneySupply(y.Keycode);
                  }}
                  className={
                    moneySupply === y.Keycode ? "activekey" : "inactiveskey"
                  }
                >
                  {y.Keycode}
                </button>
              ))}
            </div>
            <div className="chart-count">
              {LKTimePeriods.map((x) => (
                <button
                  id={x.ParentKeycode}
                  onClick={() => {
                    setState({ selection: x.ParentKeycode });
                  }}
                  className={
                    state.selection === x.ParentKeycode
                      ? "active-period"
                      : "inactives"
                  }
                >
                  {x.ParentKeycode}
                </button>
              ))}
            </div>
          </div>

          <div style={{ textAlign: "center" }}>
            <h2 className="mt-5">
              {moneySupply == "M1"
                ? moneySupplyName + " Pricing Chart"
                : moneySupplyName + " Units Chart"}
            </h2>
          </div>

          <div id="chart-timeline">
            <ReactApexChart
              options={alldata.options}
              series={series}
              type="area"
              height={350}
            />
          </div>
        </div>
      </div>

      <div className="mt-5 pt-5 container">
        <div className="content">
          <h3 className="mt-4 text-center">
            Universal Monetary Unit (UMU) Money Rates
          </h3>

          <div className="pt-3 container">
            <div className="">
              <div class="email-address p-0 m-0">
                <div class="phone-content exchange-rates     justify-content-center  email-content d-flex align-items-center mt-4">
                  <div class="tabs w-auto mt-0">
                    <div
                      onClick={() => {
                        setRateType("OFFICIAL_RATE");
                        // setFilteredFxRates([...fxRates]);
                      }}
                    >
                      <p
                        class={`me-3 ${
                          rateType === "OFFICIAL_RATE" ? "active" : ""
                        }`}
                      >
                        OFFICIAL RATES
                      </p>
                    </div>
                    <div
                      onClick={() => {
                        setRateType("BLUE_RATE");
                        // setFilteredFxRates([
                        //   ...fxRates.filter(
                        //     (r) => r.BlueBasePrice && r.BlueCoinPrice
                        //   )
                        // ]);
                      }}
                    >
                      <p
                        class={`me-3 ${
                          rateType === "BLUE_RATE" ? "active" : ""
                        }`}
                      >
                        BLUE RATES
                      </p>
                    </div>
                  </div>
                </div>
                {rateType === "OFFICIAL_RATE" && (
                  <div className="row justify-content-center">
                    <div className="col-6 text-center pt-4">
                      <div className="tab-content">
                        <p>
                          The official rates are for buying UMU using electronic
                          payment methods when funding your bank wallet.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {rateType === "BLUE_RATE" && (
                  <div className="row justify-content-center">
                    <div className="col-6 text-center pt-4">
                      <div className="tab-content">
                        <p>
                          The blue rates are for buying and selling UMU with
                          cash from a UMU currency exchange service provider
                          using your mobile wallet.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="">
            <div className="row mt-4 text-white justify-content-center">
              <div className="col-lg-8">
                <div
                  className="row py-2 justify-content-center align-items-center"
                  style={{ backgroundColor: "#060020" }}
                >
                  <div className="col-2 col-md-1 ps-md-0">
                    <h5>Country</h5>
                  </div>
                  <div className="col-3 col-md-2 ps-md-0">
                    <h5>Currency</h5>
                  </div>
                  <div className="col-3 col-md-3 text-end">
                    <h5 className="pe-md-3">
                      USD <br className="d-none d-md-block" />
                      Buy Rate
                    </h5>
                  </div>
                  <div className="col-3 col-md-3 text-end">
                    <h5 className="pe-1  me-md-3    ">
                      UMU <br />
                      Buy Rate
                    </h5>
                  </div>
                </div>
              </div>
              {fxRates.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <div
                      className={`col-lg-8 py-2 mb-3 text-style ${
                        index % 2 === 0 ? "evan" : ""
                      }`}
                    >
                      <div className="row justify-content-center align-items-center">
                        <div className="col-2 col-md-1">
                          <img
                            src={item.QuoteImageFN?.replace("_32", "")}
                            className="me-2 img-size"
                            alt="logo"
                          />
                          {/* <span> {item.QuoteCountryname}</span> */}
                        </div>
                        <div className="col-3 col-md-2">
                          <span>
                            {item.QuoteCurrencycode}
                            <br />
                            {item.QuoteCurrency}
                          </span>
                        </div>
                        <div className="col-3 col-md-3 text-end">
                          <div className="d-flex justify-content-between">
                            <span className="ps-lg-5 ms-lg-5">
                              {item.QuoteCurrencySymbol}
                            </span>
                            <span className="icon-style">
                              {rateType === "OFFICIAL_RATE"
                                ? Number(item.BasePrice)?.toFixed(6, "0")
                                : null}
                              {rateType === "BLUE_RATE"
                                ? Number(item.BlueBasePrice)?.toFixed(6, "0")
                                : null}
                            </span>
                          </div>
                        </div>
                        <div className="col-3 col-md-3 text-end">
                          <div className="d-flex justify-content-between">
                            <span className="ps-lg-5 ms-lg-5">
                              {item.QuoteCurrencySymbol}
                            </span>
                            <span className="d-inline-block span-width">
                              {rateType === "OFFICIAL_RATE"
                                ? Number(item.CoinPrice)?.toFixed(6, "0")
                                : null}
                              {rateType === "BLUE_RATE"
                                ? Number(item.BlueCoinPrice)?.toFixed(6, "0")
                                : null}
                            </span>
                          </div>
                        </div>
                        <div className="col-auto p-0">
                          <img
                            src={item.CoinImageFN}
                            className="image-class pe-2"
                            alt="logo"
                          />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
              {!isAllFxRatesFetched ? (
                <div className="col-lg-8 py-2 mb-3 text-style text-end text-blue">
                  <p
                    onClick={() => {
                      setIsAllFxRatesFetched(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    show more
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* <footer className="mt-5">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-4 col-xl-3">
              <div className="footer-logo">
                <a href="index.html">
                  <img src="/images/dcma-logo.png" alt="logo" />
                </a>
                <p className="mb-0">
                  The DCMA is a world leader in the advocacy of digital currency
                  innovations for monetary authorities.
                </p>
              </div>
            </div>
            <div className="col-md-auto">
              <div className="legal-information">
                <h3>Navigation</h3>
                <ul className="mb-0">
                  <li>
                    <a href="crypto-2-0.html">Home</a>
                  </li>
                  <li>
                    <a href="cbdc.html">DCMA</a>
                  </li>
                  <li>
                    <a href="unicoin-network.html">Unicoin Network</a>
                  </li>
                  <li>
                    <a href="universal-monetary-unit.html" className="mb-0">
                      Web Wallet
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-auto">
              <div className="social-media-links">
                <h3>Social Media Links</h3>
                <ul className="mb-0">
                  <li className="me-2">
                    <a
                      href="https://twitter.com/DcmaPress"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/76535284/admin/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
    </div>
  );
}
