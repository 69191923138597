import { hmacencrypt } from "./walletKey";
import { ADMIN_URL, UMU_URL } from "../constants/config";

import axios from "axios";

export async function modifyWallet(publicKey, privateKey, data) {
  try {
    let values = {
      sourcePublicAddress: publicKey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      `${UMU_URL}/wallet/ModifyWallet`,
      values,
      publicKey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      url: "/wallet/ModifyWallet",
      data: values,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (err) {
    if (err.response) return err.response;

    throw err;
  }
}

export async function GetWalletQRCode(publickey, privateKey) {
  try {
    const value = {
      // sourcePublicAddress: publickey
      sourcePublicAddress: publickey
    };

    // console.log(value);

    // /security/GetWalletQRCode

    const token = await hmacencrypt(
      "POST",
      // UMU_URL + "/general/GetWalletQRCode",
      ADMIN_URL + "/security/GetWalletQRCode",
      value,
      // data,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: ADMIN_URL,
      // url: `/general/GetWalletQRCode`,
      url: `/security/GetWalletQRCode`,
      data: value,
      // data,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    console.error("Error", error);
    if (error.response) return error.response;

    throw error;
  }
}

export async function GetWalletLinks(publickey, privateKey, data = {}) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/wallet/GetWalletLinks",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/wallet/GetWalletLinks`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}

export async function AttacheWallet(publickey, privateKey, AttachWalletData) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...AttachWalletData
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/wallet/AttachWallet",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/wallet/AttachWallet`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}

export async function modifyWalletApi(publickey, privateKey, AttachWalletData) {
  try {
    const value = {
      sourcePublicAddress: publickey,
      ...AttachWalletData
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/wallet/ModifyWallet",
      value,
      publickey,
      privateKey
    );

    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      data: value,
      url: `/wallet/ModifyWallet`,
      headers: {
        Authorization: token
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}

export async function requestWallet(data) {
  try {
    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      url: `/general/RequestWallet`,
      data,
      headers: {
        Authorization:
          "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
      }
    });

    return response;
  } catch (error) {
    if (error.response) return error.response;

    throw error;
  }
}
