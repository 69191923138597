import "./CreatePayee.css";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import PhoneInput from "react-phone-input-2";

import { getDialingcodesAPI, getDropdownValues } from "../../../apis/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { decrypytdata } from "../../../apis/walletKey";
import axios from "axios";
import { UMU_URL } from "../../../constants/config";
import { setLoader } from "../../../store/slices/loaderSlice";
import { GetWalletLinks } from "../../../apis/wallet";

const initData = {
  payeeTypecode: "string",
  payeecode: "string",
  publicAddress: "string",
  email: "string",
  mobileDialingcode: "string",
  mobilePhone: "string",

  accountTypecode: "string",
  accountClasscode: "string",
  swiftcode: "string",
  accountNumber: "string",

  bankAccuityID: 0,
  bankcodeType: "string",
  bankcode: "string",

  payee: "string",
  displayname: "string",
  firstname: "string",
  secondFirstname: "string",
  lastname: "string",
  secondLastname: "string",
  businessName: "string",
  addressLine1: "string",
  addressLine2: "string",
  addressLine3: "string",
  cityTown: "string",
  stateProvince: "string",
  postalCode: "string",
  countrycode: "string",
  statecode: "string",
  citycode: "string",
  username: "string"
};

const constants = {
  PUBLIC_ADDRESS: "WALLET",
  EMAIL: "EMAIL",
  MOBILE: "MOBILE",
  BANK: "BANK",
  CASHID: "CASHID"
};

export default function CreatePayee() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userData } = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.loader);

  let { PublicAddress: APPID, PrivateKey: APIKEY } = userData;

  // let APPID = "a185bcde-1d5e-47f3-8de5-405e123ca504";
  // let APIKEY = "81PliNaF5d7V9py+b4xLiQ+Ue/SWBBL/nGinomJqKJ0=";

  const [validationError, setValidationError] = useState("");
  // const [isLoading, setIsLoading] = useState(false);

  const [popup, setPopup] = useState({
    success: false,
    show: false
  });

  const [attachmentTypes, setAttachmentTypes] = useState([]);
  const [walletAttachmentCode, setWalletAttachmentCode] = useState("");

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const [dialingCodes, setDialingCode] = useState([]);
  const [selectedDialingCode, setSelectedDialingCode] = useState(null);

  const [payeeTypeList, setPayeeListType] = useState([]);
  const [payeeType, setPayeeType] = useState("");

  const [payeeTab, setPayeeTab] = useState(constants.PUBLIC_ADDRESS);

  const [createPayeeData, setCreatePayeeData] = useState({
    payeeTypecode: "",
    payeecode: ""
  });

  const [publicAddressData, setPublicAddressData] = useState({
    publicAddress: "",
    isVerified: false
  });

  const [emailData, setEmailData] = useState({
    email: "",
    isVerified: false
  });

  const [mobilePhoneData, setMobilePhoneData] = useState({
    mobilePhone: "",
    mobileDialingcode: "",
    isVerified: false
  });

  const [cashIDData, setCashIDData] = useState({
    cashID: "",
    isVerified: false
  });

  const [bankData, setBankData] = useState({
    // accountTypecode: "",
    // accountClasscode: "",
    swiftcode: "",
    accountNumber: "",
    bankRoutingNumber: ""
  });

  const [publicAddressError, setPublicAddressError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [cashIdError, setCashIdError] = useState("");
  const [bankAccountError, setBankAccountError] = useState("");

  const walletData = attachmentTypes.find(
    (item) => item.WalletLinkTypecode === payeeTab
  );

  useEffect(() => {
    async function main() {
      dispatch(setLoader(true));

      await getPayeeTypeList();

      await getDialingCodes();

      await getWallets();

      dispatch(setLoader(false));
    }

    main();
  }, []);

  async function getWallets() {
    try {
      dispatch(setLoader(true));
      const response = await GetWalletLinks(APPID, APIKEY);

      const decryptedResponse = await decrypytdata(response?.data, APIKEY);

      const responseData = JSON.parse(decryptedResponse);

      // console.log(responseData);

      if (!responseData.CommandResponse) return;

      const responseAPIData = JSON.parse(responseData.CommandResponse);

      setAttachmentTypes(responseAPIData);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoader(false));
    }
  }

  /* async function getWallets() {
    try {
      getDropdownValues("LKWALLETLINKTYPE")
        .then((response) => {
          // console.log(response);
          // console.log(response.data.commandDataset);
          setWalletAttachmentCodes(() => response.data.commandDataset);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  } */

  async function getPayeeTypeList() {
    const response = await getDropdownValues("LKPayeeType");

    setPayeeListType(response.data.commandDataset);
  }

  async function getDialingCodes() {
    try {
      const response = await getDialingcodesAPI("GetDialingcodes");

      const mobileData = response.data;

      setDialingCode(() => mobileData);

      let usaFound = mobileData.find((el) => el.Countrycode === "US");

      if (usaFound) {
        setSelectedDialingCode(usaFound);
      } else {
        setSelectedDialingCode(mobileData[0]);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function validate() {
    let validateTo = payeeTab;

    let data = {
      walletLinkTypecode: payeeTab
      // sourcePublicAddress: APPID
    };

    if (payeeTab === constants.PUBLIC_ADDRESS) {
      if (!publicAddressData.publicAddress) {
        setPublicAddressError("Public address is required");
        return;
      }

      if (
        walletData &&
        walletData.Displayname === publicAddressData.publicAddress
      ) {
        setPublicAddressError(
          `${publicAddressData.publicAddress} Public address is not allowed`
        );
        return;
      }

      data = { ...data, publicAddress: publicAddressData.publicAddress };
    }

    if (payeeTab === constants.CASHID) {
      if (!cashIDData.cashID) {
        setCashIdError("CashID is required");
        return;
      }

      const walletCashIdList = attachmentTypes.filter(
        (item) => item.WalletLinkTypecode === payeeTab
      );

      /* walletCashIdList.forEach((item) => {
        console.log({
          item,
          condition: item.WalletLink === cashIDData.cashID,
          cashID: cashIDData.cashID
        });
      }); */

      if (
        walletData &&
        walletCashIdList.find((item) => item.WalletLink === cashIDData.cashID)
        // walletData.Displayname?.split?.("cashID/")?.[1] === cashIDData.cashID
      ) {
        setCashIdError(`${cashIDData.cashID} CashID is not allowed`);
        return;
      }

      data = { ...data, cashID: cashIDData.cashID };
    }

    if (payeeTab === constants.EMAIL) {
      if (!emailData.email) {
        setEmailError("Email is required");
        return;
      }

      if (walletData && walletData.Displayname === emailData.email) {
        setEmailError(`${emailData.email} Email is not allowed`);
        return;
      }

      data = { ...data, emailAddress: emailData.email };
    }

    if (payeeTab === constants.MOBILE) {
      if (!mobilePhoneData.mobilePhone) {
        setMobileError("Mobile is required");
        return;
      }

      if (!selectedDialingCode.Keycode) {
        setMobileError("Mobile dialingcode is required");
        return;
      }

      if (
        walletData &&
        walletData.Displayname?.split?.("-")?.[1] ===
          mobilePhoneData.mobilePhone
      ) {
        setMobileError(`${mobilePhoneData.mobilePhone} Mobile is not allowed`);
        return;
      }

      data = {
        ...data,
        mobilePhone: mobilePhoneData.mobilePhone,
        mobileDialingcode: selectedDialingCode.Keycode
      };
    }

    if (payeeTab === constants.BANK) {
      if (!bankData.swiftcode) {
        setBankAccountError("Swiftcode is required");
        return;
      }

      if (!bankData.accountNumber) {
        setBankAccountError("Account number is required");
        return;
      }

      if (!bankData.bankRoutingNumber) {
        setBankAccountError("Bank routing number is required");
        return;
      }

      data = {
        ...data,
        swiftcode: bankData.swiftcode,
        accountNumber: bankData.accountNumber,
        bankRoutingNumber: bankData.bankRoutingNumber
      };
    }

    try {
      dispatch(setLoader(true));

      const response = await axios({
        method: "POST",
        baseURL: UMU_URL,
        url: "/general/ValidateWalletLink",
        data,
        headers: {
          Authorization:
            "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
        }
      });

      // console.log(response.data);

      if (response.data?.returnKeycode !== "Success") {
        // setValidationError(response.data.ReturnMessage);
        setPopup({ show: true, success: false });

        return;
      }

      navigate("/payee_confirm", {
        state: { walletLinkTypecode: data.walletLinkTypecode, ...response.data }
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoader(false));
    }
  }

  useEffect(() => {
    setPublicAddressError("");
    setEmailError("");
    setMobileError("");
    setCashIdError("");
    setBankAccountError("");
  }, [payeeTab]);

  return (
    <div className="identification">
      <div className="container">
        <div className="header">
          <Link
            /* to="/splash" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>Create Payee</h3>
        </div>

        {!isLoading ? (
          <>
            <div className="create-payee-page">
              <p>Select payee handle.</p>

              <div className="row">
                <div
                  className="col-4 mb-4"
                  onClick={() => setPayeeTab(constants.PUBLIC_ADDRESS)}
                >
                  <button
                    to="/create_payee"
                    className={`btn-sound payee-box ${
                      payeeTab === constants.PUBLIC_ADDRESS ? "active" : ""
                    }`}
                  >
                    <img
                      src="/icons/wallet-dark.png"
                      className="dark"
                      alt="dark-icon"
                    />
                    <img
                      src="/icons/wallet-light.png"
                      className="light"
                      alt="light-icon"
                    />
                    <h5>Public Wallet</h5>
                  </button>
                </div>

                <div
                  className="col-4 mb-4"
                  onClick={() => setPayeeTab(constants.EMAIL)}
                >
                  <button
                    to="/payee_email"
                    className={`btn-sound payee-box ${
                      payeeTab === constants.EMAIL ? "active" : ""
                    }`}
                  >
                    <img
                      src="/icons/email-dark.png"
                      className="dark"
                      alt="dark-icon"
                    />
                    <img
                      src="/icons/email-light.png"
                      className="light"
                      alt="light-icon"
                    />
                    <h5>Email</h5>
                  </button>
                </div>

                <div
                  className="col-4 mb-4"
                  onClick={() => setPayeeTab(constants.MOBILE)}
                >
                  <button
                    to="/payee_mobile"
                    className={`btn-sound payee-box ${
                      payeeTab === constants.MOBILE ? "active" : ""
                    }`}
                  >
                    <img
                      src="/icons/mobile-dark.png"
                      className="dark"
                      alt="dark-icon"
                    />
                    <img
                      src="/icons/mobile-light.png"
                      className="light"
                      alt="light-icon"
                    />
                    <h5>Mobile</h5>
                  </button>
                </div>

                <div
                  className="col-4"
                  onClick={() => setPayeeTab(constants.CASHID)}
                >
                  <button
                    to="/payee_username"
                    className={`btn-sound payee-box ${
                      payeeTab === constants.CASHID ? "active" : ""
                    }`}
                  >
                    <img
                      src="/icons/user-dark.png"
                      className="dark"
                      alt="dark-icon"
                    />
                    <img
                      src="/icons/user-light.png"
                      className="light"
                      alt="light-icon"
                    />
                    <h5>Cash ID</h5>
                  </button>
                </div>

                <div
                  className="col-4"
                  onClick={() => setPayeeTab(constants.BANK)}
                >
                  <button
                    to="/payee_bank"
                    className={`btn-sound payee-box ${
                      payeeTab === constants.BANK ? "active" : ""
                    }`}
                  >
                    <img
                      src="/icons/bank-dark.png"
                      className="dark"
                      alt="dark-icon"
                    />
                    <img
                      src="/icons/bank-light.png"
                      className="light"
                      alt="light-icon"
                    />
                    <h5>Bank Account</h5>
                  </button>
                </div>
              </div>

              {payeeTab === constants.PUBLIC_ADDRESS ? (
                <>
                  <p>Enter Payee Information</p>

                  <div className="add">
                    <input
                      type="text"
                      className="form-control mt-4"
                      placeholder="Public Wallet"
                      id="CashIDinput"
                      value={publicAddressData.publicAddress}
                      onChange={(e) => {
                        setPublicAddressData((prev) => ({
                          ...prev,
                          publicAddress: e.target.value
                        }));
                      }}
                    />
                    <button
                      className="btn-sound add-btn border-0"
                      onClick={() => validate()}
                    >
                      VERIFY
                    </button>
                  </div>
                  {publicAddressError && (
                    <p className="m-0 mt-1 text-danger">{publicAddressError}</p>
                  )}

                  {/* <p>error</p> */}

                  {/* <Link to="/payee_email" className="mt-5 btn next-btn">
                    CONTINUE
                  </Link> */}

                  {/* <input
                type="email"
                className="form-control"
                placeholder="Public Wallet"
              />
              <Link to="/payee_email" className="btn next-btn">
                CONTINUE
              </Link> */}
                </>
              ) : null}

              {payeeTab === constants.EMAIL ? (
                <>
                  <p>Enter Payee Information</p>

                  <div className="add">
                    <input
                      type="text"
                      className="form-control mt-4"
                      placeholder="Email"
                      id="CashIDinput"
                      value={emailData.email}
                      onChange={(e) => {
                        setEmailData((prev) => ({
                          ...prev,
                          email: e.target.value
                        }));
                      }}
                    />
                    <button
                      className="btn-sound add-btn border-0"
                      onClick={() => validate()}
                    >
                      VERIFY
                    </button>
                  </div>
                  {emailError && (
                    <p className="m-0 mt-1 text-danger">{emailError}</p>
                  )}

                  {/* <Link to="/payee_email" className="mt-5 btn next-btn">
                    CONTINUE
                  </Link> */}
                </>
              ) : null}

              {payeeTab === constants.MOBILE ? (
                <>
                  <p>Enter Payee Information</p>

                  <div className="d-flex mobile">
                    <div style={{ zIndex: 0 }} className="crypto-select">
                      <div
                        className="select"
                        onClick={() => setIsDropDownOpen((prev) => !prev)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={selectedDialingCode.ImageFN?.replace?.(
                            /\/public\//,
                            ""
                          )}
                          style={{ width: "16px" }}
                        />
                        <h6>{selectedDialingCode.Keycode}</h6>
                        <span>
                          {isDropDownOpen ? (
                            <i
                              style={{ width: "10px" }}
                              className="fa fa-angle-down text-white"
                            ></i>
                          ) : (
                            <i
                              style={{ width: "10px" }}
                              className="fa fa-angle-right text-white"
                            ></i>
                          )}
                        </span>
                      </div>
                      {isDropDownOpen ? (
                        <div className="crypto-dropdown">
                          {dialingCodes.map((val, idx) => {
                            return (
                              <div
                                key={idx}
                                onClick={() => {
                                  setIsDropDownOpen(false);
                                  setSelectedDialingCode(val);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src={val.ImageFN?.replace?.(/\/public\//, "")}
                                  style={{ width: "16px" }}
                                />{" "}
                                <span>{val.Keycode}</span>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                    <div className="add">
                      <PhoneInput
                        disableCountryCode
                        autoFormat={false}
                        // enableAreaCodes={true}
                        inputClass="form-control rounded-end"
                        placeholder="Mobile"
                        specialLabel=""
                        inputProps={{ id: "walletInput" }}
                        value={mobilePhoneData.mobilePhone}
                        onChange={(v) => {
                          setMobilePhoneData((prev) => ({
                            ...prev,
                            mobilePhone: v
                          }));
                        }}
                      />

                      {/* <input
                        type="text"
                        className="form-control rounded-end"
                        placeholder="Mobile"
                        id="walletInput"
                        value={mobilePhoneData.mobilePhone}
                        onChange={(e) => {
                          setMobilePhoneData((prev) => ({
                            ...prev,
                            mobilePhone: e.target.value
                          }));
                        }}
                      /> */}
                      <button
                        className="btn-sound add-btn border-0"
                        onClick={() => validate()}
                      >
                        VERIFY
                      </button>
                    </div>
                  </div>

                  {mobileError && (
                    <p className="m-0 mt-1 text-danger">{mobileError}</p>
                  )}
                </>
              ) : null}

              {payeeTab === constants.CASHID ? (
                <>
                  <p>Enter Payee Information</p>

                  <div className="add">
                    <input
                      type="text"
                      className="form-control mt-4"
                      placeholder="Cash ID"
                      id="CashIDinput"
                      value={cashIDData.cashID}
                      onChange={(e) => {
                        setCashIDData((prev) => ({
                          ...prev,
                          cashID: e.target.value
                        }));
                      }}
                    />
                    <button
                      className="btn-sound add-btn border-0"
                      onClick={() => validate()}
                    >
                      VERIFY
                    </button>
                  </div>

                  {cashIdError && (
                    <p className="m-0 mt-1 text-danger">{cashIdError}</p>
                  )}

                  {/* <Link to="/payee_email" className="mt-5 btn next-btn">
                    CONTINUE
                  </Link> */}
                </>
              ) : null}

              {payeeTab === constants.BANK ? (
                <>
                  <p>Enter Payee Information.</p>
                  <div className="bank">
                    {/* <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="Account Name"
                    />
                    <select
                      className="mb-3 form-select"
                      aria-label="Default select example"
                    >
                      <option>Account Class</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <select
                      className="form-select mb-3"
                      aria-label="Default select example"
                    >
                      <option>Account Type</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select> */}

                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="Swift Code"
                      value={bankData.swiftcode}
                      onChange={(e) => {
                        setBankData((prev) => ({
                          ...prev,
                          swiftcode: e.target.value
                        }));
                      }}
                    />
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="Bank Routing Number"
                      value={bankData.bankRoutingNumber}
                      onChange={(e) => {
                        setBankData((prev) => ({
                          ...prev,
                          bankRoutingNumber: e.target.value
                        }));
                      }}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Bank Account Number"
                      value={bankData.accountNumber}
                      onChange={(e) => {
                        setBankData((prev) => ({
                          ...prev,
                          accountNumber: e.target.value
                        }));
                      }}
                    />
                    {bankAccountError && (
                      <p className="m-0 mt-1 text-danger">{bankAccountError}</p>
                    )}
                    <button
                      to="/payee_username"
                      className="btn-sound btn next-btn"
                      onClick={() => validate()}
                    >
                      VERIFY
                    </button>
                  </div>
                </>
              ) : null}
            </div>

            {popup.show ? (
              <div className="popup-model">
                {popup.show && popup.success ? (
                  <div className="modal-box">
                    <div className="icons-fixed">
                      <img src="/icons/success-icon.png" alt="icon" />
                    </div>
                    <h2>Your Cash ID</h2>
                    <div className="modal-list">
                      <h5
                        style={{
                          overflowWrap: "break-word",
                          textAlign: "center"
                        }}
                      >
                        https://unicoinnetwork.com/cashID/xyz
                      </h5>
                    </div>
                    <div className="d-flex align-items-center w-100 mt-3">
                      <button
                        className="btn-sound modal-btn me-3 w-50 justify-content-center"
                        onClick={() => {
                          setPopup({ show: false, success: false });
                        }}
                      >
                        CLOSE
                      </button>
                      <button
                        className="btn-sound modal-btn fill w-50 justify-content-center"
                        onClick={() => {
                          navigator.clipboard.writeText("Copied text");
                          setPopup({ show: false, success: false });
                        }}
                      >
                        COPY
                      </button>
                    </div>
                  </div>
                ) : null}

                {popup.show && !popup.success ? (
                  <div className="modal-box">
                    <div className="icons-fixed">
                      <img src="/icons/fail.png" alt="icon" />
                    </div>
                    <h2>
                      Payee Information
                      <br />
                      Not Found
                    </h2>
                    {/* <div className="text-center mt-3 mb-5">
                      <h6>Try another cash ID</h6>
                    </div> */}
                    <div className="mt-5 d-flex align-items-center w-100 mt-3">
                      <button
                        className="btn-sound modal-btn fill w-100 justify-content-center"
                        onClick={() =>
                          setPopup({ show: false, success: false })
                        }
                      >
                        CLOSE
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
}
