import React, { useState } from "react";
import "../styles/ModalOverlay.css";

import Modal from "react-modal";
import { useContext } from "react";
import { ModalOverlayContext } from "../context/ModalOverlayContext";

Modal.setAppElement("#modal-overlay");

export default function App() {
  const { isModalOverlayOpen, toggleModal } = useContext(ModalOverlayContext);

  return (
    <div>
      <Modal
        isOpen={isModalOverlayOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
        shouldCloseOnOverlayClick={false}
      ></Modal>
    </div>
  );
}
