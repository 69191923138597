import loaderIcon from "../assets/gifs/unicoin_loader.gif";

export function showLoaderOnElement(element) {
  element.style.backgroundColor = "#acacac";
  element.style.width = "100%";
  // element.disabled = true;
  element.innerHTML = `<img width="16px" src="${loaderIcon}" alt="UMU Spinner" />`;
}

export function hideLoaderFromElement(element) {
  element.style.backgroundColor = element.dataset.color;
  element.style.color = "#fff";
  // element.disabled = false;
  element.innerHTML = element.dataset.text;
}
