import "./PayeeConfirm.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreatePayee } from "../../../apis/payees";
import { decrypytdata } from "../../../apis/walletKey";
import { setResponse } from "../../../store/slices/responseSlice";
import { setLoader } from "../../../store/slices/loaderSlice";

export default function PayeeConfirm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userData } = useSelector((state) => state.user);

  const location = useLocation();

  // console.log(location.state);

  const [isError, setIsError] = useState("");
  const [payeeNickName, setPayeeNickName] = useState("");
  const [isCreatePayeeSubmiting, setIsPayeeSubmiting] = useState(false);

  const verifiedPayeeData = location.state;
  const { PublicAddress: APPID, PrivateKey: APIKEY } = userData;

  async function createPayeeHandler() {
    setIsError(() => "");

    if (!payeeNickName) {
      setIsError(() => "Payee nickname is required");
      return;
    }

    try {
      // setIsPayeeSubmiting(true);
      dispatch(setLoader(true));

      const createPayeeResponse = await CreatePayee(APPID, APIKEY, {
        sourceWalletAddress: APPID,
        targetPublicAddress: location.state.payeePublicAddress,
        walletLinkTypecode: location.state.walletLinkTypecode,
        // walletLinkInformation: "string",
        payee: location.state.payeeDisplayname,
        displayname: payeeNickName
      });

      const decryptedPayeeResponse = await decrypytdata(
        createPayeeResponse.data,
        APIKEY
      );

      const responseAPI = {
        ...createPayeeResponse,
        headers: undefined,
        request: undefined,
        config: {
          baseURL: createPayeeResponse.config.baseURL,
          url: createPayeeResponse.config.url
        }
      };

      const decryptedPayeeResponseJSON = JSON.parse(decryptedPayeeResponse);

      console.log(decryptedPayeeResponseJSON);

      if (
        decryptedPayeeResponse.ReturnKeycode === "Success" ||
        decryptedPayeeResponse.ReturnMessage === "Success"
      ) {
        dispatch(
          setResponse({
            apiResponse: responseAPI,
            apiData: decryptedPayeeResponseJSON
          })
        );
      } else {
        dispatch(
          setResponse({
            apiResponse: responseAPI,
            apiData: decryptedPayeeResponseJSON
          })
        );
      }

      navigate("/result", { replace: true });
    } catch (err) {
      console.error(err);
      setIsError(err.message);
    } finally {
      // setIsPayeeSubmiting(false);
      dispatch(setLoader(false));
    }
  }

  return (
    <div className="identification">
      <div className="container">
        <div className="header">
          <Link
            /* to="/splash" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>Confirm Payee Information</h3>
        </div>
        <div className="confirm-payee-information">
          <div className="text-center mt-4 mb-3">
            <img
              src={
                verifiedPayeeData?.payeeImageFN
                  ? verifiedPayeeData?.payeeImageFN
                  : "/images/default.png"
              }
              alt="default"
            />
          </div>
          <div className="payee-content">
            <h6>Payee</h6>
            <h4>
              {verifiedPayeeData?.payeeDisplayname
                ? verifiedPayeeData?.payeeDisplayname
                : "Torres.co"}
            </h4>
            {/* <h6>First Name</h6>
            <h4>Anyel</h4>
            <h6>Middle Name</h6>
            <h4>Anyel</h4>
            <h6>Last Name</h6>
            <h4>Torres</h4>
            <h6>Second Last Name</h6>
            <h4>Anyel</h4> */}
            <h6>Country</h6>
            <h4>
              <img
                src={
                  verifiedPayeeData?.payeeCountrycode
                    ? `/icons/flags/${verifiedPayeeData?.payeeCountrycode?.toLowerCase()}.png`
                    : "/icons/flags/US-flag.png"
                }
                className="me-2"
                alt="flag"
                style={{ width: "32px" }}
              />
              {verifiedPayeeData?.payeeCountrycode
                ? verifiedPayeeData?.payeeCountrycode
                : "USA"}
            </h4>
          </div>

          <p className="mt-2 mb-0 text-danger">{isError}</p>
          <input
            type="text"
            className="form-control mt-4"
            placeholder="Payee Nickname"
            value={payeeNickName}
            onChange={(e) => setPayeeNickName(e.target.value)}
          />

          <div className="text-center">
            <button className="btn-sound next-btn" onClick={createPayeeHandler}>
              <i className="bi bi-arrow-right-circle-fill"></i>
              ADD PAYEE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
