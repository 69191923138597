import "./Email.css";
import { Link, useNavigate } from "react-router-dom";

export default function Email() {
  const navigate = useNavigate();

  return (
    <div className="identification">
      <div className="container">
        <div className="header">
          <Link
            /* to="/splash" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>Create Payee</h3>
        </div>
        <div className="create-payee-page">
          <p>Select payee handle.</p>
          <div className="row">
            <div className="col-4 mb-4">
              <Link to="/create_payee" className="payee-box">
                <img
                  src="/icons/wallet-dark.png"
                  className="dark"
                  alt="dark-icon"
                />
                <img
                  src="/icons/wallet-light.png"
                  className="light"
                  alt="light-icon"
                />
                <h5>Public Wallet</h5>
              </Link>
            </div>
            <div className="col-4 mb-4">
              <Link to="/payee_email" className="payee-box active">
                <img
                  src="/icons/email-dark.png"
                  className="dark"
                  alt="dark-icon"
                />
                <img
                  src="/icons/email-light.png"
                  className="light"
                  alt="light-icon"
                />
                <h5>Email</h5>
              </Link>
            </div>
            <div className="col-4 mb-4">
              <Link to="/payee_mobile" className="payee-box">
                <img
                  src="/icons/mobile-dark.png"
                  className="dark"
                  alt="dark-icon"
                />
                <img
                  src="/icons/mobile-light.png"
                  className="light"
                  alt="light-icon"
                />
                <h5>Mobile</h5>
              </Link>
            </div>

            <div className="col-4">
              <Link to="/payee_username" className="payee-box">
                <img
                  src="/icons/user-dark.png"
                  className="dark"
                  alt="dark-icon"
                />
                <img
                  src="/icons/user-light.png"
                  className="light"
                  alt="light-icon"
                />
                <h5>Cash ID</h5>
              </Link>
            </div>

            <div className="col-4">
              <Link to="/payee_bank" className="payee-box">
                <img
                  src="/icons/bank-dark.png"
                  className="dark"
                  alt="dark-icon"
                />
                <img
                  src="/icons/bank-light.png"
                  className="light"
                  alt="light-icon"
                />
                <h5>Bank Account</h5>
              </Link>
            </div>
          </div>

          <p>Enter Payee Information</p>

          <input
            type="email"
            className="form-control"
            placeholder="Email Address"
          />
          <Link to="/payee_mobile" className="btn next-btn">
            CONTINUE
          </Link>
        </div>
      </div>
    </div>
  );
}
