import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./ExchangeRates.css";
import { GetFXRatesApi } from "../../apis/FxRatesApi";
import { decrypytdata } from "../../apis/walletKey";
import { setLoader } from "../../store/slices/loaderSlice";

export default function ExchangeRates() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userData } = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.loader);
  const [fxRates, setFxRates] = useState([]);
  const [showRatesOf, setShowRatesOf] = useState("MY_RATES");
  // const [isLoading, setIsLoading] = useState(false);

  const APPID = userData?.PublicAddress;
  const APIKEY = userData?.PrivateKey;
  const userRates = userData?.CurrencycodeList;

  const userRatesList = userRates.split(",");
  const [myRates, setMyRates] = useState([]);

  // const userDataString = localStorage.getItem("user");

  // let userData = null;

  // if (userDataString) {
  //   userData = JSON.parse(userDataString);
  // }

  // const APPID = userData.commandResponse[0].PublicAddress;
  // const APIKEY = userData.commandResponse[0].PrivateKey;

  // console.log(APPID, APIKEY);

  const fetchData = async () => {
    try {
      dispatch(setLoader(true));
      const response = await GetFXRatesApi(APPID, APIKEY, {
        currencyPairTypeID: 1
      });

      const fxRatesJSON = await decrypytdata(response?.data, APIKEY);
      const fxRatesObj = JSON.parse(fxRatesJSON);

      let data = [];

      if (fxRatesObj.CommandResponse) {
        data = JSON.parse(fxRatesObj.CommandResponse);
      }

      setFxRates(() => data);

      setMyRates(
        data.filter((row) => {
          const element = userRatesList.find((val) => val === row.Currencycode);
          if (element) return true;
          else return false;
        })
      );
    } catch (error) {
      if (error.response) {
        // const fxRatesJSON = await decrypytdata(error.response?.data, APIKEY);
        // if (!fxRatesJSON) return;
        // const dataJSON = JSON.parse(fxRatesJSON);
        // let data = [];
        // if (dataJSON?.CommandResponse) {
        //   data = JSON.parse(dataJSON?.CommandResponse);
        // }
        // // console.log(data);
      } else {
        console.log(error);
      }
    } finally {
      dispatch(setLoader(false));
    }
  };

  // console.log(myRates);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="email-address p-0 identification">
      <div className="container">
        <div className="header">
          <h3 className="ms-0 mt-3">Exchange Rates</h3>
        </div>

        <div className="phone-content exchange-rates  email-content d-flex align-items-center mt-4">
          <div className="tabs mt-0">
            <div
              onClick={() => setShowRatesOf("MY_RATES")}
              style={{ cursor: "pointer" }}
            >
              <p
                className={showRatesOf === "MY_RATES" ? "me-3 active" : "me-3"}
              >
                MY RATES
              </p>
            </div>
            <div
              onClick={() => setShowRatesOf("ALL_RATES")}
              style={{ cursor: "pointer" }}
            >
              <p className={showRatesOf === "ALL_RATES" ? "active" : ""}>
                ALL RATES
              </p>
            </div>
          </div>
          <div>
            <Link to="/add_fx_rates" className="plus-icon ms-2">
              <i className="bi bi-plus"></i>
            </Link>
          </div>
        </div>

        <div className="table-bg mt-4">
          {!isLoading && (
            <>
              {showRatesOf === "ALL_RATES" && (
                <>
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th className="text-end">Currency</th>
                        {/* <th className="text-center">Country</th> */}
                        <th className="text-end px-0">
                          USD
                          <br />
                          Rate
                        </th>
                        <th className="text-end px-0">
                          UMU
                          <br />
                          Rate
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {fxRates &&
                        fxRates.length > 0 &&
                        fxRates.map((rate) => (
                          <tr key={rate.RowID}>
                            <td className="text-start">
                              <img
                                src={rate.QuoteImageFN}
                                alt="flag"
                                style={{
                                  width: "32px",
                                  height: `${
                                    rate.Currencycode === "UMU"
                                      ? "32px"
                                      : "18px"
                                  }`
                                }}
                              />
                              <span className="ps-2">{rate.Currencycode}</span>
                            </td>
                            {/* <td className="text-center"></td> */}
                            <td className="text-end">
                              <div
                                className="d-flex justify-content-between"
                                style={{ width: "100%" }}
                              >
                                <span style={{ paddingLeft: "10px" }}>
                                  {rate.QuoteCurrencySymbol}
                                </span>
                                <span style={{ wordBreak: "break-all" }}>
                                  {Number(rate.BasePrice).toFixed(2)}
                                </span>
                              </div>
                            </td>
                            <td className="text-end">
                              <div
                                className="d-flex justify-content-between"
                                style={{ width: "100%" }}
                              >
                                <span style={{ paddingLeft: "40px" }}>
                                  {rate.QuoteCurrencySymbol}
                                </span>
                                <span style={{ wordBreak: "break-all" }}>
                                  {Number(rate.CoinPrice).toFixed(4)}
                                </span>
                              </div>
                            </td>
                            <td className="d-flex justify-content-end">
                              <div
                                className="table-icon"
                                onClick={() => {
                                  // navigate("/transfer_amount", {
                                  //   state: { rate }
                                  // });F
                                  navigate("/payees", {
                                    state: { rate }
                                  });
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <img src="/icons/table-icon.png" alt="icon" />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              )}

              {showRatesOf === "MY_RATES" && (
                <>
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th className="text-end">Currency</th>
                        {/* <th className="text-center">Country</th> */}
                        <th className="text-end px-0">
                          USD
                          <br />
                          Rate
                        </th>
                        <th className="text-end px-0">
                          UMU
                          <br />
                          Rate
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {myRates.map((row, index) => {
                        return (
                          <tr key={row.RowID}>
                            <td className="text-start">
                              <img
                                src={row.QuoteImageFN}
                                alt="flag"
                                style={{
                                  width: "32px",
                                  height: `${
                                    row.Currencycode === "UMU" ? "32px" : "18px"
                                  }`
                                }}
                              />
                              <span className="ps-2">{row.Currencycode}</span>
                            </td>
                            {/* <td className="text-center"></td> */}
                            <td className="text-end">
                              <div
                                className="d-flex justify-content-between"
                                style={{ width: "100%" }}
                              >
                                <span style={{ paddingLeft: "10px" }}>
                                  {row.QuoteCurrencySymbol}
                                </span>
                                <span style={{ wordBreak: "break-all" }}>
                                  {Number(row.BasePrice).toFixed(2)}
                                </span>
                              </div>
                            </td>
                            <td className="text-end">
                              <div
                                className="d-flex justify-content-between"
                                style={{ width: "100%" }}
                              >
                                <span style={{ paddingLeft: "40px" }}>
                                  {row.QuoteCurrencySymbol}
                                </span>
                                <span style={{ wordBreak: "break-all" }}>
                                  {Number(row.CoinPrice).toFixed(4)}
                                </span>
                              </div>
                            </td>
                            <td className="d-flex justify-content-end">
                              <div
                                className="table-icon"
                                onClick={() => {
                                  // navigate("/transfer_amount", {
                                  //   state: { row }
                                  // });F
                                  navigate("/payees", {
                                    state: { row }
                                  });
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <img src="/icons/table-icon.png" alt="icon" />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              )}
            </>
          )}

          {/* {!isLoading && (
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th className="text-center">Country</th>
                  <th className="text-center">Currency</th>
                  <th className="text-end">
                    USD
                    <br />
                    Rate
                  </th>
                  <th className="text-end">
                    UMU
                    <br />
                    Rate
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody> */}
          {/* {showRatesOf === "ALL_RATES" &&
                  fxRates &&
                  fxRates.length > 0 &&
                  fxRates.map((rate) => (
                    <tr key={rate.RowID}>
                      <td className="text-center">
                        <img src={rate.QuoteImageFN} alt="flag" />
                      </td>
                      <td className="text-center">{rate.Currencycode}</td>
                      <td className="text-end">
                        <div className="d-flex justify-content-between">
                          <span>{rate.QuoteCurrencySymbol}</span>
                          <span>{Number(rate.BasePrice).toFixed(2)}</span>
                        </div>
                      </td>
                      <td className="text-end">
                        <div className="d-flex justify-content-between">
                          <span>{rate.QuoteCurrencySymbol}</span>
                          <span>{Number(rate.CoinPrice).toFixed(6)}</span>
                        </div>
                      </td>
                      <td>
                        <div
                          className="table-icon"
                          onClick={() =>
                            navigate("/transfer_amount", { state: { rate } })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <img src="/icons/table-icon.png" alt="icon" />
                        </div>
                      </td>
                    </tr>
                  ))} */}
          {/* </tbody>
            </table>
          )} */}
        </div>
      </div>
    </div>
  );
}

/*
<tr key={rate.RowID}>
  <td>
    <div>
      <input type="checkbox" />
    </div>
  </td>
  <td className="text-center">
    <img src={rate.QuoteImageFN} alt="flag" />
  </td>
  <td className="text-center">{rate.Currencycode}</td>
  <td className="text-end">
    <div className="d-flex justify-content-between">
      <span>{rate.QuoteCurrencySymbol}</span>
      <span>{Number(rate.BasePrice).toFixed(2)}</span>
    </div>
  </td>
  <td className="text-end">
    <div className="d-flex justify-content-between">
      <span>{rate.QuoteCurrencySymbol}</span>
      <span>{Number(rate.CoinPrice).toFixed(6)}</span>
    </div>
  </td>
</tr>;
*/

{
  /*
  showRatesOf === "MY_RATES" && (
    <>
      <div className="table-height">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-center">Country</th>
              <th className="text-center">Currency</th>
              <th className="text-end">
                USD
                <br />
                Rate
              </th>
              <th className="text-end">
                UMU
                <br />
                Rate
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {fxRates &&
              fxRates.length > 0 &&
              fxRates.map((rate) => (
                <tr key={rate.RowID}>
                  <td>
                    <div>
                      <input type="checkbox" />
                    </div>
                  </td>
                  <td className="text-center">
                    <img src={rate.QuoteImageFN} alt="flag" />
                  </td>
                  <td className="text-center">{rate.Currencycode}</td>
                  <td className="text-end">
                    <div className="d-flex justify-content-between">
                      <span>{rate.QuoteCurrencySymbol}</span>
                      <span>{Number(rate.BasePrice).toFixed(2)}</span>
                    </div>
                  </td>
                  <td className="text-end">
                    <div className="d-flex justify-content-between">
                      <span>{rate.QuoteCurrencySymbol}</span>
                      <span>{Number(rate.CoinPrice).toFixed(6)}</span>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="text-center mt-4">
        <button className="next-btn mt-4 me-2">CANCEL</button>
        <button className="next-btn mt-4">SUBMIT</button>
      </div>
    </>
  );
              */
}
