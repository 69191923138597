import "./WalletCashId.css";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import axios from "axios";
import { UMU_URL } from "../../constants/config";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../store/slices/loaderSlice";

export default function WalletCashId() {
  const { walletCashId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.loader);

  const [cashIdData, setCashIdData] = useState(null);

  useEffect(() => {
    async function main() {
      try {
        dispatch(setLoader(true));
        await getCashIdDetails();
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setLoader(false));
      }
    }

    main();
  }, []);

  async function getCashIdDetails() {
    const response = await axios({
      method: "POST",
      baseURL: UMU_URL,
      url: "general/GetCashID",
      data: { cashID: walletCashId },
      headers: {
        Authorization:
          "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
      }
    });

    console.log(response);

    setCashIdData(response.data || null);
  }

  return (
    <div className="email-address p-0 identification">
      <Helmet>
        <title>{cashIdData?.[0]?.Displayname}</title>
        <meta name="description" content={cashIdData?.[0]?.ShortDescription} />
        <meta property="og:image" content={cashIdData?.[0]?.ImageFN} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <div className="container">
        <div className="header">
          <h3 className="ms-0 mt-3">Cash ID</h3>
        </div>

        {!isLoading && (
          <div className="mt-5 text-white">
            <div className="text-center">
              <img
                src={
                  cashIdData?.[0]?.ImageFN
                    ? cashIdData?.[0]?.ImageFN
                    : "/images/default.png"
                }
                alt="default"
                style={{ width: "64px", height: "64px", borderRadius: "8px" }}
              />
              <h3 className="mt-4" style={{ fontSize: "20px" }}>
                {cashIdData?.[0]?.Displayname}
              </h3>
              <p style={{ fontSize: "12px" }}>{cashIdData?.[0]?.GeoLocation}</p>
              <p style={{ fontSize: "12px" }}>
                {cashIdData?.[0]?.ShortDescription}
              </p>
              <div className="mt-4 text-center d-flex align-items-center">
                <button
                  className="btn-sound cashid_btn me-2 w-100"
                  onClick={() => navigate("/payees")}
                >
                  PAY
                </button>
                <button
                  className="btn-sound cashid_btn w-100"
                  onClick={() => navigate("/payees")}
                >
                  ESCROW
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
