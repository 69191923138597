import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AttacheWallet, GetWalletLinks } from "../../../apis/wallet";
import { getDropdownValues } from "../../../apis/dropdown";

import CashId from "./CashId";
import Crypto from "./Crypto";

import "./AttachBankAccount.css";
import { decrypytdata } from "../../../apis/walletKey";
import { setResponse } from "../../../store/slices/responseSlice";
import { setLoader } from "../../../store/slices/loaderSlice";

export default function AttachAll() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { data: userData } = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.loader);

  const { PublicAddress: APPID, PrivateKey: APIKEY } = userData;

  const [initState, setInitState] = useState({
    walletLinkTypecode: "string",
    cashID: "string",
    shortDescription: "string",
    profileDescription: "string",
    imageFN: "string",
    cryptocurrencycode: "string"

    // deviceTypecode: "string",
    // deviceID: "string",
    // deviceModel: "string",
    // deviceVersion: "string",
    // countrycode: "string",
    // dialingcode: "string",
    // mobilephone: "string",
    // emailAddress: "string",
    // bankAccuityID: 0,
    // domesticRoutingSystemcode: "string",
    // domesticRoutingNumber: "string",
    // intlRoutingSystemcode: "string",
    // intlRoutingNumber: "string",
    // swiftcode: "string",
    // accountName: "string",
    // accountNumber: "string",
    // accountClasscode: "string",
    // accountTypecode: "string",
  });

  const [walletAttachmentCodes, setWalletAttachmentCodes] = useState([]);
  const [walletAttachmentCode, setWalletAttachmentCode] = useState(
    location?.state?.WalletLinkTypecode || "BANK"
  );

  const [attachmentTypes, setAttachmentTypes] = useState([]);
  const [attachmentType, setAttachmentType] = useState(1);

  const [bankRoutingSystemOptions, setBankRoutingSystemOptions] = useState([]);

  const [cryptoList, setCryptoList] = useState([]);
  const [selectedCrypto, setSelectedCrypto] = useState(null);

  const [cashIdData, setCashIdData] = useState({
    cashID: "",
    shortDescription: "",
    profileDescription: ""
  });

  const [cryptoData, setCryptoData] = useState({
    cryptocurrencycode: "",
    cryptoPublicAddress: ""
  });
  // const [isLoading, setIsLoading] = useState(false);

  const [bankAttachmentError, setBankAttachmentError] = useState("");
  const [bankSearch, setBankSearch] = useState("");
  const [bankData, setBankData] = useState({
    swiftCode: "",
    routingSystem: "",
    routingNumber: "",
    bankAccount: ""
  });

  useEffect(() => {
    async function main() {
      try {
        dispatch(setLoader(true));
        getDropdownValues("LKWALLETLINKTYPE").then((response) => {
          setWalletAttachmentCodes(() => response.data.commandDataset);
        });

        getDropdownValues("LKCrypto").then((response) => {
          // USDT,USDC,BTC,ETH
          const allowedCryptos = ["USDT", "USDC", "BTC", "ETH"];
          const filteredCryptos = response.data?.commandDataset?.filter?.(
            (row) => allowedCryptos.includes(row.Keycode)
          );

          console.log(filteredCryptos);

          /* setCryptoList(() => response.data.commandDataset);
          setSelectedCrypto(() => response.data.commandDataset[0]); */
          setCryptoList(() => filteredCryptos || []);
          setSelectedCrypto(() => filteredCryptos?.[0]);
        });
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setLoader(false));
      }
    }

    main();
  }, []);

  useEffect(() => {
    const main = async () => {
      await getBankRoutingSystemOptions();
    };
    main();
  }, []);

  async function getBankRoutingSystemOptions() {
    try {
      const response = await getDropdownValues("LKRoutingSystems");
      setBankRoutingSystemOptions(response.data.commandDataset);
      setBankData((prev) => ({
        ...prev,
        routingSystem: response.data.commandDataset.find(
          (row) => row.Keycode === "ABA"
        )
      }));
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  function validateBankAttachmentData() {
    const { swiftCode, routingNumber, bankAccount } = bankData;

    if (!swiftCode) return "Bank swift code is required";
    if (!routingNumber) return "Bank routing number is required";
    if (routingNumber.length < 5 || routingNumber.length > 10)
      return "Bank routing number must be between 5 and 10 digits";
    if (!bankAccount) return "Bank account number is required";
    if (bankAccount.length < 5 || bankAccount.length > 12)
      return "Bank account number must be between 5 and 12 digits";
  }

  async function handleBankAttachmentSubmit() {}

  /* 
  async function getWallets() {
    try {
      const response = await GetWalletLinks(APPID, APIKEY);

      const decryptedResponse = await decrypytdata(response?.data, APIKEY);

      const responseData = JSON.parse(decryptedResponse);

      console.log(responseData);

      const responseAPIData = JSON.parse(responseData.CommandResponse);

      console.log(responseAPIData);

      setAttachmentTypes(responseAPIData);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoader(false));
    }
  } */

  async function handleSubmit() {
    let data = {
      walletLinkTypecode: walletAttachmentCode
    };

    if (walletAttachmentCode === "CASHID") {
      data = { ...data, ...cashIdData };
    }

    if (walletAttachmentCode === "CRYPTO") {
      data = {
        ...data,
        ...cryptoData,
        cryptocurrencycode: selectedCrypto.Keycode
      };
    }

    if (walletAttachmentCode === "BANK") {
      setBankAttachmentError("");
      const error = validateBankAttachmentData();
      if (error) {
        setBankAttachmentError(error);
        return;
      }

      data = {
        ...data,
        domesticRoutingSystemcode: bankData.routingSystem.Keycode,
        domesticRoutingNumber: bankData.routingNumber,
        accountNumber: bankData.bankAccount,
        swiftcode: bankData.swiftCode,
        intlRoutingSystemcode: "SWIFT",
        intlRoutingNumber: bankData.swiftCode
      };
    }

    try {
      dispatch(setLoader(true));

      const response = await AttacheWallet(APPID, APIKEY, data);

      const responseAPI = {
        ...response,
        headers: undefined,
        request: undefined,
        config: { baseURL: response.config.baseURL, url: response.config.url }
      };

      const decryptedString = await decrypytdata(response.data || "", APIKEY);

      const decryptedData = decryptedString && JSON.parse(decryptedString);

      if (decryptedData?.ReturnKeycode === "Success") {
        dispatch(
          setResponse({ apiResponse: responseAPI, apiData: decryptedData })
        );
      } else {
        dispatch(
          setResponse({ apiResponse: responseAPI, apiData: decryptedData })
        );
      }

      navigate("/result");
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoader(false));
    }
  }

  return (
    <div className="identification">
      <div className="container">
        <div className="header">
          <Link
            /* to="/wallet_attachments" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>Wallet Attachment</h3>
        </div>

        {isLoading ? (
          <></>
        ) : (
          <div className="create-payee-page">
            <p>Select the type of wallet attachment.</p>

            <div className="row">
              {/* <div
                className="col-4"
                onClick={() => setWalletAttachmentCode("ALL")}
              >
                <button
                  to="/attach_all"
                  className={`payee-box${
                    walletAttachmentCode === "ALL" ? " active" : ""
                  }`}
                >
                  <img
                    src="/icons/grid-dark.png"
                    className="dark"
                    alt="dark-icon"
                  />
                  <img
                    src="/icons/grid-light.png"
                    className="light"
                    alt="light-icon"
                  />
                  <h5>All</h5>
                </button>
              </div> */}

              <div className="col-4">
                <button
                  to="/attach_bank"
                  className={`btn-sound payee-box${
                    walletAttachmentCode === "BANK" ? " active" : ""
                  }`}
                  onClick={() => setWalletAttachmentCode("BANK")}
                >
                  <img
                    src="/icons/bank-dark.png"
                    className="dark"
                    alt="dark-icon"
                  />
                  <img
                    src="/icons/bank-light.png"
                    className="light"
                    alt="light-icon"
                  />
                  <h5>Bank Account</h5>
                </button>
              </div>

              <div className="col-4 mb-4">
                <button
                  to="/attach_mobile"
                  className="btn-sound payee-box disabled"
                >
                  <img
                    src="/icons/mobile-dark.png"
                    className="dark"
                    alt="dark-icon"
                  />
                  <img
                    src="/icons/mobile-light.png"
                    className="light"
                    alt="light-icon"
                  />
                  <h5>Mobile Number</h5>
                </button>
              </div>

              <div className="col-4 mb-4">
                <button
                  to="/attach_email"
                  className="btn-sound payee-box disabled"
                >
                  <img
                    src="/icons/email-dark.png"
                    className="dark"
                    alt="dark-icon"
                  />
                  <img
                    src="/icons/email-light.png"
                    className="light"
                    alt="light-icon"
                  />
                  <h5>Email</h5>
                </button>
              </div>

              <div
                className="col-4"
                onClick={() => setWalletAttachmentCode("CASHID")}
              >
                <button
                  to="/attach_username"
                  className={`btn-sound payee-box${
                    walletAttachmentCode === "CASHID" ? " active" : ""
                  }`}
                >
                  <img
                    src="/icons/user-dark.png"
                    className="dark"
                    alt="dark-icon"
                  />
                  <img
                    src="/icons/user-light.png"
                    className="light"
                    alt="light-icon"
                  />
                  <h5>Cash ID</h5>
                </button>
              </div>

              <div
                className="col-4"
                onClick={() => setWalletAttachmentCode("CRYPTO")}
              >
                <button
                  to="/attach_cryptocurrency"
                  className={`btn-sound payee-box${
                    walletAttachmentCode === "CRYPTO" ? " active" : ""
                  }`}
                >
                  <img
                    src="/icons/cryptocurrency-dark.png"
                    className="dark"
                    alt="dark-icon"
                  />
                  <img
                    src="/icons/cryptocurrency-light.png"
                    className="light"
                    alt="light-icon"
                  />
                  <h5>Cryptocurrency</h5>
                </button>
              </div>

              <div
                className="col-4"
                onClick={() => setWalletAttachmentCode("APPS")}
              >
                <Link
                  to="/attachapps"
                  className={`payee-box${
                    walletAttachmentCode === "APPS" ? " active" : ""
                  }`}
                >
                  <img
                    src="/icons/app-icon-dark.png"
                    className="dark"
                    alt="dark-icon"
                  />
                  <img
                    src="/icons/app-icon-light.png"
                    className="light"
                    alt="light-icon"
                  />
                  <h5>Apps</h5>
                </Link>
              </div>
            </div>

            {!isLoading ? (
              <>
                {/* {walletAttachmentCode === "ALL" ? (
                  <div className="table-bg table-content">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Wallet Attchment</th>
                          <th className="text-end">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border-0">Crypto</td>
                          <td className="border-0">
                            <img
                              src="/images/crypto/btc.png"
                              className="crypto-img me-2"
                              alt="img"
                            />
                            BTC
                          </td>
                          <td className="text-end border-0">
                            <Link to="/" className="action-btn me-1">
                              <i className="bi bi-pen"></i>
                            </Link>
                            <Link to="/" className="action-btn">
                              <i className="bi bi-trash"></i>
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td className="border-0">Email</td>
                          <td className="border-0">
                            <Link to="/" className="email-link">
                              jobcox@abc.com
                            </Link>
                          </td>
                          <td className="text-end border-0">
                            <Link to="/" className="action-btn me-1">
                              <i className="bi bi-pen"></i>
                            </Link>
                            <Link to="/" className="action-btn">
                              <i className="bi bi-trash"></i>
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td className="border-0">Bank Account</td>
                          <td className="border-0">2983742857257</td>
                          <td className="text-end border-0">
                            <Link to="/" className="action-btn me-1">
                              <i className="bi bi-pen"></i>
                            </Link>
                            <Link to="/" className="action-btn">
                              <i className="bi bi-trash"></i>
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : null} */}

                {walletAttachmentCode === "BANK" && (
                  <>
                    {bankAttachmentError && (
                      <p className="text-danger">{bankAttachmentError}</p>
                    )}

                    <div className="payees-content d-flex mt-4">
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          <i className="bi bi-search"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Bank"
                          value={bankSearch}
                          onChange={(e) => setBankSearch(e.target.value)}
                        />
                      </div>
                    </div>

                    <input
                      type="text"
                      className="form-control mt-3"
                      placeholder="SWIFT / BIC Code"
                      value={bankData.swiftCode}
                      onChange={(e) =>
                        setBankData((prev) => ({
                          ...prev,
                          swiftCode: e.target.value
                        }))
                      }
                    />
                    <select
                      // type="text"
                      className="form-select mt-3"
                      style={{ backgroundColor: "#797979", color: "#fff" }}
                      value={bankData.routingSystem.Keycode}
                      onChange={(e) =>
                        setBankData((prev) => ({
                          ...prev,
                          routingSystem: bankRoutingSystemOptions.find(
                            (row) => row.Keycode === e.target.value
                          )
                        }))
                      }
                    >
                      {/* <option disabled>Domestic Routing System</option> */}
                      {bankRoutingSystemOptions &&
                        bankRoutingSystemOptions?.map((row, index) => {
                          return (
                            <option
                              key={`${row.ItemID}-${index}`}
                              value={row.Keycode}
                            >
                              {row.Displayname}
                            </option>
                          );
                        })}
                    </select>
                    <input
                      type="text"
                      className="form-control mt-3"
                      placeholder="Bank Routing Number"
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                      }
                      value={bankData.routingNumber}
                      onChange={(e) =>
                        setBankData((prev) => ({
                          ...prev,
                          routingNumber: e.target.value
                        }))
                      }
                    />
                    <input
                      type="text"
                      className="form-control mt-3"
                      placeholder="Bank Account Number"
                      value={bankData.bankAccount}
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                      }
                      onChange={(e) =>
                        setBankData((prev) => ({
                          ...prev,
                          bankAccount: e.target.value
                        }))
                      }
                    />
                    <div className="d-flex align-items-center mt-5">
                      <button
                        role="button"
                        className="btn-sound btn next-btn next-btn-border me-3 mt-0"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        CANCEL
                      </button>
                      <button
                        role="button"
                        className="btn-sound btn next-btn mt-0"
                        onClick={handleSubmit}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </>
                )}

                {walletAttachmentCode === "CASHID" ? (
                  <CashId
                    cashIdData={cashIdData}
                    setCashIdData={setCashIdData}
                    handleSubmit={handleSubmit}
                  />
                ) : null}

                {walletAttachmentCode === "CRYPTO" ? (
                  <Crypto
                    handleSubmit={handleSubmit}
                    cryptoData={cryptoData}
                    setCryptoData={setCryptoData}
                    cryptoList={cryptoList}
                    selectedCrypto={selectedCrypto}
                    setSelectedCrypto={setSelectedCrypto}
                  />
                ) : null}
              </>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
