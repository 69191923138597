import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import "./Attachment/AttachBankAccount.css";
import { GetWalletLinks } from "../../apis/wallet";
import { decrypytdata } from "../../apis/walletKey";
import { setLoader } from "../../store/slices/loaderSlice";

// import CashId from "./CashId";
// import Crypto from "./Crypto";

export default function AttachAll() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader);

  const { data: userData } = useSelector((state) => state.user);

  const { PublicAddress: APPID, PrivateKey: APIKEY } = userData;

  const [attachmentTypes, setAttachmentTypes] = useState([]);
  const [attachId, setAttachId] = useState("ALL");

  // const [isLoading, setIsLoading] = useState(false);

  // console.log(attachmentTypes);

  useEffect(() => {
    getWallets();
  }, []);

  async function getWallets() {
    try {
      dispatch(setLoader(true));
      const response = await GetWalletLinks(APPID, APIKEY);

      const decryptedResponse = await decrypytdata(response?.data, APIKEY);

      const responseData = JSON.parse(decryptedResponse);

      // console.log(responseData);

      if (!responseData.CommandResponse) return;

      const responseAPIData = JSON.parse(responseData.CommandResponse);

      setAttachmentTypes(responseAPIData);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoader(false));
    }
  }

  return (
    <div className="identification">
      <div className="container">
        <div className="header">
          <div>
            <Link
              /* to="/wallet" */ role="button"
              onClick={() => navigate(-1, { replace: true })}
              className="arrow-bg"
            >
              <i className="bi bi-arrow-left-short"></i>
            </Link>
          </div>

          <h3 className="ms-0">Wallet Attachments</h3>

          <div>
            <Link
              to="/attach" role="button"
              /* onClick={() => navigate("/attach")} */
              className="arrow-bg"
            >
              <img
                src="icons\wallet-plus-white.png"
                style={{ width: "25px" }}
              />
            </Link>
          </div>
        </div>

        <div className="create-payee-page">
          <p>Select the type of attachment to display.</p>

          {!isLoading ? (
            <>
              <div className="row mt-4">
                <div className="col-4" onClick={() => setAttachId("ALL")}>
                  <button
                    to="/attach_all"
                    className={`btn-sound payee-box ${
                      attachId === "ALL" ? " active" : ""
                    }`}
                  >
                    <img
                      src="/icons/grid-dark.png"
                      className="dark"
                      alt="dark-icon"
                    />
                    <img
                      src="/icons/grid-light.png"
                      className="light"
                      alt="light-icon"
                    />
                    <h5>All</h5>
                  </button>
                </div>

                <div className="col-4" onClick={() => setAttachId("BANK")}>
                  {/* <button to="/attach_bank" className="payee-box disabled"> */}
                  <button
                    to="/attach_bank"
                    className={`btn-sound payee-box ${
                      attachId === "BANK" ? " active" : ""
                    }`}
                  >
                    <img
                      src="/icons/bank-dark.png"
                      className="dark"
                      alt="dark-icon"
                    />
                    <img
                      src="/icons/bank-light.png"
                      className="light"
                      alt="light-icon"
                    />
                    <h5>Bank Account</h5>
                    {/* <h5>{elm.WalletLink}</h5> */}
                  </button>
                </div>

                <div
                  className="col-4 mb-4"
                  onClick={() => setAttachId("MOBILE")}
                >
                  <button
                    to="/attach_mobile"
                    className={`btn-sound payee-box ${
                      attachId === "MOBILE" ? " active" : ""
                    }`}
                  >
                    {/* <button to="/attach_mobile" className="payee-box disabled"> */}
                    <img
                      src="/icons/mobile-dark.png"
                      className="dark"
                      alt="dark-icon"
                    />
                    <img
                      src="/icons/mobile-light.png"
                      className="light"
                      alt="light-icon"
                    />
                    <h5>Mobile Number</h5>
                    {/* <h5>{elm.WalletLink}</h5> */}
                  </button>
                </div>

                <div
                  className="col-4 mb-4"
                  onClick={() => setAttachId("EMAIL")}
                >
                  <button
                    to="/attach_email"
                    className={`btn-sound payee-box ${
                      attachId === "EMAIL" ? " active" : ""
                    }`}
                  >
                    {/* <button to="/attach_email" className="payee-box disabled"> */}
                    <img
                      src="/icons/email-dark.png"
                      className="dark"
                      alt="dark-icon"
                    />
                    <img
                      src="/icons/email-light.png"
                      className="light"
                      alt="light-icon"
                    />
                    <h5>Email</h5>
                    {/* <h5>{elm.WalletLink}</h5> */}
                  </button>
                </div>

                <div className="col-4" onClick={() => setAttachId("CASHID")}>
                  <button
                    to="/attach_username"
                    className={`btn-sound payee-box ${
                      attachId === "CASHID" ? " active" : ""
                    }`}
                  >
                    <img
                      src="/icons/user-dark.png"
                      className="dark"
                      alt="dark-icon"
                    />
                    <img
                      src="/icons/user-light.png"
                      className="light"
                      alt="light-icon"
                    />
                    <h5>Cash ID</h5>
                    {/* <h5>{elm.WalletLink}</h5> */}
                  </button>
                </div>

                <div className="col-4" onClick={() => setAttachId("CRYPTO")}>
                  <button
                    to="/attach_cryptocurrency"
                    className={`btn-sound payee-box ${
                      attachId === "CRYPTO" ? " active" : ""
                    }`}
                  >
                    <img
                      src="/icons/cryptocurrency-dark.png"
                      className="dark"
                      alt="dark-icon"
                    />
                    <img
                      src="/icons/cryptocurrency-light.png"
                      className="light"
                      alt="light-icon"
                    />
                    <h5>Cryptocurrency</h5>
                    {/* <h5>{elm.WalletLink}</h5> */}
                  </button>
                </div>
              </div>

              {attachmentTypes.find(
                (el) => el.WalletLinkTypecode === attachId || attachId === "ALL"
              ) ? (
                <div className="table-bg table-content mt-5">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Wallet Attchment</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {attachId === "ALL" ? (
                        <>
                          {attachmentTypes.map((el, idx) => {
                            return (
                              <React.Fragment key={idx}>
                                {el.WalletLinkTypecode === "CRYPTO" ? (
                                  <tr>
                                    <td className="border-0">Crypto</td>
                                    <td className="border-0">
                                      {/* <img
                src="/images/crypto/btc.png"
                className="crypto-img me-2"
                alt="img"
              /> */}
                                      {el.WalletLink}
                                    </td>
                                    <td className="text-end border-0">
                                      <Link /* to="/" */ role="button" className="action-btn me-1">
                                        <i className="bi bi-pen"></i>
                                      </Link>
                                      <Link /* to="/" */ role="button" className="action-btn">
                                        <i className="bi bi-trash"></i>
                                      </Link>
                                    </td>
                                  </tr>
                                ) : null}

                                {el.WalletLinkTypecode === "EMAIL" ? (
                                  <tr>
                                    <td className="border-0">Email</td>
                                    <td className="border-0">
                                      {/* <Link to="/" className="email-link"> */}
                                      {el.WalletLink}
                                      {/* </Link> */}
                                    </td>
                                    <td className="text-end border-0">
                                      <Link /* to="/" */ role="button" className="action-btn me-1">
                                        <i className="bi bi-pen"></i>
                                      </Link>
                                      <Link /* to="/" */ role="button" className="action-btn">
                                        <i className="bi bi-trash"></i>
                                      </Link>
                                    </td>
                                  </tr>
                                ) : null}

                                {el.WalletLinkTypecode === "BANK" ? (
                                  <tr>
                                    <td className="border-0">Bank Account</td>
                                    <td className="border-0">2983742857257</td>
                                    <td className="text-end border-0">
                                      <Link /* to="/" */ role="button" className="action-btn me-1">
                                        <i className="bi bi-pen"></i>
                                      </Link>
                                      <Link /* to="/" */ role="button" className="action-btn">
                                        <i className="bi bi-trash"></i>
                                      </Link>
                                    </td>
                                  </tr>
                                ) : null}

                                {el.WalletLinkTypecode === "CASHID" ? (
                                  <tr>
                                    <td className="border-0">Cash ID</td>
                                    <td className="border-0">
                                      {el.WalletLink}
                                    </td>
                                    <td className="text-end border-0">
                                      <Link /* to="/" */ role="button" className="action-btn me-1">
                                        <i className="bi bi-pen"></i>
                                      </Link>
                                      <Link /* to="/" */ role="button" className="action-btn">
                                        <i className="bi bi-trash"></i>
                                      </Link>
                                    </td>
                                  </tr>
                                ) : null}

                                {el.WalletLinkTypecode === "MOBILE" ? (
                                  <tr>
                                    <td className="border-0">Mobile</td>
                                    <td className="border-0">
                                      {el.WalletLink}
                                    </td>
                                    <td className="text-end border-0">
                                      <Link /* to="/" */ role="button" className="action-btn me-1">
                                        <i className="bi bi-pen"></i>
                                      </Link>
                                      <Link /* to="/" */ role="button" className="action-btn">
                                        <i className="bi bi-trash"></i>
                                      </Link>
                                    </td>
                                  </tr>
                                ) : null}
                              </React.Fragment>
                            );
                          })}
                        </>
                      ) : null}

                      {attachId === "CASHID" ? (
                        <tr>
                          <td className="border-0">Cash ID</td>
                          <td className="border-0">
                            {
                              attachmentTypes.find(
                                (el) => el?.WalletLinkTypecode === "CASHID"
                              )?.WalletLink
                            }
                          </td>
                          <td className="text-end border-0">
                            <Link /* to="/" */ role="button" className="action-btn me-1">
                              <i className="bi bi-pen"></i>
                            </Link>
                            <Link /* to="/" */ role="button" className="action-btn">
                              <i className="bi bi-trash"></i>
                            </Link>
                          </td>
                        </tr>
                      ) : null}

                      {attachId === "CRYPTO"
                        ? attachmentTypes
                            .filter(
                              (row) => row.WalletLinkTypecode === "CRYPTO"
                            )
                            .map((row, idx) => {
                              return (
                                <tr key={idx}>
                                  <td className="border-0">Crypto</td>
                                  <td className="border-0">
                                    {/* <img
                            src="/images/crypto/btc.png"
                            className="crypto-img me-2"
                            alt="img"
                          /> */}
                                    {
                                      row.WalletLink
                                      // attachmentTypes.find(
                                      //   (row) =>
                                      // row?.WalletLinkTypecode === "CRYPTO"
                                      // )?.WalletLink
                                    }
                                  </td>
                                  <td className="text-end border-0">
                                    <Link /* to="/" */ role="button" className="action-btn me-1">
                                      <i className="bi bi-pen"></i>
                                    </Link>
                                    <Link /* to="/" */ role="button" className="action-btn">
                                      <i className="bi bi-trash"></i>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })
                        : null}

                      {attachId === "EMAIL" &&
                      attachmentTypes.find(
                        (el) => el?.WalletLinkTypecode === "EMAIL"
                      )?.WalletLink ? (
                        <tr>
                          <td className="border-0">Email</td>
                          <td className="border-0">
                            {
                              attachmentTypes.find(
                                (el) => el?.WalletLinkTypecode === "EMAIL"
                              )?.WalletLink
                            }
                          </td>
                          <td className="text-end border-0">
                            <Link /* to="/" */ role="button" className="action-btn me-1">
                              <i className="bi bi-pen"></i>
                            </Link>
                            <Link /* to="/" */ role="button" className="action-btn">
                              <i className="bi bi-trash"></i>
                            </Link>
                          </td>
                        </tr>
                      ) : null}

                      {attachId === "MOBILE" &&
                      attachmentTypes.find(
                        (el) => el?.WalletLinkTypecode === "MOBILE"
                      )?.WalletLink ? (
                        <tr>
                          <td className="border-0">Phone</td>
                          <td className="border-0">
                            {
                              attachmentTypes.find(
                                (el) => el?.WalletLinkTypecode === "MOBILE"
                              )?.WalletLink
                            }
                          </td>
                          <td className="text-end border-0">
                            <Link /* to="/" */ role="button" className="action-btn me-1">
                              <i className="bi bi-pen"></i>
                            </Link>
                            <Link /* to="/" */ role="button" className="action-btn">
                              <i className="bi bi-trash"></i>
                            </Link>
                          </td>
                        </tr>
                      ) : null}

                      {attachId === "BANK" &&
                      attachmentTypes.find(
                        (el) => el?.WalletLinkTypecode === "BANK"
                      )?.WalletLink ? (
                        <tr>
                          <td className="border-0">BANK</td>
                          <td className="border-0">
                            {
                              attachmentTypes.find(
                                (el) => el?.WalletLinkTypecode === "BANK"
                              )?.WalletLink
                            }
                          </td>
                          <td className="text-end border-0">
                            <Link /* to="/" */ role="button" className="action-btn me-1">
                              <i className="bi bi-pen"></i>
                            </Link>
                            <Link /* to="/" */ role="button" className="action-btn">
                              <i className="bi bi-trash"></i>
                            </Link>
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              ) : (
                <>
                  <h5 className="mt-3 text-center text-white">
                    No Attachments Found
                  </h5>
                </>
              )}
            </>
          ) : null}

          {/* {!isLoading ? (
            <>
              {walletAttachmentCode === "ALL" ? (
                <div className="table-bg table-content">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Wallet Attchment</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border-0">Crypto</td>
                        <td className="border-0">
                          <img
                            src="/images/crypto/btc.png"
                            className="crypto-img me-2"
                            alt="img"
                          />
                          BTC
                        </td>
                        <td className="text-end border-0">
                          <Link to="/" className="action-btn me-1">
                            <i className="bi bi-pen"></i>
                          </Link>
                          <Link to="/" className="action-btn">
                            <i className="bi bi-trash"></i>
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0">Email</td>
                        <td className="border-0">
                          <Link to="/" className="email-link">
                            jobcox@abc.com
                          </Link>
                        </td>
                        <td className="text-end border-0">
                          <Link to="/" className="action-btn me-1">
                            <i className="bi bi-pen"></i>
                          </Link>
                          <Link to="/" className="action-btn">
                            <i className="bi bi-trash"></i>
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td className="border-0">Bank Account</td>
                        <td className="border-0">2983742857257</td>
                        <td className="text-end border-0">
                          <Link to="/" className="action-btn me-1">
                            <i className="bi bi-pen"></i>
                          </Link>
                          <Link to="/" className="action-btn">
                            <i className="bi bi-trash"></i>
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}

              {walletAttachmentCode === "CASHID" ? (
                <CashId
                  cashIdData={cashIdData}
                  setCashIdData={setCashIdData}
                  handleSubmit={handleSubmit}
                />
              ) : null}

              {walletAttachmentCode === "CRYPTO" ? (
                <Crypto
                  setIsLoading={setIsLoading}
                  handleSubmit={handleSubmit}
                  cryptoData={cryptoData}
                  setCryptoData={setCryptoData}
                  cryptoList={cryptoList}
                  selectedCrypto={selectedCrypto}
                  setSelectedCrypto={setSelectedCrypto}
                />
              ) : null}
            </>
          ) : null} */}
        </div>
      </div>
    </div>
  );
}
