import { Link } from "react-router-dom";

export default function HomeHeader() {
  return (
    <div
      className="container-fluid"
      style={{ backgroundColor: "rgb(217, 174, 105)" }}
    >
      <div className="mb-2">
        <div className="text-center">
          <img
            src="/coins/umucoin.png"
            className="img-fluid logo"
            alt=""
            // style={{ width: "250px", height: "250px" }}
          />
        </div>
      </div>

      <div className="">
        <div className="justify-content-center d-flex pb-2 pb-md-3 pb-lg-4">
          <Link to="/" className="menu-list">
            Home
          </Link>
          <a
            href="https://dcma.io"
            className="menu-list px-4"
            target="_blank"
            rel="noreferrer"
          >
            DCMA
          </a>
          <a
            href="https://unicoinnetwork.com"
            target="_blank"
            className="menu-list"
            rel="noreferrer"
          >
            Unicoin Network
          </a>
          <Link className="menu-list ps-4" to="/splash">
            Bank Wallet
          </Link>

          <Link className="menu-list ps-4" to="/whitepaper">
            Whitepaper
          </Link>
        </div>
      </div>
    </div>
  );
}
