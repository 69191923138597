import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./RegistrationPhone.css";
import { UMU_URL } from "../../constants/config";
import { getDialingcodesAPI, getDropdownValues } from "../../apis/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../store/slices/loaderSlice";

export default function RegistrationPhone() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader);

  // const [isLoading, setLoading] = useState(false);

  const [showDropDown, setShowDropDown] = useState(false);

  const [validationError, setValidationError] = useState("");

  const [userProfileTypes, setUserProfileTypes] = useState([]);
  const [userProfileTypecode, setUserProfileTypecode] = useState("");

  const [dialingCodes, setDialingCode] = useState([]);
  const [selectedDialingCode, setSelectedDialingCode] = useState({});

  const [mobilePhone, setMobilePhone] = useState("");

  const [individualData, setIndividualData] = useState({
    firstname: "",
    secondFirstname: "",
    lastname: "",
    secondLastname: "",
    fullname: ""
  });

  const [businessData, setBusinessData] = useState({
    businessname: ""
  });

  useEffect(() => {
    async function main() {
      try {
        dispatch(setLoader(true));
        const response = await axios.get("https://api.ipify.org?format=json");
        // console.log(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setLoader(false));
      }
    }

    main();
  }, []);

  useEffect(() => {
    getUserProfiles();
    getDialingCodes();
  }, []);

  // console.log(selectedDialingCode);

  async function getDialingCodes() {
    try {
      dispatch(setLoader(true));

      const response = await getDialingcodesAPI("GetDialingcodes");

      const mobileData = response.data;

      setDialingCode(() => mobileData);

      setSelectedDialingCode(() => mobileData[0]);
    } catch (err) {
      // console.log(err);
    } finally {
      dispatch(setLoader(false));
    }
  }

  async function getUserProfiles() {
    try {
      dispatch(setLoader(true));

      const response = await getDropdownValues("LKUserProfileType");

      setUserProfileTypes(() => response.data.commandDataset);

      setUserProfileTypecode(() => "Individual");
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setLoader(false));
    }
  }

  function validateIndividualData(data) {
    if (!data.mobilePhone) return "Mobile number is required";
    if (!data.firstname) return "Firstname is required";
    if (!data.lastname) return "Lastname is required";
  }

  function validateBusinessData(data) {
    if (!data.businessname) return "Businessname is required";
    if (!data.mobilePhone) return "Mobile number is required";
  }

  async function handleSubmit() {
    try {
      let data = {
        mobilePhone,
        userProfileTypecode,
        mobileDialingcode: selectedDialingCode.Keycode,
        currencycode: selectedDialingCode.Currencycode,
        mobileCountrycode: selectedDialingCode.Countrycode,
        paymentCurrencycode: "UMU",
        currencycodeList: null
      };

      if (userProfileTypecode === "Individual") {
        const { firstname, secondFirstname, lastname, secondLastname } =
          individualData;

        const fullname = [];

        if (firstname) fullname.push(firstname);
        if (secondFirstname) fullname.push(secondFirstname);
        if (lastname) fullname.push(lastname);
        if (secondLastname) fullname.push(secondLastname);

        data = {
          ...data,
          ...individualData,
          fullname: fullname.join(" ")
        };

        if (!data.secondFirstname) data.secondFirstname = null;
        if (!data.secondLastname) data.secondLastname = null;

        const error = validateIndividualData(data);

        if (error) {
          setValidationError(error);

          return;
        }
      }

      if (userProfileTypecode === "Business") {
        data = { ...data, ...businessData };

        const error = validateBusinessData(data);

        if (error) {
          setValidationError(error);

          return;
        }
      }

      let mobileNumber = {
        mobilePhone: `${data.mobileDialingcode}${data.mobilePhone}`
      };

      dispatch(setLoader(true));

      const response = await axios.post(
        `${UMU_URL}/general/GetMobileVerificationCode`,
        mobileNumber,
        {
          headers: {
            Authorization:
              "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
          }
        }
      );

      // console.log(data);
      if (
        response.data?.returnKeycode === "Success" ||
        response.data?.ReturnKeycode === "Success"
      ) {
        navigate("/mobilecode", { state: { ...data } });
        return;
      } else
        setValidationError(
          response.data?.returnMessage ||
            response.data?.ReturnMessage ||
            "Mobile Number is not valid"
        );
    } catch (err) {
      setValidationError("Mobile Number is not valid");
    } finally {
      dispatch(setLoader(false));
    }
  }

  return (
    <div className="email-address">
      <div className="container">
        <div className="phone-content email-content">
          <h3>Enter your mobile number</h3>
          <p>Make sure your mobile number is active</p>

          <div className="tabs">
            <p
              className={
                userProfileTypecode === "Individual" ? "me-3 active" : "me-3"
              }
              onClick={() => {
                setUserProfileTypecode(() => "Individual");
                setValidationError(() => "");
                setMobilePhone(() => "");
                setIndividualData(() => ({
                  firstname: "",
                  secondFirstname: "",
                  lastname: "",
                  secondLastname: "",
                  fullname: ""
                }));
              }}
            >
              PERSONAL
            </p>
            <p
              className={
                userProfileTypecode === "Business" ? "me-3 active" : "me-3"
              }
              onClick={() => {
                setUserProfileTypecode(() => "Business");
                setMobilePhone(() => "");
                setValidationError(() => "");
                setBusinessData(() => ({ businessname: "" }));
              }}
            >
              BUSINESS
            </p>
          </div>

          {!isLoading && (
            <div>
              {validationError && (
                <p className="mt-3 text-danger">{validationError}</p>
              )}

              <div className="phone-bg mt-4">
                {userProfileTypecode === "Business" && (
                  <input
                    type="text"
                    className="form-control mb-4"
                    placeholder="Business Name"
                    value={businessData.businessname}
                    onChange={(e) => {
                      setBusinessData((prev) => ({
                        ...prev,
                        businessname: e.target.value
                      }));
                    }}
                  />
                )}

                {userProfileTypecode === "Individual" && (
                  <>
                    <input
                      type="text"
                      className="form-control mb-4"
                      placeholder="First Name"
                      value={individualData.firstname}
                      onChange={(e) => {
                        setIndividualData((prev) => ({
                          ...prev,
                          firstname: e.target.value
                        }));
                      }}
                    />

                    <input
                      type="text"
                      className="form-control mb-4"
                      placeholder="Middle Name (Optional)"
                      value={individualData.secondFirstname}
                      onChange={(e) => {
                        setIndividualData((prev) => ({
                          ...prev,
                          secondFirstname: e.target.value
                        }));
                      }}
                    />

                    <input
                      type="text"
                      className="form-control mb-4"
                      placeholder="Last Name"
                      value={individualData.lastname}
                      onChange={(e) => {
                        setIndividualData((prev) => ({
                          ...prev,
                          lastname: e.target.value
                        }));
                      }}
                    />

                    <input
                      type="text"
                      className="form-control mb-4"
                      placeholder="Second Last Name (Optional)"
                      value={individualData.secondLastname}
                      onChange={(e) => {
                        setIndividualData((prev) => ({
                          ...prev,
                          secondLastname: e.target.value
                        }));
                      }}
                    />
                  </>
                )}

                <div className="d-flex ">
                  <div
                    className="me-3 p-2 text-light d-flex justify-content-center align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowDropDown((prev) => !prev)}
                  >
                    <img
                      className="me-1"
                      src={selectedDialingCode.ImageFN?.replace?.(
                        /\/public\//,
                        ""
                      )}
                    />
                    <label>{selectedDialingCode.Keycode}</label>
                  </div>

                  <input
                    placeholder="Mobile Number"
                    className="px-2 text-5 form-control-1 rm-arrow"
                    type="text"
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                    }
                    value={mobilePhone}
                    onChange={(e) => {
                      const number = e.target.value;

                      setValidationError(() => "");

                      setMobilePhone(() => number);
                    }}
                  />
                </div>
              </div>

              {showDropDown && (
                <div className="dropdown-option">
                  {dialingCodes &&
                    dialingCodes.length > 0 &&
                    dialingCodes.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSelectedDialingCode(item);
                            setShowDropDown((prev) => false);
                          }}
                        >
                          <img
                            className="me-2"
                            src={item.ImageFN?.replace?.(/\/public\//, "")}
                          />
                          <span>{item.Displayname}</span>
                        </div>
                      );
                    })}
                </div>
              )}

              <button
                className="btn-sound btn next-btn mt-5"
                to="/mobilecode"
                onClick={handleSubmit}
              >
                NEXT
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
