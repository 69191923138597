// import http from "./httpService";
import axios from "axios";

import { UMU_URL, WEB_WALLET_URL } from "../constants/config";
import { hmacencrypt } from "./walletKey";

export async function GetFXRatesApi(publickey, privateKey, data = {}) {
  try {
    let values = {
      sourcePublicAddress: publickey,
      ...data
      // currencyPaircode: "",
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/money/GetFXRates",
      values,
      publickey,
      privateKey
    );

    const response = await axios.post(`${UMU_URL}/money/GetFXRates`, values, {
      headers: {
        Authorization: token
      }
    });

    // const response = await http.post(
    //   "/money/GetFXRates",
    //   values,

    //   {
    //     headers: {
    //       Authorization: token
    //     }
    //   }
    // );

    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    }

    throw error;
  }
}

export async function getFxRatesApi2(publickey, privateKey, data) {
  try {
    let values = {
      sourcePublicAddress: publickey,
      ...data
    };

    const token = await hmacencrypt(
      "POST",
      UMU_URL + "/general/FetchFXRates",
      values,
      publickey,
      privateKey
    );

    const response = await axios.post(
      `${UMU_URL}/general/FetchFXRates`,
      values,
      {
        headers: {
          Authorization: token
        }
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
}
