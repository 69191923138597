import { useState } from "react";
import { Link } from "react-router-dom";

import "../wallet/Attachment/AttachBankAccount.css";

export default function AddFunds() {
  return (
    <div className="identification">
      <div className="container">
        <div className="header">
          <h3 className="ms-0 pt-3">ADD FUNDS</h3>
        </div>

        <div className="create-payee-page">
          <p>Select the payment method for adding funds to your wallet.</p>

          <div className="row">
            <div className="col-4">
              <Link to="/add_umu_bank" className="payee-box">
                <img
                  src="/icons/bank-dark.png"
                  className="dark"
                  alt="dark-icon"
                />
                <img
                  src="/icons/bank-light.png"
                  className="light"
                  alt="light-icon"
                />
                <h5>Bank Account</h5>
              </Link>
            </div>

            <div className="col-4">
              <Link to="/add_umu_apps" className="payee-box">
                <img
                  src="/icons/app-icon-dark.png"
                  className="dark"
                  alt="dark-icon"
                />
                <img
                  src="/icons/app-icon-light.png"
                  className="light"
                  alt="light-icon"
                />
                <h5>Apps</h5>
              </Link>
            </div>

            <div className="col-4">
              <Link to="/add_umu_crypto" className="payee-box">
                <img
                  src="/icons/cryptocurrency-dark.png"
                  className="dark"
                  alt="dark-icon"
                />
                <img
                  src="/icons/cryptocurrency-light.png"
                  className="light"
                  alt="light-icon"
                />
                <h5>Cryptocurrency</h5>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
