import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UMU_URL, UMU_SITE } from "../../../constants/config";

export default function CashId({ cashIdData, setCashIdData, handleSubmit }) {
  const navigate = useNavigate();

  const [isVerified, setIsVerified] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [popup, setPopup] = useState({
    success: false,
    show: false
  });

  function cashidValidation() {
    if (!cashIdData.cashID) return "CashID is required";
    if (cashIdData.cashID.length < 2 || cashIdData.cashID.length > 16)
      return "CashID should be Minimum 2 Maximum 16 characters long.";
  }

  // Make api call
  async function validateCashId() {
    const error = cashidValidation();
    if (error) {
      setValidationError(error);
      return;
    }

    try {
      const response = await axios.post(
        `${UMU_URL}/general/ValidateCashID`,
        { cashID: cashIdData.cashID },
        {
          headers: {
            Authorization:
              "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
          }
        }
      );

      if (
        response.data.ReturnKeycode === "Success" ||
        response.data.returnKeycode === "Success"
      ) {
        setPopup({ success: true, show: true });
        setIsVerified(true);
      }
    } catch (error) {
      setPopup({ success: false, show: true });
    }
  }

  return (
    <>
      <div className="identification">
        {validationError && <p className="text-danger">{validationError}</p>}
        <div className=" create-payee-page">
          <div className="bank">
            <div className="add">
              <input
                type="text"
                className="form-control mt-4"
                placeholder="Cash ID"
                id="CashIDinput"
                value={cashIdData.cashID}
                onChange={(e) => {
                  setCashIdData((prev) => ({
                    ...prev,
                    cashID: e.target.value
                  }));
                }}
                disabled={isVerified}
              />
              <button
                className="btn-sound add-btn border-0"
                onClick={validateCashId}
              >
                VERIFY
              </button>
            </div>

            <input
              type="text"
              className="form-control mt-4"
              placeholder="Industry or Profession (Optional)"
              value={cashIdData.shortDescription}
              onChange={(e) => {
                setCashIdData((prev) => ({
                  ...prev,
                  shortDescription: e.target.value
                }));
              }}
            />

            <div>
              <textarea
                className="form-control mt-4"
                placeholder="Profile Description (Optional)"
                rows="3"
                value={cashIdData.profileDescription}
                onChange={(e) => {
                  setCashIdData((prev) => ({
                    ...prev,
                    profileDescription: e.target.value
                  }));
                }}
              ></textarea>
            </div>

            <div className="text-center mt-5 d-flex align-items-center">
              <button
                type="button"
                className="btn-sound btn next-btn next-btn-border me-3 mt-0"
                onClick={() => {
                  navigate(-1);
                }}
              >
                CANCEL
              </button>

              <button
                type="button"
                className="btn-sound next-btn mt-0"
                onClick={() => {
                  if (isVerified) {
                    setCashIdData({
                      cashID: cashIdData.cashID?.trim?.(),
                      shortDescription: cashIdData.shortDescription || null,
                      profileDescription: cashIdData.profileDescription || null
                    });
                    handleSubmit();
                    return;
                  }

                  setValidationError("Please verify cash id");
                }}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>

      {popup.show ? (
        <div className="popup-model">
          {popup.show && popup.success ? (
            <div className="modal-box">
              <div className="icons-fixed">
                <img src="/icons/success-icon.png" alt="icon" />
              </div>
              <h2>Your Cash ID</h2>
              <div className="modal-list">
                <h5 style={{ overflowWrap: "break-word", textAlign: "center" }}>
                  {UMU_SITE}/cashID/
                  <br />
                  {cashIdData.cashID}
                </h5>
              </div>
              <div className="d-flex align-items-center w-100 mt-3">
                <button
                  className="btn-sound modal-btn me-3 w-50 justify-content-center"
                  onClick={() => {
                    setPopup({ show: false, success: false });
                  }}
                >
                  CLOSE
                </button>
                <button
                  className="btn-sound modal-btn fill w-50 justify-content-center"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${UMU_SITE}/cashID/${cashIdData.cashID}`
                    );
                    setPopup({ show: false, success: false });
                  }}
                >
                  COPY
                </button>
              </div>
            </div>
          ) : null}

          {popup.show && !popup.success ? (
            <div className="modal-box">
              <div className="icons-fixed">
                <img src="/icons/fail.png" alt="icon" />
              </div>
              <h2>Cash ID is taken</h2>
              <div className="text-center mt-3 mb-5">
                <h6>Try another cash ID</h6>
              </div>
              <div className="d-flex align-items-center w-100 mt-3">
                <button
                  className="btn-sound modal-btn fill w-100 justify-content-center"
                  onClick={() => {
                    setPopup({ show: false, success: false });
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
