import "./TransferReview.css";
import { Link, useNavigate } from "react-router-dom";

export default function ReceiverSummary() {
  const navigate = useNavigate();

  return (
    <div className="email-address p-0 identification">
      <div className="container">
        <div className="header">
          <Link
            /* to="/splash" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>Summary</h3>
        </div>

        <div className="summary-box">
          <div className="summary-box-logo">
            <img src="/images/summary-logo.png" alt="logo" />
          </div>

          <h1 className="text-center">Summary</h1>

          <div className="summary-list mb-3">
            <p>From</p>
            <h5 className=" mb-3">Vavel Mfg</h5>
          </div>

          <div className="d-flex justify-content-between mb-3">
            <div className="summary-list mb-3">
              {/* <p>Payment Amount</p> */}
              <p>Settlement</p>
              <h5>
                {/* <span>$</span> 5,432 */}
                {/* <span>$</span> */} EUR
              </h5>
            </div>
            <div className="summary-list text-end mb-3">
              {/* <p>Payment Currency</p> */}
              <p>Settlement Amount</p>
              {/* <h5>EUR</h5> */}
              <h5>€ 1,800,000.00</h5>
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3">
            <div className="summary-list mb-3">
              {/* <p>Bank Rate</p> */}
              <p>UMU Rate</p>
              <h5>
                {/* <span>$</span> 123.987655 */}
                {/* <span>$</span>  */} 2.4590
              </h5>
            </div>

            <div className="summary-list mb-3 text-end">
              {/* <p>Bank Fee</p> */}
              <p>UMU Amount</p>
              <h5>
                {/* <span>&Uuml;</span> 0.00001 */}
                {/* <span>&Uuml;</span> */} Ü 732,004.880000
              </h5>
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3">
            <div className="summary-list mb-3">
              {/* <p>Recipient Amount</p> */}
              {/* <p>Originating</p> */}
              {/* <p>Local Currency</p> */}
              <p>Wallet</p>
              <h5>
                {/* <span>$</span> 123.987655 */}
                {/* <span>$</span> */} GHS
              </h5>
            </div>

            <div className="summary-list mb-3 text-end">
              {/* <p>Recipient Currency</p> */}
              {/* <p>Originating Amount</p> */}
              {/* <p>Local Amount</p> */}
              <p>Wallet Amount</p>
              {/* <h5>NGN</h5> */}
              <h5>882,000,000.00</h5>
            </div>
          </div>

          <div className="d-flex justify-content-end mb-3">
            {/* <div className="summary-list mb-3">
              <p>UMU Rate</p>
              <h5>
                <span>$</span> 123.987655
              </h5>
            </div> */}

            {/* <div className="summary-list text-end">
              <p>UMU Total Amount</p>
              <h5>
                <span>&Uuml;</span> 5,432.987655
              </h5>
            </div> */}
          </div>
        </div>

        <div className="text-center">
          <Link className="next-btn w-100 mt-4" to="/">
            CONTINUE
          </Link>
        </div>
      </div>
    </div>
  );
}
