import "./Pincode.css";
import { Link, useNavigate } from "react-router-dom";

export default function Pincode() {
  const navigate = useNavigate();

  return (
    <div className="email-address p-0 identification">
      <div className="container">
        <div className="header">
          <Link
            /* to="/splash" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>PIN Code</h3>
        </div>
        <div className="email-content phone-verify-content pin-code">
          <form>
            <div className="d-flex justify-content-evenly">
              <input
                type="password"
                className="form-control-2"
                placeholder=""
              />
              <input
                type="password"
                className="form-control-2"
                placeholder=""
              />
              <input
                type="password"
                className="form-control-2"
                placeholder=""
              />
              <input
                type="password"
                className="form-control-2"
                placeholder=""
              />
            </div>
            <Link to="/email" className="btn next-btn">
              CONTINUE
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}
