import axios from "axios";
import { UMU_URL } from "../constants/config";

export async function resetEmailAPI(email) {
  const response = await axios({
    method: "POST",
    baseURL: UMU_URL,
    url: "/general/ForgotPassword",
    data: { email },
    headers: {
      Authorization:
        "Hmac 91ca2e37-d738-4306-8808-c87cc3d871f9:cKnB6Oyqh+q9I39GZIh2RU1SnXgR154fTTrjzqEaJ0Q=:e485eeee4d4348febcc3354440e76d72:1674628795:https%3A%2F%2Flocalhost%3A44319%2Fgeneral%2Frequestwallet"
    }
  });

  return response;
}
