import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./slices/userSlice";
import errorReducer from "./slices/errorSlice";
import responseReducer from "./slices/responseSlice";
import loaderReducer from "./slices/loaderSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    error: errorReducer,
    response: responseReducer,
    loader: loaderReducer
  }
});

export default store;
