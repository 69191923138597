import { useEffect } from "react";
// import { getTokenPrice, getTokenSupply } from "../apis/token";
import { useDispatch, useSelector } from "react-redux";
import { decrypytdata } from "../apis/walletKey";
import { setResponse } from "../store/slices/responseSlice";
import { useNavigate } from "react-router-dom";
import { getBalances } from "../apis/termPurchase";

export default function TestCommand() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userData } = useSelector((state) => state.user);

  const { PublicAddress: APPID, PrivateKey: APIKEY } = userData;

  useEffect(() => {
    async function main() {
      try {
        const modifyWalletResponse = await getBalances(APPID, APIKEY);
        const decryptedResponse = await decrypytdata(
          modifyWalletResponse.data,
          APIKEY
        );

        const response = JSON.parse(decryptedResponse);

        // console.log(response);

        // const responseAPI = {
        //   ...modifyWalletResponse,
        //   headers: undefined,
        //   request: undefined,
        //   config: {
        //     baseURL: modifyWalletResponse.config.baseURL,
        //     url: modifyWalletResponse.config.url
        //   }
        // };

        console.log({
          ...response,
          CommandResponse: JSON.parse(response.CommandResponse)
        });

        // dispatch(setResponse({ apiResponse: responseAPI, apiData: response }));

        // navigate("/result");
      } catch (error) {
        console.error(error);
      }
    }

    main();
  }, []);

  return <></>;
}
