import axios from "axios";

import { ADMIN_URL } from "../constants/config";

export async function getDropdownValues(type) {
  try {
    let dataDictionary = await axios.post(
      ADMIN_URL + "/common/GetLookupTable",
      {
        lookupTable: type
      }
    );

    return dataDictionary;
  } catch (err) {
    throw err;
  }
}

export async function getDialingcodesAPI(type) {
  try {
    let response = await axios.post(ADMIN_URL + "/common/GetDialingcodes", {
      LookupTable: type
    });

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getCurrencycodesAPI(type) {
  let dataDictionary = await axios.post(
    ADMIN_URL + "/common/GetCurrencycodes",
    {
      LookupTable: type
    }
  );
  return dataDictionary.data;
}
