import { Link, useNavigate } from "react-router-dom";

import "./AttachBankAccount.css";

export default function AttachBankAccount() {
  const navigate = useNavigate();

  return (
    <div className="identification">
      <div className="container">
        <div className="header">
          <Link
            /* to="/splash" */ role="button"
            onClick={() => navigate(-1, { replace: true })}
            className="arrow-bg"
          >
            <i className="bi bi-arrow-left-short"></i>
          </Link>
          <h3>Wallet Attachment</h3>
        </div>
        <div className="create-payee-page">
          <p>Select the type of attachment to display.</p>
          <div className="row">
            {/*<div className="col-4">*/}
            {/*    <Link to="/attach_all" className="payee-box">*/}
            {/*        <img*/}
            {/*            src="/icons/grid-dark.png"*/}
            {/*            className="dark"*/}
            {/*            alt="dark-icon"*/}
            {/*        />*/}
            {/*        <img*/}
            {/*            src="/icons/grid-light.png"*/}
            {/*            className="light"*/}
            {/*            alt="light-icon"*/}
            {/*        />*/}
            {/*        <h5>All</h5>*/}
            {/*    </Link>*/}
            {/*</div>*/}
            <div className="col-4">
              <Link to="/attach_bank" className="payee-box active  ">
                <img
                  src="/icons/bank-dark.png"
                  className="dark"
                  alt="dark-icon"
                />
                <img
                  src="/icons/bank-light.png"
                  className="light"
                  alt="light-icon"
                />
                <h5>Bank Account</h5>
              </Link>
            </div>
            <div className="col-4 mb-4">
              <Link to="/attach_mobile" className="payee-box">
                <img
                  src="/icons/mobile-dark.png"
                  className="dark"
                  alt="dark-icon"
                />
                <img
                  src="/icons/mobile-light.png"
                  className="light"
                  alt="light-icon"
                />
                <h5>Mobile Number</h5>
              </Link>
            </div>
            <div className="col-4 mb-4">
              <Link to="/attach_email" className="payee-box">
                <img
                  src="/icons/email-dark.png"
                  className="dark"
                  alt="dark-icon"
                />
                <img
                  src="/icons/email-light.png"
                  className="light"
                  alt="light-icon"
                />
                <h5>Email</h5>
              </Link>
            </div>
            <div className="col-4">
              <Link to="/attach_username" className="payee-box">
                <img
                  src="/icons/user-dark.png"
                  className="dark"
                  alt="dark-icon"
                />
                <img
                  src="/icons/user-light.png"
                  className="light"
                  alt="light-icon"
                />
                <h5>Username</h5>
              </Link>
            </div>
            <div className="col-4">
              <Link to="/attach_cryptocurrency" className="payee-box">
                <img
                  src="/icons/cryptocurrency-dark.png"
                  className="dark"
                  alt="dark-icon"
                />
                <img
                  src="/icons/cryptocurrency-light.png"
                  className="light"
                  alt="light-icon"
                />
                <h5>Cryptocurrency</h5>
              </Link>
            </div>
          </div>
          <input
            type="text"
            className="form-control mt-4"
            placeholder="Swift Code"
          />
          <input
            type="text"
            className="form-control mt-3"
            placeholder="Routing Number"
          />
          <input
            type="text"
            className="form-control mt-3"
            placeholder="Account Number"
          />
          <div className="d-flex align-items-center mt-5">
            <Link to="/" className="btn next-btn next-btn-border me-3 mt-0">
              CANCEL
            </Link>
            <Link to="/" className="btn next-btn mt-0">
              SUBMIT
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
