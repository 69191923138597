import React from "react";

const CommandResultFailure = ({ state }) => {
  const initialValues = {
    NodeNetwork: state.response.apiResponse?.config?.baseURL || "NULL",
    EndPoint: state.response.apiResponse?.config?.url || "NULL",
    NetworkResponseCode: state.response.apiResponse?.status || "NULL",
    NetworkResponseMessage: state.response.apiResponse?.status,

    Transaction:
      state.response.apiData?.TransactionHash ||
      state.response.apiData?.transactionHash,
    CreationDate:
      state.response.apiData?.CreationDate ||
      state.response.apiData?.creationDate ||
      "NULL",
    ElapsedTime:
      state.response.apiData?.ElapsedTime ||
      state.response.apiData?.elapsedTime ||
      "NULL",
    ReturnMessage:
      state.response.apiData?.ReturnMessage ||
      state.response.apiData?.returnMessage ||
      "NULL",
    ReturnKeycode:
      state.response.apiData?.ReturnKeycode ||
      state.response.apiData?.returnKeycode ||
      "NULL",
    ReturnCode:
      state.response.apiData?.Returncode ||
      state.response.apiData?.returncode ||
      "NULL",

    PublicAddress: state.user.data?.PublicAddress
      ? state.user.data?.PublicAddress
      : "NULL",
    PrivateKey: state.user.data?.PrivateKey
      ? state.user.data?.PrivateKey
      : "NULL"
  };

  // console.log(initialValues);

  return (
    <div className="transaction-page">
      <div className="row g-0">
        <div className="col-12 identification mb-4">
          <div className="header">
            <h3 className="ms-0 mt-3">Transaction Result</h3>
          </div>
        </div>

        <div className="col-12">
          <div className="heading">
            <div className="money-heading">
              <h3>Transaction</h3>
              <p>{initialValues?.Transaction}</p>
            </div>
            {/* <i className="bi bi-pencil-square"></i> */}
          </div>
          <hr className="" />
        </div>

        <div className="col-12">
          {initialValues?.NodeNetwork && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Node Network <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>{initialValues.NodeNetwork}</p>
              </div>
            </>
          )}
          {initialValues?.EndPoint && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  End Point <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>{initialValues?.EndPoint}</p>
              </div>
            </>
          )}
          {initialValues?.NetworkResponseCode && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Network Response Code
                  <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>{initialValues?.NetworkResponseCode}</p>
              </div>
            </>
          )}
          {initialValues?.NetworkResponseMessage && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Network Response Message
                  <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                {/* {resultDatas.resultReducer.data.returnMessage} */}
                {initialValues?.NetworkResponseMessage === 511 && (
                  <p>Network Authentication Required</p>
                )}
                {initialValues?.NetworkResponseMessage === 401 && (
                  <p>Authentication Failure</p>
                )}
                {initialValues?.NetworkResponseMessage === 403 && (
                  <p>Authorization Failure</p>
                )}
                {initialValues?.NetworkResponseMessage === 404 && (
                  <p>Not Found</p>
                )}
                {initialValues?.NetworkResponseMessage === 400 && (
                  <p>Bad Request</p>
                )}
                {initialValues?.NetworkResponseMessage === 500 && (
                  <p>Unknown Error </p>
                )}
              </div>
              {/* <hr className="mt-4" /> */}
            </>
          )}
          {initialValues?.CreationDate && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Creation Date <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultDatas.EncryptDataReducer.data?.data.Returncode} */}
                  {initialValues?.CreationDate}
                </p>
              </div>
            </>
          )}
          {initialValues?.ElapsedTime && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Elapsed Time <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultDatas.EncryptDataReducer.data?.data.Returncode} */}
                  {initialValues?.ElapsedTime}
                </p>
              </div>
              <hr className="mt-4" />
            </>
          )}
        </div>

        <div className="col-12">
          {initialValues?.PublicAddress && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Public Address <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>{initialValues?.PublicAddress}</p>
              </div>
            </>
          )}

          {initialValues?.ReturnMessage && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Return Message <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultDatas.EncryptDataReducer.data?.data.ReturnMessage} */}
                  {initialValues?.ReturnMessage}
                </p>
              </div>
            </>
          )}
          {initialValues?.ReturnKeycode && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Return Keycode <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultDatas.EncryptDataReducer.data?.data.ReturnKeycode} */}
                  {initialValues?.ReturnKeycode}
                </p>
              </div>
            </>
          )}
          {initialValues?.ReturnCode && (
            <>
              <div className="money-transaction-icon">
                <h3>
                  Return Code <i className="fa fa-circle-question me-2"></i>
                </h3>
              </div>
              <div className="money-transaction-text">
                <p>
                  {/* {resultDatas.EncryptDataReducer.data?.data.Returncode} */}
                  {initialValues?.ReturnCode}
                </p>
              </div>
              {/* <hr className="mt-4" /> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommandResultFailure;
