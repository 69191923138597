import { createSlice } from "@reduxjs/toolkit";

const errorSlice = createSlice({
  name: "error",
  initialState: {
    data: null
  },
  reducers: {
    setError: (state, action) => {
      state.data = action.payload;
    }
  }
});

export default errorSlice.reducer;

export const { setError } = errorSlice.actions;
